export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAIL = "GET_USER_LIST_FAIL";

export const CLEAR_USER_LIST = "CLEAR_USER_LIST";

export const GET_CONTRACTOR_LIST = "GET_CONTRACTOR_LIST";
export const GET_CONTRACTOR_LIST_SUCCESS = "GET_CONTRACTOR_LIST_SUCCESS";
export const GET_CONTRACTOR_LIST_FAIL = "GET_CONTRACTOR_LIST_FAIL";

export const GET_STUDENT_LIST = "GET_STUDENT_LIST";
export const GET_STUDENT_LIST_SUCCESS = "GET_STUDENT_LIST_SUCCESS";
export const GET_STUDENT_LIST_FAIL = "GET_STUDENT_LIST_FAIL";

export const FETCH_ADMIN_COMMON_DATA = "FETCH_ADMIN_COMMON_DATA";
export const FETCH_ADMIN_COMMON_DATA_SUCCESS = "FETCH_ADMIN_COMMON_DATA_SUCCESS";
export const FETCH_ADMIN_COMMON_DATA_FAIL = "FETCH_ADMIN_COMMON_DATA_FAIL";

export const FETCH_ADMIN_STATES = "FETCH_ADMIN_STATES";
export const FETCH_ADMIN_STATES_SUCCESS = "FETCH_ADMIN_STATES_SUCCESS";
export const FETCH_ADMIN_STATES_FAIL = "FETCH_ADMIN_STATES_FAIL";

export const FETCH_METRO_AREAS = "FETCH_METRO_AREAS";
export const FETCH_METRO_AREAS_SUCCESS = "FETCH_METRO_AREAS_SUCCESS";
export const FETCH_METRO_AREAS_FAIL = "FETCH_METRO_AREAS_FAIL";

export const REQUEST_CHANGE_USER_STATUS = "REQUEST_CHANGE_USER_STATUS";
export const REQUEST_CHANGE_USER_STATUS_SUCCESS = "REQUEST_CHANGE_USER_STATUS_SUCCESS";
export const REQUEST_CHANGE_USER_STATUS_FAIL = "REQUEST_CHANGE_USER_STATUS_FAIL";

export const GET_RATING_SUMMARY = "GET_RATING_SUMMARY";
export const GET_RATING_SUMMARY_SUCCESS = "GET_RATING_SUMMARY_SUCCESS";
export const GET_RATING_SUMMARY_FAIL = "GET_RATING_SUMMARY_FAIL";

export const GET_HOSPITAL_LIST = "GET_HOSPITAL_LIST";
export const GET_HOSPITAL_LIST_SUCCESS = "GET_HOSPITAL_LIST_SUCCESS";
export const GET_HOSPITAL_LIST_FAIL = "GET_HOSPITAL_LIST_FAIL";

export const FETCH_STUDENT_DATA = "FETCH_STUDENT_DATA";
export const FETCH_STUDENT_DATA_SUCCESS = "FETCH_STUDENT_DATA_SUCCESS";
export const FETCH_STUDENT_DATA_FAIL = "FETCH_STUDENT_DATA_FAIL";

export const GET_ROO_ADMIN_LIST = "GET_ROO_ADMIN_LIST";
export const GET_ROO_ADMIN_LIST_SUCCESS = "GET_ROO_ADMIN_LIST_SUCCESS";
export const GET_ROO_ADMIN_LIST_FAIL = "GET_ROO_ADMIN_LIST_FAIL";

export const GET_ROO_SUPER_USER_LIST = "GET_ROO_SUPER_USER_LIST";
export const GET_ROO_SUPER_USER_LIST_SUCCESS = "GET_ROO_SUPER_USER_LIST_SUCCESS";
export const GET_ROO_SUPER_USER_LIST_FAIL = "GET_ROO_SUPER_USER_LIST_FAIL";

export const CREATE_ROO_INTERNAL_USER = "CREATE_ROO_INTERNAL_USER";
export const CREATE_ROO_INTERNAL_USER_SUCCESS = "CREATE_ROO_INTERNAL_USER_SUCCESS";
export const CREATE_ROO_INTERNAL_USER_FAIL = "CREATE_ROO_INTERNAL_USER_FAIL";

export const DELETE_ROO_INTERNAL_USER = "DELETE_ROO_INTERNAL_USER";
export const DELETE_ROO_INTERNAL_USER_SUCCESS = "DELETE_ROO_INTERNAL_USER_SUCCESS";
export const DELETE_ROO_INTERNAL_USER_FAIL = "DELETE_ROO_INTERNAL_USER_FAIL";

export const FETCH_HOSPITAL_DATA = "FETCH_HOSPITAL_DATA";
export const FETCH_HOSPITAL_DATA_SUCCESS = "FETCH_HOSPITAL_DATA_SUCCESS";
export const FETCH_HOSPITAL_DATA_FAIL = "FETCH_HOSPITAL_DATA_FAIL";

export const UPDATE_HOSPITAL_CONTACT = "UPDATE_HOSPITAL_CONTACT";
export const UPDATE_HOSPITAL_CONTACT_SUCCESS = "UPDATE_HOSPITAL_CONTACT_SUCCESS";
export const UPDATE_HOSPITAL_CONTACT_FAIL = "UPDATE_HOSPITAL_CONTACT_FAIL";

export const RESET_RATINNGS_SUMMARY = "RESET_RATINNGS_SUMMARY";

export const ADMIN_SET_HOSPITAL_OFFILNE_PAYMENT = "ADMIN_SET_HOSPITAL_OFFILNE_PAYMENT";
export const ADMIN_SET_HOSPITAL_OFFILNE_PAYMENT_SUCCESS =
  "ADMIN_SET_HOSPITAL_OFFILNE_PAYMENT_SUCCESS";
export const ADMIN_SET_HOSPITAL_OFFILNE_PAYMENT_FAIL = "ADMIN_SET_HOSPITAL_OFFILNE_PAYMENT_FAIL";

export const UDPATE_TECH_TIER_REQUEST = "UDPATE_TECH_TIER_REQUEST";
export const UDPATE_TECH_TIER_SUCCESS = "UDPATE_TECH_TIER__SUCCESS";
export const UDPATE_TECH_TIER_FAIL = "UDPATE_TECH_TIER_FAIL";

export const INITIALIZE_FLAG = "INITIALIZE_FLAG";

export const FETCH_ADMIN_TECH_PROFILE_DATA_REQUEST = "FETCH_ADMIN_TECH_PROFILE_DATA_REQUEST";
export const FETCH_ADMIN_TECH_PROFILE_DATA_SUCCESS = "FETCH_ADMIN_TECH_PROFILE_DATA_SUCCESS";
export const FETCH_ADMIN_TECH_PROFILE_DATA_FAIL = "FETCH_ADMIN_TECH_PROFILE_DATA_FAIL";

export const UDPATE_TECH_PROFILE_DATA_REQUEST = "UDPATE_TECH_PROFILE_DATA_REQUEST";
export const UDPATE_TECH_PROFILE_DATA_SUCCESS = "UDPATE_TECH_PROFILE_DATA_SUCCESS";
export const UDPATE_TECH_PROFILE_DATA_FAIL = "UDPATE_TECH_PROFILE_DATA_FAIL";

export const RESET_TECH_PLAID_MD_STATUS_SUCCESS = "RESET_TECH_PLAID_MD_STATUS_SUCCESS";

export const UDPATE_TECH_PROFILE_PHOTO_REQUEST = "UDPATE_TECH_PROFILE_PHOTO_REQUEST";
export const UDPATE_TECH_PROFILE_PHOTO_SUCCESS = "UDPATE_TECH_PROFILE_PHOTO_SUCCESS";
export const UDPATE_TECH_PROFILE_PHOTO_FAIL = "UDPATE_TECH_PROFILE_PHOTO_FAIL";

export const ADMIN_UPDATE_HOSPITAL_DELAY_PAYMENT_SETTING_REQUEST =
  "ADMIN_UPDATE_HOSPITAL_DELAY_PAYMENT_SETTING_REQUEST";
export const ADMIN_UPDATE_HOSPITAL_DELAY_PAYMENT_SETTING_SUCCESS =
  "ADMIN_UPDATE_HOSPITAL_DELAY_PAYMENT_SETTING_SUCCESS";
export const ADMIN_UPDATE_HOSPITAL_DELAY_PAYMENT_SETTING_FAIL =
  "ADMIN_UPDATE_HOSPITAL_DELAY_PAYMENT_SETTING_FAIL";

export const ADMIN_UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_REQUEST =
  "ADMIN_UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_REQUEST";
export const ADMIN_UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_SUCCESS =
  "ADMIN_UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_SUCCESS";
export const ADMIN_UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_FAIL =
  "ADMIN_UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_FAIL";

export const UDPATE_TECH_PROB_STATUS_REQUEST = "UDPATE_TECH_PROB_STATUS_REQUEST";
export const UDPATE_TECH_PROB_STATUS_SUCCESS = "UDPATE_TECH_PROB_STATUS_SUCCESS";
export const UDPATE_TECH_PROB_STATUS_FAIL = "UDPATE_TECH_PROB_STATUS_FAIL";

export const SET_UNSPENDED_DATE_REQUEST = "SET_UNSPENDED_DATE_REQUEST";
export const SET_UNSPENDED_DATE_SUCCESS = "SET_UNSPENDED_DATE_SUCCESS";
export const SET_UNSPENDED_DATE_FAIL = "SET_UNSPENDED_DATE_FAIL";

export const DELETE_HOSPITAL = "DELETE_HOSPITAL";
export const DELETE_HOSPITAL_SUCCESS = "DELETE_HOSPITAL_SUCCESS";
export const DELETE_HOSPITAL_FAIL = "DELETE_HOSPITAL_FAIL";

export const DELETE_CONTRACTOR = "DELETE_CONTRACTOR";
export const DELETE_CONTRACTOR_SUCCESS = "DELETE_CONTRACTOR_SUCCESS";
export const DELETE_CONTRACTOR_FAIL = "DELETE_CONTRACTOR_FAIL";

export const DELETE_STUDENT = "DELETE_STUDENT";
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const DELETE_STUDENT_FAIL = "DELETE_STUDENT_FAIL";

export const FILE_DOWNLOAD_SUCCESS = "FILE_DOWNLOAD_SUCCESS";
export const FILE_DOWNLOAD_FAILED = "FILE_DOWNLOAD_FAILED";
export const FILE_DOWNLOAD = "FILE_DOWNLOAD";
export const FILE_REMOVE = "FILE_REMOVE";

export const SET_REPUTATION_LOCATION_FAVORABILITY = "SET_REPUTATION_LOCATION_FAVORABILITY";
export const SET_REPUTATION_LOCATION_FAVORABILITY_SUCCESS =
  "SET_REPUTATION_LOCATION_FAVORABILITY_SUCCESS";

export const VALIDATE_ADDRESS = "VALIDATE_ADDRESS";
export const SHOW_INVALID_ADRESS_MODAL = "SHOW_INVALID_ADRESS_MODAL";

export const GET_VET_TECH_BY_HOSPITAL_ID = "GET_VET_TECH_BY_HOSPITAL_ID";
export const GET_VET_TECH_BY_HOSPITAL_ID_SUCCESS = "GET_VET_TECH_BY_HOSPITAL_ID_SUCCESS";
export const GET_VET_TECH_BY_HOSPITAL_ID_ERROR = "GET_VET_TECH_BY_HOSPITAL_ID_ERROR";
export const CLEAR_VET_TECH_BY_HOSPITAL_ID = "CLEAR_VET_TECH_BY_HOSPITAL_ID";
export const SUBMIT_FULLTIME_PLACEMENT = "SUBMIT_FULLTIME_PLACEMENT";
export const SUBMIT_FULLTIME_PLACEMENT_SUCCESS = "SUBMIT_FULLTIME_PLACEMENT_SUCCESS";
export const SUBMIT_FULLTIME_PLACEMENT_ERROR = "SUBMIT_FULLTIME_PLACEMENT_ERROR";
export const GET_FULLTIME_PLACEMENT_LOGS = "GET_FULLTIME_PLACEMENT_LOGS";
export const GET_FULLTIME_PLACEMENT_LOGS_SUCCESS = "GET_FULLTIME_PLACEMENT_LOGS_SUCCESS";
export const GET_FULLTIME_PLACEMENT_LOGS_ERROR = "GET_FULLTIME_PLACEMENT_LOGS_ERROR";
export const UPDATE_PLACEMENT_PAYMENT_STATUS = "UPDATE_PLACEMENT_PAYMENT_STATUS";
export const UPDATE_PLACEMENT_PAYMENT_STATUS_SUCCESS = "UPDATE_PLACEMENT_PAYMENT_STATUS_SUCCESS";
export const UPDATE_PLACEMENT_PAYMENT_STATUS_ERROR = "UPDATE_PLACEMENT_PAYMENT_STATUS_ERROR";

export const UPDATE_STRIPE_CONNECT_ACCOUNT_ID = "UPDATE_STRIPE_CONNECT_ACCOUNT_ID";
export const UPDATE_STRIPE_CONNECT_ACCOUNT_ID_SUCCESS = "UPDATE_STRIPE_CONNECT_ACCOUNT_ID_SUCCESS";
export const UPDATE_STRIPE_CONNECT_ACCOUNT_ID_FAIL = "UPDATE_STRIPE_CONNECT_ACCOUNT_ID_FAIL";
