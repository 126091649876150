import React from "react";
import { Input } from "@RooUI";

type InputProps = React.ComponentProps<typeof Input>;
type PhoneInputProps = Omit<InputProps, "onChange"> & {
  onChange: (phone: string) => void;
};

export const PhoneInput = (props: PhoneInputProps) => {
  const mobileMask = (value: InputProps["value"]) => {
    if (!value) {
      return "";
    }
    // Remove non-numeric characters
    const numbersOnly = value.toString().replace(/\D/g, "");

    // Format the number
    let formattedValue = "";
    for (let i = 0; i < numbersOnly.length; i++) {
      if (i === 0) {
        formattedValue += "(" + numbersOnly[i];
      } else if (i === 3) {
        formattedValue += ") " + numbersOnly[i];
      } else if (i === 6) {
        formattedValue += "-" + numbersOnly[i];
      } else {
        formattedValue += numbersOnly[i];
      }
    }
    return formattedValue;
  };

  return (
    <Input
      {...props}
      inputMode="numeric"
      maxLength={14} // Maximum length of formatted phone number
      value={mobileMask(props.value)}
      onChange={(e) => {
        // Extract only digits from the input value
        if (props.onChange) {
          const rawPhoneNumber = e.target.value.replace(/\D/g, "");
          props.onChange(rawPhoneNumber);
        }
      }}
    />
  );
};
