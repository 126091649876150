import { POST } from "@roo-dev/roo-node-types";

import * as C from "../../constants/vetConstants";

export function searchShiftRequestAction(
  value: POST.Vet.Shift.SearchShiftsRequestBody,
  isShiftPreview?: boolean
) {
  return {
    type: C.SEARCH_SHIFT_REQUEST,
    payload: { value, isShiftPreview },
  };
}

export function getLinkedShiftAction(shiftGroupId) {
  return {
    type: C.GET_LINKED_SHIFT_REQUEST,
    payload: shiftGroupId,
  };
}

export function setCalendarViewAction() {
  return {
    type: C.SET_VET_DASHBOARD_CALENDAR_VIEW,
  };
}

export function setGridViewAction() {
  return {
    type: C.SET_VET_DASHBOARD_GRID_VIEW,
  };
}

export function setMapViewAction() {
  return {
    type: C.SET_VET_DASHBOARD_MAP_VIEW,
  };
}

export function vetRequestShiftRequestAction(
  value,
  shiftData = null,
  availableShifts = null,
  isFromAllDone = false,
  flexStartTimeRange = null,
  isFlexibleSurgery = 0,
  isInstantBook = false
) {
  return {
    type: C.VET_REQUEST_SHIFT_REQUEST,
    payload: value,
    shiftData: shiftData,
    availableShifts: availableShifts,
    isFromAllDone: isFromAllDone,
    flexStartTimeRange: flexStartTimeRange,
    isFlexibleSurgery: isFlexibleSurgery,
    isInstantBook,
  };
}

export function initializeVetDashboardStateRequestAction() {
  return {
    type: C.INITIALIZE_VET_DASHBOARD,
  };
}

export function showFirstTimerModalRequestAction(value, requestFrom = null) {
  return {
    type: C.SHOW_FIRST_TIMER_MODAL,
    payload: value,
    requestFrom: requestFrom,
  };
}

export function updateShowPaymentInfoModal(value) {
  return {
    type: C.UPDATE_SHOW_PAYMENT_INFO_MODAL,
    payload: value,
  };
}

export function showFirstTimerModalNoSkip() {
  return {
    type: C.SHOW_FIRST_TIMER_MODAL_NO_SKIP,
  };
}

export function hideFirstTimerModalRequestAction() {
  return {
    type: C.HIDE_FIRST_TIMER_MODAL,
  };
}

export function fetchVetFirstTimerInfo() {
  return {
    type: C.FETCH_VET_FIRST_TIMER_INFO_REQUEST,
  };
}

export function updateVetFirstTimerInfoRequestAction(data) {
  return {
    type: C.UPDATE_VET_FIRST_TIMER_INFO_REQUEST,
    payload: data,
  };
}

export function getPendingRatingsModal(data) {
  return {
    type: C.GET_PENDING_VET_RATINGS_REQUEST,
    payload: data,
  };
}

export function closeRatingModal() {
  return {
    type: C.GET_PENDING_VET_RATINGS_FAIL,
  };
}

export function hideIncompletLicenseModal() {
  return {
    type: C.HIDE_INCOMPLETE_LICENSE_MODAL,
  };
}

export function showShiftNotAvailableModal(data) {
  return {
    type: C.VET_REQUEST_SHIFT_NOT_AVAILABLE,
    payload: data,
  };
}

export function checkVetLicenses(data) {
  return {
    type: C.CHECK_VET_LICENSE_REQUEST,
    payload: data,
  };
}

export function closeShiftNotAvailableModal() {
  return {
    type: C.CLOSE_SHIFT_NOT_AVAILABLE_MODAL,
  };
}

export function submitVetW9(data) {
  return {
    type: C.SUBMIT_VET_W9_REQUEST,
    payload: data,
  };
}

export function hideW9Error() {
  return {
    type: C.HIDE_VET_W9_ERROR,
  };
}

export function saveSearchShiftParameters(data) {
  return {
    type: C.SAVE_SEARCH_SHIFT_PARAMETERS,
    payload: data,
  };
}

export function saveSharedSearch(data) {
  return {
    type: C.SAVE_SHARED_SEARCH,
    payload: data,
  };
}

export function getMyShiftsAction(data) {
  return {
    type: C.GET_ALL_MY_SHIFTS_REQUEST,
    payload: data,
  };
}

export function initializeSearchResult() {
  return {
    type: C.INITIALIZE_SEARCH_RESULTS,
  };
}

export function initializeVetLicenseExpiring() {
  return {
    type: C.INITIALIZE_VET_LICENSE_EXPIRING,
  };
}

export function checkVetLicenseExpiredAction() {
  return {
    type: C.CHECK_VET_LICENSE_EXPIRED_REQUEST,
  };
}

export function openLicenseExpiredModalAction() {
  return {
    type: C.OPEN_VET_LICENSE_EXPIRED_MODAL,
  };
}

export function closeLicenseExpiredModalAction() {
  return {
    type: C.CLOSE_VET_LICENSE_EXPIRED_MODAL,
  };
}

export function intializeSearchClickAction() {
  return {
    type: C.INITIALIZE_SEARCH_CLICK,
  };
}

export const getShiftDetailsAction = (shiftGroupId) => {
  return {
    type: C.FETCH_VET_SHIFT_DETAILS_REQUEST,
    shiftGroupId: shiftGroupId,
  };
};

export function fetchCommonDataAction(data) {
  return {
    type: C.FETCH_COMMON_DATA_REQUEST,
    payload: data,
  };
}

export function fetchCalculateShiftPricingAction(data) {
  return {
    type: C.FETCH_CALCULATE_SHIFT_PRICING,
    payload: data,
  };
}
export function getEventForVetAction(data) {
  return {
    type: C.GET_EVENT_FOR_VET,
    payload: data,
  };
}

export function getVetPaymentInfo() {
  return {
    type: C.GET_VET_PAYMENT_INFO,
  };
}

export function updateVetDetailsPaymentInfo(data) {
  return {
    type: C.UPDATE_VET_DETAILS_PAYMENT_INFO,
    payload: data,
  };
}

export function resetVetDetailsPaymentInfo() {
  return { type: C.RESET_VET_DETAILS_PAYMENT_INFO };
}

export function createVetPromoTracking(data) {
  return {
    type: C.CREATE_VET_PROMO_TRACKING,
    payload: data,
  };
}

export function clearVetPromoTrackingData() {
  return {
    type: C.CLEAR_VET_PROMO_TRACKING_DATA,
  };
}

export function getVetActivePromosByUserId(data) {
  return {
    type: C.GET_VET_PROMO_TRACKING_BY_USER_ID,
    payload: data,
  };
}

export function getVetCompletedPromos(data) {
  return {
    type: C.GET_VET_COMPLETED_PROMOS,
    payload: data,
  };
}

export function getVetBadgesAction(value) {
  return {
    type: C.GET_VET_BADGES,
    payload: value,
  };
}

export function getVetConfirmedShiftData(data) {
  return {
    type: C.GET_VET_CONFIRMED_SHIFT_DATA,
    payload: data,
  };
}

export function vetSkipW9Modal(data) {
  return {
    type: C.VET_SKIP_W9_MODAL,
    payload: data,
  };
}

export function deferHospitalRating(data) {
  return {
    type: C.DEFER_HOSPITAL_RATING,
    payload: data,
  };
}

export function deferAllHospitalRatings(data) {
  return {
    type: C.DEFER_ALL_HOSPITAL_RATINGS,
    payload: data,
  };
}

export function saveShiftTotalCount(data) {
  return {
    type: C.SAVE_SHIFT_COUNT,
    payload: data,
  };
}

export function setDashboardView(data) {
  return {
    type: C.SET_DASHBOARD_VIEW,
    payload: data,
  };
}

export function clearDashboardView() {
  return {
    type: C.CLEAR_DASHBOARD_VIEW,
  };
}

export function getNonRequestSurveyEligibility(data) {
  return {
    type: C.GET_NON_REQUEST_SURVEY_ELIGIBILITY,
    payload: data,
  };
}

export function submitVetSurvey(data) {
  return {
    type: C.VET_SUBMIT_SURVEY,
    payload: data,
  };
}

export function setIsInitialMapSortComplete() {
  return {
    type: C.SET_IS_INITIAL_MAP_SORT_COMPLETE,
  };
}

export function getVetPromoShifts(data) {
  return {
    type: C.GET_VET_PROMO_SHIFTS,
    payload: data,
  };
}

export function toggleVetPromoShiftPanel(data) {
  return {
    type: C.TOGGLE_VET_PROMO_SHIFTS_PANEL,
    payload: data,
  };
}

export function deleteVetShiftRequest(data) {
  return {
    type: C.DELETE_VET_SHIFT_REQUEST,
    payload: data,
  };
}

export function resetVetShiftRequestCancelledSuccess() {
  return {
    type: C.RESET_VET_SHIFT_REQUEST_CANCELLED_SUCCESS,
  };
}

export function cancelConfirmedVetShift(data) {
  return {
    type: C.CANCEL_CONFIRMED_VET_SHIFT,
    payload: data,
  };
}

export function resetCancelConfirmedVetShiftFlag() {
  return {
    type: C.RESET_CANCELLED_VET_SHIFT_SUCCESS_FLAG,
  };
}

export function toggleVetDeleteShiftRequestPanel(data) {
  return {
    type: C.TOGGLE_VET_DELETE_SHIFT_REQUEST_PANEL,
    payload: data,
  };
}

export function toggleVetCancelConfirmedShiftPanel(data) {
  return {
    type: C.TOGGLE_VET_CANCEL_CONFIRMED_SHIFT_PANEL,
    payload: data,
  };
}

export function vetShiftCancellationRequestSuccess() {
  return {
    type: C.CONFIRMED_VET_SHIFT_CANCELLATION_REQUEST_SUCCESS,
  };
}

export function resetVetShiftCancellationRequestSuccess() {
  return {
    type: C.RESET_CONFIRMED_VET_SHIFT_CANCELLATION_REQUEST_SUCCESS,
  };
}

export function getShiftRecommendations() {
  return {
    type: C.FETCH_SHIFT_RECOMMENDATIONS,
  };
}

export function setRequestDetails(data) {
  return {
    type: C.SET_REQUEST_DETAILS,
    payload: data,
  };
}

export function resetRequestDetails() {
  return {
    type: C.RESET_REQUEST_DETAILS,
  };
}
