import { useEffect, useState } from "react";

import { useAppSelector } from "../../../store/service";

const MEDIA_OPTION_REFERRAL_SOURCE = "Roo Direct Referral";

export type MediaSourceOption = {
  label: string;
  name: "hearAboutRoo";
  value: number;
};

export const useMediaSourceOptions = () => {
  const [options, setOptions] = useState<MediaSourceOption[]>([]);
  const [referralOptionId, setReferralOptionId] = useState<number | null>(null);
  const mediaSource = useAppSelector((state) => state.register.mediaSource);

  useEffect(() => {
    setOptions(
      mediaSource.map((src) => {
        if (src.source === MEDIA_OPTION_REFERRAL_SOURCE) {
          setReferralOptionId(src.id);
        }
        return { value: src.id, label: src.source, name: "hearAboutRoo" };
      })
    );
  }, [mediaSource]);

  return { mediaSourceOptions: options, referralOptionId };
};
