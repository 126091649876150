import React from "react";

import { grayWhite, purpleBase } from "../../styles/constants";
import { Loading } from "../Loading";

import { StyledButton } from "./ButtonStyles";

type RemovedProps = "asChild" | "defaultChecked" | "defaultValue" | "color" | "value";

export interface ButtonProps extends Omit<React.ComponentPropsWithoutRef<"button">, RemovedProps> {
  buttonType?: "primary" | "secondary" | "danger";
  loading?: boolean;
  size?: "small" | "regular";
  testId?: string;
  title?: string;
  children?: React.ReactNode;
}

export const Button = ({
  buttonType = "secondary",
  loading,
  onClick,
  size = "regular",
  testId,
  title,
  type = "button",
  children,
  ...rest
}: ButtonProps) => (
  <StyledButton
    $buttonType={buttonType}
    $size={size}
    data-testid={testId}
    type={type}
    onClick={(event) => {
      if (onClick && !loading) onClick(event);
    }}
    title={title}
    {...rest}
  >
    {loading ? (
      <Loading color={buttonType === "secondary" ? purpleBase : grayWhite} size={size} />
    ) : title ? (
      title
    ) : (
      children
    )}
  </StyledButton>
);
