import { VetPreferredAreasOfPractice } from "../api/vet";
import { createDictionary } from "../helpers/jsUtility";

import { ENTERPRISE_ADMIN, GROUP_ADMIN, HOSPITAL_ADMIN, HOSPITAL_USER } from "./UserRoleConstants";

export type CustomCheckbox = {
  id: number;
  label: string;
  checked: boolean;
  value: number;
};

export const ANIMAL_TYPES = [
  { id: 1, label: "Small animals", name: "animalTypeId" },
  { id: 2, label: "Mixed", name: "animalTypeId" },
  { id: 3, label: "Feline-only", name: "animalTypeId" },
  { id: 4, label: "Large animals", name: "animalTypeId" },
  { id: 5, label: "Exotic", name: "animalTypeId" },
];

export const HOSPITAL_USER_TYPES = [
  { value: ENTERPRISE_ADMIN, label: "Enterprise Admin", name: "hospitalUserType" },
  { value: GROUP_ADMIN, label: "Group Admin", name: "hospitalUserType" },
  { value: HOSPITAL_ADMIN, label: "Hospital Admin", name: "hospitalUserType" },
  { value: HOSPITAL_USER, label: "Hospital User", name: "hospitalUserType" },
];

export const PROCEDURE_TYPES = {
  DENTAL: 1,
  INTERNAL: 2,
  ULTRASOUND: 3,
  SOFT_TISSUE: 4,
  ORTHOPEDICS: 5,
  ELECTIVE: 6,
  EMERGENT: 7,
  NO_SURGERY: 8,
};

export const PROCEDURE_TYPE_OPTIONS = [
  { id: 1, label: "Dental", name: "procedureTypeId" },
  { id: 2, label: "Internal", name: "procedureTypeId" },
  { id: 3, label: "Ultrasound", name: "procedureTypeId" },
  { id: 4, label: "Soft-tissue", name: "procedureTypeId" },
  { id: 5, label: "Orthopedics", name: "procedureTypeId" },
  { id: 6, label: "Elective", name: "procedureTypeId" },
  { id: 7, label: "Emergent", name: "procedureTypeId" },
  { id: 8, label: "I would prefer not to do surgery", name: "procedureTypeId" },
];

export const SPECIAL_SURGERY_IDS = [2, 3, 4, 5, 6, 7];

export const DENTAL_SURGERY_ID = 1;

export const oProcedureTypesById = createDictionary(PROCEDURE_TYPE_OPTIONS, "id");

export const aSpecializationOptions = [
  { id: 1, label: "Equine", name: "techSpecializationType" },
  { id: 7, label: "Ultrasound", name: "techSpecializationType" },
  { id: 2, label: "Feline only", name: "techSpecializationType" },
  { id: 8, label: "Fear Free Certified", name: "techSpecializationType" },
  { id: 3, label: "Avian/Exotic", name: "techSpecializationType" },
  { id: 9, label: "CPR Certified", name: "techSpecializationType" },
  { id: 4, label: "Dentistry", name: "techSpecializationType" },
  { id: 10, label: "Behavior", name: "techSpecializationType" },
  { id: 5, label: "Surgery (pre, monitoring, post op recovery)", name: "techSpecializationType" },
  { id: 11, label: "Shelter/LCHV", name: "techSpecializationType" },
  { id: 6, label: "Specialty (Oncology, Emergency)", name: "techSpecializationType" },
];

export const aSpecializationOptionsForCA = [
  { id: 1, label: "Emergency & Critical Care", name: "techSpecializationType" },
  { id: 2, label: "Zoological Medicine", name: "techSpecializationType" },
  { id: 3, label: "Internal Medicine", name: "techSpecializationType" },
  { id: 4, label: "Dental Technician", name: "techSpecializationType" },
  { id: 5, label: "Anesthesia and Analgesia", name: "techSpecializationType" },
  { id: 6, label: "Basic Ultrasound", name: "techSpecializationType", info: "FAST Scans, Big 5" },
  {
    id: 7,
    label: "Intermediate Ultrasound",
    name: "techSpecializationType",
    info: "Full Abdominal Scans, Soft-Tissue Pathology, Acquiring Diagnostic Images",
  },
  { id: 8, label: "Surgery Assistance", name: "techSpecializationType" },
  { id: 9, label: "Animal Rehabilitation", name: "techSpecializationType" },
  { id: 10, label: "Laser Therapy", name: "techSpecializationType" },
  { id: 11, label: "Shelter", name: "techSpecializationType" },
  { id: 12, label: "Equine", name: "techSpecializationType" },
  { id: 13, label: "Avian and Exotic", name: "techSpecializationType" },
  { id: 14, label: "Oncology", name: "techSpecializationType" },
  { id: 15, label: "Cardiology", name: "techSpecializationType" },
  { id: 16, label: "Radiology", name: "techSpecializationType" },
  { id: 18, label: "Dermatology", name: "techSpecializationType" },
  { id: 21, label: "Orthopedic", name: "techSpecializationType" },
  { id: 22, label: "Nephrology", name: "techSpecializationType" },
  { id: 19, label: "Cancer", name: "techSpecializationType" },
  { id: 20, label: "Ophthalmology", name: "techSpecializationType" },
  { id: 23, label: "Neurology", name: "techSpecializationType" },
];

export const updatedSpecializationOptions = [
  { id: 1, label: "Emergency & Critical Care", name: "techSpecializationType" },
  { id: 2, label: "Internal Medicine", name: "techSpecializationType" },
  { id: 3, label: "Anesthesia & Analgesia", name: "techSpecializationType" },
  { id: 4, label: "Avian & Exotic", name: "techSpecializationType" },
  { id: 5, label: "Cardiology", name: "techSpecializationType" },
  { id: 6, label: "Dermatology", name: "techSpecializationType" },
  { id: 7, label: "Nephrology", name: "techSpecializationType" },
  { id: 8, label: "Ophthalmology", name: "techSpecializationType" },
  { id: 9, label: "Dental Technician", name: "techSpecializationType" },
  { id: 10, label: "Oncology", name: "techSpecializationType" },
  { id: 11, label: "Neurology", name: "techSpecializationType" },
];

export const aSoftwareOptions = [
  {
    value: "Advantage+ by ImproMed",
    id: 6,
    name: "practiceManagementSoftware",
    label: "Advantage+ by ImproMed",
  },
  {
    value: "Avimark",
    id: 2,
    name: "practiceManagementSoftware",
    label: "Avimark",
  },
  {
    value: "Chameleon",
    id: 10,
    name: "practiceManagementSoftware",
    label: "Chameleon",
  },
  {
    value: "ClienTrax",
    id: 9,
    name: "practiceManagementSoftware",
    label: "ClienTrax",
  },
  {
    value: "Clinic HQ",
    id: 24,
    name: "practiceManagementSoftware",
    label: "Clinic HQ",
  },
  {
    value: "Cornerstone",
    id: 1,
    name: "practiceManagementSoftware",
    label: "Cornerstone",
  },
  {
    value: "Covetrus Pulse (formerly eVet)",
    id: 7,
    name: "practiceManagementSoftware",
    label: "Covetrus Pulse (formerly eVet)",
  },
  {
    value: "DaySmart Vet (formerly Vetter)",
    id: 8,
    name: "practiceManagementSoftware",
    label: "DaySmart Vet (formerly Vetter)",
  },
  {
    value: "DVMAX",
    id: 4,
    name: "practiceManagementSoftware",
    label: "DVMAX",
  },
  {
    value: "ezyVet",
    id: 3,
    name: "practiceManagementSoftware",
    label: "ezyVet",
  },
  {
    value: "IDEXX Neo",
    id: 11,
    name: "practiceManagementSoftware",
    label: "IDEXX Neo",
  },
  {
    value: "ImproMed Infinity",
    id: 12,
    name: "practiceManagementSoftware",
    label: "ImproMed Infinity",
  },
  {
    value: "IntraVet",
    id: 13,
    name: "practiceManagementSoftware",
    label: "IntraVet",
  },
  {
    value: "PetPass",
    id: 14,
    name: "practiceManagementSoftware",
    label: "PetPass",
  },
  {
    value: "Petware",
    id: 15,
    name: "practiceManagementSoftware",
    label: "Petware",
  },
  {
    value: "ProVet",
    id: 16,
    name: "practiceManagementSoftware",
    label: "ProVet",
  },
  {
    value: "Rhapsody",
    id: 17,
    name: "practiceManagementSoftware",
    label: "Rhapsody",
  },
  {
    value: "Shelterluv",
    id: 18,
    name: "practiceManagementSoftware",
    label: "Shelterluv",
  },
  {
    value: "Shepherd",
    id: 19,
    name: "practiceManagementSoftware",
    label: "Shepherd",
  },
  {
    value: "Vetspire",
    id: 20,
    name: "practiceManagementSoftware",
    label: "Vetspire",
  },
  {
    value: "VIA (Veterinary Information and Administration)",
    id: 21,
    name: "practiceManagementSoftware",
    label: "VIA (Veterinary Information and Administration)",
  },
  {
    value: "V-Tech",
    id: 22,
    name: "practiceManagementSoftware",
    label: "V-Tech",
  },
  {
    value: "WoofWare",
    id: 23,
    name: "practiceManagementSoftware",
    label: "WoofWare",
  },
  {
    value: "Other",
    id: 5,
    name: "practiceManagementSoftware",
    label: "Other",
  },
];

export const oSoftwareOptions = createDictionary(aSoftwareOptions, "value");

export const matchSoftware = (
  practiceManagementSoftware?: string
): {
  value?: string;
  id?: number;
  name?: string;
  label?: string;
} => {
  if (!practiceManagementSoftware) {
    return {};
  }
  return (
    aSoftwareOptions.find(
      (software) =>
        software.label.toLowerCase().trim().replaceAll(" ", "") ===
          practiceManagementSoftware.toLowerCase().trim().replaceAll(" ", "") ||
        software.label
          .toLowerCase()
          .trim()
          .replaceAll(" ", "")
          .indexOf(practiceManagementSoftware.toLowerCase().trim().replaceAll(" ", "")) > -1
    ) ?? {}
  );
};

export const aEquipmentOptions = [
  { id: 1, label: "IDEXX", name: "techEquipment" },
  { id: 2, label: "Abaxis", name: "techEquipment" },
  { id: 3, label: "Heska", name: "techEquipment" },
  { id: 4, label: "Other", name: "techEquipment" },
];

export const aStudentSubspecialityInterestOptions = [
  {
    id: 1,
    value: 1,
    label: "General Practice",
    name: "studentSubspecialiyInterest",
    shortLabel: "GP",
  },
  {
    id: 15,
    value: 15,
    label: "Small Animal",
    name: "studentSubspecialtyInterest",
    shortLabel: "Small",
  },
  {
    id: 16,
    value: 16,
    label: "Large Animal",
    name: "studentSubspecialtyInterest",
    shortLabel: "Large",
  },
  {
    id: 17,
    value: 17,
    label: "Exotic Animal",
    name: "studentSubspecialtyInterest",
    shortLabel: "Exotic",
  },
  { id: 2, value: 2, label: "Surgery", name: "studentSubspecialiyInterest", shortLabel: "SX" },
  { id: 3, value: 3, label: "Dentistry", name: "studentSubspecialiyInterest" },
  { id: 4, value: 4, label: "Emergency", name: "studentSubspecialiyInterest", shortLabel: "ER" },
  {
    id: 5,
    value: 5,
    label: "Dermatology",
    name: "studentSubspecialiyInterest",
    shortLabel: "Derm",
  },
  {
    id: 6,
    value: 6,
    label: "Ophthalmology",
    name: "studentSubspecialiyInterest",
    shortLabel: "Optho",
  },
  { id: 7, value: 7, label: "Neurology", name: "studentSubspecialiyInterest", shortLabel: "Neuro" },
  {
    id: 8,
    value: 8,
    label: "Orthopedics",
    name: "studentSubspecialiyInterest",
    shortLabel: "Ortho",
  },
  { id: 9, value: 9, label: "Behavior", name: "studentSubspecialiyInterest" },
  { id: 10, value: 10, label: "Nutrition", name: "studentSubspecialiyInterest" },
  {
    id: 11,
    value: 11,
    label: "Internal Medicine",
    name: "studentSubspecialiyInterest",
    shortLabel: "IM",
  },
  {
    id: 12,
    value: 12,
    label: "Cardiology",
    name: "studentSubspecialiyInterest",
    shortLabel: "Cardio",
  },
  { id: 13, value: 13, label: "Radiology", name: "studentSubspecialiyInterest" },
  {
    id: 14,
    value: 14,
    label: "Business Management",
    name: "studentSubspecialiyInterest",
    shortLabel: "Business",
  },
];

export const aHospitalApptTypeKeys = [
  "hasApptGeneral",
  "hasApptWellness",
  "hasApptWalkin",
  "hasApptNonwell",
  "hasApptEmergency",
  "hasApptHighVol",
  "hasApptLargeAnimal",
  "hasApptShelter",
  "hasApptSurgery",
  "hasApptSpecialty",
  "hasApptDental",
  "hasApptAcupuncture",
  "hasApptChiropractic",
  "hasApptLaser",
  "hasApptUrgentCare",
];

export const aVetAreasOfPracticeKeys = [
  "isPrefNoPreference",
  "isPrefGeneral",
  "isPrefEmergency",
  "isPrefHighVol",
  "isPrefLargeAnimal",
  "isPrefShelter",
  "isPrefSurgery",
  "isPrefSpecialty",
  "isPrefChiropractic",
  "isPrefLaserTherapy",
  "isPrefAcupunture",
  "isPrefUrgentCare",
  "isPrefOther",
];

export const aVetAreasOfPracticeLabels = [
  "No Preference",
  "General Practice",
  "Emergency",
  "High Volume/Low Cost",
  "Large Animal",
  "Shelter",
  "Surgery",
  "Specialty",
  "Chiropractic",
  "Laser therapy",
  "Acupuncture",
  "Urgent care",
  "Other",
];

export const EXTERNSHIP_STATUS = {
  OPEN: 1,
  APPLIED: 2,
  CONTACTED: 3,
  HIRED: 4,
  DENIED: 5,
};

export const externshipStatusOptions = [
  { value: EXTERNSHIP_STATUS.OPEN, label: "Open", name: "status" },
  { value: EXTERNSHIP_STATUS.APPLIED, label: "Applied", name: "status" },
  { value: EXTERNSHIP_STATUS.CONTACTED, label: "Contacted", name: "status" },
  { value: EXTERNSHIP_STATUS.HIRED, label: "Hired", name: "status" },
  { value: EXTERNSHIP_STATUS.DENIED, label: "Not Selected", name: "status" },
];

export type DaysOfWeekCheckbox = { id: string; label: string; checked: boolean };

export const DAYS_OF_WEEK: DaysOfWeekCheckbox[] = [
  { id: "0", label: "Sunday", checked: false },
  { id: "1", label: "Monday", checked: false },
  { id: "2", label: "Tuesday", checked: false },
  { id: "3", label: "Wednesday", checked: false },
  { id: "4", label: "Thursday", checked: false },
  { id: "5", label: "Friday", checked: false },
  { id: "6", label: "Saturday", checked: false },
];

export const TYPICAL_AVAILABILITY_OPTIONS = [
  { id: 1, label: "Sunday", checked: false },
  { id: 2, label: "Monday", checked: false },
  { id: 3, label: "Tuesday", checked: false },
  { id: 4, label: "Wednesday", checked: false },
  { id: 5, label: "Thursday", checked: false },
  { id: 6, label: "Friday", checked: false },
  { id: 7, label: "Saturday", checked: false },
  { id: 8, label: "Varies", checked: false },
];

export const TYPICAL_AVAILABILITY = {
  SUNDAY: 1,
  MONDAY: 2,
  TUESDAY: 3,
  WEDNESDAY: 4,
  THURSDAY: 5,
  FRIDAY: 6,
  SATURDAY: 7,
  VARIES: 8,
};

const type = "informational";
const size = "small";

export const HOSPITAL_TYPES = [
  {
    id: "0",
    value: "0",
    label: "Small animal",
    icon: "SoundDetectionDogBarkingRound",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: "1",
    value: "1",
    label: "Large animal",
    icon: "OutlineSavings",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: "2",
    value: "2",
    label: "Exotic animal",
    icon: "RavenOutlined",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: "3",
    value: "3",
    label: "Shelter or non-profit",
    icon: "Pets",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: "4",
    value: "4",
    label: "Emergency or speciality",
    icon: "Emergency",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
];

// These are the "old" version of search filter options for appointment types.
export const APPOINTMENT_TYPES = [
  {
    id: 1,
    value: 1,
    label: "Mostly wellness",
    icon: "SpaOutlined",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: 2,
    value: 2,
    label: "Mostly non-well",
    icon: "LocalPharmacyOutlined",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: 3,
    value: 3,
    label: "Emergency",
    icon: "Emergency",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: 4,
    value: 4,
    label: "Urgent care",
    icon: "LocalHospital",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: 5,
    value: 5,
    label: "Vaccine only",
    icon: "VaccinesOutlined",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: 6,
    value: 6,
    label: "Surgery expected",
    icon: "SurgicalOutlined",
    type,
    size,
    checked: false,
  },
  {
    id: 7,
    value: 7,
    label: "No surgery",
    icon: "StethoscopeOutlined",
    type,
    size,
    checked: false,
  },
];

// These are the "new" version of search filter options for appointment types
// They are also used during registration and in a vet's profile
export const IMPROVED_FILTER_APPOINTMENT_TYPES = [
  {
    id: 1,
    value: 1,
    label: "General practice",
    icon: "SpaOutlined",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: 2,
    value: 2,
    label: "Urgent care",
    icon: "LocalHospital",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: 3,
    value: 3,
    label: "Vaccine only",
    icon: "VaccinesOutlined",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: 4,
    value: 4,
    label: "Emergency",
    icon: "Emergency",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
];

export const idToFieldMap: Record<number, keyof VetPreferredAreasOfPractice> = {
  1: "isPrefGeneral",
  2: "isPrefUrgentCare",
  3: "isPrefVaccine",
  4: "isPrefEmergency",
  5: "isPrefShelter",
  6: "isPrefSurgery",
  7: "isPrefSpecialty",
  8: "isPrefChiropractic",
  9: "isPrefLaserTherapy",
  10: "isPrefAcupunture",
  11: "isPrefLargeAnimal",
  12: "isPrefOther",
  13: "isPrefNoPreference",
  14: "isPrefHighVol",
};

// These are the "new" version of search filter options for procedure types
// They are also used during registration and in a vet's profile
export const IMPROVED_PREFERRED_PROCEDURES = [
  {
    id: 1,
    value: 1,
    label: "Dental",
    icon: "SpaOutlined",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: 4,
    value: 4,
    label: "Soft-tissue",
    icon: "LocalHospital",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: 5,
    value: 6,
    label: "Orthopedics",
    icon: "VaccinesOutlined",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: 7,
    value: 7,
    label: "Emergent",
    icon: "Emergency",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: 9,
    value: 9,
    label: "Spay/Neuter",
    icon: "Emergency",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
  {
    id: 8,
    value: 8,
    label: "Prefer no surgery",
    icon: "Emergency",
    type,
    size,
    checked: false,
    style: { borderRadius: "16px" },
  },
];

export const LOOKING_TO_START_USING_ROO = {
  WITHIN_NEXT_MONTH: 1,
  ONE_TO_THREE_MONTHS: 2,
  THREE_TO_SIX_MONTHS: 3,
  MORE_THAN_SIX_MONTHS: 4,
  OTHER: 5,
};

export const LOOKING_TO_START_OPTIONS = [
  {
    label: "Within the next month",
    value: 1,
  },
  {
    label: "Between 1 to 3 months",
    value: 2,
  },
  {
    label: "Between 3 to 6 months",
    value: 3,
  },
  {
    label: "More than 6 months",
    value: 4,
  },
  {
    label: "Other",
    value: 5,
  },
];

export const CURRENT_SITUATION_OPTIONS = [
  {
    label: "Only doing relief work",
    value: 1,
  },
  {
    label: "Have a primary job and do some relief",
    value: 2,
  },
  {
    label: "Working relief while in a career transition",
    value: 3,
  },
];
