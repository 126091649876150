import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import * as confirmMessages from "../../../constants/confirmMessage";
import * as ErrorMessage from "../../../constants/errorMessage";
import { UNSET_LOADER } from "../../../constants/loader";
import * as ActionTypes from "../../../constants/student";

function* fetchHospitalViewDetailsAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/hospital/getHopsitalProfileInfoById?userType=${action.payload.userTypeId}&hospitalId=${action.payload.hospitalId}`
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.FETCH_HOSPITAL_VIEW_INTERNSHIP_PROFILE_SUCCESS,
        payload: data.data,
      });
      yield getActiveInternshipsByHospitalIdAndStudenIdAsync(action);
      yield getActiveExternshipsByHospitalIdAndStudentIdAsync(action);
      yield put({ type: UNSET_LOADER });
    } else {
      yield put({ type: ActionTypes.FETCH_HOSPITAL_VIEW_INTERNSHIP_PROFILE_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching hospital profile: " },
    });
    yield put({
      type: ActionTypes.FETCH_HOSPITAL_VIEW_INTERNSHIP_PROFILE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getActiveInternshipsByHospitalIdAndStudenIdAsync(action) {
  try {
    const { hospitalId, studentId } = action.payload;
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/student/internships/getActiveInternshipsByHospitalIdAndStudenId/${hospitalId}/${studentId}`
    );

    const data = yield response.json();

    if (data && data.status_code === 200) {
      yield put({
        type: ActionTypes.GET_ACTIVE_INTERNSHIPS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: ActionTypes.GET_ACTIVE_INTERNSHIPS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
    yield put({ type: UNSET_LOADER });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching active internships: " },
    });
    yield put({
      type: ActionTypes.GET_ACTIVE_INTERNSHIPS_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* requestInternshipAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/internship/requestInternship",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.REQUEST_INTERNSHIP_SUCCESS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: ActionTypes.REQUEST_INTERNSHIP_FAIL,
        payload: {
          status: data.status_code,
          message:
            data.status_code === 412 && confirmMessages[data.message]
              ? confirmMessages[data.message]
              : ErrorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while requesting internship: " } });
    yield put({
      type: ActionTypes.REQUEST_INTERNSHIP_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getActiveExternshipsByHospitalIdAndStudentIdAsync(action) {
  try {
    const { hospitalId, studentId } = action.payload;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/student/externships/getActiveExternshipsByHospitalIdAndStudentId/${hospitalId}/${studentId}`
    );

    const data = yield response.json();

    if (data && data.status_code === 200) {
      yield put({
        type: ActionTypes.GET_ACTIVE_EXTERNSHIPS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: ActionTypes.GET_ACTIVE_EXTERNSHIPS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
    yield put({ type: UNSET_LOADER });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching active externships: " },
    });
    yield put({
      type: ActionTypes.GET_ACTIVE_EXTERNSHIPS_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* requestExternshipAsync(action) {
  try {
    const userId = localStorage.getItem("userId");
    action.payload.userId = userId;
    if (!action.payload.studentId) {
      const studentId = localStorage.getItem("studentId");
      action.payload.studentId = studentId;
    }
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/student/externships/requestExternship",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.REQUEST_EXTERNSHIP_SUCCESS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: ActionTypes.REQUEST_EXTERNSHIP_FAIL,
        payload: {
          status: data.status_code,
          message:
            data.status_code === 412 && confirmMessages[data.message]
              ? confirmMessages[data.message]
              : ErrorMessage.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while requesting externship: " } });
    yield put({
      type: ActionTypes.REQUEST_EXTERNSHIP_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootHospitalProfileForStudentSaga() {
  yield takeEvery(
    ActionTypes.FETCH_HOSPITAL_VIEW_INTERNSHIP_PROFILE_REQUEST,
    fetchHospitalViewDetailsAsync
  );
  yield takeEvery(
    ActionTypes.GET_ACTIVE_INTERNSHIPS_REQUEST,
    getActiveInternshipsByHospitalIdAndStudenIdAsync
  );
  yield takeEvery(ActionTypes.REQUEST_INTERNSHIP_REQUEST, requestInternshipAsync);
  yield takeEvery(
    ActionTypes.GET_ACTIVE_EXTERNSHIPS_REQUEST,
    getActiveExternshipsByHospitalIdAndStudentIdAsync
  );
  yield takeEvery(ActionTypes.REQUEST_EXTERNSHIP_REQUEST, requestExternshipAsync);
}
