import React from "react";

import { Icon } from "../Icon";
import { grayLightest, greenBase } from "../../styles/constants";

import {
  ToggleContainer,
  ToggleDiv,
  ToggleInput,
  ToggleLabel,
  ToggleText,
} from "./CustomToggleStyles";

export interface CustomToggleProps {
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  name?: string;
  isFullWidth?: boolean;
  label?: string;
  testId?: string;
}

export const Toggle: React.FC<CustomToggleProps> = (props) => {
  const { checked, disabled, name, label, testId } = props;
  return (
    <ToggleContainer {...props}>
      <ToggleText {...props}>{label ? label : name}</ToggleText>
      <ToggleLabel data-testid={testId}>
        <ToggleInput type="checkbox" {...props} />
        <ToggleDiv checked={checked} disabled={disabled}>
          <Icon name="Check" size={12} color={disabled ? grayLightest : greenBase} />
        </ToggleDiv>
      </ToggleLabel>
    </ToggleContainer>
  );
};
