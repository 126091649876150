import { Table as AntdTable, TableProps as AntdTableProps, ConfigProvider } from "antd";
import React from "react";
import { blueDark600, grayWhite, secondaryFont } from "@RooUI";

export interface TableProps extends AntdTableProps<any> {}
export const Table = (props: TableProps) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: grayWhite,
            headerColor: blueDark600,
            fontFamily: secondaryFont,
          },
        },
      }}
    >
      <AntdTable {...props} />
    </ConfigProvider>
  );
};
