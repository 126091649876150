import React, { useEffect, useState } from "react";
import { CSSProperties } from "styled-components";

import { isMobileDevice } from "../helpers/jsUtility";

export const PrivacyPolicyComponent = ({ modal }: { modal?: boolean }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [paddingRight, setPaddingRight] = useState(0);
  const [paddingRight2, setPaddingRight2] = useState(0);

  useEffect(() => {
    setIsMobile(isMobileDevice());
    setPaddingRight(window.innerWidth - 300);
    setPaddingRight2(window.innerWidth - 350);

    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  const titleStyle = {
    fontFamily: "Jost",
    fontWeight: "600",
    fontSize: isMobile ? "20px" : "32px",
    lineHeight: isMobile ? "28.9px" : "46.24px",
    color: "#303031",
  };

  const bodyStyle = {
    fontWeight: "500",
    fontSize: isMobile ? "14px" : "16px",
    lineHeight: isMobile ? "21px" : "24px",
    color: "#303031",
  };

  const bodyStyleExpansive1 = {
    fontWeight: "500",
    fontSize: isMobile ? "14px" : "16px",
    lineHeight: isMobile ? "21px" : "24px",
    color: "#303031",
    marginBottom: "0",
    whiteSpace: (isMobile ? "nowrap" : undefined) as CSSProperties["whiteSpace"],
    paddingRight: isMobile ? `${paddingRight2}px` : "",
  };

  const largeBodyStyle = {
    fontFamily: "Jost",
    fontWeight: "500",
    fontSize: isMobile ? "18px" : "20px",
    lineHeight: isMobile ? "26px" : "28.9px",
    color: "#303031",
  };

  const largeBodyStyleExpansive1 = {
    ...largeBodyStyle,
    whiteSpace: "nowrap" as CSSProperties["whiteSpace"],
    paddingRight: isMobile ? `${paddingRight}px` : "",
  };

  const linkStyle = {
    fontWeight: "600",
    fontSize: isMobile ? "14px" : "16px",
    lineHeight: isMobile ? "21px" : "24px",
    color: "#007A4C",
    backgroundColor: "transparent",
    textDecoration: "underline",
  };

  const tableBorder = { border: "4px solid #EDEAF5" };

  const partialTableBorder = {
    borderTop: "4px solid #EDEAF5",
    borderBottom: "8px solid #EDEAF5",
  };

  return (
    <div className={modal ? "body-content privacy-table" : "container terms-privacy-wrapper"}>
      <div className={modal ? "" : "col-md-10 offset-md-1"}>
        {modal ? null : (
          <>
            <p
              style={{
                color: "#412894",
                fontWeight: "700",
                fontSize: isMobile ? "40px" : "56px",
                lineHeight: isMobile ? "60px" : "84px",
                marginTop: "40px",
              }}
            >
              Privacy Policy
            </p>
            <p
              style={{
                fontFamily: "Jost",
                fontWeight: "500",
                fontSize: isMobile ? "18px" : "20px",
                lineHeight: isMobile ? "26px" : "28.9px",
                color: "#59595A",
              }}
            >
              Last updated: September 1, 2024
            </p>
          </>
        )}

        <p style={bodyStyle}>
          This Privacy Policy (“Privacy Policy”) applies to Roo Veterinary, Inc. (“Roo”) and is
          designed to help you understand how we collect, use, and safeguard the information you
          provide to us and to assist you in making informed decisions when using our Service
          (defined below).
        </p>
        <p style={bodyStyle}>
          For purposes of this Agreement, the “Roo Platform” collectively refers to Roo’s mobile
          website which can be accessed at{" "}
          <a style={linkStyle} href="https://roo.vet/" target="_blank" rel="noreferrer">
            www.roo.vet
          </a>{" "}
          and the associated technology platform, together with any other website, our mobile
          application software (the “App”), and any technology platform Roo may provide now or in
          the future.
        </p>
        <p style={bodyStyle}>
          “Service” refers to Roo’s services accessed via the Roo Platform, which include a
          marketplace where licensed veterinarians and other qualified veterinary professionals can
          be matched with veterinary hospitals, veterinarian offices or other facilities engaged in
          the practice of veterinary medicine seeking the services of a professional on a contract
          basis to perform veterinary services.
        </p>
        <p style={bodyStyle}>
          The terms “we,” “us,” and “our” refer to Roo. “You” and “your” refer to you, as a user of
          our Roo Platform or our Service.
        </p>
        <p style={bodyStyle}>
          By accessing the Roo Platform or our Service, you accept our Privacy Policy and Terms of
          Use (found{" "}
          <a style={linkStyle} href="https://roo.vet/terms-of-use" target="_blank" rel="noreferrer">
            here
          </a>
          ), and you consent to our collection, storage, use and disclosure of your personal
          information as described in this Privacy Policy.
        </p>
        <p style={bodyStyle}>
          If you are a resident of California, please see the ”Your California Rights” section
          below.
        </p>
        <p style={titleStyle}>I. Information we collect</p>
        <p style={bodyStyle}>
          We collect “non-personal information” and “personal information” through our Service.
          Non-personal information includes information that cannot be reasonably linked to you; it
          may include anonymized or de-identified personal information. Personal information is
          information that can reasonably be linked to you and includes your full name (first name,
          last name), email address, address, telephone, bank account information (either “financial
          information” or “bank account and routing number”), and other specific hospital (e.g.
          credit card) or veterinary information. We collect this information from you through the
          registration process contact form on the Roo Platform. We may also collect certain other
          personal information as described below.
        </p>
        <p style={largeBodyStyle}>1. Information collected via technology </p>
        <p style={bodyStyle}>
          In an effort to improve the quality of the Service, we track information provided to us by
          your browser or by our App when you view or use the Service, such as the website you came
          from (known as the “referring URL”), the type of browser you use, your IP address, the
          device from which you connected to the Service, the time and date of access,
          referring/exit pages and URLs, platform types, viewed webpages, links that are clicked,
          keystrokes typed, movement of the mouse or pointer, preferences that are generated based
          on the data you submit, and precise geolocation (if you allow it).
        </p>
        <p style={bodyStyle}>
          We collect this information from you and from our website technology’s interaction with
          your browser or devices, including through cookies and web beacons (also known as an
          Internet tag, pixel tag or clear GIF). Cookies are small text files which include a unique
          identifier. Cookies are sent to a user’s browser from our servers and are stored on the
          user’s computer hard drive. Sending a cookie to a user’s browser may enable us to collect
          information about that user and keep a record of the user’s preferences when utilizing our
          services, both on an individual and aggregate basis.
        </p>
        <p style={bodyStyle}>How does Roo use cookies?</p>
        <p style={bodyStyle}>
          Roo may use both persistent and session cookies; persistent cookies remain on your
          computer after you close your session and until you delete them, while session cookies
          expire when you close your browser. There are various types of cookies that may be
          applicable:
        </p>
        <ol>
          <li style={bodyStyle}>Cookies by the site owner (here, Roo);</li>
          <li style={bodyStyle}>
            Third party cookies that enable third party features or functionality to be provided on
            or through the site (e.g. usage analytics). The parties that set third party cookies can
            recognize your computer both when it visits the site and also when it visits certain
            other websites;
          </li>
          <li style={bodyStyle}>
            Persistent cookies are activated each time that you visit the site and remain on your
            device for the period of time specified in the cookie; and
          </li>
          <li style={bodyStyle}>
            Session cookies are activated when you open the browser window and finish when you close
            the browser window; once you close the browser, all session cookies are deleted.
          </li>
        </ol>
        <p style={bodyStyle}>
          We use cookies for several reasons. Some cookies are required for technical reasons in
          order for the Roo Platform to operate and are referred to as “essential” or “strictly
          necessary” cookies. We use these essential cookies to:
        </p>
        <ol>
          <li style={bodyStyle}>authenticate your credentials when logging you into our Site;</li>
          <li style={bodyStyle}>to protect your security; and</li>
          <li style={bodyStyle}>
            to help us detect and fight spam, abuse and other activities that violate Roo’s Terms of
            Use.
          </li>
        </ol>
        <p style={bodyStyle}>
          Other cookies remember information about your browser and your preferences. For example,
          cookies help us remember your preferred language. We can then provide you with content in
          your preferred language without having to ask you each time you visit our site.‍
        </p>
        <p style={bodyStyle}>
          Cookies also enable us to track and target the interests of our users to enhance the
          experience on the Roo Platform. For example, these types of cookies help us test different
          versions of the Roo Platform to see which particular features or content users prefer.
        </p>
        <p style={bodyStyle}>
          To help us better understand how people use the Roo Platform, certain third parties serve
          cookies through the Roo Platform for advertising, analytics, and other purposes. This is
          described in more detail below. We don’t release the information collected from our own
          cookies to any third parties other than to our service providers who assist us with cookie
          activities.
        </p>
        <p style={bodyStyle}>
          The specific types of cookies served through the Roo Platform and the purposes they
          perform are described in the table below:
        </p>

        <table style={tableBorder} cellPadding="10" className="table-responsive">
          <thead style={{ borderBottom: "8px solid #EDEAF5" }}>
            <tr>
              <th style={largeBodyStyle}>TYPES OF COOKIE</th>
              <th style={largeBodyStyle}>USE OF COOKIES</th>
              <th style={largeBodyStyle}>WHO SERVES THESE COOKIES ON OUR SITE?</th>
            </tr>
          </thead>
          <tbody>
            <tr style={partialTableBorder}>
              <td style={bodyStyle} valign="top">
                <p style={largeBodyStyleExpansive1}>Strictly Necessary cookies:</p> These cookies
                are strictly necessary to provide you with services available through the Roo
                Platform, to analyze traffic, and to use some of its features such as access to
                secure areas.
              </td>
              <td style={bodyStyle} valign="top">
                <p style={bodyStyleExpansive1}>We use Strictly Necessary cookies to:</p> Identify
                you as being logged in to the site and to authenticate you, connect you to the right
                services on the site, process payments, and for security purposes.
              </td>
              <td style={bodyStyle} valign="top">
                Amazon Web Services, for authentication; Stripe, payment processing
              </td>
            </tr>
            <tr style={partialTableBorder}>
              <td style={bodyStyle} valign="top">
                <p style={largeBodyStyle}>Analytical/Performance cookies:</p> These cookies track
                user behavior in an aggregated form, using an encoded-identification, through
                various systems.
              </td>
              <td style={bodyStyle} valign="top">
                We use these Analytical/ Performance cookies to: carry out web analytics and provide
                statistics on how the site is used, obtain data on the number of users of the site
                that have viewed a service, help us improve the site by measuring errors that occur,
                and test site designs.
              </td>
              <td style={bodyStyle} valign="top">
                <ul>
                  <li style={bodyStyle}>
                    Google Analytics is a web analytics service provided by Google, Inc. (“Google”).
                    Google uses cookies to help us analyze how users use the site (see&nbsp;
                    <a
                      style={linkStyle}
                      href="https://analytics.google.com/analytics/web/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://analytics.google.com/analytics/web/
                    </a>
                    );
                  </li>
                  <li style={bodyStyle}>New Relic (performance monitoring)</li>
                  <li style={bodyStyle}>Posthog (monitoring for troubleshooting and analytics)</li>
                  <li style={bodyStyle}>Roo (session cookies for event tracking/analytics)</li>
                  <li style={bodyStyle}>Refiner.io (NPS surveying and analytics).</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style={bodyStyle} valign="top">
                <p style={largeBodyStyle}>Targeting cookies:</p> These cookies are used to make
                advertising messages more relevant to you. They perform functions like preventing
                the same ad from continuously reappearing, ensuring that ads are properly displayed
                for advertisers, and in some cases, selecting advertisements that are based on your
                interests.
              </td>
              <td style={bodyStyle} valign="top">
                We may use cookies for targeting advertising to you through third party services
                after you leave our site.
              </td>
              <td style={bodyStyle} valign="top">
                <ul>
                  <li style={bodyStyle}>Google Analytics (advertising retargeting)</li>
                  <li style={bodyStyle}>Google Doubleclick (targeted advertising)</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={bodyStyle}>How can I manage or disable cookies?</p>
        <p style={bodyStyle}>
          You can disable certain tracking as discussed in this section. For Strictly Necessary and
          Functionality cookies, which are essential to the Roo Platform and which are required in
          order to deliver the Services to you, you cannot refuse them. You can block or delete them
          by changing your browser settings however, as described below.
        </p>
        <p style={bodyStyle}>
          You have the right to decide whether to accept or reject cookies. You may refuse cookies
          on the Roo Platform by following the instructions below. Some third parties allow you to
          refuse their cookies universally by using the following opt-out links:&nbsp;
          <a
            style={linkStyle}
            href="http://www.aboutads.info/choices/"
            target="_blank"
            rel="noreferrer"
          >
            http://www.aboutads.info/choices/
          </a>{" "}
          or{" "}
          <a
            style={linkStyle}
            href="http://www.youronlinechoices.com"
            target="_blank"
            rel="noreferrer"
          >
            http://www.youronlinechoices.com
          </a>
          .
        </p>
        <p style={bodyStyle}>
          You can set or update your web browser controls to accept or refuse cookies. If you choose
          to reject cookies, you may still use our site, though your access to some functionality
          and areas of our site may be restricted and/or have limited functionality. You should
          visit your browser’s help menu for more information (web browser controls vary from
          browser to browser).
        </p>
        <p style={bodyStyle}>
          Generally speaking, most advertising networks offer you a way to opt out of targeted
          advertising. This opt-out function relates to a device or browser, and you must opt out on
          every device and in every browser used. Please note that tools may use so-called opt out
          cookies in order to remember your objection. Additionally, you can generally avoid the
          creation of usage profiles by deactivating cookie usage.
        </p>
        <p style={bodyStyle}>
          If you would like to find out more information, please visit&nbsp;
          <a
            style={linkStyle}
            href="http://www.aboutads.info/choices/"
            target="_blank"
            rel="noreferrer"
          >
            http://www.aboutads.info/choices/
          </a>{" "}
          or{" "}
          <a
            style={linkStyle}
            href="http://www.youronlinechoices.com"
            target="_blank"
            rel="noreferrer"
          >
            http://www.youronlinechoices.com
          </a>
          . For further information on cookies, including how to see what cookies have been set on
          your device and how to manage and delete them, visit{" "}
          <a
            style={linkStyle}
            href="http://www.allaboutcookies.org"
            target="_blank"
            rel="noreferrer"
          >
            www.allaboutcookies.org
          </a>
          .
        </p>
        <p style={bodyStyle}>
          We do not control any of the above opt-out links and are not responsible for any choices
          you make using these mechanisms or the continued availability or accuracy of these
          mechanisms.
        </p>
        <p style={largeBodyStyle}>2. Information you provide to us</p>
        <p style={bodyStyle}>
          In addition to the information provided automatically by your browser when you visit the
          Roo Platform, we collect information from you when you create a personal profile to become
          a user of to the Roo Platform Service. You can create a profile by registering with the
          Service and entering your email address and creating a user name and a password, along
          with other user information asked for during the registration process. By registering, you
          are authorizing us to collect, store, and use your name, email address, and other
          identifying information you may provide to us when you register or update an account in
          accordance with this Privacy Policy.
        </p>
        <p style={bodyStyle}>We may also collect the following information from you:</p>
        <ul>
          <li style={bodyStyle}>
            When you register for certain accounts: employment or education information, date of
            birth, and social security number;
          </li>
          <li style={bodyStyle}>
            Payment and billing information, such as billing address, methods of payment and/or any
            communications we have received about payment or billing;
          </li>
          <li style={bodyStyle}>
            The contents of any communication to us, including by sending a message through the Roo
            Platform and/or to the addresses indicated on the Roo Platform or by calling, e-mailing,
            texting, or chatting online with our Roo Support team. Please note that any
            communications sent to us via online chat may be recorded and shared with our service
            providers who enable these communications and any communications via telephone may be
            recorded and transcribed for customer service and training purposes;{" "}
          </li>
          <li style={bodyStyle}>
            A photograph of you, if you attend a Roo-sponsored event or trade show; and
          </li>
          <li style={bodyStyle}>
            Any other voluntary information you may provide us in the course of your interactions
            with us (such as by exchanging business cards, by responding to surveys, or by providing
            Feedback).
          </li>
        </ul>
        <p style={largeBodyStyle}>3. Other information</p>
        <p style={bodyStyle}>We may also collect:</p>
        <ul>
          <li style={bodyStyle}>
            Preference information, such as your account settings, contact channel and types of
            services/offers that interest you;
          </li>
          <li style={bodyStyle}>
            Inferences, drawn from the information identified above, to create a profile about a
            consumer reflecting the consumer’s preferences, characteristics, psychological trends,
            predispositions, behavior, attitudes, intelligence, abilities, and aptitudes; and{" "}
          </li>
          <li style={bodyStyle}>
            If you interact with us through a social media service or log in using social media
            credentials, depending on your social media settings, we may have access to your
            information from that social network such as your name, email address, age, gender, and
            location.
          </li>
        </ul>
        <p style={largeBodyStyle}>4. Children’s Privacy</p>
        <p style={bodyStyle}>
          The Roo Platform and the Service are directed to the business needs of qualified
          veterinary professionals and facilities engaged in the practice of veterinary medicine.
          The Roo Platform and the Service are not directed to consumers or anyone under the age of
          16. Roo does not knowingly collect or solicit information from anyone under the age of 16
          or allow anyone under the age of 16 to sign up for the Service. In the event that we learn
          that we have gathered personal information from anyone under the age of 16 without the
          consent of a parent or guardian, we will delete that information as soon as possible. If
          you believe we have collected such information, please contact us at{" "}
          <a style={linkStyle} href="mailto:hello@roo.vet">
            hello@roo.vet
          </a>
          . We do not have actual knowledge of selling or sharing personal information of consumers
          under the age of 16.
        </p>
        <p style={titleStyle}>II. How we use and share information</p>
        <p style={largeBodyStyle}>1. Personal Information</p>
        <p style={bodyStyle}>
          Except as otherwise stated in this Privacy Policy, we do not sell, trade, or rent your
          personal information with third parties without your consent.
        </p>
        <p style={bodyStyle}>
          We use personal information to provide the Service. Roo may request your personal
          information that is necessary to provide the Service. We will also use your personal
          information to communicate with you about your account and in response to your
          communications with us.{" "}
        </p>
        <p style={bodyStyle}>
          We may disclose personal information to vendors who are performing services for Roo for
          business purposes to enable Roo to provide the Service, such as the servers for our email
          communications who are provided access to user’s email address for purposes of sending
          emails from us. Those vendors use your personal information only at our direction and in
          accordance with our Privacy Policy.
        </p>
        <p style={bodyStyle}>
          Roo may use your personal information to authenticate and personalize the use of the Roo
          Platform, to enable you to access the Service, and to be sure the Roo Platform works
          properly with your device. If you are a Roo user, we use your personal information to
          provide you with an identity in your usage of the Service and to create a session replay
          that shows your visit to the Roo Platform. We may also use your personal information to
          protect the security or integrity of the Roo Platform and our business, such as by
          protecting against and preventing fraud, unauthorized transactions, and managing risk
          exposure, including by identifying potential hackers and other unauthorized users, and, in
          an aggregated non-specific format, for analytical and demographic purposes.
        </p>
        <p style={bodyStyle}>
          Roo uses cookies to remember users’ settings (e.g. language preference) for authentication
          on the Roo Platform and to provide relevant information on our public websites. For
          information about how users can control the use of cookies, please see the cookie section
          below. If you reject cookies, you may still use the Roo Platform, but your ability to use
          some features or areas of the Roo Platform or the Service may be limited.
        </p>
        <p style={bodyStyle}>
          Roo may use your personal information to efficiently respond to customer service requests
          and support needs. We may use the personal information you provided to us, particularly
          your business contact information and information relevant for the engagement, for the
          purpose of managing the relationship, in connection with any potential contractual
          arrangements between our companies, and to send you updates about new products and offers
          that may be of interest to you. We also may process your personal information as a part of
          a services engagement to provide you with our services.
        </p>
        <p style={bodyStyle}>
          Roo may use your Feedback (see the Terms of Use for more information) and usage data to
          improve the Service.
        </p>
        <p style={bodyStyle}>
          Roo may use your Information to collect and remit payments on behalf of Users. Roo does
          not share this information with outside parties except to the extent necessary to provide
          the service.
        </p>
        <p style={bodyStyle}>
          In general, the personal information you provide to us is used to help us communicate with
          you. For example, we use personal information to contact users in response to questions,
          solicit feedback from users, provide technical support, and inform users about promotional
          offers.{" "}
        </p>
        <p style={bodyStyle}>
          We may share personal information with outside parties if we have a good-faith belief that
          access, use, preservation, or disclosure of the information is reasonably necessary to
          meet any applicable legal process or enforceable governmental request; to enforce
          applicable Terms of Service, including investigation of potential violations; address
          fraud, security or technical concerns; or to protect against harm to the rights, property,
          or safety of our users or the public as required or permitted by law.
        </p>
        <p style={bodyStyle}>
          Roo may be legally obligated to disclose information about you to the government or third
          parties under certain circumstances, such as in connection with illegal activity on the
          Roo Platform or services or to respond to a subpoena, court order, or other legal process.
          Roo reserves the right to release your personal information to law enforcement or other
          government officials as deemed necessary or appropriate in Roo’s sole and absolute
          discretion.
        </p>
        <p style={bodyStyle}>
          In the event we undergo a business transaction such as a merger, acquisition by another
          company, or sale of all or a portion of our assets, your personal information may be among
          the assets transferred. You acknowledge and consent that such transfers may occur and are
          permitted by this Privacy Policy, and that any acquirer of our assets may continue to
          process your personal information as set forth in this Privacy Policy.
        </p>
        <p style={bodyStyle}>
          When you register for the Service, you give your express written consent and agree to
          receive telephone calls and text messages from, or on behalf of, Roo Veterinary at the
          telephone number provided for employment-related communications.
        </p>
        <p style={bodyStyle}>
          Roo may collect photographs of you at Roo-sponsored trade show or social events. Roo may
          use those photographs on social media or in limited marketing materials. By attending such
          events, you voluntarily consent and authorize Roo Veterinary, Inc. and its employees,
          agents, and subcontractors (hereafter referred to as “Company”) to use, publish,
          duplicate, disseminate, and publicly display, electronically or in print, photographs of
          you taken at those events for such purposes.
        </p>
        <p style={largeBodyStyle}>Targeted Advertising</p>
        <p style={bodyStyle}>
          Roo may allow third parties, such as select partners and affiliates that we believe may
          have or facilitate offers that may be of interest to you, to serve cookies through our
          site on your computer or mobile device for advertising. These companies may use
          information about your visits to this and other sites in order to provide relevant
          advertisements about goods and services that you may be interested in. They may also
          employ technology that is used to measure the effectiveness of advertisements. This can be
          accomplished through cookies, web beacons, or other tracking technologies as described
          above, to collect information about your visits to this and other sites in order to
          provide relevant advertisements about goods and services of potential interest to you. The
          information collected through this process does not enable Roo or third-party service
          providers to identify your name and contact details unless you choose to provide these.
        </p>
        <p style={bodyStyle}>
          Please note that to the extent advertising technology is integrated into our site, you may
          still receive advertising content even if you opt-out of targeted advertising. In that
          case, the advertising content will just not be tailored to your interests.
        </p>
        <p style={largeBodyStyle}>2. Non-Personal Information</p>
        <p style={bodyStyle}>
          In general, we use non-personal information to help us improve the Service. We also
          aggregate non-personal information in order to track trends and analyze use patterns on
          the Roo Platform. This Privacy Policy does not limit in any way our use or disclosure of
          non-personal information, and we reserve the right to use and disclose such non-personal
          information to our partners, advertisers, and other third parties at our discretion.
        </p>
        <p style={titleStyle}>III. How we protect information</p>
        <p style={bodyStyle}>
          We implement security measures designed to protect your information from unauthorized
          access. Your account is protected by your account password, and we urge you to take steps
          to keep your personal information safe by not disclosing your password and by logging out
          of your account after each use. We further protect your information by implementing
          certain technological security measures; these may include encryption, firewalls, and
          secure socket layer technology. However, these measures do not guarantee that your
          information will not be accessed, disclosed, altered, or destroyed by breach of such
          firewalls and secure server software. By using our Service, you acknowledge that you
          understand and agree to assume these risks.
        </p>
        <p style={titleStyle}>IV. Your choices</p>
        <p style={largeBodyStyle}>1. Removal</p>
        <p style={bodyStyle}>
          You may opt-out of receiving promotional communications, remove your information from our
          database, remove your information from blogs, forums or testimonials, unsubscribe from
          communications related to the Roo Platform or services, or cancel your account by
          contacting us at{" "}
          <a style={linkStyle} href="mailto:hello@roo.vet">
            hello@roo.vet
          </a>
          . In some cases, we may not be able to remove your personal information, in which case we
          will explain that we are unable to do so and why.
        </p>
        <p style={largeBodyStyle}>2. Unsubscribing</p>
        <p style={bodyStyle}>
          You have the right at any time to prevent us from contacting you for marketing purposes.
          When we send a promotional communication to a user, the user can choose not to receive
          further promotional communications by following the unsubscribe instructions provided in
          each promotional email. You can also indicate that you do not wish to receive marketing
          communications from us by unsubscribing from our email. Please note that notwithstanding
          the promotional preferences you indicate by either unsubscribing or opting out from our
          marketing emails, we may continue to send you administrative emails including, for
          example, periodic updates to our Privacy Policy.
        </p>
        <p style={largeBodyStyle}>3. Cookies</p>
        <p style={bodyStyle}>
          Most browsers accept cookies by default. You can instruct your browser, by changing its
          settings, to decline or delete cookies in accordance with the cookie section above.
        </p>
        <p style={largeBodyStyle}>4. Correcting, Updating, and Accessing</p>
        <p style={bodyStyle}>
          Upon your request and authentication of your identity, Roo will provide you with
          information about the personal information we have collected from you, whether we hold
          your personal information or whether we process your personal information on behalf of a
          third party. You have the right to contact us to request the correction, modification or
          deletion of your personal information. To make such a request and/or to pose any other
          questions regarding this Privacy Policy, please contact us at{" "}
          <a style={linkStyle} href="mailto:hello@roo.vet">
            hello@roo.vet
          </a>
          . Requests made to Roo to access, change, or delete personal information will be addressed
          within 45 days or earlier if required by applicable laws or regulations.
        </p>
        <p style={bodyStyle}>
          Where we are unable to provide access to certain personal information that you request and
          are required to refuse, we will notify you in writing, document the reasons and outline
          further steps which are available to you.
        </p>
        <p style={titleStyle}>V. Record retention </p>
        <p style={bodyStyle}>
          We will retain your personal information for as long as needed or permitted in light of
          the purposes for which it was obtained, to provide the applicable service, and as needed
          to comply with our legal obligations, resolve disputes, and enforce our agreements. The
          criteria used to determine our retention periods include: (a) the length of time we have
          an ongoing relationship with our Customers and provide services, (b) whether there is a
          legal obligation to which we are subject, and (c) whether retention is advisable in light
          of our legal position (such as in regard to applicable statutes of limitations, litigation
          or regulatory investigations).{" "}
        </p>
        <p style={titleStyle}>VI. Links to third-party websites</p>
        <p style={bodyStyle}>
          As part of the Roo Platform and the Service, we may provide links to or compatibility with
          other websites or applications. However, we are not responsible for the privacy practices
          employed by those websites or the information or content they contain. This Privacy Policy
          applies solely to information collected by us through the Roo Platform and the Service.
          Therefore, this Privacy Policy does not apply to your use of a third-party website
          accessed by selecting a link on our Roo Platform or via our Service. To the extent that
          you access or use the Service through or on another website or application, then the
          privacy policy of that other website or application will apply to your access or use of
          that site or application. We encourage our users to read the privacy statements of other
          websites before proceeding to use them.
        </p>
        <p style={titleStyle}>VII. Your California rights</p>
        <p style={bodyStyle}>
          The following disclosures apply only to individuals who reside in California.
        </p>
        <p style={bodyStyle}>
          Consumer privacy laws in California provide residents certain rights with respect to their
          personal information and require businesses collecting or disclosing personal information
          to provide notice of these rights. If you are a California resident, state law provides
          you the following rights with respect to your personal information:
        </p>
        <ul>
          <li style={bodyStyle}>
            The right to know what personal information we have collected, used, disclosed, and sold
            about you, including the categories of personal information, the categories of sources
            from which the personal information is collected, the business or commercial purpose for
            collecting, selling, or sharing personal information, the categories of third parties to
            whom Roo discloses personal information, and the specific pieces of personal information
            Roo has collected about you;
          </li>
          <li style={bodyStyle}>
            The right to correct inaccuracies in your personal information, taking into account the
            nature of the personal information and the purposes of the processing;
          </li>
          <li style={bodyStyle}>
            The right to request that we delete any personal information we have about you. This is,
            however, not an absolute right and Roo may have legal grounds for keeping such data; and
          </li>
          <li style={bodyStyle}>
            The right to opt out of the selling or sharing of your personal information, which you
            can exercise by emailing us at{" "}
            <a style={linkStyle} href="mailto:hello@roo.vet">
              hello@roo.vet
            </a>{" "}
            or by modifying your privacy preferences (e.g., Global Privacy Control), available
            through certain internet browsers and extensions, that signal your preference to opt
            out. Please note that clearing your cookies at any time will remove the signal of your
            selected privacy preferences.
          </li>
        </ul>
        <p style={bodyStyle}>
          California law requires us to identify, for the 12-month period prior to the date of this
          Privacy Policy, what information we may have “sold” or “shared” about you. For the
          12-month period prior to the date of this Privacy Policy, Roo has not sold any personal
          information about its customers. For the 12-month period prior to the date of this Privacy
          Policy, Roo has only shared personal information about its customers as described above.
          Roo does not use or disclose sensitive personal information, as defined in applicable
          laws, for any purposes other than those permitted by applicable law.{" "}
        </p>
        <p style={bodyStyle}>
          Opt-Out Preference Signals: We are working towards enabling Global Privacy Control (GPC)
          as an additional way for you to submit a request to opt out of the sale and sharing of
          your personal information through browsers and/or browser extensions that support such a
          signal. Until then, you can opt out of the use of third-party cookies for personalized
          advertising by setting your cookie preferences on your browser or as described above.
        </p>
        <p style={bodyStyle}>
          To exercise any of the above rights, please submit a request to{" "}
          <a style={linkStyle} href="mailto:hello@roo.vet">
            hello@roo.vet
          </a>
          . You may also call us at 833-766-8387. Your request should specify which right you are
          seeking to exercise and the scope of the request.
        </p>
        <p style={bodyStyle}>
          When you exercise these rights and submit a request to us, we will verify your identity by
          asking you to log in to your account if you have one with us. Or if you do not, we may ask
          for your email address. We may also use a third-party verification provider to verify your
          identity. We will endeavor to honor such requests unless such a request conflicts with
          certain lawful exemptions under the state’s consumer privacy law.
        </p>
        <p style={bodyStyle}>
          You may also designate an authorized agent to make a request on your behalf. However, we
          will require written proof of the agent’s permission to do so and verify your identity
          directly.
        </p>
        <p style={bodyStyle}>
          We will strive to confirm receipt of your request within 10 days and to respond to all
          verifiable consumer requests within 45 days of receipt. If we require more time (an
          additional 45 days), we will inform you in writing.{" "}
        </p>
        <p style={bodyStyle}>
          You have the right to not be discriminated against by us as a result of your exercising
          any of your rights. The fact that you have elected to exercise these rights will have no
          adverse effect on the price and quality of our services.
        </p>
        <p style={bodyStyle}>
          This Privacy Policy describes how we may share your information for marketing purposes as
          described above. If you are a California resident, the Shine the Light law permits you to
          request and obtain from us once per calendar year information about any of your personal
          information shared with third parties for their own direct marketing purposes, including
          the categories of information and the names and addresses of those businesses with which
          we have shared such information. To request this information and for any other questions
          about our privacy practices and compliance with California law, please contact us as
          described below.
        </p>
        <p style={titleStyle}>VIII. Changes to our privacy policy</p>
        <p style={bodyStyle}>
          Roo reserves the right to change this policy at any time. If and when Roo does so, Roo
          will post a notification on the Roo Platform. You will be informed of material changes in
          advance. You should periodically check the Roo Platform and this privacy page for updates.
          You acknowledge and agree that it is your responsibility to review this Privacy Policy
          periodically and be aware of modifications.
        </p>
        <p style={titleStyle}>IX. Contact us</p>
        <p style={bodyStyle}>
          If you have any questions regarding this Privacy Policy or wish to contact Roo regarding
          your rights as a California resident, please contact us by sending an email to&nbsp;
          <a style={linkStyle} href="mailto:hello@roo.vet">
            hello@roo.vet
          </a>{" "}
          or via mail or telephone at:
        </p>
        <p style={bodyStyle}>
          <strong>Address:</strong> Roo, 595 Pacific Ave, 4th floor, San Francisco, CA 94133
        </p>
        <p style={bodyStyle}>
          <strong>Telephone:</strong> 1-833-Roo-Vets (833-766-8387)
        </p>
      </div>
    </div>
  );
};

// eslint-disable-next-line no-restricted-exports
export default PrivacyPolicyComponent;
