import { Link } from "@RooUI";
import React, { ChangeEvent, FormEvent } from "react";
import { ConnectedProps, connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Form from "react-validation/build/form";
import validator from "validator";

import { REGISTRATION_PATHS } from "../RegistrationNew/Components/Common/service";
import { HOSPITAL_USER_TYPE } from "../constants/UserTypeConstants";
import { AuthUtility } from "../helpers/AuthUtility";
import { RootState } from "../store";
import { Event, EventTracker } from "../tracking/service/EventTracker/EventTrackerService";

import * as LoginActionCreators from "./Action/LoginActions";
import HeaderLogIn from "./HeaderLogIn";

// Define the props for the component
interface LoginProps extends RouteComponentProps, ConnectedProps<typeof connector> {}

// Define the state for the component
interface LoginState {
  form: {
    email: string;
    password: string;
  };
  emailErrorMessage: string;
  passwordErrorMessage: string;
  isFromRegistration: boolean;
  selectedUserType: string;
}

class Login extends React.Component<LoginProps, LoginState> {
  private params: URLSearchParams;

  constructor(props: LoginProps) {
    super(props);
    const { location, history } = props;

    props.initializeLoginState(props.redirectUrl);
    this.params = new URLSearchParams(location.search);

    const referralCode = this.params.get("referralCode");
    const isFromHomePage = this.params.get("isFromHome");

    const userTypeId = parseInt(localStorage.getItem("userTypeId") || "0");

    if ((typeof referralCode !== "undefined" && referralCode !== null) || isFromHomePage) {
      history.push({ pathname: REGISTRATION_PATHS.Landing, search: location.search });
    }

    if (AuthUtility.isAuthorisedUser() && userTypeId !== HOSPITAL_USER_TYPE) {
      history.push("/register/continue");
    }

    this.state = {
      form: {
        email: "",
        password: "",
      },
      emailErrorMessage: "",
      passwordErrorMessage: "",
      isFromRegistration: location.state && (location.state as any).isFromRegistration,
      selectedUserType: "",
    };
  }

  componentDidUpdate() {
    const { isNewPasswordRequired, history } = this.props;
    if (isNewPasswordRequired) {
      history.push("/resetPassword");
    }
  }

  handleClickSignUp = () => {
    const { history, location } = this.props;

    EventTracker.send({
      eventName: Event.Name.SIGN_UP,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.EVENT,
      entityId: undefined,
      context: {
        action: "click sign up button",
        location: "login page form",
      },
    });
    history.push({ pathname: REGISTRATION_PATHS.Landing, search: location.search });
  };

  handleLogin = (e: FormEvent) => {
    e.preventDefault();
    const { form } = this.state;
    if (!validator.isEmpty(form.email)) {
      if (!validator.isEmail(form.email)) {
        this.setState({
          emailErrorMessage: "Enter valid email",
          passwordErrorMessage: "",
        });
      } else if (validator.isEmpty(form.password)) {
        this.setState({
          passwordErrorMessage: "Required*",
          emailErrorMessage: "",
        });
      } else {
        this.props.submitLogin(form);
      }
    } else if (validator.isEmpty(form.password)) {
      this.setState({
        emailErrorMessage: "Required*",
        passwordErrorMessage: "Required*",
      });
    }
  };

  handleForgotPassword = () => {
    this.props.history.push("/forgotPassword");
  };

  handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value.trim(),
      },
      emailErrorMessage: "",
      passwordErrorMessage: "",
    });
  };

  render() {
    const { loginMessage, isShowLoader } = this.props;
    const { form } = this.state;
    let elLoginMessage = null;
    let elLoginMessageLink = null;

    const sHospitalTechMessage =
      "We’re sorry, Roo is currently not available for hospitals in your state.  We’ll reach out to you if there are any updates.";
    const userDoesNotExistMessage = "Incorrect username or password.";

    if (loginMessage) {
      const { message } = loginMessage;
      let messageContent;
      switch (message) {
        case "isHospitalTech":
          messageContent = sHospitalTechMessage;
          break;
        case "User does not exist.":
          messageContent = userDoesNotExistMessage;
          break;
        default:
          messageContent = message;
          break;
      }

      if (!isShowLoader) {
        elLoginMessage = (
          <span className="text-danger message" data-testid="loginError">
            {messageContent}
          </span>
        );
      }
      if (loginMessage.link) {
        elLoginMessageLink = (
          <span className="message">
            <a href={loginMessage.link}>clicking here</a>.
          </span>
        );
      }
    }

    const passwordInput = document.getElementById("password-input");
    const passwordInputWidth = passwordInput?.offsetWidth || 0;

    return (
      <div className="login inner-container d-flex align-items-center align-items-sm-start">
        <HeaderLogIn />

        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="col-md-8 col-lg-6 px-0">
              <div className="well">
                <h5 className="form-title text-center font-w-500" data-testid="loginHeader">
                  {this.state.isFromRegistration
                    ? "Sign up successfully completed, please re-enter your log-in information"
                    : "Log In"}
                </h5>
                <Form
                  className=""
                  ref={(c: HTMLFormElement | null) => {
                    if (c) {
                      (this as any).form = c;
                    }
                  }}
                >
                  <div className="registration-form-group">
                    <label>Email</label>
                    <div className="required">
                      <input
                        type="text"
                        name="email"
                        onChange={this.handleChangeInput}
                        className="form-control"
                        value={form.email}
                        placeholder="Enter your email"
                        aria-label="email"
                        aria-required="true"
                      />
                      <span className="text-danger message" data-testid="emailErrorMessage">
                        {this.state.emailErrorMessage}
                      </span>
                    </div>
                  </div>
                  <div className="registration-form-group small-mb">
                    <label htmlFor="password-input">Password</label>
                    <div className="required">
                      <input
                        className="form-control"
                        type="password"
                        onChange={this.handleChangeInput}
                        name="password"
                        value={form.password}
                        id="password-input"
                        placeholder="Enter your password"
                        aria-label="password"
                        aria-required="true"
                      />
                      <span className="text-danger message" data-testid="passwordErrorMessage">
                        {this.state.passwordErrorMessage}
                      </span>
                    </div>
                  </div>
                  <Link onClick={this.handleForgotPassword}>Forgot Password?</Link>
                  <div className="text-center pt-3 d-flex justify-content-center">
                    <div className={isShowLoader ? "btn-loader" : ""}>
                      <input
                        type="submit"
                        onClick={this.handleLogin}
                        className="roo-button primary"
                        style={{ width: `${passwordInputWidth}px` }}
                        value="LOG IN"
                        aria-label="Log in"
                      />
                    </div>
                  </div>
                </Form>
                <div className="text-center pt-3">
                  {elLoginMessage}
                  {elLoginMessageLink}
                  <div className="registration-text">
                    New to Roo?{" "}
                    <Link onClick={this.handleClickSignUp} testId="showSignUpModal">
                      Sign up
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  loginMessage: state.login.loginMessage,
  isShowLoader: state.login.isShowLoader,
  isNewPasswordRequired: state.login.isNewPasswordRequired,
  redirectUrl: state.login.redirectUrl,
});

const mapDispatchToProps = {
  initializeLoginState: LoginActionCreators.initializeLoginStateRequestAction,
  submitLogin: LoginActionCreators.loginRequestAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

// eslint-disable-next-line no-restricted-exports
export default withRouter(connector(Login));
