import * as Sentry from "@sentry/react";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";

import { showToast } from "../../Common/Toasts/action/ToastActions";
import { getConvertedTimeZone } from "../../Common/dateUtils";
import { checkIsAllShiftRequestsExpired } from "../../Common/shiftDataHelper";
import { rooFetch } from "../../api/common/rooFetch";
import { CONFIRM_TECH_SHIFT_NO_MODAL, CONFIRM_VET_SHIFT_NO_MODAL } from "../../constants/calendar";
import { EXCEPTION_MESSAGE } from "../../constants/errorMessage";
import { SHOW_HOSPITAL_BILLING_MODAL } from "../../constants/hospitalConstants";
import { SET_LOADER } from "../../constants/loader";
import { unsetLoaderAction } from "../../loader/actions/loaderAction";
import { ProfilePanelTypes, ShiftStatus, ToastType } from "../ProfilePanelTypes";
import { openProfilePanel } from "../actions/actions";
import {
  CLOSE_PROFILE_PANEL,
  CONFIRM_SHIFT_REQUEST,
  GET_SHIFT_DETAILS_FOR_PANEL_FAIL,
  GET_SHIFT_DETAILS_FOR_PANEL_REQUEST,
  GET_SHIFT_DETAILS_FOR_PANEL_SUCCESS,
  OPEN_PROFILE_PANEL,
  OPEN_PROFILE_PANEL_FROM_CALENDAR,
  OPEN_PROFILE_PANEL_REQUEST,
  SHOW_TOAST_FOR_PANEL,
} from "../constants";

const getNewProfilePanelData = (state: any) => state.profilePanel.profilePanelData;
const checkIsHospitalBillingSetUp = (state: any) => state.login.isHospitalBillingSetUp;

function* openProfilePanelRequestAsync(action: any): any {
  const currentData: any = yield select(getNewProfilePanelData);
  if (
    currentData?.contractorId !== action.payload?.contractorId ||
    currentData?.shiftData?.shiftGroupId !== action.payload?.shiftData?.shiftGroupId ||
    currentData?.isVet !== action.payload?.isVet
  ) {
    if (!action.payload.isFromDeepLink) {
      yield put({ type: CLOSE_PROFILE_PANEL });
      yield delay(0);
    }
    yield put({ type: OPEN_PROFILE_PANEL, payload: action.payload });
  }
}

function* openProfilePanelFromCalendarAsync(action: any): any {
  const { isVet } = action.payload;
  let { shiftData } = action.payload;
  const {
    shiftId,
    shiftGroupId,
    hospitalTimezone,
    shiftRequestDetails,
    shiftEndDatetime,
    confirmedVetId,
    confirmedTechId,
    confirmedVetName,
    confirmedTechName,
    requestCount,
  } = shiftData;
  const localEndDateTime: any = shiftEndDatetime * 1000;
  const nowDateTime = (new Date().getTime() / 1000 + "").split(".")[0];
  const nowDateTimeConverted: any = getConvertedTimeZone(
    nowDateTime,
    null,
    hospitalTimezone
  ).startDateTime;
  let isAllRequestsExpired = false;

  if (shiftRequestDetails?.length > 0) {
    isAllRequestsExpired = checkIsAllShiftRequestsExpired(shiftRequestDetails);
  }

  let panelType: ProfilePanelTypes;
  let shiftStatus: ShiftStatus;
  let isFetchFullfilledShiftDetails = false;
  let contractorId;

  const isConfirmedShift = (isVet && confirmedVetId) || (!isVet && confirmedTechId);
  const isCompletedShift = isConfirmedShift && localEndDateTime <= nowDateTimeConverted;
  const isActiveRequestShift = requestCount > 0 && !isAllRequestsExpired;

  if (isCompletedShift) {
    panelType = ProfilePanelTypes.ViewProfle;
    shiftStatus = ShiftStatus.Completed;
    contractorId = isVet ? confirmedVetId : confirmedTechId;
    shiftData.contractorName = isVet ? confirmedVetName : confirmedTechName;
    isFetchFullfilledShiftDetails = true;
  } else if (isConfirmedShift) {
    panelType = ProfilePanelTypes.ViewProfle;
    shiftStatus = ShiftStatus.Confirmed;
    contractorId = isVet ? confirmedVetId : confirmedTechId;
  } else if (isActiveRequestShift) {
    panelType = ProfilePanelTypes.RequestedState;
    shiftStatus = ShiftStatus.Requested;
  } else if (isAllRequestsExpired) {
    panelType = ProfilePanelTypes.RequestedState;
    shiftStatus = ShiftStatus.AllRequestExpired;
  } else {
    panelType = ProfilePanelTypes.ShiftDetails;
    shiftStatus = ShiftStatus.Unfilled;
    shiftData = null;
  }
  yield put(
    openProfilePanel({
      shiftData,
      shiftGroupId,
      shiftId,
      isVet,
      contractorId,
      panelType,
      shiftStatus,
      isFetchFullfilledShiftDetails,
      fromPage: "calendar",
    })
  );
}

function* getFullfilledShiftDetailsByIdAsync(action: any): any {
  try {
    const { isVet, shiftGroupId, panelType, shiftStatus } = action.payload;
    let queryParams = "";

    if (
      panelType === ProfilePanelTypes.RequestedState ||
      panelType === ProfilePanelTypes.DeclineRequest ||
      panelType === ProfilePanelTypes.RemoveShift
    )
      queryParams = "?isRequested=1";
    else if (panelType === ProfilePanelTypes.ViewProfle) {
      if (shiftStatus === ShiftStatus.Confirmed || shiftStatus === ShiftStatus.Cancelled)
        queryParams = "?isConfirmed=1";
      else if (shiftStatus === ShiftStatus.Completed) queryParams = "?isCompleted=1";
    }

    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/hospital/${
          isVet ? "shift" : "tech_shift"
        }/getShiftDetailsForPanel/${shiftGroupId}${queryParams}`
    );

    const responseData: any = yield response.json();

    if (response.status === 200) {
      yield put({
        type: GET_SHIFT_DETAILS_FOR_PANEL_SUCCESS,
        payload: {
          shiftDetails: {
            ...action.payload,
            ...responseData,
          },
          isVet,
          isAllRequestExpired: shiftStatus === ShiftStatus.AllRequestExpired,
        },
      });
      yield put(unsetLoaderAction());
    } else {
      yield put({
        type: GET_SHIFT_DETAILS_FOR_PANEL_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "getFullfilledShiftDetailsByIdAsync :: error " },
    });
    yield put(unsetLoaderAction());
    yield put({
      type: GET_SHIFT_DETAILS_FOR_PANEL_FAIL,
    });
  }
}

function* confirmShiftRequestAsync(action: any): any {
  const isHospitalBillingSetUp: any = yield select(checkIsHospitalBillingSetUp);
  const { isVet, contractorId, shiftData, userId, hospitalId } = action.payload;
  const {
    shiftGroupId,
    shiftDate,
    shiftCreatedBy,
    fullName,
    vetName,
    techName,
    requestedShiftStartTime,
    isRequestWithoutSurgery,
    isVetBid,
  } = shiftData;

  const requestId = shiftData?.requestId || shiftData?.shiftRequestDetails[0]?.requestId;

  if (!isHospitalBillingSetUp) {
    let params = {};
    if (isVet) {
      params = {
        shiftGroupId,
        vetId: contractorId,
        isFromMobile: false,
      };
    } else {
      params = {
        techShiftGroupId: shiftGroupId,
        techId: contractorId,
        isFromMobile: false,
      };
    }
    yield put({
      type: SHOW_HOSPITAL_BILLING_MODAL,
      payload: params,
    });
  } else {
    let confirmParams: any = {
      shiftDate,
      shiftGroupId,
      hospitalId: parseInt(hospitalId),
      userId,
      shiftCreatedBy,
      isFromPanel: true,
    };
    if (isVet) {
      confirmParams = {
        ...confirmParams,
        vetId: contractorId,
        vetName: fullName || vetName,
        requestId,
        requestedShiftStartTime,
        isRequestWithoutSurgery,
        isVetBid,
      };
      yield put({
        type: SET_LOADER,
      });
      yield put({
        type: CONFIRM_VET_SHIFT_NO_MODAL,
        payload: confirmParams,
      });
    } else {
      confirmParams = {
        ...confirmParams,
        techId: contractorId,
        techName: fullName || techName,
        requestId,
      };
      yield put({
        type: SET_LOADER,
      });
      yield put({
        type: CONFIRM_TECH_SHIFT_NO_MODAL,
        payload: confirmParams,
      });
    }
  }
}

function* showPanelToastAsync(action: any): any {
  const { toastType, message } = action.payload;

  let variant: any = "success";
  let toastMsg = "Roo hoo!";
  let description;

  switch (toastType) {
    case ToastType.TechConfirmed:
      description = "Tech shift was successfully confirmed";
      break;
    case ToastType.TechConfirmedError:
    case ToastType.VetConfirmedError:
      variant = "error";
      description = message ? message : EXCEPTION_MESSAGE;
      break;
    case ToastType.VetConfirmed:
      description = "Vet shift was successfully confirmed";
      break;
    case ToastType.TechRated:
      description = "Tech shift was successfully rated";
      break;
    case ToastType.VetRated:
      description = "Vet shift was successfully rated";
      break;
    case ToastType.TechShiftRemoved:
      description = "Tech shift was successfully removed";
      break;
    case ToastType.VetShiftRemoved:
      description = "Vet shift was successfully removed";
      break;
    case ToastType.VetRequestDeclined:
    case ToastType.TechRequestDeclined:
      description = "Request was successfully declined.";
      break;
    case ToastType.CounterBidSubmitted:
      toastMsg = "Success!";
      description = "Counteroffer detail is sent to the vet.";
      break;
    case ToastType.TechProbationLimitReached:
      description = "Unable to add request. You have reached probation limit.";
      break;
    case ToastType.ShiftBoosted:
      description = "Shift was successfully boosted.";
      break;
    case ToastType.AdminShiftRequestConfirmed:
      description = "The shift request has been confirmed.";
      break;
    case ToastType.AdminShiftRequestRemoved:
      description = "The shift request has been removed.";
      break;
  }

  yield put(
    showToast({
      variant,
      message: toastMsg,
      description,
    })
  );
}

// eslint-disable-next-line no-restricted-exports
export default function* profilePanelSaga() {
  yield takeEvery(OPEN_PROFILE_PANEL_REQUEST, openProfilePanelRequestAsync);
  yield takeEvery(OPEN_PROFILE_PANEL_FROM_CALENDAR, openProfilePanelFromCalendarAsync);
  yield takeEvery(GET_SHIFT_DETAILS_FOR_PANEL_REQUEST, getFullfilledShiftDetailsByIdAsync);
  yield takeEvery(CONFIRM_SHIFT_REQUEST, confirmShiftRequestAsync);
  yield takeEvery(SHOW_TOAST_FOR_PANEL, showPanelToastAsync);
}
