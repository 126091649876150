import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@RooUI";

import { PasswordInput } from "../../../Common/Form/PasswordInput";
import Modal from "../../../Common/ModalComponent";
import { FORGOT_PASSWORD_SUCCESS } from "../../../constants/confirmMessage";
import HeaderLogIn from "../../HeaderLogIn";

import { useFormContext } from "./resetPasswordFormContext";

export const ResetPasswordComponent = ({ success }: { success?: boolean }) => {
  const {
    form: { password, passwordConfirm },
    onChange,
    onSave,
    errors,
    loading,
  } = useFormContext();

  const history = useHistory();

  return (
    <>
      <div className="page-wrapper">
        <div className="login inner-container d-flex align-items-center align-items-sm-start">
          <HeaderLogIn />
          <div className="container">
            <div className="d-flex justify-content-center">
              <div className="col-md-8 col-lg-6 px-0">
                <div className="well forgot-password-box">
                  <h5 className="form-title text-center">Create New Password</h5>
                  <div className="form-group required">
                    <PasswordInput
                      name="password"
                      label="New Password"
                      password={password}
                      error={errors["password"]}
                      onChange={(value) => onChange("password", value)}
                    />
                  </div>
                  <div className="form-group pt-3 required">
                    <PasswordInput
                      name="passwordConfirm"
                      label="Re-enter New Password"
                      password={passwordConfirm}
                      error={errors["passwordConfirm"]}
                      onChange={(value) => onChange("passwordConfirm", value)}
                    />
                  </div>
                  <div className="d-flex justify-content-center pt-5">
                    <Button
                      title="SUBMIT"
                      buttonType="primary"
                      onClick={onSave}
                      loading={loading}
                      testId="resetPassword"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        showModal={success}
        onCloseHandler={() => history.push("/login")}
        modalBody={
          <div className="messages success-message p-0">
            {FORGOT_PASSWORD_SUCCESS}
            <div className="pt-4">
              <Button
                onClick={() => history.push("/login")}
                buttonType="primary"
                title="Okay"
                style={{ margin: "auto" }}
              />
            </div>
          </div>
        }
        smModal={true}
      />
    </>
  );
};
