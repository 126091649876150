import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import * as ErrorMessage from "../../../constants/errorMessage";
import * as ActionTypes from "../../../constants/techConstants";

export function* updatePreferredAreasOfPractice(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/tech/updatePreferredAreasOfPractice",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.UPDATE_TECH_PREFERRED_AREAS_OF_PRACTICE_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_TECH_PREFERRED_AREAS_OF_PRACTICE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating preferred areas of practice: " },
    });
    yield put({
      type: ActionTypes.UPDATE_TECH_PREFERRED_AREAS_OF_PRACTICE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}
export function* updatePreferredAreasSliders(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/tech/updatePreferredAreasSliders",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.UPDATE_TECH_PREFERRED_AREAS_SLIDERS_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_TECH_PREFERRED_AREAS_SLIDERS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating preferred areas sliders: " },
    });
    yield put({
      type: ActionTypes.UPDATE_TECH_PREFERRED_AREAS_SLIDERS_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}
export function* updateTechCharacterType(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/tech/updateTechCharacterType",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.UPDATE_TECH_CHARACTER_TYPE_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_TECH_CHARACTER_TYPE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating tech character type: " },
    });
    yield put({
      type: ActionTypes.UPDATE_TECH_CHARACTER_TYPE_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}
export function* updateTechAdditionalSkills(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/tech/updateTechAdditionalSkills",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.UPDATE_TECH_ADDITIONAL_SKILLS_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_TECH_ADDITIONAL_SKILLS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating tech additional skills: " },
    });
    yield put({
      type: ActionTypes.UPDATE_TECH_ADDITIONAL_SKILLS_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* updateTechShowOnboarding(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/tech/updateTechShowOnboarding",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: ActionTypes.UPDATE_TECH_SHOW_ONBOARDING_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_TECH_SHOW_ONBOARDING_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating tech show onboarding: " },
    });
    yield put({
      type: ActionTypes.UPDATE_TECH_SHOW_ONBOARDING_FAIL,
      payload: { status: error.status_code, message: ErrorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootTechOnboardingSaga() {
  yield takeEvery(
    ActionTypes.UPDATE_TECH_PREFERRED_AREAS_OF_PRACTICE,
    updatePreferredAreasOfPractice
  );
  yield takeEvery(ActionTypes.UPDATE_TECH_PREFERRED_AREAS_SLIDERS, updatePreferredAreasSliders);
  yield takeEvery(ActionTypes.UPDATE_TECH_CHARACTER_TYPE, updateTechCharacterType);
  yield takeEvery(ActionTypes.UPDATE_TECH_ADDITIONAL_SKILLS, updateTechAdditionalSkills);
  yield takeEvery(ActionTypes.UPDATE_TECH_SHOW_ONBOARDING, updateTechShowOnboarding);
}
