import { APIResponse } from "./common/response";
import { get, patch } from "./common/rooFetch";

export interface Task {
  id: number;
  type: string;
  createdOn: Date;
  targetType: string;
  targetId: number;
  taskParams: JSON;
  completedOn: Date;
  completedBy: number;
  dueDate: Date;
  deletedOn: Date;
}

export const getHospitalTasks = async (hospitalId: number): Promise<Task[]> => {
  const response = await get<APIResponse<Task[]>>(`api/hospital/${hospitalId}/tasks`);
  return response.data;
};

export const completeTask = async (taskId: number): Promise<void> => {
  await patch<{ completedOn: Date }, APIResponse<boolean>>(`api/tasks/${taskId}`, {
    completedOn: new Date(),
  });
};
