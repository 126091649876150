import { z } from "zod";

export interface APIResponse<ResponseData> {
  success: boolean;
  status_code: number;
  message: string;
  data: ResponseData;
}

export interface EmptyResponse {
  status: string;
  status_code: number;
  message: string;
}

export interface APIResponseData<ResponseData> {
  data: ResponseData;
  success?: boolean;
}

/**
 * API data payload.
 */

const RooDataSchema = z.object({
  success: z.boolean().optional(),
  data: z.any().optional(),
});

type BaseRooData = z.infer<typeof RooDataSchema>;

type RooData<T> = Omit<BaseRooData, "data"> & { data: T };

/**
 * API response.
 */

export const rooResponseSchema = z.object({
  status: z.string(),
  status_code: z.number(),
  message: z.string(),
  data: RooDataSchema,
});

type BaseRooResponse = z.infer<typeof rooResponseSchema>;

export type RooResponse<T> = Omit<BaseRooResponse, "data"> & { data: RooData<T> };

/**
 * Raw mysql2 insert / update response.
 */

const databaseInsertUpdateSchema = z.object({
  affectedRows: z.number(),
  changedRows: z.number(),
  fieldCount: z.number(),
  info: z.string(),
  insertId: z.number(),
  serverStatus: z.number(),
  warningStatus: z.number(),
});

export type DatabaseInsert = z.infer<typeof databaseInsertUpdateSchema>;

export type DatabaseUpdate = z.infer<typeof databaseInsertUpdateSchema>;
