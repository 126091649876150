import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import * as UserTypeConstants from "../../../constants/UserTypeConstants";
import { UNSET_LOADER } from "../../../constants/loader";
import * as ManualSMSActionTypes from "../../../constants/manualSMS";

export function* getUserListAsync(action) {
  const apiURL =
    parseInt(action.userType) === UserTypeConstants.VET_USER_TYPE
      ? "api/admin/users/vets?isFromManualSMS=true"
      : "api/admin/users/hospitals";
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + apiURL);

    const responseData = yield response.json();
    if (responseData.status_code === 200) {
      yield put({
        type: ManualSMSActionTypes.SMS_GET_USER_LIST_SUCCESS,
        payload: responseData.data.data,
      });
    } else {
      yield put({
        type: ManualSMSActionTypes.SMS_GET_USER_LIST_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting user list: " } });
  }
}

export function* sendSMSAsync(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/admin/users/sms", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    const responseData = yield response.json();
    if (responseData.status_code === 200) {
      yield put({ type: UNSET_LOADER });
      yield put({ type: ManualSMSActionTypes.ADMIN_SEND_SMS_SUCCESS });
    } else {
      yield put({
        type: ManualSMSActionTypes.ADMIN_SEND_SMS_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while sending sms: " } });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootManualSMSSaga() {
  yield takeEvery(ManualSMSActionTypes.SMS_GET_USER_LIST, getUserListAsync);
  yield takeEvery(ManualSMSActionTypes.ADMIN_SEND_SMS, sendSMSAsync);
}
