export const createShortLink = async (link: string): Promise<string> => {
  const DOMAIN = window.RooConfig.SHORT_IO_DOMAIN;
  const PUBLIC_KEY = window.RooConfig.SHORT_IO_PUBLIC_KEY;

  const requestData = {
    domain: DOMAIN,
    originalURL: link,
  };

  // eslint-disable-next-line roo/no-restricted-functions
  const response = await fetch("https://api.short.io/links/public", {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      authorization: PUBLIC_KEY,
    },
    body: JSON.stringify(requestData),
  });

  const data = await response.json();
  return data.shortURL;
};
