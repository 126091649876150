import React from "react";

export const MdsUrgentCare = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="18"
    viewBox="0 0 20 18"
    {...props}
    fill="none"
  >
    <path
      d="M7.23695 10.1997H13.1946M10.2171 7.22111V13.1812M7.24515 3.66434V1.51256C7.24515 1.23028 7.50763 1 7.83448 1H12.5986C12.923 1 13.1879 1.23028 13.1879 1.51256V3.66434M2.79026 3.664H17.6397C18.6285 3.664 19.43 4.46553 19.43 5.45426V14.9503C19.43 15.939 18.6285 16.7406 17.6397 16.7406H2.79026C1.80153 16.7406 1 15.939 1 14.9503V5.45426C1 4.46553 1.80153 3.664 2.79026 3.664Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={props.fill} // apply fill to stroke
    />
  </svg>
);
MdsUrgentCare.isCustomSvg = true;
