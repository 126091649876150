import React from "react";

import { Container as ContainerStyle } from "./ContainerStyles";

export interface ContainerProps {
  clickable?: boolean;
  nested?: boolean;
  children?: React.ReactNode;
  backgroundColor?: string;
  borderColor?: string;
  onClick?: (e: React.MouseEvent) => void;
  style?: Object;
}

export const Container: React.FC<ContainerProps> = (props) => {
  return <ContainerStyle {...props}>{props.children}</ContainerStyle>;
};
