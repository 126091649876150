import { find } from "lodash";

import { getProcedureTypeLabels } from "../../Common/shiftDataHelper";
import { UPDATE_SHIFT_REQUEST_END_TIMESTAMP } from "../../constants/calendar";
import * as C from "../../constants/vetConstants";

const initialState = {
  searchShiftResult: [],
  hospitalNameOptions: [],
  searchCenterLatitude: "",
  searchCenterLongitude: "",
  isCalendarView: false,
  isGridView: false,
  isMapView: false,
  isRecsView: false,
  isSearchShiftSuccess: false,

  linkedShiftData: [],
  linkedShiftFetched: false,

  shiftRequestResponse: {
    isShiftRequest: false,
    shiftGroupId: null,
  },
  isW9Success: false,
  W9ErrorMessage: "",
  signNowDocument: false,
  signatureId: "",
  vetLicenseInformation: [],
  vetSpecializationInformation: [],
  check: false,
  isShowFirstTimerModal: false,
  licenseInformationSuccess: false,
  bankingInfoSuccess: false,
  isShowIncompleteLicenseModal: false,
  isShowShiftNotAvailableModal: false,
  requestedHospOfShiftNotAvailable: "",
  shiftRequestResponseMessage: "",
  ratingModal: false,
  searchParameters: {},
  sharedSearch: null,
  totalCount: undefined,
  vetSignNowData: {},
  myShifts: [],
  requestFrom: null,
  isSearchClicked: false,
  errorMessage: "",
  isLicenseGettingExpired: false,
  isLicenseExpired: false,
  isOpenLicenseExpiredModal: false,
  shiftDetails: null,
  // isShowConfirmRequestClosed: false,
  // isShowTrainingPopup: false,
  commonData: {
    appointmentType: [],
    procedureType: [],
  },
  isCalculateShiftCostActionCompleted: false,
  isCalculatingShiftPricing: false,
  shiftAmount: 0,
  vetShiftAmount: 0,
  eventListForVet: [],
  isEventListFetched: false,
  isUpdateInfoPayment: null,
  badges: {
    currentBadges: [],
    upComingBadges: [],
  },
  isFirstConfirmedShift: null,
  showPaymentInfoReminderModal: null,
  isFirstConfirmedShiftRated: null,
  isSkippedW9Modal: null,
  isFromSearchThisArea: false,
  selectedZoom: null,
  isInitialMapSortComplete: false,
  isShowDeleteShiftRequestPanel: false,
  isShowCancelConfirmedShiftPanel: false,
  isConfirmedShiftCancelledSuccess: false,
  cancelShiftData: {},
  requestDetails: {},
  dashboardView: null,
};

// eslint-disable-next-line default-param-last
const vetDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.SEARCH_SHIFT_REQUEST: {
      return {
        ...state,
        isSearchShiftSuccess: false,
      };
    }
    case C.SEARCH_SHIFT_SUCCESS:
      if (action.payload.searchShiftResult === undefined) {
        return {
          ...state,
          searchShiftResult: [],
          hospitalNameOptions: [],
          isSearchShiftSuccess: true,
          totalCount: undefined,
          isSearchClicked: action.payload.isSearchClicked,
          searchCenterLatitude: "",
          searchCenterLongitude: "",
          isFromSearchThisArea: action.payload.isFromSearchThisArea,
          selectedZoom: action.payload.selectedZoom,
        };
      } else {
        const hospitalNamesSet = new Set();
        const aSearchShiftResult = action.payload.searchShiftResult.map((o) => {
          hospitalNamesSet.add(o.hospitalName);
          // if shift has surgeries, then convert procedure type ids to labels
          if (o.procedureTypeIds) {
            o.procedureTypes = getProcedureTypeLabels(o.procedureTypeIds);
          }
          return o;
        });
        return {
          ...state,
          searchShiftResult: aSearchShiftResult,
          hospitalNameOptions: !action.payload.isHospitalNamesSearched
            ? Array.from(hospitalNamesSet)
            : state.hospitalNameOptions,
          isSearchShiftSuccess: true,
          totalCount: action.payload.totalCount,
          isSearchClicked: action.payload.isSearchClicked,
          searchCenterLatitude: action.payload.latitude,
          searchCenterLongitude: action.payload.longitude,
          isFromSearchThisArea: action.payload.isFromSearchThisArea,
          selectedZoom: action.payload.selectedZoom,
        };
      }

    case C.INITIALIZE_SEARCH_RESULTS:
    case C.SEARCH_SHIFT_FAIL:
      return {
        ...state,
        isSearchShiftSuccess: false,
        searchShiftResult: [],
        totalCount: undefined,
        requestFrom: null,
      };

    case C.GET_LINKED_SHIFT_SUCCESS:
      return {
        ...state,
        linkedShiftData: action.payload,
        linkedShiftFetched: true,
      };

    case C.GET_LINKED_SHIFT_FAIL:
      return {
        ...state,
        linkedShiftFetched: true,
      };

    case C.SET_VET_DASHBOARD_CALENDAR_VIEW:
      return {
        ...state,
        isCalendarView: true,
        isGridView: false,
        isMapView: false,
        isRecsView: false,
      };

    case C.SET_VET_DASHBOARD_GRID_VIEW:
      return {
        ...state,
        searchShiftResult: [],
        isCalendarView: false,
        isGridView: true,
        isMapView: false,
        requestFrom: null,
        isRecsView: false,
      };

    case C.SET_VET_DASHBOARD_MAP_VIEW:
      return {
        ...state,
        searchShiftResult: [],
        isCalendarView: false,
        isGridView: false,
        isMapView: true,
        isRecsView: false,
      };

    case C.SET_VET_DASHBOARD_RECS_VIEW:
      return {
        ...state,
        searchShiftResult: [],
        isCalendarView: false,
        isGridView: false,
        isMapView: false,
        isRecsView: true,
      };
    case C.FETCH_SHIFT_RECOMMENDATIONS:
      return {
        ...state,
        isRecommendedShiftsLoading: true,
      };
    case C.GET_SHIFT_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        searchShiftResult: action.payload,
        isSearchShiftSuccess: true,
        totalCount: action.payload.length,
        isSearchClicked: false,
        isRecommendedShiftsLoading: false,
      };

    case C.GET_SHIFT_RECOMMENDATIONS_FAIL:
      return {
        ...state,
        isSearchShiftSuccess: false,
        searchShiftResult: [],
        totalCount: undefined,
        requestFrom: null,
        isRecommendedShiftsLoading: false,
      };

    case C.VET_REQUEST_SHIFT_SUCCESS:
      let shiftData;
      let updateIndex = null;
      const isFromAllDone = action.payload.isFromAllDone;
      const flexStartTimeRange = action.payload.flexStartTimeRange;
      const isFlexibleSurgery = action.payload.isFlexibleSurgery;

      const updatedShift = action.payload.shiftRequestResponse;
      let linkedShiftData = [];

      if (state.requestFrom) {
        shiftData = [...action.payload.availableShifts];
      } else if (action.payload.shiftData.length > 0) {
        shiftData = [...action.payload.shiftData];
      } else {
        shiftData = [...state.searchShiftResult];
      }
      shiftData.map((element, index) => {
        if (element.shiftGroupId === updatedShift.shiftGroupId) {
          updateIndex = index;
        }
      });
      const currentTimeStamp = (new Date().getTime() / 1000 + "").split(".")[0];
      const updateSearchShift = [...shiftData];
      const myShifts = [...state.myShifts];
      if (updateIndex !== null) {
        // if shift requested from roo4you blog pg, may not be in the searchShiftsList so first check updateIndex
        const oRequestedShift = updateSearchShift[updateIndex];
        oRequestedShift.shiftRequestedOn = currentTimeStamp;
        if (flexStartTimeRange || isFlexibleSurgery) {
          oRequestedShift.requestedVetShiftAmount = updatedShift.requestedVetShiftAmount;
          oRequestedShift.requestedHospitalShiftAmount = updatedShift.requestedHospitalShiftAmount;
        }
        if (flexStartTimeRange) {
          oRequestedShift.requestedShiftStartTime = updatedShift.requestedShiftStartTime;
          oRequestedShift.requestedShiftEndTime = updatedShift.requestedShiftEndTime;
          oRequestedShift.isDeductBoostAmount = updatedShift.isDeductBoostAmount;
        }
        if (isFlexibleSurgery) {
          oRequestedShift.isRequestWithoutSurgery = updatedShift.isRequestWithoutSurgery;
        }
        if (action.payload.isInstantBook) {
          oRequestedShift.confirmedVetId = Number(action.payload.shiftRequestResponse.vetId);
        }
        myShifts.push({
          ...oRequestedShift,
          vetShiftStatus: action.payload.isInstantBook ? "CONFIRMED" : "PENDING",
        });
      } else if (state.linkedShiftData?.length) {
        linkedShiftData = [...state.linkedShiftData];

        linkedShiftData[0].shiftRequestedOn = currentTimeStamp;
        linkedShiftData[0].requestedVetShiftAmount = updatedShift.requestedVetShiftAmount;
        linkedShiftData[0].requestedHospitalShiftAmount = updatedShift.requestedHospitalShiftAmount;
        linkedShiftData[0].requestedShiftStartTime = updatedShift.requestedShiftStartTime;
        linkedShiftData[0].requestedShiftEndTime = updatedShift.requestedShiftEndTime;
        linkedShiftData[0].isRequestWithoutSurgery = updatedShift.isRequestWithoutSurgery;
        linkedShiftData[0].isDeductBoostAmount = updatedShift.isDeductBoostAmount;
        linkedShiftData[0].stateId = updatedShift.stateId;
      }

      if (!isFromAllDone && localStorage.getItem("requestedShiftGroupId")) {
        //this is to delete the local storage if we are requesting it from All Done page
        //and local storage is set.Need to keep the local storage as user can also
        //refresh after requesting at all done page.
        localStorage.removeItem("requestedShiftGroupId");
      }

      return {
        ...state,
        myShifts,
        searchShiftResult: [...updateSearchShift],
        shiftRequestResponse: {
          ...state.shiftRequestResponse,
          isShiftRequest: true,
          shiftGroupId: updatedShift.shiftGroupId,
        },
        check: true,
        shiftDetails: isFromAllDone
          ? {
              ...state.shiftDetails,
              shiftRequestedOn: currentTimeStamp,
              requestedVetShiftAmount: updatedShift.requestedVetShiftAmount,
              requestedHospitalShiftAmount: updatedShift.requestedHospitalShiftAmount,
              requestedShiftStartTime: updatedShift.requestedShiftStartTime,
              requestedShiftEndTime: updatedShift.requestedShiftEndTime,
            }
          : null,
        linkedShiftData:
          state.linkedShiftData && state.linkedShiftData.length == 1 ? linkedShiftData : null,
        isShowConfirmRequestClosed: true,
      };
    case C.VET_REQUEST_SHIFT_NOT_AVAILABLE:
      return {
        ...state,
        requestedHospOfShiftNotAvailable: action.payload.hospitalName,
        requestedDateOfShiftNotAvailable: action.payload.shiftDate,
        isShowShiftNotAvailableModal: true,
        shiftRequestResponseMessage: action.payload.message,
        shiftRequestResponse: {
          isShiftRequest: false,
          shiftGroupId: null,
        },
      };
    case C.SHOW_INCOMPLETE_LICENSE_MODAL:
      return {
        ...state,
        isShowIncompleteLicenseModal: true,
      };
    case C.HIDE_INCOMPLETE_LICENSE_MODAL:
      return {
        ...state,
        isShowIncompleteLicenseModal: false,
      };
    case C.CLOSE_SHIFT_NOT_AVAILABLE_MODAL:
      return {
        ...state,
        isShowShiftNotAvailableModal: false,
        requestedHospOfShiftNotAvailable: "",
        shiftRequestResponseMessage: "",
      };
    case C.VET_REQUEST_SHIFT_FAIL:
      return {
        ...state,
        shiftRequestResponse: {
          ...state.shiftRequestResponse,
          isShiftRequest: false,
          shiftGroupId: null,
        },
      };
    case C.INITIALIZE_VET_DASHBOARD:
      return {
        ...initialState,
      };
    case C.SHOW_FIRST_TIMER_MODAL:
      return {
        ...state,
        isShowFirstTimerModal: true,
        // Now that they've seen this modal, we don't want to keep showing it
        showPaymentInfoReminderModal: false,
      };
    case C.HIDE_FIRST_TIMER_MODAL:
      return {
        ...state,
        isShowFirstTimerModal: false,
        isSkippedW9Modal: 1,
      };
    case C.UPDATE_SHOW_PAYMENT_INFO_MODAL:
      return {
        ...state,
        showPaymentInfoReminderModal: action.payload.showPaymentInfoReminderModal,
      };
    case C.FETCH_VET_FIRST_TIMER_INFO_SUCCESS:
      return {
        ...state,
        vetSignNowData: action.payload.vetSignNowData,
      };
    case C.UPDATE_VET_FIRST_TIMER_INFO_SUCCESS:
      return {
        ...state,
        licenseInformationSuccess: true,
      };
    case C.UPDATE_VET_FIRST_TIMER_INFO_FAIL:
      return {
        ...state,
        licenseInformationSuccess: false,
      };
    // case C.UPDATE_VET_BANKING_INFO_SUCCESS:
    //   return {
    //     ...state,
    //     bankingInfoSuccess: true,
    //     isShowFirstTimerModal: false,
    //     licenseInformationSuccess: false,
    //   };
    // case C.UPDATE_VET_BANKING_INFO_FAIL:
    //   return {
    //     ...state,
    //     bankingInfoSuccess: false,
    //   };

    case C.GET_PENDING_VET_RATINGS_SUCCESS:
      let currentPendingRatingIndex = state.currentPendingRatingIndex;
      let totalPendingRatingCount = state.totalPendingRatingCount;
      const isReviewsReloaded =
        totalPendingRatingCount - (state?.currentPendingRatingIndex - 1) !=
        action.payload.responseData?.totalCount
          ? true
          : false;
      if (!totalPendingRatingCount || isReviewsReloaded) {
        currentPendingRatingIndex = 1;
        totalPendingRatingCount = action.payload.responseData?.totalCount;
      }

      return {
        ...state,
        ratingModal: true,
        hospitalRatings: action.payload.responseData,
        totalPendingRatingCount,
        currentPendingRatingIndex,
      };

    case C.GET_PENDING_VET_RATINGS_FAIL:
      return {
        ...state,
        ratingModal: false,
      };

    case C.UPDATE_CURRENT_HOSPITAL_RATING_BY_VET_INDEX: {
      return {
        ...state,
        currentPendingRatingIndex: state.currentPendingRatingIndex + 1,
      };
    }

    case C.SUBMIT_VET_W9_SUCCESS: {
      if (action.payload.data.length === 0) {
        return {
          ...state,
          isW9Success: false,
          W9ErrorMessage: action.payload.errorMessage,
        };
      } else {
        return {
          ...state,
          isW9Success: true,
        };
      }
    }
    case C.SUBMIT_VET_W9_FAIL: {
      return {
        ...state,
        isW9Success: false,
        W9ErrorMessage: action.payload.errorMessage,
      };
    }
    case C.HIDE_VET_W9_ERROR: {
      return {
        ...state,
        W9ErrorMessage: "",
      };
    }
    case C.SAVE_SEARCH_SHIFT_PARAMETERS: {
      return {
        ...state,
        searchParameters: action.payload,
      };
    }

    case C.SAVE_SHARED_SEARCH: {
      return {
        ...state,
        sharedSearch: action.payload,
      };
    }

    case C.GET_ALL_MY_SHIFTS_SUCCESS: {
      // confirmed (and completed) to update search result grid if pending shift is now confirmed
      const oConfirmedByShiftId = {};
      action.payload.myShifts.forEach((o) => {
        if (o.confirmedVetId) {
          oConfirmedByShiftId[o.shiftId] = true;
        }
      });
      const aSearchShiftResultConfirmedRemoved = [];
      // TODO : we should NOT be doing this on the FE. This should happen on the BE
      state.searchShiftResult.forEach((o) => {
        if (!oConfirmedByShiftId[o.shiftId]) {
          const data = find(action.payload.myShifts, { shiftId: o.shiftId });
          const newData = { ...o, ...data };
          aSearchShiftResultConfirmedRemoved.push(newData);
        }
      });
      return {
        ...state,
        myShifts: action.payload.myShifts,
        searchShiftResult: aSearchShiftResultConfirmedRemoved,
      };
    }

    case C.GET_ALL_MY_SHIFTS_FAIL: {
      return {
        ...state,
        myShifts: [],
        errorMessage: action.payload.errorMessage,
      };
    }

    case C.CHECK_VET_LICENSE_EXPIRING_SUCCESS: {
      return {
        ...state,
        isLicenseGettingExpired: action.payload.isLicenseGettingExpired,
      };
    }

    case C.INITIALIZE_VET_LICENSE_EXPIRING: {
      return {
        ...state,
        isLicenseGettingExpired: false,
      };
    }

    case C.CHECK_VET_LICENSE_EXPIRED_SUCCESS: {
      return {
        ...state,
        isLicenseExpired: action.payload.isLicenseExpired,
      };
    }

    case C.OPEN_VET_LICENSE_EXPIRED_MODAL: {
      return {
        ...state,
        isOpenLicenseExpiredModal: true,
      };
    }
    case C.CLOSE_VET_LICENSE_EXPIRED_MODAL: {
      return {
        ...state,
        isOpenLicenseExpiredModal: false,
      };
    }
    case C.INITIALIZE_SEARCH_CLICK: {
      return {
        ...state,
        isSearchClicked: false,
      };
    }
    case C.FETCH_VET_SHIFT_DETAILS_SUCCESS: {
      return {
        ...state,
        shiftDetails: action.payload[0],
      };
    }

    case C.FETCH_COMMON_DATA_SUCCESS: {
      return {
        ...state,
        commonData: {
          ...state.commonData,
          appointmentType: action.payload.commonData.appointmentType.data,
          procedureType: action.payload.commonData.procedureType.data,
        },
      };
    }

    case C.FETCH_CALCULATE_SHIFT_PRICING:
      return {
        ...state,
        isCalculateShiftCostActionCompleted: false,
        isCalculatingShiftPricing: true,
      };

    case C.FETCH_CALCULATE_SHIFT_PRICING_SUCCESS:
      return {
        ...state,
        shiftAmount: action.payload.data.shiftAmount,
        vetShiftAmount: action.payload.data.vetShiftAmount,
        rateCardVersion: action.payload.data.version,
        isCalculateShiftCostActionCompleted: true,
        isCalculatingShiftPricing: false,
      };

    case C.FETCH_CALCULATE_SHIFT_PRICING_FAIL:
      return {
        ...state,
        isCalculateShiftCostActionCompleted: true,
        isCalculatingShiftPricing: false,
      };
    case C.GET_EVENT_FOR_VET_SUCCESS:
      return {
        ...state,
        eventListForVet: action.payload,
      };
    case C.GET_EVENT_FOR_VET_FAIL:
      return {
        ...state,
        eventListForVet: [],
      };
    case C.GET_EVENT_FOR_VET:
      return {
        ...state,
        payload: action.payload,
      };

    // case C.GET_VET_PAYMENT_INFO_SUCCESS:
    //   return {
    //     ...state,
    //     vetPaymentInfo: action.payload,
    //   };

    // case C.GET_VET_PAYMENT_INFO_FAIL:
    //   return {
    //     ...state,
    //     vetPaymentInfo: {},
    //   };
    case C.UPDATE_VET_DETAILS_PAYMENT_INFO:
      return { ...state, isUpdateInfoPayment: null };
    case C.UPDATE_VET_DETAILS_PAYMENT_INFO_SUCCESS:
      return { ...state, isUpdateInfoPayment: true };
    case C.UPDATE_VET_DETAILS_PAYMENT_INFO_FAIL:
      return { ...state, isUpdateInfoPayment: false };
    case C.RESET_VET_DETAILS_PAYMENT_INFO:
      return { ...state, isUpdateInfoPayment: null };
    case C.GET_VET_PROMO_TRACKING_BY_USER_ID_SUCCESS:
      return {
        ...state,
        vetPromoTracking: action.payload,
      };
    case C.GET_VET_PROMO_TRACKING_BY_USER_ID_FAIL:
      return {
        ...state,
        vetPromoTracking: [],
      };
    case C.CREATE_VET_PROMO_TRACKING_SUCCESS:
      return {
        ...state,
        isCreateVetPromoTrackingSuccess: action.payload,
      };
    case C.CREATE_VET_PROMO_TRACKING_FAIL:
      return {
        ...state,
        isCreateVetPromoTrackingSuccess: false,
        createPromoTrackingError: action.payload,
      };
    case C.CLEAR_VET_PROMO_TRACKING_DATA: {
      return {
        ...state,
        isCreateVetPromoTrackingSuccess: false,
        createPromoTrackingError: null,
      };
    }
    case C.GET_VET_COMPLETED_PROMOS_SUCCESS:
      return {
        ...state,
        vetCompletedPromos: action.payload,
        isVetCompletedPromosLoaded: true,
      };
    case C.GET_VET_COMPLETED_PROMOS_FAIL:
      return {
        ...state,
        vetCompletedPromos: [],
        isVetCompletedPromosLoaded: false,
      };
    case C.GET_VET_BADGES_SUCCESS:
      return {
        ...state,
        badges: action.payload,
      };
    case C.GET_VET_CONFIRMED_SHIFT_DATA_SUCCESS:
      return {
        ...state,
        isFirstConfirmedShift: action.payload.isFirstConfirmedShift,
        isFirstShiftRated: action.payload.isFirstShiftRated,
        isSkippedW9Modal: action.payload.isSkippedW9Modal,
      };
    case C.GET_VET_CONFIRMED_SHIFT_DATA_FAIL:
      return {
        ...state,
        isFirstConfirmedShift: null,
        isFirstShiftRated: null,
        isSkippedW9Modal: null,
      };
    case C.VET_SKIP_W9_MODAL_SUCCESS:
      return {
        ...state,
        isSkippedW9Modal: 1,
      };
    case C.SHOW_FIRST_TIMER_MODAL_NO_SKIP:
      return {
        ...state,
        isShowFirstTimerModal: true,
        isNoSkipFirstTimerModal: true,
      };
    case C.SAVE_SHIFT_COUNT_SUCCESS:
      return {
        ...state,
        sortedShiftCount: action.payload.totalCount,
      };
    case C.SAVE_SHIFT_COUNT_FAIL:
      return {
        ...state,
        sortedShiftCount: null,
      };
    case C.SET_DASHBOARD_VIEW_SUCCESS:
      return {
        ...state,
        dashboardView: action.payload,
      };
    case C.SET_DASHBOARD_VIEW_FAIL:
      return {
        ...state,
        dashboardView: null,
      };
    case C.CLEAR_DASHBOARD_VIEW:
      return {
        ...state,
        dashboardView: null,
      };
    case C.GET_NON_REQUEST_SURVEY_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        isEligibleForNonRequestSurvey: action.payload,
      };

    case C.GET_NON_REQUEST_SURVEY_ELIGIBILITY_FAIL:
    case C.VET_SUBMIT_SURVEY:
      return {
        ...state,
        isEligibleForNonRequestSurvey: false,
      };

    case C.SET_IS_INITIAL_MAP_SORT_COMPLETE_SUCCESS:
      return {
        ...state,
        isInitialMapSortComplete: true,
      };
    case C.GET_VET_PROMO_SHIFTS_SUCCESS:
      return {
        ...state,
        vetPromoShifts: action.payload,
      };
    case C.TOGGLE_VET_PROMO_SHIFTS_PANEL_OPEN:
      return {
        ...state,
        isShowPromoShiftPanel: true,
        selectedPromoName: action.payload,
      };
    case C.TOGGLE_VET_PROMO_SHIFTS_PANEL_CLOSED:
      return {
        ...state,
        isShowPromoShiftPanel: false,
        selectedPromoName: null,
      };
    case C.TOGGLE_VET_DELETE_SHIFT_REQUEST_PANEL_OPEN: {
      return {
        ...state,
        isShowDeleteShiftRequestPanel: true,
        deleteShiftRequestDetails: action.payload,
      };
    }
    case C.TOGGLE_VET_DELETE_SHIFT_REQUEST_PANEL_CLOSED: {
      return {
        ...state,
        isShowDeleteShiftRequestPanel: false,
        deleteShiftRequestDetails: null,
      };
    }
    case C.DELETE_VET_SHIFT_REQUEST_SUCCESS: {
      return {
        ...state,
        cancelledVetShiftRequestShiftId: action.payload,
        cancelledShiftRequestError: null,
        isShiftRequestCancelledSucess: true,
      };
    }
    case C.DELETE_VET_SHIFT_REQUEST_FAIL: {
      return {
        ...state,
        cancelledVetShiftRequestShiftId: null,
        cancelledShiftRequestError: action.payload.message,
        isShiftRequestCancelledSucess: false,
      };
    }

    case C.RESET_VET_SHIFT_REQUEST_CANCELLED_SUCCESS: {
      return {
        ...state,
        isShiftRequestCancelledSucess: false,
      };
    }

    case C.CANCEL_CONFIRMED_VET_SHIFT_SUCCESS: {
      return {
        ...state,
        isConfirmedShiftCancelledSuccess: true,
        cancelledShiftDate: state.cancelShiftData?.shiftDate,
      };
    }

    case C.CONFIRMED_VET_SHIFT_CANCELLATION_REQUEST_SUCCESS: {
      return {
        ...state,
        isShiftCancellationRequestSuccess: true,
      };
    }

    case C.RESET_CONFIRMED_VET_SHIFT_CANCELLATION_REQUEST_SUCCESS: {
      return {
        ...state,
        isShiftCancellationRequestSuccess: false,
      };
    }

    case C.TOGGLE_VET_CANCEL_CONFIRMED_SHIFT_PANEL: {
      return {
        ...state,
        cancelShiftData: action.payload.isShow ? action.payload.cancelShiftDetails : {},
        isShowCancelConfirmedShiftPanel: action.payload.isShow,
      };
    }

    case C.RESET_CANCELLED_VET_SHIFT_SUCCESS_FLAG: {
      return {
        ...state,
        isConfirmedShiftCancelledSuccess: false,
        cancelledShiftDate: null,
      };
    }

    case UPDATE_SHIFT_REQUEST_END_TIMESTAMP: {
      const updatedShiftData = action.payload;
      const stateSearchResult = [...state.searchShiftResult];
      const shiftToBeUpdated = find(stateSearchResult, {
        shiftGroupId: updatedShiftData.shiftGroupId,
      });
      if (shiftToBeUpdated)
        shiftToBeUpdated.shiftRequestEndTimestamp = updatedShiftData.expiresTimeStamp;
      return {
        ...state,
        searchShiftResult: [...stateSearchResult],
        myShifts: [...state.myShifts, shiftToBeUpdated],
      };
    }

    case C.SET_REQUEST_DETAILS: {
      return {
        ...state,
        requestDetails: action.payload,
      };
    }

    case C.RESET_REQUEST_DETAILS: {
      return {
        ...state,
        requestDetails: {},
        shiftAmount: 0,
        vetShiftAmount: 0,
        isCalculateShiftCostActionCompleted: false,
        isCalculatingShiftPricing: false,
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default vetDashboardReducer;
