import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { APIResponse } from "../../../api/common/response";
import { get, post } from "../../../api/common/rooFetch";
import * as C from "../../../constants/adminEventConstants";
import { EXCEPTION_MESSAGE } from "../../../constants/errorMessage";

export function* addEventSaga(action: any) {
  try {
    const response: APIResponse<any> = yield call(post, "api/admin/event", action.payload);

    if (response.status_code === 200) {
      yield put({
        type: C.ADD_EVENT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while adding event: " } });

    yield put({
      type: C.ADD_EVENT_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

export function* getEventSaga(action: any) {
  try {
    const response: APIResponse<any> = yield call(
      get,
      `api/admin/event?statusType=${action.payload.statusType}&isMostRecent=${action.payload.isMostRecent}`
    );

    if (response.status_code === 200) {
      yield put({ type: C.GET_EVENT_SUCCESS, payload: response.data });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting event: " } });

    yield put({
      type: C.GET_EVENT_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

export function* uploadEventFileSaga(action: any) {
  const formData = new FormData();

  for (let i = 0; i < action.payload.files.length; i++) {
    formData.append("document", action.payload.files[i]);
    formData.append("eventId", action.payload.eventId);
    formData.append("uploadFileType", "eventFile");
  }

  try {
    const response: APIResponse<any> = yield call(post, "api/user/uploadDocument", formData);

    if (response.status_code === 200) {
      yield put({
        type: C.UPLOAD_EVENT_FILE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while uploading event file: " } });

    yield put({
      type: C.UPLOAD_EVENT_FILE_FAIL,
      payload: { status: error.status_code, message: EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rooEventSaga() {
  yield takeEvery(C.ADD_EVENT, addEventSaga);
  yield takeEvery(C.GET_EVENT, getEventSaga);
  yield takeEvery(C.UPLOAD_EVENT_FILE, uploadEventFileSaga);
}
