import { clone, cloneDeep } from "lodash";

import * as C from "../../constants/enterpriseConstants";
import {
  getFilteredHospitalAdmins,
  getFilteredHospitalUsers,
  getFilteredUserByHospitals,
  getFilteredUserByPersons,
  parseHospitalsPerGroup,
  updateUserRole,
} from "../../helpers/enterpriseUtility";

const initialState = {
  myHospitalGroups: [],
  allAccessibleHospitals: [],
  usersByHospitals: [],
  groupAdmins: [],
  hospitalAdmins: [],
  hospitalUsers: [],
  enterpriseInvoicedCustomerData: {},

  isSaveHospitalGroupSuccess: false,
  isSaveGroupHospitalsSuccess: false,
  isSaveGroupAdminSuccess: false,
  isSaveEnterpriseHospitalSuccess: false,
  isUpdateHospitalEnterpriseStatusSuccess: false,
  isUpdateHospitalInvoicedStatusSuccess: false,
  isDeleteHospitalGroupSuccess: false,
  isGroupNameUpdated: false,
  isDeleteEnterpriseHospitalSuccess: false,
  isDeleteGroupHospitalSuccess: false,
  isDeleteGroupAdminSuccess: false,
  isActionSuccess: false,
  isGroupHospitalUsersFetched: false,
  isGetEnterpriseInvoicedCustomerSuccess: false,
  isGetEnterpriseInvoicedCustomerFail: false,
  isUpdateEnterpriseInvoicedCustomerSuccess: false,
  isUpdateEnterpriseInvoicedCustomerFail: false,
  errorMessage: null,
  blockContractorTypeEnterprise: null,
};

// eslint-disable-next-line default-param-last
const enterpriseReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.RESET_ENTERPRISE_STATE:
      return {
        ...initialState,
      };

    case C.CLOSE_FEEDBACK_RESEST_FLAGS:
      return {
        ...state,
        isSaveHospitalGroupSuccess: false,
        isSaveGroupHospitalsSuccess: false,
        isSaveGroupAdminSuccess: false,
        isSaveEnterpriseHospitalSuccess: false,
        isDeleteHospitalGroupSuccess: false,
        isGroupNameUpdated: false,
        isDeleteEnterpriseHospitalSuccess: false,
        isUpdateHospitalEnterpriseStatusSuccess: false,
        isUpdateHospitalInvoicedStatusSuccess: false,
        isDeleteGroupHospitalSuccess: false,
        isDeleteGroupAdminSuccess: false,
        isActionSuccess: false,
        errorMessage: null,
      };

    case C.SAVE_ENTERPRISE_HOSPITAL_SUCCESS:
      return {
        ...state,
        isSaveEnterpriseHospitalSuccess: true,
      };

    case C.SAVE_GROUP_HOSPITALS_SUCCESS:
      return {
        ...state,
        isSaveGroupHospitalsSuccess: true,
      };

    case C.SAVE_GROUP_ADMIN_SUCCESS:
      return {
        ...state,
        isSaveGroupAdminSuccess: true,
      };

    case C.SAVE_HOSPITAL_GROUP_SUCCESS:
      return {
        ...state,
        isSaveHospitalGroupSuccess: true,
      };

    case C.SAVE_HOSPITAL_GROUP_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };

    case C.DELETE_HOSPITAL_GROUP_SUCCESS:
      return {
        ...state,
        isDeleteHospitalGroupSuccess: true,
      };
    case C.UPDATE_HOSPITAL_GROUP_NAME_SUCCESS:
      return {
        ...state,
        isGroupNameUpdated: true,
      };

    case C.DELETE_GROUP_ADMIN_SUCCESS:
      return {
        ...state,
        isDeleteGroupAdminSuccess: true,
      };

    case C.DELETE_GROUP_HOSPITAL_SUCCESS:
      return {
        ...state,
        isDeleteGroupHospitalSuccess: true,
      };

    case C.DELETE_ENTERPRISE_HOSPITAL_SUCCESS:
      return {
        ...state,
        isDeleteEnterpriseHospitalSuccess: true,
      };

    case C.UPDATE_HOSPITAL_ENTERPRISE_STATUS_SUCCESS:
      return {
        ...state,
        isUpdateHospitalEnterpriseStatusSuccess: true,
      };

    case C.UPDATE_HOSPITAL_INVOICED_STATUS_SUCCESS:
      return {
        ...state,
        isUpdateHospitalInvoicedStatusSuccess: true,
      };

    case C.GET_GROUP_MANAGE_USERS:
      return {
        ...state,
        isGroupHospitalUsersFetched: true,
      };

    case C.GET_GROUP_MANAGE_USERS_SUCCESS:
      const {
        isGetAllUsersByHospitals,
        isGetGroupAdminsOnly,
        isGetHospitalAdminsOnly,
        isGetHospitalUsersOnly,
      } = action.payload;
      const data = { ...state };
      if (isGetAllUsersByHospitals) {
        data.usersByHospitals = getFilteredUserByHospitals(
          [...action.payload.groupHospitalUsers, ...action.payload.groupAdmins],
          cloneDeep(state.usersByHospitals),
          action.payload.hospitalIds
        );
      } else {
        const { isSearchClicked, groupAdmins, groupHospitalUsers } = action.payload;

        if (
          !isSearchClicked ||
          isGetGroupAdminsOnly ||
          isGetHospitalAdminsOnly ||
          isGetHospitalUsersOnly
        ) {
          const users = isGetGroupAdminsOnly
            ? action.payload.groupAdmins
            : action.payload.groupHospitalUsers;
          const formattedUsersList = getFilteredUserByPersons(users, state.allAccessibleHospitals);
          data[
            isGetGroupAdminsOnly
              ? "groupAdmins"
              : isGetHospitalAdminsOnly
              ? "hospitalAdmins"
              : "hospitalUsers"
          ] = formattedUsersList;
        } else {
          data.groupAdmins = getFilteredUserByPersons(groupAdmins, state.allAccessibleHospitals);
          data.hospitalAdmins = getFilteredHospitalAdmins(
            cloneDeep(groupHospitalUsers),
            state.allAccessibleHospitals
          );
          data.hospitalUsers = getFilteredHospitalUsers(
            cloneDeep(groupHospitalUsers),
            state.allAccessibleHospitals
          );
        }
      }
      return data;

    case C.GET_MY_HOSPITAL_GROUPS_LIST:
      return {
        ...state,
        myHospitalGroups: [],
      };

    case C.GET_MY_HOSPITAL_GROUPS_LIST_SUCCESS:
      const formattedData = parseHospitalsPerGroup(clone(action.payload.hospitalGroups));
      return {
        ...state,
        myHospitalGroups: formattedData.hospitalsPerGroup,
        allAccessibleHospitals: formattedData.hospitals,
      };

    case C.GET_GROUP_MANAGE_USERS_FAIL:
    case C.GET_MY_HOSPITAL_GROUPS_LIST_FAIL:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };

    case C.UPDATE_ACCESS_OF_HOSPITAL_USER:
      const updatedUsers = updateUserRole(cloneDeep(state.usersByHospitals), action.payload);
      return {
        ...state,
        usersByHospitals: updatedUsers,
      };

    case C.GET_ENTERPRISE_INVOICED_CUSTOMER_SUCCESS:
      return {
        ...state,
        enterpriseInvoicedCustomerData: action.payload,
        isGetEnterpriseInvoicedCustomerSuccess: true,
      };

    case C.GET_ENTERPRISE_INVOICED_CUSTOMER_FAIL:
      return {
        ...state,
        enterpriseInvoicedCustomerData: {},
        isGetEnterpriseInvoicedCustomerFail: true,
      };

    case C.RESET_ENTERPRISE_INVOICED_CUSTOMER:
      return {
        ...state,
        enterpriseInvoicedCustomerData: {},
        isGetEnterpriseInvoicedCustomerSuccess: false,
        isGetEnterpriseInvoicedCustomerFail: false,
        isUpdateEnterpriseInvoicedCustomerSuccess: false,
        isUpdateEnterpriseInvoicedCustomerFail: false,
      };

    case C.UPDATE_ENTERPRISE_INVOICED_CUSTOMER_SUCCESS:
      return {
        ...state,
        isUpdateEnterpriseInvoicedCustomerSuccess: true,
      };

    case C.UPDATE_ENTERPRISE_INVOICED_CUSTOMER_FAIL:
      return {
        ...state,
        isUpdateEnterpriseInvoicedCustomerFail: true,
      };

    case C.GET_BLOCKED_CONTRACTORS_ENTERPRISE_SUCCESS:
      return {
        ...state,
        blockedContractorsEnterprise: action.payload,
      };

    case C.UPDATE_BLOCK_CONTRACTOR_TYPE_ENTERPRISE:
      return {
        ...state,
        blockContractorTypeEnterprise: action.payload,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default enterpriseReducer;
