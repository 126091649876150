import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import * as ReferralActionTypes from "../../../constants/adminReferrals";
import * as ErrorMessages from "../../../constants/errorMessage";
import { UNSET_LOADER } from "../../../constants/loader";
import * as ReferStatusTypes from "../../../constants/referNewUser";

export function* getReferredUserListAsync(action) {
  const { referStatusType, offset, referralSearchParams } = action.payload;
  const encodedSearchParams = referralSearchParams
    ? `&searchParam=${encodeURIComponent(referralSearchParams)}`
    : "";

  let apiEndpoint = `api/admin/referuser?referStatus=${referStatusType}&offset=${offset}${encodedSearchParams}`;

  if (referStatusType === ReferStatusTypes.REFER_STATUS_TYPE.PAID) {
    apiEndpoint = `api/admin/referuser/status/paid?offset=${offset}${encodedSearchParams}`;
  }

  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + apiEndpoint);
    const responseData = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: ReferralActionTypes.ADMIN_GET_REFER_USER_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: ReferralActionTypes.ADMIN_GET_REFER_USER_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting referred user list" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: ReferralActionTypes.ADMIN_GET_REFER_USER_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

export function* updatePromoAmountAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/referralPromoAmount",
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    const responseData = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: ReferralActionTypes.ADMIN_CHANGE_PROMO_AMOUNT_SUCCESS,
        payload: { data: response.data, promoamount: action.payload.currentReferralPromoAmount },
      });
    } else {
      yield put({
        type: ReferralActionTypes.ADMIN_CHANGE_PROMO_AMOUNT_FAIL,
        payload: { status: responseData.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while updating promo amount" } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: ReferralActionTypes.ADMIN_CHANGE_PROMO_AMOUNT_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

export function* payReferralAmountAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/payReferralAmount",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const responseData = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (responseData.status_code === 200) {
      if (responseData.data.isPaymentAccount === false) {
        yield put({
          type: ReferralActionTypes.ADMIN_PAY_REFER_AMOUNT_FAIL,
          payload: {
            status: responseData.status_code,
            message: ErrorMessages.ADMIN_REFER_PAY_APPROVE_ERROR_MESSAGE,
          },
        });
      } else {
        yield put({
          type: ReferralActionTypes.ADMIN_PAY_REFER_AMOUNT_SUCCESS,
          payload: {
            data: responseData.data,
            referredByUserTypeId: action.payload.referredByUserTypeId,
          },
        });
      }
    } else {
      yield put({
        type: ReferralActionTypes.ADMIN_PAY_REFER_AMOUNT_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while paying referral amount" } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: ReferralActionTypes.ADMIN_PAY_REFER_AMOUNT_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

export function* getEmailFooterAsync(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/admin/emailFooter");
    const responseData = yield response.json();
    const emailFooter = responseData.data.emailFooter;
    const footMessage = emailFooter ? emailFooter.footMessage : "";

    yield put({ type: UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: ReferralActionTypes.ADMIN_GET_EMAIL_FOOTER_SUCCESS,
        payload: footMessage,
      });
    } else {
      yield put({
        type: ReferralActionTypes.ADMIN_GET_EMAIL_FOOTER_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting email footer" } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: ReferralActionTypes.ADMIN_GET_EMAIL_FOOTER_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

export function* updateEmailFooterAsync(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/admin/emailFooter", {
      method: "PUT",
      body: JSON.stringify(action.payload),
    });

    const responseData = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: ReferralActionTypes.ADMIN_UPDATE_EMAIL_FOOTER_SUCCESS,
        payload: action.payload,
      });
    } else {
      yield put({
        type: ReferralActionTypes.ADMIN_UPDATE_EMAIL_FOOTER_FAIL,
        payload: { status: responseData.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while updating email footer" } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: ReferralActionTypes.ADMIN_UPDATE_EMAIL_FOOTER_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

export function* getHospitalsCreditAsync(action) {
  const apiEndpoint = "api/admin/referuser/hospital/credit?offset=" + action.payload.offset;
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + apiEndpoint);
    const responseData = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: ReferralActionTypes.ADMIN_GET_HOSPITAL_CREDIT_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: ReferralActionTypes.ADMIN_GET_HOSPITAL_CREDIT_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting hospital credit" } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: ReferralActionTypes.ADMIN_GET_HOSPITAL_CREDIT_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

export function* getContractorsBonusAsync(action) {
  const { offset, referralSearchParams } = action.payload;
  const encodedSearchParams = referralSearchParams
    ? `&searchParam=${encodeURIComponent(referralSearchParams)}`
    : "";

  const apiEndpoint = `api/admin/contractor/bonus?&offset=${offset}${encodedSearchParams}`;

  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + apiEndpoint);
    const responseData = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: ReferralActionTypes.ADMIN_GET_CONTRACTOR_BONUS_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: ReferralActionTypes.ADMIN_GET_CONTRACTOR_BONUS_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting contractor bonus" } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: ReferralActionTypes.ADMIN_GET_CONTRACTOR_BONUS_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootAdminReferralSaga() {
  yield takeEvery(ReferralActionTypes.ADMIN_GET_REFER_USER_REQUEST, getReferredUserListAsync);
  yield takeEvery(ReferralActionTypes.ADMIN_CHANGE_PROMO_AMOUNT_REQUEST, updatePromoAmountAsync);
  yield takeEvery(ReferralActionTypes.ADMIN_PAY_REFER_AMOUNT_REQUEST, payReferralAmountAsync);
  yield takeEvery(ReferralActionTypes.ADMIN_GET_EMAIL_FOOTER, getEmailFooterAsync);
  yield takeEvery(ReferralActionTypes.ADMIN_UPDATE_EMAIL_FOOTER, updateEmailFooterAsync);
  yield takeEvery(ReferralActionTypes.ADMIN_GET_HOSPITAL_CREDIT_REQUEST, getHospitalsCreditAsync);
  yield takeEvery(ReferralActionTypes.ADMIN_GET_CONTRACTOR_BONUS_REQUEST, getContractorsBonusAsync);
}
