import {
  BodyText,
  Button,
  FlexLayout,
  Heading,
  Link,
  Modal,
  SmallText,
  grayBase,
  grayLight400,
  spacingXL,
} from "@RooUI";
import React, { useState } from "react";
import styled from "styled-components";

import type { EventName } from "@roo-dev/event-tracking/src/Event";

import { MarketStatus, useGetMarketStatus } from "../../../api/user/useGetMarketStatus";
import { useGetServerFeatureFlagVariantKey } from "../../../api/user/useServerFeatureFlag";
import { MeetYourRooRep } from "../../../Common/MeetYourRooRep/MeetYourRooRep";
import PageLoader from "../../../Common/PageLoader";
import { useRooTranslation } from "../../../Common/Wrappers/useRooTranslation";
import { CalendlyPointOfEntry } from "../../../constants/posthogEventContextTypes";
import { FEATURE_FLAGS, FEATURE_FLAG_VARIANTS } from "../../../constants/postHogFeatureFlags";
import { UTM_SOURCE_EVENT } from "../../../EventSignUp/components/events/common/EventSignUpRedirectContainer";
import { isMobileDevice } from "../../../helpers/jsUtility";
import { formatPrice, getCopyrightYear } from "../../../helpers/textUtility";
import { Event, EventTracker } from "../../../tracking/service/EventTracker/EventTrackerService";
import { useProgressPercentEffect } from "../Common/hooks/useProgressPercentEffect";
import { useRedirect } from "../Common/hooks/useRedirect";
import { useRegistrationContext } from "../Common/registrationContext";
import { REGISTRATION_PROGRESS_PERCENT } from "../Common/service";
import { RegistrationPageContainer } from "../RegistrationPageContainer/RegistrationPageContainer";

export const CALENDLY_CALL_PROMO_AMOUNT = 30;

export const RegistrationMeetYourRooRep = () => {
  const {
    registrationContext: { entityId, utmSource, userId, email },
  } = useRegistrationContext();
  const isMobile = isMobileDevice();
  const { redirectToCalendly, redirectToReferral, redirectToVetPreferences } = useRedirect();
  useProgressPercentEffect(REGISTRATION_PROGRESS_PERCENT.MeetRooRep);

  const isFromEvent = utmSource === UTM_SOURCE_EVENT;
  const showBackButton = !isFromEvent;

  // Calendly promo
  const formattedPromoAmount = formatPrice(CALENDLY_CALL_PROMO_AMOUNT);
  const formattedPromoAmountDecimal = formatPrice(CALENDLY_CALL_PROMO_AMOUNT, {
    isForceDecimal: true,
  });
  const { data: marketStatus } = useGetMarketStatus(userId);
  const userInMarket = marketStatus === MarketStatus.IN_MARKET;
  const { data: calendlyCallPromoVariantKey, isLoading } = useGetServerFeatureFlagVariantKey(
    userId,
    FEATURE_FLAGS.CALENDLY_CALL_PROMO,
    userInMarket
  );
  const isCalendlyCallPromoVariantKeyTest =
    calendlyCallPromoVariantKey === FEATURE_FLAG_VARIANTS.TEST;
  const isCalendlyCallPromoEnabled = userInMarket && isCalendlyCallPromoVariantKeyTest;
  const { t } = useRooTranslation();

  const [isPromoTermsModalOpen, setIsPromoTermsModalOpen] = useState(false);

  const sendEvent = (event: EventName) => {
    EventTracker.send({
      eventName: event,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.VET,
      entityId: entityId,
      context: {
        pointOfEntry: CalendlyPointOfEntry.RegistrationMeetYourRooRep,
      },
    });
  };

  const handleScheduleCall = () => {
    sendEvent(Event.Name.VET_SCHEDULE_ACTIVATION_CALL_START);

    redirectToCalendly({ shiftPreview: true });
  };

  const handleViewShifts = () => {
    sendEvent(Event.Name.VET_SKIP_ACTIVATION_CALL);
    redirectToReferral();
  };

  const handlePromoTermsClick = () => {
    setIsPromoTermsModalOpen(true);
  };

  const handleClosePromoTermsModal = () => {
    setIsPromoTermsModalOpen(false);
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <RegistrationPageContainer
      linkProps={
        showBackButton
          ? { children: "Back to Preferences", onClick: redirectToVetPreferences }
          : undefined
      }
    >
      {/* Header section */}
      <FlexLayout vertical gap="xl" style={{ marginBottom: spacingXL }}>
        <Heading level={3} color={grayBase}>
          {isCalendlyCallPromoEnabled
            ? t("registration.meetYourRooRep.promo.title", { promoAmount: formattedPromoAmount })
            : t("registration.meetYourRooRep.title")}
        </Heading>
        <SubHeading>
          {isCalendlyCallPromoEnabled
            ? t("registration.meetYourRooRep.promo.subtitle", { promoAmount: formattedPromoAmount })
            : t("registration.meetYourRooRep.subtitle")}
        </SubHeading>
      </FlexLayout>

      {/* Middle section */}
      <MeetYourRooRep
        vetId={entityId}
        pointOfEntry={CalendlyPointOfEntry.RegistrationMeetYourRooRep}
        isCalendlyCallPromoEnabled={isCalendlyCallPromoEnabled}
      />

      {/* Action section */}
      <FlexLayout
        gap="m"
        style={{ marginTop: spacingXL }}
        vertical={isMobile}
        align={isMobile ? "center" : undefined}
      >
        <Button
          buttonType="primary"
          title="Schedule Activation Call"
          onClick={handleScheduleCall}
          testId="scheduleActivationBtn"
        />
        <Link linkSize="small" onClick={handleViewShifts} testId="justLookingLink">
          Skip for now and see shifts first
        </Link>
      </FlexLayout>

      {/* Footer section */}
      {isCalendlyCallPromoEnabled && (
        <span>
          <InlineSmallText color={grayLight400}>
            {t("registration.meetYourRooRep.promo.footer.text")}{" "}
          </InlineSmallText>
          <Link linkSize="small" linkStyle="muted" onClick={handlePromoTermsClick}>
            {t("registration.meetYourRooRep.promo.footer.terms_and_conditions")}
          </Link>
        </span>
      )}

      {/* Promo terms modal section */}
      {isCalendlyCallPromoEnabled && (
        <Modal
          size="m"
          header={t("registration.meetYourRooRep.promo.modal.title")}
          body={t("registration.meetYourRooRep.promo.modal.disclaimer", {
            emailId: email,
            promoAmountDecimal: formattedPromoAmountDecimal,
            copyrightYear: getCopyrightYear(),
          })}
          showModal={isPromoTermsModalOpen}
          onCloseModal={handleClosePromoTermsModal}
          primaryButtonProps={{
            title: t("registration.meetYourRooRep.promo.modal.cta"),
            onClick: handleClosePromoTermsModal,
          }}
        />
      )}
    </RegistrationPageContainer>
  );
};

const SubHeading = styled.div`
  ${BodyText};
`;

const InlineSmallText = styled(SmallText)`
  display: inline;
`;
