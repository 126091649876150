import styled from "styled-components";
import { smallMediaBreakpoint, spacingL, spacingS } from "@RooUI";

export const NotificationHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: ${spacingL};
  @media (max-width: ${smallMediaBreakpoint}) {
    flex-direction: column;
    gap: ${spacingS};
  }
`;
