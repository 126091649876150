import styled from "styled-components";

import {
  Color,
  blueDark600,
  grayWhite,
  smallMediaBreakpoint,
  spacingM,
  spacingS,
  spacingXS,
  spacingXXL,
} from "../../styles/constants";
import { FlexLayout } from "../FlexLayout";
import { Link } from "../Link";
import { Button } from "../Button";

export const StatusAlertStyle = styled(FlexLayout)<{
  backgroundColor?: Color;
}>`
  width: 100%;
  padding: ${spacingM} ${spacingXXL};
  background-color: ${(props) => props.backgroundColor || blueDark600};
  color: ${grayWhite};

  @media (max-width: ${smallMediaBreakpoint}) {
    flex-direction: column;
    align-items: flex-start;
    padding: ${spacingM};
  }
`;

export const IconTextContainer = styled(FlexLayout)`
  @media (max-width: ${smallMediaBreakpoint}) {
    width: 100%;
  }
`;

export const ActionsContainer = styled(FlexLayout)`
  @media (max-width: ${smallMediaBreakpoint}) {
    width: 100%;
    margin-top: ${spacingM};
  }
`;

export const StatusAlertLink = styled(Link)<{ color?: string }>`
  color: ${(props) => props.color || grayWhite} !important;
  font-weight: 400;

  &:hover {
    color: ${(props) => props.color || grayWhite} !important;
  }

  svg {
    margin: ${spacingXS} 0 0 ${spacingXS};
  }

  @media (max-width: ${smallMediaBreakpoint}) {
    margin-top: ${spacingS};
  }
`;

export const StatusAlertButton = styled(Button)`
  @media (max-width: ${smallMediaBreakpoint}) {
    width: 100%;
  }
`;
