import React from "react";
import { TextAreaProps } from "antd/es/input";

import { InputContainer } from "../AntInputStyles";
import { ErrorText } from "../../Text/ErrorText";
import { FormLabel } from "../../FormLabel";
import { grayBase, redBase } from "../../../styles/constants";

import { CustomTextArea, SubText } from "./AntOpenTextStyles";

export interface AntTextAreaProps extends TextAreaProps {
  error?: string;
  isHideErrorText?: boolean;
  label?: React.ReactNode;
  width?: string;
  subText?: string;
}
export const OpenText: React.FC<AntTextAreaProps> = (props) => {
  const { error, isHideErrorText, label, subText, disabled } = props;
  return (
    <InputContainer>
      <FormLabel error={!!error} disabled={disabled}>
        {label}
      </FormLabel>
      <CustomTextArea {...props} />
      {subText && (
        <SubText disabled={disabled} color={error ? redBase : grayBase}>
          {subText}
        </SubText>
      )}
      {error && !isHideErrorText && <ErrorText>{error}</ErrorText>}
    </InputContainer>
  );
};
