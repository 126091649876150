import style from "styled-components";

import { grayBase, spacingXXL } from "../../styles/constants";

export const ButtonTabContainer = style.div`
    display: flex;
    flex-direction: row;
    gap: ${spacingXXL};
    position: relative;
    z-index: 2;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 8px;
    }
`;

export const TabAndPanelContainer = style.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const Overlay = style.div`
  background-color: ${grayBase};
  opacity: 0.25;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`;
