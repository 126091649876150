import React, { useCallback } from "react";

import Modal, { ModalComponentProps } from "../Common/ModalComponent";

interface UserSuspendedModalProps extends Omit<ModalComponentProps, "modalBody"> {
  modalMessage: string;
}

export const UserSuspendedModal: React.FC<UserSuspendedModalProps> = ({
  modalMessage,
  onCloseHandler,
  ...rest
}) => {
  const clearStorateAndClose = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      localStorage.clear();

      if (onCloseHandler) {
        onCloseHandler(e);
      }
    },
    [onCloseHandler]
  );

  return (
    <Modal
      {...rest}
      onCloseHandler={clearStorateAndClose}
      modalBody={
        <div className="messages success-message p-0">
          {modalMessage}
          <div>
            <button className="btn btn-primary mt-4" onClick={clearStorateAndClose}>
              Okay
            </button>
          </div>
        </div>
      }
    />
  );
};
