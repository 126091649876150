import React from "react";
import { ConfigProvider, Rate } from "antd";
import { StarFilled } from "@ant-design/icons";

import {
  blueDark600,
  blueLightest,
  disabledTextColor,
  grayBase,
  primaryFont,
  redBase,
  spacingXS,
} from "../../styles/constants";

import { RatingProps } from ".";

const getStarColor = ({ error, starColor }: any) => {
  if (error) return redBase;
  else if (starColor) return starColor;
  else return blueDark600;
};

const labelStyle = {
  fontSize: "12px",
  marginTop: "4px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "20px",
  color: disabledTextColor,
  fontFamily: primaryFont,
};

const selectedLabelStyle = {
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: 24,
  color: grayBase,
  fontFamily: primaryFont,
  alignItems: "center",
  marginLeft: spacingXS,
};

const customIconsLabeled: Record<number, React.ReactNode> = {
  1: (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "38px" }}>
      <StarFilled style={{ fontSize: "32px" }} />
      <span style={labelStyle}>Poor</span>
    </div>
  ),
  2: (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "38px" }}>
      <StarFilled style={{ fontSize: "32px" }} />
      <span style={labelStyle}>Bad</span>
    </div>
  ),
  3: (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "38px" }}>
      <StarFilled style={{ fontSize: "32px" }} />
      <span style={labelStyle}>Ok</span>
    </div>
  ),
  4: (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "38px" }}>
      <StarFilled style={{ fontSize: "32px" }} />
      <span style={labelStyle}>Good</span>
    </div>
  ),
  5: (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "38px" }}>
      <StarFilled style={{ fontSize: "32px" }} />
      <span style={labelStyle}>Great!</span>
    </div>
  ),
};

const customIcons: Record<number, React.ReactNode> = {
  1: (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "38px" }}>
      <StarFilled style={{ fontSize: "32px" }} />
    </div>
  ),
  2: (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "38px" }}>
      <StarFilled style={{ fontSize: "32px" }} />
    </div>
  ),
  3: (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "38px" }}>
      <StarFilled style={{ fontSize: "32px" }} />
    </div>
  ),
  4: (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "38px" }}>
      <StarFilled style={{ fontSize: "32px" }} />
    </div>
  ),
  5: (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "38px" }}>
      <StarFilled style={{ fontSize: "32px" }} />
    </div>
  ),
};

const labelsArray = ["Poor", "Bad", "Ok", "Good", "Great!"];

const getRateComponent = (props: RatingProps) => {
  const { value, labels } = props;
  if (labels) {
    return value ? (
      <>
        <div style={{ display: "flex", alignItems: "center", height: 32 }}>
          <Rate {...props} character={({ index = 0 }) => customIcons[index + 1]} />
          <span
            style={{
              ...selectedLabelStyle,
            }}
          >
            {labelsArray[value - 1]}
          </span>
        </div>
      </>
    ) : (
      <Rate {...props} character={({ index = 0 }) => customIconsLabeled[index + 1]} />
    );
  }
  return <Rate {...props} />;
};

export const RatingStyled = (props: RatingProps) => {
  const { error, starColor } = props;
  return (
    <ConfigProvider
      theme={{
        components: {
          Rate: {
            starColor: getStarColor({ error, starColor }),
            starBg: error ? redBase : blueLightest,
          },
        },
      }}
    >
      {getRateComponent(props)}
    </ConfigProvider>
  );
};
