import moment from "moment-timezone"; // same as moment, but with additional timezone features needed when shared code with back end
import React, { Component } from "react";

class CountDown extends Component {
  state = {
    counter: "",
  };

  componentDidMount() {
    const { countDownDate, currentTimeStamp, handleTimeExpired, isShowFormattedCounter } =
      this.props;
    const eventTime = countDownDate;
    const diffTime = eventTime - currentTimeStamp;
    let duration = moment.duration(diffTime, "seconds");
    const interval = 1;

    this.countInterval = setInterval(() => {
      duration = moment.duration(duration.asSeconds() - interval, "seconds");
      const nDays = duration.days();
      const nHours = duration.hours();
      const nMinutes = duration.minutes();
      const nSeconds = duration.seconds();
      let counter;
      if (isShowFormattedCounter) {
        if (nHours === 0 && nDays === 0) counter = ` ${nMinutes}m ${nSeconds}s`;
        else if (nDays === 0) counter = ` ${nHours}h ${nMinutes}m ${nSeconds}s`;
        else counter = ` ${nDays}d ${nHours}h ${nMinutes}m`;
      } else {
        counter = ` ${nDays}d ${nHours}h ${nMinutes}m ${nSeconds}s`;
      }

      if (handleTimeExpired && nDays + nHours + nMinutes + nSeconds <= 0) {
        handleTimeExpired();
      }
      if (nDays + nHours + nMinutes + nSeconds >= 0) {
        this.setState({
          counter: counter,
        });
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.countInterval);
  }

  render() {
    const { additionalClassName } = this.props;
    return (
      <span className={`countFixWidth ${additionalClassName ? additionalClassName : ""}`}>
        {this.state.counter}
      </span>
    );
  }
}

// eslint-disable-next-line no-restricted-exports
export default CountDown;
