import * as Sentry from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import { post } from "../common/rooFetch";

const licenseDataSchema = z.object({
  message: z.string(),
  success: z.boolean(),
});

export type LicenseData = z.infer<typeof licenseDataSchema>;
export type VerifyLicenseDataParams = {
  userId: number;
  licenseNumber: string;
  state: string;
  zipcode: string;
  isFromWeb: boolean;
};

export const verifyLicenseData = async (params: VerifyLicenseDataParams): Promise<LicenseData> => {
  const { userId, licenseNumber, state, zipcode, isFromWeb } = params;

  const response = await post<VerifyLicenseDataParams, LicenseData>(
    `api/vet/verifyLicenseData`,
    {
      userId,
      licenseNumber,
      state,
      zipcode,
      isFromWeb,
    },
    true
  );

  const parsedResponse = licenseDataSchema.safeParse(response);
  if (!parsedResponse.success) {
    Sentry.captureException(parsedResponse.error);
    throw new Error("Invalid response format for license data.");
  }

  return parsedResponse.data;
};

export const useVerifyLicenseData = () => {
  return useMutation((params: VerifyLicenseDataParams) => verifyLicenseData(params));
};
