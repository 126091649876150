export const FETCH_ADD_SHIFT_COMMON_DATA_REQUEST = "FETCH_ADD_SHIFT_COMMON_DATA_REQUEST";
export const FETCH_ADD_SHIFT_COMMON_DATA_SUCCESS = "FETCH_ADD_SHIFT_COMMON_DATA_SUCCESS";
export const FETCH_ADD_SHIFT_COMMON_DATA_FAIL = "FETCH_ADD_SHIFT_COMMON_DATA_FAIL";

export const CALCULATE_BULK_SHIFT_PRICING = "CALCULATE_BULK_SHIFT_PRICING";
export const CALCULATE_BULK_SHIFT_PRICING_SUCCESS = "CALCULATE_BULK_SHIFT_PRICING_SUCCESS";
export const CALCULATE_BULK_SHIFT_PRICING_FAIL = "CALCULATE_BULK_SHIFT_PRICING_FAIL";

export const FETCH_HOLIDAYS = "FETCH_HOLIDAYS";
export const FETCH_HOLIDAYS_SUCCESS = "FETCH_HOLIDAYS_SUCCESS";
export const FETCH_HOLIDAYS_FAIL = "FETCH_HOLIDAYS_FAIL";

export const RESET_HOLIDAYS = "RESET_HOLIDAYS";

export const ADD_SHIFT = "ADD_SHIFT";
export const ADD_SHIFT_SUCCESS = "ADD_SHIFT_SUCCESS";
export const ADD_SHIFT_FAIL = "ADD_SHIFT_FAIL";
export const RESET_ADD_SHIFT_FAIL = "RESET_ADD_SHIFT_FAIL";

export const GET_SHIFT_FULLFILLMENT_PREDICTION = "GET_SHIFT_FULLFILLMENT_PREDICTION";
export const GET_SHIFT_FULLFILLMENT_PREDICTION_SUCCESS =
  "GET_SHIFT_FULLFILLMENT_PREDICTION_SUCCESS";
export const GET_SHIFT_FULLFILLMENT_PREDICTION_FAIL = "GET_SHIFT_FULLFILLMENT_PREDICTION_FAIL";

export const FETCH_PREVIOUS_SHIFT_REQUEST = "FETCH_PREVIOUS_SHIFT_REQUEST";
export const FETCH_PREVIOUS_SHIFT_SUCCESS = "FETCH_PREVIOUS_SHIFT_SUCCESS";
export const FETCH_PREVIOUS_SHIFT_FAIL = "FETCH_PREVIOUS_SHIFT_FAIL";

export const RESET_SHIFT_UPDATED_FLAG = "RESET_SHIFT_UPDATED_FLAG";

export const FETCH_HOSPITAL_DETAILS_REQUEST = "FETCH_HOSPITAL_DETAILS_REQUEST";
export const FETCH_HOSPITAL_DETAILS_SUCCESS = "FETCH_HOSPITAL_DETAILS_SUCCESS";
export const FETCH_HOSPITAL_DETAILS_FAIL = "FETCH_HOSPITAL_DETAILS_FAIL";

export const UPDATE_HOSPITAL_IMAGES_REQUEST = "UPDATE_HOSPITAL_IMAGES_REQUEST";
export const UPDATE_HOSPITAL_IMAGES_SUCCESS = "UPDATE_HOSPITAL_IMAGES_SUCCESS";
export const UPDATE_HOSPITAL_IMAGES_FAIL = "UPDATE_HOSPITAL_IMAGES_FAIL";

export const FETCH_VET_VIEW_PROFILE_DETAILS_REQUEST = "FETCH_VET_VIEW_PROFILE_DETAILS_REQUEST";
export const FETCH_VET_VIEW_PROFILE_DETAILS_SUCCESS = "FETCH_VET_VIEW_PROFILE_DETAILS_SUCCESS";
export const FETCH_VET_VIEW_PROFILE_DETAILS_FAIL = "FETCH_VET_VIEW_PROFILE_DETAILS_FAIL";

export const FETCH_STATES_HOSPITAL_PROFILE_REQUEST = "FETCH_STATES_HOSPITAL_PROFILE_REQUEST";
export const FETCH_STATES_HOSPITAL_PROFILE_SUCCESS = "FETCH_STATES_HOSPITAL_PROFILE_SUCCESS";
export const FETCH_STATES_HOSPITAL_PROFILE_FAIL = "FETCH_STATES_HOSPITAL_PROFILE_FAIL";

export const SHOW_HOSPITAL_BILLING_MODAL = "SHOW_HOSPITAL_BILLING_MODAL";
export const HIDE_HOSPITAL_BILLING_MODAL = "HIDE_HOSPITAL_BILLING_MODAL";

export const SHOW_PROFILE_PANEL_REQUEST = "SHOW_PROFILE_PANEL_REQUEST";
export const SHOW_PROFILE_PANEL = "SHOW_PROFILE_PANEL";
export const HIDE_PROFILE_PANEL = "HIDE_PROFILE_PANEL";

export const SUBMIT_VET_RATING = "SUBMIT_VET_RATING";
export const SUBMIT_VET_RATING_SUCCESS = "SUBMIT_VET_RATING_SUCCESS";
export const SUBMIT_VET_RATING_FAIL = "SUBMIT_VET_RATING_FAIL";

export const DEFER_VET_RATING = "DEFER_VET_RATING";
export const DEFER_VET_RATING_SUCCESS = "DEFER_VET_RATING_SUCCESS";
export const DEFER_VET_RATING_FAIL = "DEFER_VET_RATING_FAIL";

export const DEFER_ALL_RATING = "DEFER_ALL_RATING";
export const DEFER_ALL_RATING_SUCCESS = "DEFER_ALL_RATING_SUCCESS";
export const DEFER_ALL_RATING_FAIL = "DEFER_ALL_RATING_FAIL";

export const UPDATE_CURRENT_VET_RATING_INDEX = "UPDATE_CURRENT_VET_RATING_INDEX";
export const UPDATE_CURRENT_TECH_RATING_INDEX = "UPDATE_CURRENT_TECH_RATING_INDEX";

export const GET_HOSPITAL_PENDING_VET_RATING_REQUEST = "GET_HOSPITAL_PENDING_VET_RATING_REQUEST";
export const GET_HOSPITAL_PENDING_VET_RATING_SUCCESS = "GET_HOSPITAL_PENDING_VET_RATING_SUCCESS";
export const GET_HOSPITAL_PENDING_VET_RATING_FAIL = "GET_HOSPITAL_PENDING_VET_RATING_FAIL";

export const GET_VET_SHIFTS = "GET_VET_SHIFTS";
export const GET_VET_SHIFTS_FAIL = "GET_VET_SHIFTS_FAIL";
export const GET_VET_SHIFTS_SUCCESS = "GET_VET_SHIFTS_SUCCESS";

export const MOBILE_CONFIRM_VET_REQUEST = "MOBILE_CONFIRM_VET_REQUEST";
export const MOBILE_CONFIRM_VET_SUCCESS = "MOBILE_CONFIRM_VET_SUCCESS";
export const MOBILE_CONFIRM_VET_FAIL = "MOBILE_CONFIRM_VET_FAIL";

export const EDIT_SHIFT = "EDIT_SHIFT";
export const EDIT_SHIFT_FAIL = "EDIT_SHIFT_FAIL";
export const EDIT_SHIFT_SUCCESS = "EDIT_SHIFT_SUCCESS";

export const NON_PROFIT_VALUE = 3;
export const EMERGENCY_SPECIALITY = 4;

export const FETCH_HOSPITAL_SETTING_INFO = "FETCH_HOSPITAL_SETTING_INFO";
export const FETCH_HOSPITAL_SETTING_INFO_SUCCESS = "FETCH_HOSPITAL_SETTING_INFO_SUCCESS";
export const FETCH_HOSPITAL_SETTING_INFO_FAIL = "FETCH_HOSPITAL_SETTING_INFO_FAIL";

export const FETCH_MY_ACCOUNT_SETTING_INFO = "FETCH_MY_ACCOUNT_SETTING_INFO";
export const FETCH_MY_ACCOUNT_SETTING_INFO_SUCCESS = "FETCH_MY_ACCOUNT_SETTING_INFO_SUCCESS";
export const FETCH_MY_ACCOUNT_SETTING_INFO_FAIL = "FETCH_MY_ACCOUNT_SETTING_INFO_FAIL";

export const UPDATE_MY_ACCOUNT_SETTING_INFO = "UPDATE_MY_ACCOUNT_SETTING_INFO";
export const UPDATE_MY_ACCOUNT_SETTING_INFO_SUCCESS = "UPDATE_MY_ACCOUNT_SETTING_INFO_SUCCESS";
export const UPDATE_MY_ACCOUNT_SETTING_INFO_FAIL = "UPDATE_MY_ACCOUNT_SETTING_INFO_FAIL";

export const SET_LOCAL_HOSPITL_MICRO_DEPOSITS_STATUS = "SET_LOCAL_HOSPITAL_MICRO_DEPOSITS_STATUS";

export const ADD_HOSPITAL_CARD_REQUEST = "ADD_HOSPITAL_CARD_REQUEST";
export const ADD_HOSPITAL_CARD_SUCCESS = "ADD_HOSPITAL_CARD_SUCCESS";
export const ADD_HOSPITAL_CARD_FAIL = "ADD_HOSPITAL_CARD_FAIL";

export const DELETE_HOSPITAL_CARD_REQUEST = "DELETE_HOSPITAL_CARD_REQUEST";
export const DELETE_HOSPITAL_CARD_SUCCESS = "DELETE_HOSPITAL_CARD_SUCCESS";
export const DELETE_HOSPITAL_CARD_FAIL = "DELETE_HOSPITAL_CARD_FAIL";

export const UPDATE_HOSPITAL_SETTING_INFORMATION = "UPDATE_HOSPITAL_SETTING_INFORMATION";
export const UPDATE_HOSPITAL_SETTING_INFORMATION_SUCCESS =
  "UPDATE_HOSPITAL_SETTING_INFORMATION_SUCCESS";
export const UPDATE_HOSPITAL_SETTING_INFORMATION_FAIL = "UPDATE_HOSPITAL_SETTING_INFORMATION_FAIL";

export const CHANGE_PRIMARY_CONTACT = "CHANGE_PRIMARY_CONTACT";
export const CHANGE_PRIMARY_CONTACT_SUCCESS = "CHANGE_PRIMARY_CONTACT_SUCCESS";
export const CHANGE_PRIMARY_CONTACT_FAIL = "CHANGE_PRIMARY_CONTACT_FAIL";

export const UPDATE_BILLING_EMAILS = "UPDATE_BILLING_EMAILS";
export const UPDATE_BILLING_EMAILS_SUCCESS = "UPDATE_BILLING_EMAILS_SUCCESS";
export const UPDATE_BILLING_EMAILS_FAIL = "UPDATE_BILLING_EMAILS_FAIL";
export const RESET_UPDATE_BILLING_EMAILS_FLAG = "RESET_UPDATE_BILLING_EMAILS_FLAG";

export const SET_DEFAULT_CARD_REQUEST = "SET_DEFAULT_CARD_REQUEST";
export const SET_DEFAULT_CARD_SUCCESS = "SET_DEFAULT_CARD_SUCCESS";
export const SET_DEFAULT_CARD_FAIL = "SET_DEFAULT_CARD_FAIL";

export const INITIALIZE_ADD_SHIFT_STATE = "INITIALIZE_ADD_SHIFT_STATE";

export const DELETE_HOSPITAL_PROFILE_IMAGE_REQUEST = "DELETE_HOSPITAL_PROFILE_IMAGE_REQUEST";
export const DELETE_HOSPITAL_PROFILE_IMAGE_SUCCESS = "DELETE_HOSPITAL_PROFILE_IMAGE_SUCCESS";
export const DELETE_HOSPITAL_PROFILE_IMAGE_FAIL = "DELETE_HOSPITAL_PROFILE_IMAGE_FAIL";

export const UPDATE_HOSPITAL_PROFILE_REQUEST = "UPDATE_HOSPITAL_PROFILE_REQUEST";
export const UPDATE_HOSPITAL_PROFILE_SUCCESS = "UPDATE_HOSPITAL_PROFILE_SUCCESS";
export const UPDATE_HOSPITAL_PROFILE_FAIL = "UPDATE_HOSPITAL_PROFILE_FAIL";
export const CLOSE_SHIFT_ACTION_RESULT_MESSAGE_MODAL = "CLOSE_SHIFT_ACTION_RESULT_MESSAGE_MODAL";

export const INITIALIZE_SHIFT_CONFIRM_FLAG = "INITIALIZE_SHIFT_CONFIRM_FLAG";
export const SHOW_TRAINING_POPUP = "SHOW_TRAINING_POPUP";

export const GET_AUTO_CONFIRM_CONTRACTOR_LIST = "GET_AUTO_CONFIRM_CONTRACTOR_LIST";
export const GET_AUTO_CONFIRM_CONTRACTOR_LIST_FAIL = "GET_AUTO_CONFIRM_CONTRACTOR_LIST_FAIL";
export const GET_AUTO_CONFIRM_CONTRACTOR_LIST_SUCCESS = "GET_AUTO_CONFIRM_CONTRACTOR_LIST_SUCCESS";

export const ADD_PROVIDER_TO_AUTO_CONFIRM = "ADD_PROVIDER_TO_AUTO_CONFIRM";
export const ADD_PROVIDER_TO_AUTO_CONFIRM_FAIL = "ADD_PROVIDER_TO_AUTO_CONFIRM_FAIL";
export const ADD_PROVIDER_TO_AUTO_CONFIRM_SUCCESS = "ADD_PROVIDER_TO_AUTO_CONFIRM_SUCCESS";

export const SAVE_FUTURE_AUTO_CONFIRM = "SAVE_FUTURE_AUTO_CONFIRM";
export const SAVE_FUTURE_AUTO_CONFIRM_FAIL = "SAVE_FUTURE_AUTO_CONFIRM_FAIL";
export const SAVE_FUTURE_AUTO_CONFIRM_SUCCESS = "SAVE_FUTURE_AUTO_CONFIRM_SUCCESS";

export const DELETE_FUTURE_AUTO_CONFIRM = "DELETE_FUTURE_AUTO_CONFIRM";
export const DELETE_FUTURE_AUTO_CONFIRM_FAIL = "DELETE_FUTURE_AUTO_CONFIRM_FAIL";
export const DELETE_FUTURE_AUTO_CONFIRM_SUCCESS = "DELETE_FUTURE_AUTO_CONFIRM_SUCCESS";

export const DISABLE_FUTURE_AUTO_CONFIRM = "DISABLE_FUTURE_AUTO_CONFIRM";
export const DISABLE_FUTURE_AUTO_CONFIRM_FAIL = "DISABLE_FUTURE_AUTO_CONFIRM_FAIL";
export const DISABLE_FUTURE_AUTO_CONFIRM_SUCCESS = "DISABLE_FUTURE_AUTO_CONFIRM_SUCCESS";

export const ENABLE_FUTURE_AUTO_CONFIRM = "ENABLE_FUTURE_AUTO_CONFIRM";
export const ENABLE_FUTURE_AUTO_CONFIRM_FAIL = "ENABLE_FUTURE_AUTO_CONFIRM_FAIL";
export const ENABLE_FUTURE_AUTO_CONFIRM_SUCCESS = "ENABLE_FUTURE_AUTO_CONFIRM_SUCCESS";

export const ENABLE_FUTURE_AUTO_CONFIRM_RECOMMENDATION =
  "ENABLE_FUTURE_AUTO_CONFIRM_RECOMMENDATION";
export const ENABLE_FUTURE_AUTO_CONFIRM_RECOMMENDATION_SUCCESS =
  "ENABLE_FUTURE_AUTO_CONFIRM_RECOMMENDATION_SUCCESS";
export const ENABLE_FUTURE_AUTO_CONFIRM_RECOMMENDATION_FAIL =
  "ENABLE_FUTURE_AUTO_CONFIRM_RECOMMENDATION_FAIL";

export const CLOSE_FUTURE_AUTO_CONFIRM = "CLOSE_FUTURE_AUTO_CONFIRM";

export const GET_BLOCKED_CONTRACTOR_LIST = "GET_BLOCKED_CONTRACTOR_LIST";
export const GET_BLOCKED_CONTRACTOR_LIST_FAIL = "GET_BLOCKED_CONTRACTOR_LIST_FAIL";
export const GET_BLOCKED_CONTRACTOR_LIST_SUCCESS = "GET_BLOCKED_CONTRACTOR_LIST_SUCCESS";

export const REMOVE_BLOCKED_CONTRACTOR = "REMOVE_BLOCKED_CONTRACTOR";
export const REMOVE_BLOCKED_CONTRACTOR_FAIL = "REMOVE_BLOCKED_CONTRACTOR_FAIL";
export const REMOVE_BLOCKED_CONTRACTOR_SUCCESS = "REMOVE_BLOCKED_CONTRACTOR_SUCCESS";

export const FETCH_SHIFT_DETAILS = "FETCH_SHIFT_DETAILS";
export const FETCH_SHIFT_DETAILS_FAIL = "FETCH_SHIFT_DETAILS_FAIL";
export const FETCH_SHIFT_DETAILS_SUCCESS = "FETCH_SHIFT_DETAILS_SUCCESS";

export const GET_PLAID_TOKEN = "GET_PLAID_TOKEN";
export const GET_PLAID_TOKEN_FAIL = "GET_PLAID_TOKEN_FAIL";
export const GET_PLAID_TOKEN_SUCCESS = "GET_PLAID_TOKEN_SUCCESS";

export const ADD_PAYMENT_METHOD_REQUEST = "ADD_PAYMENT_METHOD_REQUEST";
export const ADD_PAYMENT_METHOD_FAIL = "ADD_PAYMENT_METHOD_FAIL";
export const ADD_PAYMENT_METHOD_SUCCESS = "ADD_PAYMENT_METHOD_SUCCESS";

export const SAVE_BILLING_EMAILS = "SAVE_BILLING_EMAILS";
export const SAVE_BILLING_EMAILS_FAIL = "SAVE_BILLING_EMAILS_FAIL";
export const SAVE_BILLING_EMAILS_SUCCESS = "SAVE_BILLING_EMAILS_SUCCESS";

export const INITIALIZE_HOSPITAL_SETTINGS = "INITIALIZE_HOSPITAL_SETTINGS";
export const INITIALIZE_HOSPITAL_BILLING_STATE = "INITIALIZE_HOSPITAL_BILLING_STATE";
export const INITIALIZE_DEFAULT_VET_RATING_COUNT = "INITIALIZE_DEFAULT_VET_RATING_COUNT";
export const INITIALIZE_DEFAULT_TECH_RATING_COUNT = "INITIALIZE_DEFAULT_TECH_RATING_COUNT";
export const INITIALIZE_RATING_MODAL_FLAG = "INITIALIZE_RATING_MODAL_FLAG";
export const INITIALIZE_VET_RATING_SUBMITTED_FLAG = "INITIALIZE_VET_RATING_SUBMITTED_FLAG";
export const INITIALIZE_TECH_RATING_SUBMITTED_FLAG = "INITIALIZE_TECH_RATING_SUBMITTED_FLAG";

export const GET_HOSPITAL_PENDING_REVIEWS_FLAG = "GET_HOSPITAL_PENDING_REVIEWS_FLAG";
export const GET_HOSPITAL_PENDING_REVIEWS_FLAG_SUCCESS =
  "GET_HOSPITAL_PENDING_REVIEWS_FLAG_SUCCESS";
export const GET_HOSPITAL_PENDING_REVIEWS_FLAG_FAIL = "GET_HOSPITAL_PENDING_REVIEWS_FLAG_FAIL";

export const UPDATE_HOSPITAL_PENDING_REVIEWS_FLAG = "UPDATE_HOSPITAL_PENDING_REVIEWS_FLAG";
export const UPDATE_HOSPITAL_PENDING_REVIEWS_FLAG_SUCCESS =
  "UPDATE_HOSPITAL_PENDING_REVIEWS_FLAG_SUCCESS";
export const UPDATE_HOSPITAL_PENDING_REVIEWS_FLAG_FAIL =
  "UPDATE_HOSPITAL_PENDING_REVIEWS_FLAG_FAIL";

export const SET_MODAL_OPEN_FLAG = "SET_MODAL_OPEN_FLAG";
export const RESET_MODAL_OPEN_FLAG = "RESET_MODAL_OPEN_FLAG";

export const HALF_SHIFT = "HalfShift";
export const FULL_SHIFT = "FullShift";

export const PRIMARILY_SMALL_ANIMAL_HOSPITAL = 0;
export const PRIMARILY_EXOTIC_ANIMAL_HOSPITAL = 1;
export const PRIMARILY_LARGE_ANIMAL_HOSPITAL = 2;
export const ANIMAL_WELFARE_SHELTER_NON_PROFIT_HOSPITAL = 3;
export const EMERGENCY_OR_SPECIALITY_HOSPITAL = 4;
export const OTHER_HOSPITAL = 5;

export const FETCH_SHIFT_COUNT_NOTIFICATION_REQUEST = "FETCH_SHIFT_COUNT_NOTIFICATION_REQUEST";
export const FETCH_SHIFT_COUNT_NOTIFICATION_FAIL = "FETCH_SHIFT_COUNT_NOTIFICATION_FAIL";
export const FETCH_SHIFT_COUNT_NOTIFICATION_SUCCESS = "FETCH_SHIFT_COUNT_NOTIFICATION_SUCCESS";

export const NOTIFICATION_MODAL_CLOSE = "NOTIFICATION_MODAL_CLOSE";
export const NOTIFICATION_MODAL_OPEN = "NOTIFICATION_MODAL_OPEN";

// --- TECH related

export const GET_TECH_SHIFTS = "GET_TECH_SHIFTS";
export const GET_TECH_SHIFTS_FAIL = "GET_TECH_SHIFTS_FAIL";
export const GET_TECH_SHIFTS_SUCCESS = "GET_TECH_SHIFTS_SUCCESS";

export const FETCH_ADD_TECH_SHIFT_REQUEST = "FETCH_ADD_TECH_SHIFT_REQUEST";
export const FETCH_ADD_TECH_SHIFT_SUCCESS = "FETCH_ADD_TECH_SHIFT_SUCCESS";
export const FETCH_ADD_TECH_SHIFT_FAIL = "FETCH_ADD_TECH_SHIFT_FAIL";
export const RESET_ADD_TECH_SHIFT_FAIL = "RESET_ADD_TECH_SHIFT_FAIL";

export const FETCH_PREVIOUS_TECH_SHIFT_REQUEST = "FETCH_PREVIOUS_TECH_SHIFT_REQUEST";
export const FETCH_PREVIOUS_TECH_SHIFT_SUCCESS = "FETCH_PREVIOUS_TECH_SHIFT_SUCCESS";
export const FETCH_PREVIOUS_TECH_SHIFT_FAIL = "FETCH_PREVIOUS_TECH_SHIFT_FAIL";

export const UPDATE_REQUESTED_SHIFTS_LOCAL = "UPDATE_REQUESTED_SHIFTS_LOCAL";
export const RESET_TECH_SHIFT_UPDATED_FLAG = "RESET_TECH_SHIFT_UPDATED_FLAG";

export const FETCH_TECH_VIEW_PROFILE_DETAILS_REQUEST = "FETCH_TECH_VIEW_PROFILE_DETAILS_REQUEST";
export const FETCH_TECH_VIEW_PROFILE_DETAILS_SUCCESS = "FETCH_TECH_VIEW_PROFILE_DETAILS_SUCCESS";
export const FETCH_TECH_VIEW_PROFILE_DETAILS_FAIL = "FETCH_TECH_VIEW_PROFILE_DETAILS_FAIL";

export const SUBMIT_TECH_RATING = "SUBMIT_TECH_RATING";
export const SUBMIT_TECH_RATING_SUCCESS = "SUBMIT_TECH_RATING_SUCCESS";
export const SUBMIT_TECH_RATING_FAIL = "SUBMIT_TECH_RATING_FAIL";

export const DEFER_TECH_RATING = "DEFER_TECH_RATING";
export const DEFER_TECH_RATING_SUCCESS = "DEFER_TECH_RATING_SUCCESS";
export const DEFER_TECH_RATING_FAIL = "DEFER_TECH_RATING_FAIL";

export const HOSPITAL_CONFIRM_TECH_REQUEST = "HOSPITAL_CONFIRM_TECH_REQUEST";
export const HOSPITAL_CONFIRM_TECH_SUCCESS = "HOSPITAL_CONFIRM_TECH_SUCCESS";
export const HOSPITAL_CONFIRM_TECH_FAIL = "HOSPITAL_CONFIRM_TECH_FAIL";

export const EDIT_TECH_SHIFT_REQUEST = "EDIT_TECH_SHIFT_REQUEST";
export const EDIT_TECH_SHIFT_REQUEST_FAIL = "EDIT_TECH_SHIFT_REQUEST_FAIL";
export const EDIT_TECH_SHIFT_REQUEST_SUCCESS = "EDIT_TECH_SHIFT_REQUEST_SUCCESS";

export const INITIALIZE_ADD_TECH_SHIFT_STATE = "INITIALIZE_ADD_TECH_SHIFT_STATE";

export const FETCH_TECH_SHIFT_DETAILS_FOR_EDIT = "FETCH_TECH_SHIFT_DETAILS_FOR_EDIT";
export const FETCH_TECH_SHIFT_DETAILS_FOR_EDIT_FAIL = "FETCH_TECH_SHIFT_DETAILS_FOR_EDIT_FAIL";
export const FETCH_TECH_SHIFT_DETAILS_FOR_EDIT_SUCCESS =
  "FETCH_TECH_SHIFT_DETAILS_FOR_EDIT_SUCCESS";

export const FETCH_TECH_SHIFT_COUNT_NOTIFICATION_REQUEST =
  "FETCH_TECH_SHIFT_COUNT_NOTIFICATION_REQUEST";
export const FETCH_TECH_SHIFT_COUNT_NOTIFICATION_FAIL = "FETCH_TECH_SHIFT_COUNT_NOTIFICATION_FAIL";
export const FETCH_TECH_SHIFT_COUNT_NOTIFICATION_SUCCESS =
  "FETCH_TECH_SHIFT_COUNT_NOTIFICATION_SUCCESS";

export const GET_HOSPITAL_PENDING_TECH_RATING_REQUEST = "GET_HOSPITAL_PENDING_TECH_RATING_REQUEST";
export const GET_HOSPITAL_PENDING_TECH_RATING_SUCCESS = "GET_HOSPITAL_PENDING_TECH_RATING_SUCCESS";
export const GET_HOSPITAL_PENDING_TECH_RATING_FAIL = "GET_HOSPITAL_PENDING_TECH_RATING_FAIL";
// --- end TECH related

export const INITIALIZE_HOSPITAL_PROFILE_STATE = "INITIALIZE_HOSPITAL_PROFILE_STATE";

export const DOWNLOAD_PDF_REQUEST = "DOWNLOAD_PDF_REQUEST";
export const DOWNLOAD_PDF_FAIL = "DOWNLOAD_PDF_FAIL";
export const DOWNLOAD_PDF_SUCCESS = "DOWNLOAD_PDF_SUCCESS";

// export const FETCH_RENEW_SHIFTS_REQUEST = "FETCH_RENEW_SHIFTS_REQUEST";
// export const FETCH_RENEW_SHIFTS_SUCCESS = "FETCH_RENEW_SHIFTS_SUCCESS";
// export const FETCH_RENEW_SHIFTS_FAIL = "FETCH_RENEW_SHIFTS_FAIL";

// export const UPDATE_RENEW_SHIFTS_FLAG_REQUEST = "UPDATE_RENEW_SHIFTS_FLAG_REQUEST";
// export const UPDATE_RENEW_SHIFTS_FLAG_SUCCESS = "UPDATE_RENEW_SHIFTS_FLAG_SUCCESS";
// export const UPDATE_RENEW_SHIFTS_FLAG_FAIL = "UPDATE_RENEW_SHIFTS_FLAG_FAIL";

// export const UPDATE_RENEW_SHIFT_DATE_REQUEST = "UPDATE_RENEW_SHIFT_DATE_REQUEST";
// export const UPDATE_RENEW_SHIFT_DATE_SUCCESS = "UPDATE_RENEW_SHIFT_DATE_SUCCESS";
// export const UPDATE_RENEW_SHIFT_DATE_FAIL = "UPDATE_RENEW_SHIFT_DATE_FAIL";

// export const UPDATE_RENEW_BUTTON_REQUEST = "UPDATE_RENEW_BUTTON_REQUEST";
// export const UPDATE_RENEW_BUTTON_SUCCESS = "UPDATE_RENEW_BUTTON_SUCCESS";
// export const UPDATE_RENEW_BUTTON_FAIL = "UPDATE_RENEW_BUTTON_FAIL";

export const CLOSE_VET_RATING_MODAL = "CLOSE_VET_RATING_MODAL";
export const CLOSE_TECH_RATING_MODAL = "CLOSE_TECH_RATING_MODAL";

export const UPDATE_HOSPITAL_PROFILE_IMAGE_SUCCESS = "UPDATE_HOSPITAL_PROFILE_IMAGE_SUCCESS";
export const UPDATE_HOSPITAL_PROFILE_IMAGE_FAIL = "UPDATE_HOSPITAL_PROFILE_IMAGE_FAIL";

export const UPDATE_HOSPITAL_SHIFT_IMAGE = "UPDATE_HOSPITAL_SHIFT_IMAGE";
export const SET_PROFILE_IMAGE = "SET_PROFILE_IMAGE";

export const ARRANGE_HOSPITAL_IMAGES = "ARRANGE_HOSPITAL_IMAGES";

export const HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_REQUEST =
  "HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_REQUEST";
export const HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_SUCCESS =
  "HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_SUCCESS";
export const HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_FAIL =
  "HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_FAIL";
export const RESET_HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_ACTION =
  "RESET_HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_ACTION";
export const SET_RELOAD_TECH_SHIFTS_FLAG = "SET_RELOAD_TECH_SHIFTS_FLAG";

export const BLOCK_CONTRACTOR = "BLOCK_CONTRACTOR";
export const BLOCK_CONTRACTOR_SUCCESS = "BLOCK_CONTRACTOR_SUCCESS";
export const BLOCK_CONTRACTOR_FAIL = "BLOCK_CONTRACTOR_FAIL";
export const HIDE_BLOCK_TECHVET_CONFIRMATION_MODAL = "HIDE_BLOCK_TECHVET_CONFIRMATION_MODAL";

export const UPDATE_BLOCKED_CONTRACTOR_COUNT = "UPDATE_BLOCKED_CONTRACTOR_COUNT";
export const SET_BLOCKED_CONTRACTOR_COUNT = "SET_BLOCKED_CONTRACTOR_COUNT";

export const SUBMIT_OVERTIME = "SUBMIT_OVERTIME";
export const SUBMIT_OVERTIME_SUCCESS = "SUBMIT_OVERTIME_SUCCESS";
export const SUBMIT_OVERTIME_FAIL = "SUBMIT_OVERTIME_FAIL";

export const UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_REQUEST =
  "UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_REQUEST";
export const UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_SUCCESS =
  "UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_SUCCESS";
export const UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_FAIL =
  "UPDATE_HOSPITAL_INTERNSHIPS_AVAILABILITY_FAIL";
export const BULK_DELETE_SHIFTS_H = "BULK_DELETE_SHIFTS_H";
export const BULK_DELETE_SHIFTS_SUCCESS = "BULK_DELETE_SHIFTS_SUCCESS";
export const BULK_DELETE_SHIFTS_FAIL = "BULK_DELETE_SHIFTS_FAIL";

export const HOSPITAL_RESPONSE_RATING_DATA = "HOSPITAL_RESPONSE_RATING_DATA";
export const HOSPITAL_RESPONSE_RATING_DATA_SUCCESS = "HOSPITAL_RESPONSE_RATING_DATA_SUCCESS";
export const HOSPITAL_RESPONSE_RATING_DATA_FAIL = "HOSPITAL_RESPONSE_RATING_DATA_FAIL";

export const HOSPITAL_RESPONSE_RATING_COMMENTS = "HOSPITAL_RESPONSE_RATING_COMMENTS";
export const HOSPITAL_RESPONSE_RATING_COMMENTS_SUCCESS =
  "HOSPITAL_RESPONSE_RATING_COMMENTS_SUCCESS";
export const HOSPITAL_RESPONSE_RATING_COMMENTS_FAIL = "HOSPITAL_RESPONSE_RATING_COMMENTS_FAIL";
export const SHIFT_IDS_BY_HOSPITAL_ID = "SHIFT_IDS_BY_HOSPITAL_ID";
export const SHIFT_IDS_BY_HOSPITAL_ID_SUCCESS = "SHIFT_IDS_BY_HOSPITAL_ID_SUCCESS";
export const SHIFT_IDS_BY_HOSPITAL_ID_FAIL = "SHIFT_IDS_BY_HOSPITAL_ID_FAIL";

export const TECH_SHIFT_IDS_BY_HOSPITAL_ID = "TECH_SHIFT_IDS_BY_HOSPITAL_ID";
export const TECH_SHIFT_IDS_BY_HOSPITAL_ID_SUCCESS = "TECH_SHIFT_IDS_BY_HOSPITAL_ID_SUCCESS";
export const TECH_SHIFT_IDS_BY_HOSPITAL_ID_FAIL = "TECH_SHIFT_IDS_BY_HOSPITAL_ID_FAIL";

export const BOOST_SHIFT = "BOOST_SHIFT";
export const BOOST_SHIFT_FAIL = "BOOST_SHIFT_FAIL";
export const BOOST_SHIFT_SUCCESS = "BOOST_SHIFT_SUCCESS";
export const RESET_BOOST_SHIFT = "RESET_BOOST_SHIFT";

export const HOSPITAL_RESPONSE_RATING = "HOSPITAL_RESPONSE_RATING";
export const HOSPITAL_RESPONSE_RATING_SUCCESS = "HOSPITAL_RESPONSE_RATING_SUCCESS";
export const HOSPITAL_RESPONSE_RATING_FAIL = "HOSPITAL_RESPONSE_RATING_FAIL";

export const UPDATE_HOSPITAL_RESPONSE_RATING_STATUS = "UPDATE_HOSPITAL_RESPONSE_RATING_STATUS";
export const UPDATE_HOSPITAL_RESPONSE_RATING_STATUS_SUCCESS =
  "UPDATE_HOSPITAL_RESPONSE_RATING_STATUS_SUCCESS";
export const UPDATE_HOSPITAL_RESPONSE_RATING_STATUS_FAIL =
  "UPDATE_HOSPITAL_RESPONSE_RATING_STATUS_FAIL";

export const GET_THREE_MONTHS_HOSPITAL_SHIFTS = "GET_THREE_MONTHS_HOSPITAL_SHIFTS";
export const GET_THREE_MONTHS_HOSPITAL_SHIFTS_FAIL = "GET_THREE_MONTHS_HOSPITAL_SHIFTS_FAIL";
export const GET_THREE_MONTHS_HOSPITAL_SHIFTS_SUCCESS = "GET_THREE_MONTHS_HOSPITAL_SHIFTS_SUCCESS";

export const CLEAR_BULK_DELETE_DATA = "CLEAR_BULK_DELETE_DATA";

export const BOOST_ALL_SHIFTS = "BOOST_ALL_SHIFTS";
export const BOOST_ALL_SHIFTS_SUCCESS = "BOOST_ALL_SHIFTS_SUCCESS";
export const BOOST_ALL_SHIFTS_SUCCESS_NO_MODAL = "BOOST_ALL_SHIFTS_SUCCESS_NO_MODAL";
export const BOOST_ALL_SHIFTS_FAIL = "BOOST_ALL_SHIFTS_FAIL";

export const CLEAR_BOOST_ALL_DATA = "CLEAR_BOOST_ALL_DATA";

export const BOOST_ALL_TECH_SHIFTS = "BOOST_ALL_TECH_SHIFTS";
export const BOOST_ALL_TECH_SHIFTS_SUCCESS = "BOOST_ALL_TECH_SHIFTS_SUCCESS";
export const BOOST_ALL_TECH_SHIFTS_SUCCESS_NO_MODAL = "BOOST_ALL_TECH_SHIFTS_SUCCESS_NO_MODAL";
export const BOOST_ALL_TECH_SHIFTS_FAIL = "BOOST_ALL_TECH_SHIFTS_FAIL";

export const CLEAR_BOOST_ALL_TECH_DATA = "CLEAR_BOOST_ALL_TECH_DATA";

export const PENDING_REVIEW_DATA = "PENDING_REVIEW_DATA";
export const PENDING_REVIEW_DATA_SUCCESS = "PENDING_REVIEW_DATA_SUCCESS";
export const PENDING_REVIEW_DATA_FAIL = "PENDING_REVIEW_DATA_FAIL";

export const TECH_PENDING_REVIEW_DATA = "TECH_PENDING_REVIEW_DATA";
export const TECH_PENDING_REVIEW_DATA_SUCCESS = "TECH_PENDING_REVIEW_DATA_SUCCESS";
export const TECH_PENDING_REVIEW_DATA_FAIL = "TECH_PENDING_REVIEW_DATA_FAIL";

export const UPDATE_HOSPITAL_TOUR_STATUS = "UPDATE_HOSPITAL_TOUR_STATUS";
export const UPDATE_HOSPITAL_TOUR_STATUS_SUCCESS = "UPDATE_HOSPITAL_TOUR_STATUS_SUCCESS";
export const UPDATE_HOSPITAL_TOUR_STATUS_FAIL = "UPDATE_HOSPITAL_TOUR_STATUS_FAIL";

export const UPDATE_ROO_UNI_CALLOUT_STATUS = "UPDATE_ROO_UNI_CALLOUT_STATUS";
export const UPDATE_ROO_UNI_CALLOUT_STATUS_SUCCESS = "UPDATE_ROO_UNI_CALLOUT_STATUS_SUCCESS";
export const UPDATE_ROO_UNI_CALLOUT_STATUS_FAIL = "UPDATE_ROO_UNI_CALLOUT_STATUS_FAIL";

export const UPDATE_HAS_PAYMENT_FAILED_FLAG = "UPDATE_HAS_PAYMENT_FAILED_FLAG";
export const GET_HAS_PAYMENT_FAILED_FLAG_REQUEST = "GET_HAS_PAYMENT_FAILED_FLAG_REQUEST";
export const GET_HAS_PAYMENT_FAILED_FLAG_SUCCESS = "GET_HAS_PAYMENT_FAILED_FLAG_SUCCESS";
export const GET_HAS_PAYMENT_FAILED_FLAG_FAIL = "GET_HAS_PAYMENT_FAILED_FLAG_FAIL";

export const CREATE_HOSPITAL_INVOICED_CUSTOMER = "CREATE_HOSPITAL_INVOICED_CUSTOMER";
export const CREATE_HOSPITAL_INVOICED_CUSTOMER_SUCCESS =
  "CREATE_HOSPITAL_INVOICED_CUSTOMER_SUCCESS";
export const CREATE_HOSPITAL_INVOICED_CUSTOMER_FAIL = "CREATE_HOSPITAL_INVOICED_CUSTOMER_FAIL";

export const GET_INVOICED_CUSTOMER = "GET_INVOICED_CUSTOMER";
export const GET_INVOICED_CUSTOMER_SUCCESS = "GET_INVOICED_CUSTOMER_SUCCESS";
export const GET_INVOICED_CUSTOMER_FAIL = "GET_INVOICED_CUSTOMER_FAIL";

export const ADD_CANCELLATION_SHIFT_ID = "ADD_CANCELLATION_SHIFT_ID";

export const GET_REFUND_AMOUNT = "GET_REFUND_AMOUNT";
export const GET_REFUND_AMOUNT_SUCCESS = "GET_REFUND_AMOUNT_SUCCESS";
export const GET_REFUND_AMOUNT_FAIL = "GET_REFUND_AMOUNT_FAIL";
