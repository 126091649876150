import React from "react";
import { Helmet } from "react-helmet-async";
import { Route, RouteProps, useLocation } from "react-router-dom";

import { AuthUtility } from "../helpers/AuthUtility";

const DEFAULT_TITLE = "Roo";
const indexForHelmet = window.RooConfig.WHICH_ENV === "prod" ? "index" : "noindex,nofollow";

export interface ProtectedRouteProps<C extends React.ComponentType<any>> extends RouteProps {
  title?: string;
  component: C;
  componentProps?: React.ComponentProps<C>;
}

const getTitleForHelmet = (title?: string) => {
  return [title || "", DEFAULT_TITLE].filter((item) => item.trim() !== "").join(" | ");
};

const ProtectedRoute = <C extends React.ComponentType<any>>({
  title,
  component: Component,
  componentProps,
  ...routeProps
}: ProtectedRouteProps<C>) => {
  const { pathname, search } = useLocation();
  const titleForHelmet = getTitleForHelmet(title);

  return (
    <Route
      {...routeProps}
      render={(routeComponentProps) => {
        const isAuthorisedUser = AuthUtility.isAuthorisedUser();

        if (!isAuthorisedUser) {
          const redirectUrl = pathname + search;

          if (
            localStorage.getItem("redirectUrl") !== redirectUrl &&
            !redirectUrl.startsWith("/login")
          ) {
            localStorage.setItem("redirectUrl", redirectUrl);
          }

          window.location.replace(`/login/${new Date().getTime()}`);
          return null;
        }

        const props = {
          ...componentProps,
          ...routeComponentProps,
        } as React.ComponentProps<C>;

        return (
          <>
            <Helmet>
              <title>{titleForHelmet}</title>
              <meta name="robots" content={indexForHelmet} />
            </Helmet>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

// eslint-disable-next-line no-restricted-exports
export default ProtectedRoute;
