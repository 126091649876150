import React from "react";
import { Heading, SmallText } from "@RooUI";

import { SubHeaderContainer } from "../../EditProfile/styles";
import { Shift } from "../interfaces";

import ToggleRowNew from "./ToggleRow";

interface NotificationRowProps {
  settingKey: string;
  settingValue: Shift;
  handleToggleChange?: (params: {
    label: string;
    value: boolean;
    notificationTypeId: number;
  }) => void;
  renderOnlyToggle?: boolean;
}

export const NotificationRow: React.FC<NotificationRowProps> = ({
  settingKey,
  settingValue,
  handleToggleChange,
  renderOnlyToggle,
}) => {
  return (
    <React.Fragment key={settingKey}>
      <SubHeaderContainer>
        {!renderOnlyToggle && (
          <>
            <Heading level={5}>{settingKey}</Heading>
            {settingValue?.label?.length > 0 && <SmallText>{settingValue.label}</SmallText>}
          </>
        )}

        <ToggleRowNew
          toggleOptions={settingValue?.toggleOptions}
          handleToggleChange={handleToggleChange}
        />
      </SubHeaderContainer>
    </React.Fragment>
  );
};
