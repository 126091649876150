import React, { useEffect } from "react";
import { Container } from "@RooUI";

import { OnHospitalHubSpotFormSubmitted } from "./EventSignUp";

const HUBSPOT_REGION = "na1";
const HUBSPOT_PORTAL_ID = "5920186";

export const HubSpotForm = ({
  formId,
  onSuccess,
}: {
  formId: string;
  onSuccess: OnHospitalHubSpotFormSubmitted;
}) => {
  const containerId = `hubspot-form-${formId}`;

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    script.async = true;

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: HUBSPOT_REGION,
          portalId: HUBSPOT_PORTAL_ID,
          formId: formId,
          target: `#${containerId}`,
          cssClass: "hs-form",
          css: ".hs-form input[type=text], .hs-form input[type=email], .hs-form select { width: 100%; background-color: white; border: 2px solid rgb(217, 212, 234); border-radius: 6px; font-size: 14px; line-height: 20px; padding: 12px 8px; height: 48px; margin: 8px 0; } .hs-form label { font-size: 16px; line-height: 24px; font-weight: 500; margin: 8px 0 0 0; } .hs-form input[type=submit] { margin-top: 10px; } .hs-form ul { list-style-type: none; padding: 0; } .hs-form label.hs-error-msg { font-size: 12px; line-height: 20px; color: #E81616; font-weight: 500; margin-top: 0; } .hs-form input.error { border-color: #E81616; } .hs-form fieldset .input { margin-right: 16px !important; } .hbspt-form p { font-size: 16px; line-height: 24px; }",
          submitButtonClass: "roo-button primary",
        });
      }
    };

    document.body.appendChild(script);

    window.addEventListener("message", (event) => {
      if (event.data.type === "hsFormCallback" && event.data.eventName === "onFormSubmitted") {
        onSuccess();
      }
    });

    return () => {
      document.body.removeChild(script);
    };
  }, [containerId, formId, onSuccess]);

  return (
    <Container>
      <div id={containerId} />
    </Container>
  );
};
