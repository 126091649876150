import React, { FC } from "react";

import { Icon } from "../Icon";
import { redBase } from "../../styles/constants";

import { ErrorTextStyles } from "./ErrorTextStyles";

export const ErrorText: FC<{ children?: React.ReactNode }> = ({ children }) => (
  <ErrorTextStyles show={!!children}>
    <Icon name="Error" size={"s"} color={redBase} />
    {children}
  </ErrorTextStyles>
);
