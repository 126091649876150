import styled from "styled-components";

import { purpleLight50, spacingL, spacingS, spacingXS } from "../../styles/constants";

export const StyledMultiSelect = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContainer = styled.div`
  margin-top: ${spacingL};
  display: flex;
  flex-direction: column;
  gap: ${spacingS};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${purpleLight50};
  border-radius: ${spacingXS};
  padding: ${spacingXS} ${spacingS};
`;
