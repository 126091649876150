import { rooFetch } from "../common/rooFetch";

export type Conversation = {
  id: number;
  profileImage?: string;
  hospitalName?: string;
  hospitalId: number;
  participants: { name: string; userId: number; userTypeId: string }[];
  messages: {
    text: string;
    id: number;
    read: boolean;
    createdAt: string;
    participant: { name: string; userId: number; userTypeId: string };
  }[];
};

export const getChatMessages = async (
  userEmail: string,
  hospitalId: number
): Promise<Conversation[]> => {
  const response = await rooFetch(
    `${window.RooConfig.MESSAGING_API_URL}/api/conversation/messages?userEmail=${encodeURIComponent(
      userEmail
    )}&hospitalId=${hospitalId || ""}`
  );

  if (response.status !== 200) {
    throw new Error("Api failed");
  }

  return response.json();
};
