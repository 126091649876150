export const HOSPITAL_USER_TYPE = 1;
export const VET_USER_TYPE = 2;
export const ADMIN_USER_TYPE = 3;
export const TECH_USER_TYPE = 4;
export const STUDENT_USER_TYPE = 5;
export const ROO_SUPER_USER_TYPE = 6;

export const USER_TYPE = {
  HOSPITAL_USER_TYPE,
  VET_USER_TYPE,
  ADMIN_USER_TYPE,
  TECH_USER_TYPE,
  STUDENT_USER_TYPE,
  ROO_SUPER_USER_TYPE,
} as const;

export type UserTypeName = "hospital" | "vet" | "admin" | "tech" | "student" | "superuser";

export const userTypeNames: { [key: number]: UserTypeName } = {
  [HOSPITAL_USER_TYPE]: "hospital",
  [VET_USER_TYPE]: "vet",
  [ADMIN_USER_TYPE]: "admin",
  [TECH_USER_TYPE]: "tech",
  [STUDENT_USER_TYPE]: "student",
  [ROO_SUPER_USER_TYPE]: "superuser",
};

export type UserType = (typeof USER_TYPE)[keyof typeof USER_TYPE];

export const isUserType = (value: any): value is UserType =>
  Object.values(USER_TYPE).includes(value);
