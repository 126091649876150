import React from "react";
import { Select, SelectProps } from "@RooUI";

import { StateOption } from "../EditProfile/service";

import { useStates } from "./hooks/useStates";

export const StateSelect = (props: Omit<SelectProps<StateOption>, "options">) => {
  const { stateOptions, loading } = useStates();
  return (
    <Select
      label="State"
      options={stateOptions}
      searchable
      clearable
      {...props}
      // Prevent visual artifact of initial value by not rendering placeholder/value until states are fetched
      // If no value is provided, then we can display the placeholder immediately
      // Remove this once we implement react-query/RTK query/etc.
      placeholder={loading && props.value ? undefined : props.placeholder}
      value={loading ? undefined : props.value}
      additionalSearchFilterFields={["stateCode"]}
    />
  );
};
