import style from "styled-components";

import {
  blueBackground,
  grayBase,
  purpleBackground,
  purpleBase,
  spacingS,
} from "../../styles/constants";

export const NestedTabContainer = style.div`
    display: flex;
    flex-direction: row;
`;

export const TabAndPanelContainer = style.div`
    width: 100%;
    display: grid;
    grid-template-rows: min-content 1fr;
    overflow-y: auto;
    height: 100%;
`;

const BaseBadge = style.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 28px;
  height: 28px;
  border-radius: 50%;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  margin-left: ${spacingS};
  padding: 0 6px;
`;

export const AlertBadge = style(BaseBadge)`
  background-color: ${purpleBase};
  color: ${blueBackground};
`;

export const MutedAlertBadge = style(BaseBadge)`
  background-color: ${purpleBackground};
  color: ${grayBase};
`;
