import React from "react";

export const Mds360Round = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px"
    fill="#e8eaed"
    {...props}
  >
    <path d="M394-291q-136-15-225-66T80-486q0-79 116.5-134.5T480-676q168 0 284 55.5T880-486q0 54-54.5 97T679-320q-12 4-22.5-3.5T646-344q0-14 8.5-25t22.5-16q66-20 104.5-49t38.5-52q0-32-83.5-81T480-616q-172 0-256 49t-84 81q0 45 57.5 77.5T397-349l-61-60q-9-9-9-21.5t9-21.5q9-9 21-9t21 9l111 110q9 9 9 21t-9 21L378-189q-9 9-21.5 9t-21.5-9q-9-9-9-21t9-21l59-60Z" />
  </svg>
);
Mds360Round.isCustomSvg = true;
