import { z } from "zod";

import { POST, Schemas } from "@roo-dev/roo-node-types";

import { type CustomCheckbox, type DaysOfWeekCheckbox } from "../../constants/checkBoxConstants";

export type Meridiem = "AM" | "PM";

export type Time = {
  hour: string;
  minute: string;
  meridiem: Meridiem;
};

export type Rank = z.infer<typeof Schemas.Search.rankSchema>;

// TODO: Move this to "@roo-dev/roo-node-types".
const ranks = ["price", "date", "distance", "recommended", "rating"] as const;

export const isRank = (rank: string): rank is Rank => ranks.includes(rank as Rank);

export type BasicSearch = {
  zipcode: string;
  miles: number;
  startDate: Date;
  endDate: Date;
  rank: Rank;
  usePreferences?: boolean;
};

export type AdvancedSearch = {
  // Checkbox[]
  expectedAppointmentTypeId: CustomCheckbox[];
  typeOfHospital: CustomCheckbox[];
  daysOfWeek: DaysOfWeekCheckbox[];
  // boolean
  isBoostedShiftsOnly: boolean;
  isDEALicenseRequired: boolean;
  isDEALicenseNotRequired?: boolean;
  isNoWalkInsAllowed?: boolean;
  isStateWhereLicensed?: boolean;
  isFearFreeCertified: boolean;
  isFavoritesOnly: boolean;
  isInstantBookable: boolean;
  additionalDoctor: boolean;
  // time
  startTimeInputHour: string;
  startTimeInputMinute: string;
  startTimeInputPeriod: Meridiem;
  endTimeInputHour: string;
  endTimeInputMinute: string;
  endTimeInputPeriod: Meridiem;
  // number
  minPay: number | null;
  // string
  hospitalName: string[];
};

export type Search = BasicSearch & AdvancedSearch;

export type VetSearch = BasicSearch & AdvancedSearch;

export type TechSearch = BasicSearch;

export const checkboxGroups = [
  "expectedAppointmentTypeId",
  "typeOfHospital",
  "daysOfWeek",
] as const;

export type CheckboxGroup = (typeof checkboxGroups)[number];

export const booleanFilters = [
  "isBoostedShiftsOnly",
  "isDEALicenseRequired",
  "isDEALicenseNotRequired",
  "isNoWalkInsAllowed",
  "isStateWhereLicensed",
  "isFearFreeCertified",
  "isFavoritesOnly",
  "isInstantBookable",
  "additionalDoctor",
] as const;

export type BooleanFilter = (typeof booleanFilters)[number];

export const stringFilters = ["hospitalName"] as const;

export const numberFilters = ["minPay"] as const;

export type NumberFilter = (typeof numberFilters)[number];

export const timeComponents = [
  "startTimeInputHour",
  "startTimeInputMinute",
  "startTimeInputPeriod",
  "endTimeInputHour",
  "endTimeInputMinute",
  "endTimeInputPeriod",
] as const;

export type TimeComponent = (typeof timeComponents)[number];

/**
 * SaveSearch
 */

export const savedSearchParams = [
  "zipcode",
  "miles",
  "startDate",
  "endDate",
  "rank",
  ...checkboxGroups,
  ...booleanFilters,
  ...stringFilters,
  ...numberFilters,
  "startTime",
  "endTime",
  "usePreferences",
] as const;

export type SavedSearchParam = (typeof savedSearchParams)[number];

export const isBooleanFilter = (key: string): key is BooleanFilter =>
  booleanFilters.includes(key as BooleanFilter);

export const isNumberFilter = (key: string): key is NumberFilter =>
  numberFilters.includes(key as NumberFilter);

// Roo-Node-Types

export type SearchShiftsParameters = POST.Vet.Shift.SearchShiftsRequestBody & {
  usePreferences?: boolean;
  isDEALicenseNotRequired?: boolean;
  isNoWalkInsAllowed?: boolean;
  isStateWhereLicensed?: boolean;
  shiftDates: string;
};
