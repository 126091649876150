import { Select } from "antd";
import React from "react";
import styled from "styled-components";

import {
  BodyText,
  blueDark600,
  blueLightest,
  disabledTextDark,
  grayBackground,
  grayBase,
  grayLight300,
  inputBorderRadius,
  primaryFont,
  redBase,
} from "../../styles/constants";
import { Icon } from "../Icon";

interface DropdownMenuProps {
  menu: React.ReactNode;
  testId: string;
}

export const StyledSelect = styled(Select)`
  &.ant-select-single.ant-select-lg,
  &.ant-select-multiple.ant-select-lg {
    width: clamp(90px, 100%, 780px);
    min-height: 48px;
  }

  .ant-select-selector {
    width: 100%;
    border-radius: ${inputBorderRadius} !important;
    border: 2px solid ${blueLightest} !important;
    outline: ${blueDark600} !important;
    font-family: ${primaryFont} !important;
  }
  &:not(.ant-select-disabled):hover .ant-select-selector,
  &:not(.ant-select-disabled).ant-select-focused .ant-select-selector {
    border-color: ${blueDark600} !important;
  }

  &:not(.ant-select-disabled).ant-select-status-error .ant-select-selector {
    border: 2px solid ${redBase} !important;
  }
  .ant-select-clear {
    width: 24px;
    height: 24px;
    margin-top: -12px;
  }
  &.ant-select-disabled .ant-select-selector {
    border: 2px solid ${blueLightest} !important;
    background-color: ${grayBackground} !important;
    color: ${disabledTextDark} !important;
  }
  .ant-select-selection-placeholder {
    ${BodyText}
    color: ${(props) => `${props.status == "error" ? redBase : grayLight300}`};
  }
` as typeof Select;

export const StyledDropDown = styled.div`
  .ant-select-item {
    border-radius: 0;
    font-family: ${primaryFont} !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${blueDark600};
    color: #fff;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled):hover {
    background-color: ${blueLightest};
    color: ${grayBase};
    font-weight: normal;
  }
`;

export const MultiSelectChipContainer = styled.div`
  margin-right: 4px;
  svg {
    margin-left: 2px;
  }
`;

export const getArrowColor = (error: boolean, disabled?: boolean) => {
  let color = grayBase;
  if (error) color = redBase;
  else if (disabled) color = disabledTextDark;
  return color;
};

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ testId, menu }) => (
  <div data-testid={testId}>
    <StyledDropDown>{menu}</StyledDropDown>
  </div>
);

export const ToggleIcon = ({ color }: { color: string }) => (
  <Icon color={color} name="ExpandMore" />
);
export const ClearIcon = () => <Icon name="Close" />;
