import { USER_TYPE, UserType } from "../../../constants/UserTypeConstants";
import { AuthUtility } from "../../../helpers/AuthUtility";

import { type RegistrationContextStateType, useRegistrationContext } from "./registrationContext";

const registrationSteps = [
  "Login",
  "Landing",
  "SignUp",
  "Location",
  "License",
  "VetPreferences",
  "Education",
  "Calendly",
  "Hireflix",
  "Referral",
  "Continue",
  "MeetRooRep",
] as const;

export type RegistrationStep = (typeof registrationSteps)[number];

export const REGISTRATION_PATHS: Record<RegistrationStep, string> = {
  Login: "/login",
  Landing: "/register",
  SignUp: "/register/sign-up",
  Location: "/register/location",
  License: "/register/license",
  VetPreferences: "/register/vetPreferences",
  Education: "/register/education",
  Calendly: "/register/calendly",
  Referral: "/register/referral",
  Hireflix: "/register/hireflix",
  Continue: "/register/continue",
  MeetRooRep: "/register/meet-roo-rep",
};

export const REGISTRATION_PROGRESS_PERCENT = {
  Landing: 20,
  SignUp: 40,
  Location: 60,
  License: 70,
  VetPreferences: 80,
  Education: 90,
  MeetRooRep: 90,
  Referral: 100,
};

export const getIsEntity = (userType?: UserType) => {
  return {
    isVet: userType === USER_TYPE.VET_USER_TYPE,
    isTech: userType === USER_TYPE.TECH_USER_TYPE,
    isHospital: userType === USER_TYPE.HOSPITAL_USER_TYPE,
    isStudent: userType === USER_TYPE.STUDENT_USER_TYPE,
  };
};

export const useIsEntity = () => {
  const {
    registrationContext: { userType },
  } = useRegistrationContext();
  return getIsEntity(userType);
};

export const saveRegistrationContextToLocalStorage = ({
  userType,
  userId,
  email,
  entityId,
  zipcode,
}: RegistrationContextStateType["registrationContext"]) => {
  const { isVet, isTech } = getIsEntity(userType);

  AuthUtility.setIsAuthorisedUser(true);

  // Save data to local storage for integration with the rest of the app.
  localStorage.setItem("isShowOnboarding", "0");

  if (userType) {
    localStorage.setItem("userTypeId", userType.toString());
  }

  if (userId && userId > 0) {
    localStorage.setItem("userId", String(userId));
  }

  if (email) {
    localStorage.setItem("email", email);
  }

  if (isVet && entityId && entityId > 0) {
    localStorage.setItem("vetId", String(entityId));
  } else if (isTech && entityId && entityId > 0) {
    localStorage.setItem("techId", String(entityId));
  }

  if (zipcode) {
    localStorage.setItem("zipcode", zipcode);
  }
};
