import moment from "moment";

export const SET_TOAST_DATA = "SET_TOAST_DATA";

export const CLEAR_TOAST_DATA = "CLEAR_TOAST_DATA";

export const PROMO_SHIFT_REQUEST = {
  variant: "success",
  message: "You made a promo shift request!",
  description: "Complete it to progress your current promo.",
};

export const shiftNotAvailableToasts = (
  shiftRequestResponseMessage: string,
  requestedDateOfShiftNotAvailable: string
) => {
  let toastMessage = "";
  let toastDescription = "";
  switch (shiftRequestResponseMessage) {
    case "not available":
    case "user blocked":
      toastMessage = "Sorry, shift not available";
      toastDescription = "This shift was recently fulfilled";
      break;
    case "max overlaps":
      toastMessage = `Too many requests on ${moment(requestedDateOfShiftNotAvailable).format(
        "MMM DD"
      )}`;
      toastDescription = "If one expires, you may add more (5 max)";
      break;
    case "shift within 8 hours":
      toastMessage = "Starts/ends too close to other shift";
      toastDescription = "Your shifts must be at least 8 hours apart";
      break;
    case "probation_limit_reached":
      toastMessage = "Probation limit reached";
      toastDescription = "Unable to add request. You have reached probation limit.";
      break;
  }
  return { toastMessage, toastDescription };
};
