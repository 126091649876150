import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { useGetUserPromos } from "../hooks/api/useGetUserPromos";
import giftCard from "../static/images/gift_card.svg";
import { RootState } from "../store";

export const PromoNavLink = (props: { route: { promos: string } }) => {
  const { userId } = useSelector((state: RootState) => state.login);
  const { promos } = useGetUserPromos(userId);
  const toRoute = props.route.promos + (promos.eligible?.length ? "" : "/active");

  return (
    <NavLink activeClassName="is-active" to={toRoute}>
      <img alt="Gift" src={giftCard} className="mr-1" /> Promos
    </NavLink>
  );
};
