import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { EmptyResponse } from "../../../api/common/response";
import { rooFetch } from "../../../api/common/rooFetch";
import * as ActionTypes from "../../../constants/forgotPasswordConstants";
import { ForgotPasswordActions } from "../actions/ForgotPasswordActions";

export function* sendResetPasswordSuccessEmail(action: ForgotPasswordActions) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/user/passwordResetSuccessEmail",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const responseData: EmptyResponse = yield response.json();

    if (responseData.status_code === 200) {
      yield put({ type: ActionTypes.SEND_RESET_PASSWORD_SUCCESS_EMAIL_SUCCESS });
    } else {
      yield put({ type: ActionTypes.SEND_RESET_PASSWORD_SUCCESS_EMAIL_FAIL });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while sending reset password success email: " },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootForgotPasswordSaga() {
  yield takeEvery(
    ActionTypes.SEND_RESET_PASSWORD_SUCCESS_EMAIL_REQUEST,
    sendResetPasswordSuccessEmail
  );
}
