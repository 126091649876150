import * as Sentry from "@sentry/react";
import { find } from "lodash";
import { call, put, takeEvery } from "redux-saga/effects";

import { identifyPosthog } from "../../../Common/Wrappers/identifyPosthog";
import { rooFetch } from "../../../api/common/rooFetch";
import * as C from "../../../constants/constants";
import * as errorMessage from "../../../constants/errorMessage";
import { UNSET_LOADER } from "../../../constants/loader";

export function* getHospitalBasicInfoAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/hospitalBasicInformation?userId=" + action.payload
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      let hospRegData = {};
      if (data.data && data.data.data[0]) {
        hospRegData = data.data.data[0];
      }
      yield put({ type: C.FETCH_HOSPITAL_BASIC_INFO_SUCCESS, payload: hospRegData });
    } else if (data.status_code === 400) {
      yield put({
        type: C.FETCH_HOSPITAL_BASIC_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting hospital basic info: " },
    });
    yield put({
      type: C.FETCH_HOSPITAL_BASIC_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getHospitalDataPageAsync(action) {
  try {
    const { userId, hospitalId } = action.payload;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        "api/hospital/hospitalDataPage?userId=" +
        userId +
        "&hospitalId=" +
        hospitalId
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      const hospRegData = data.data.data ? data.data.data[0] : {};
      yield put({ type: C.FETCH_HOSPITAL_DATA_PAGE_SUCCESS, payload: hospRegData });
    } else if (data.status_code === 400) {
      yield put({
        type: C.FETCH_HOSPITAL_DATA_PAGE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting hospital data page: " },
    });
    yield put({
      type: C.FETCH_HOSPITAL_DATA_PAGE_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getHospitalGetToKnowAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/hospitalGetToKnow?userId=" + action.payload
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      const hospRegData = data.data.data ? data.data.data[0] : {};
      yield put({ type: C.FETCH_HOSPITAL_GET_TO_KNOW_SUCCESS, payload: hospRegData });
    } else if (data.status_code === 400) {
      yield put({
        type: C.FETCH_HOSPITAL_GET_TO_KNOW_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting hospital get to know: " },
    });
    yield put({
      type: C.FETCH_HOSPITAL_GET_TO_KNOW_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getHospitalPhotoPageAsync() {
  try {
    const hospitalId = localStorage.getItem("hospitalId");
    const userId = localStorage.getItem("userId");

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/hospital/hospitalPhotoPage?userId=${userId}&hospitalId=${hospitalId}`
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      const hospitalData = data.data.hospital[0];
      const hospitalImages = data.data.hospitalImages.length > 0 ? data.data.hospitalImages : [];
      yield put({
        type: C.FETCH_HOSPITAL_PHOTO_PAGE_SUCCESS,
        payload: { hospitalData, hospitalImages },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: C.FETCH_HOSPITAL_PHOTO_PAGE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting hospital photo page: " },
    });
    yield put({
      type: C.FETCH_HOSPITAL_PHOTO_PAGE_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* saveHospitalBasicInformationAsync(action) {
  try {
    const hospitalData = {
      ...action.payload,
      hearAboutRoo: action.payload.hearAboutRoo.value || action.payload.hearAboutRoo,
      stateId: action.payload.stateId.value || action.payload.stateId,
      stateName: action.payload.stateId.label || action.payload.stateName,
    };
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/updateHospitalProfile",
      {
        method: "POST",
        body: JSON.stringify(hospitalData),
      }
    );
    const data = yield response.json();

    if (data.status_code === 200) {
      hospitalData.metroAreaId = data.data ? parseInt(data.data.metroAreaId) : null;

      if (hospitalData.metroAreaId) {
        identifyPosthog({ userId: data.data.userId, metroAreaId: hospitalData.metroAreaId });
        localStorage.setItem("metroAreaId", hospitalData.metroAreaId);
      }

      yield put({ type: C.SAVE_HOSPITAL_BASIC_INFO_SUCCESS, payload: hospitalData });
    } else {
      yield put({
        type: C.SAVE_HOSPITAL_BASIC_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while saving hospital basic info: " },
    });
    yield put({
      type: C.SAVE_HOSPITAL_BASIC_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* saveHospitalDataPageAsync(action) {
  try {
    const hospitalData = action.payload;
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/updateHospitalProfile",
      {
        method: "POST",
        body: JSON.stringify(hospitalData),
      }
    );
    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: C.SAVE_HOSPITAL_DATA_PAGE_SUCCESS, payload: action.payload });
    } else {
      yield put({
        type: C.SAVE_HOSPITAL_DATA_PAGE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while saving hospital data page: " },
    });
    yield put({
      type: C.SAVE_HOSPITAL_DATA_PAGE_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* saveHospitalGetToKnowAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/updateHospitalProfile",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: C.SAVE_HOSPITAL_GET_TO_KNOW_SUCCESS, payload: action.payload });
    } else {
      yield put({
        type: C.SAVE_HOSPITAL_GET_TO_KNOW_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while saving hospital get to know: " },
    });
    yield put({
      type: C.SAVE_HOSPITAL_GET_TO_KNOW_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* createHospitalProfileAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/updateHospitalProfile",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: C.CREATE_HOSPITAL_PROFILE_SUCCESS,
        payload: {
          status: data.status_code,
          message: data.message,
          referralCode: data.data.referralCode,
          isLastPage: action.payload.isLastPage,
        },
      });
      if (data?.data?.calendlyLink) {
        localStorage.setItem("calendlyLink", data.data.calendlyLink);
      }
    } else {
      yield put({
        type: C.CREATE_HOSPITAL_PROFILE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while creating hospital profile: " },
    });
  }
}

export function* uploadHospitalImagesAsync(action) {
  const formData = new FormData();

  for (let i = 0; i < action.payload.data.length; i++) {
    formData.append("document", action.payload.data[i]);
    formData.append("fileName", action.payload.data[i].fileName || action.payload.data[i].name);
  }
  formData.append("userId", localStorage.getItem("hospitalId")); // yes. hospital id, aws s3 storage uses userId prop
  formData.append("userType", "hospital");
  if (action.payload.isShiftPhoto) {
    formData.append("isShiftPhoto", action.payload.isShiftPhoto);
    if (action.payload.oldShiftPhotoId) {
      // attach old profile photo id to delete it
      formData.append("oldShiftPhotoId", action.payload.oldShiftPhotoId);
    }
  }
  if (action.payload.isFirstImage) {
    formData.append("isFirstImage", action.payload.isFirstImage);
  }
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/user/uploadDocument", {
      method: "POST",
      body: formData,
    });

    const data = yield response.json();

    if (data.status_code === 200) {
      if (data?.data?.calendlyLink) {
        localStorage.setItem("calendlyLink", data.data.calendlyLink);
      }
      const isProfileImage = find(data.data.data, { isShiftPhoto: 1 })?.imagePath;
      yield put({
        type: C.SET_PROFILE_IMAGE,
        payload: isProfileImage,
      });
      yield put({
        type: C.UPLOAD_HOSPITAL_IMAGES_SUCCESS,
        payload: { status: data.status_code, hospitalImages: data.data.data },
      });
      yield put({
        type: UNSET_LOADER,
      });
    } else {
      yield put({
        type: C.UPLOAD_HOSPITAL_IMAGES_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while uploading hospital images: " },
    });
    yield put({
      type: C.UPLOAD_HOSPITAL_IMAGES_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* hospitalImageDeleteAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/user/deleteProfileImages",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      let isProfileImage = "";
      for (const img of data.data.data) {
        if (img.isShiftPhoto) {
          isProfileImage = img.imagePath;
          break;
        }
      }
      yield put({
        type: C.SET_PROFILE_IMAGE,
        payload: isProfileImage,
      });
      yield put({
        type: C.DELETE_HOSPITAL_IMAGE_SUCCESS,
        payload: { status: data.status_code, hospitalImages: data.data.data },
      });
    } else {
      yield put({
        type: C.DELETE_HOSPITAL_IMAGE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting hospital image: " } });
    yield put({ type: UNSET_LOADER });
  }
}

export function* getDeocdedValuesAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/utility/decrypt-email-invite/",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: C.GET_DECODED_VALUES_SUCCESS,
        payload: { status: data.status_code, data: data.data },
      });
    } else {
      yield put({
        type: C.GET_DECODED_VALUES_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting decoded values: " } });
  }
}

export function* upload501c3LetterAsync(action) {
  const formData = new FormData();
  let userId;
  if (localStorage.getItem("hospitalId") !== null) {
    userId = localStorage.getItem("hospitalId");
  } else {
    userId = action.payload.hospitalId;
  }
  for (let i = 0; i < action.payload.files.length; i++) {
    formData.append("document", action.payload.files[i]);
    formData.append("userId", userId);
    formData.append("userType", "hospital");
    formData.append("uploadFileType", "501c3Letter");
  }
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/user/uploadDocument", {
      method: "POST",
      body: formData,
    });

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: C.UPLOAD_HOSPITAL_501C3_LETTER_SUCCESS,
        payload: { status: data.status_code, hospital501c3Letter: data.data.data },
      });
    } else {
      yield put({
        type: C.UPLOAD_HOSPITAL_501C3_LETTER_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while uploading hospital 501c3 letter: " },
    });
    yield put({
      type: C.UPLOAD_HOSPITAL_501C3_LETTER_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* delete501c3LetterAsync(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/user/delete501c3Letter", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: C.DELETE_HOSPITAL_501C3_LETTER_SUCCESS,
        payload: { status: data.status_code, hospital501c3Letter: data.data.data },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: C.DELETE_HOSPITAL_501C3_LETTER_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while deleting hospital 501c3 letter: " },
    });
    yield put({
      type: C.DELETE_HOSPITAL_501C3_LETTER_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootRegisterHospitalSaga() {
  // yield takeEvery( C.HOSPITAL_DETAILS_CHANGE_REQUEST, changeHospitalDetailsAsync );

  yield takeEvery(C.FETCH_HOSPITAL_BASIC_INFO, getHospitalBasicInfoAsync);
  yield takeEvery(C.FETCH_HOSPITAL_DATA_PAGE, getHospitalDataPageAsync);
  yield takeEvery(C.FETCH_HOSPITAL_GET_TO_KNOW, getHospitalGetToKnowAsync);
  yield takeEvery(C.FETCH_HOSPITAL_PHOTO_PAGE, getHospitalPhotoPageAsync);
  yield takeEvery(C.SAVE_HOSPITAL_BASIC_INFO, saveHospitalBasicInformationAsync);
  yield takeEvery(C.SAVE_HOSPITAL_DATA_PAGE, saveHospitalDataPageAsync);
  yield takeEvery(C.SAVE_HOSPITAL_GET_TO_KNOW, saveHospitalGetToKnowAsync);
  yield takeEvery(C.UPLOAD_HOSPITAL_IMAGES_REQUEST, uploadHospitalImagesAsync);
  yield takeEvery(C.CREATE_HOSPITAL_PROFILE_REQUEST, createHospitalProfileAsync);
  yield takeEvery(C.DELETE_HOSPITAL_IMAGE_REQUEST, hospitalImageDeleteAsync);
  yield takeEvery(C.GET_DECODED_VALUES_REQUEST, getDeocdedValuesAsync);
  yield takeEvery(C.UPLOAD_HOSPITAL_501C3_LETTER_REQUEST, upload501c3LetterAsync);
  yield takeEvery(C.DELETE_HOSPITAL_501C3_LETTER_REQUEST, delete501c3LetterAsync);
}
