import React from "react";

export const MdsSyringeOutlined = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px"
    fill="#e8eaed"
    {...props}
  >
    <path d="M149.13-519.61Q141-529 141-541.47q0-12.46 8-21.53l126-125-57-58-26 26q-9.07 9-21.53 9-12.47 0-21.34-8.84-8.13-8.11-8.13-20.63 0-12.53 8-21.53l94-94q9.07-9 21.53-9 12.47 0 21.34 9.39Q294-847 294-834.53q0 12.46-8 20.53l-26 27 57 57 126-127q9.07-8 21.53-8 12.47 0 21.47 8 8 9.07 8 21.53 0 12.47-8 21.47l-34 34 339 338q17 18 17 42.94 0 24.94-17 42.06l-50 50 172 172h-84L699-265l-50 50q-17.12 17-42.06 17T564-215L226-554l-34 34q-9.07 9-21.53 9-12.47 0-21.34-8.61ZM267-596l339 339 142-142-85-85-70 70q-8 9-20.5 9t-20.63-8.25q-8.87-9-8.87-21.38 0-12.37 9-21.37l70-70-85-85-70 70q-9.07 9-21.53 9-12.47 0-21.47-9-8-8-8-20.47 0-12.46 8-21.53l70-70-85-85-142 142Zm-7-7 142-142-142 142Z" />
  </svg>
);
MdsSyringeOutlined.isCustomSvg = true;
