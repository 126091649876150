export const PENDING = 0;
export const APPROVED = 1;
export const NONRESPONSIVE = 2;
export const DISABLED = 3;
export const BLOCKED = 4;
export const ACTIVE = 5;
export const REGISTERED_R4U = 6;
export const INVITED = 7;
export const INCOMPLETE = 8;
export const SUSPENDED = 9;
export const POSTING_PAUSED = 10;
export const NO_ASSIGNED_HOSPITALS = 11;
export const PENDING_AUTO_APPROVAL = 12;

export const USER_STATUS = {
  PENDING,
  APPROVED,
  NONRESPONSIVE,
  DISABLED,
  BLOCKED,
  ACTIVE,
  REGISTERED_R4U,
  INVITED,
  INCOMPLETE,
  SUSPENDED,
  POSTING_PAUSED,
  NO_ASSIGNED_HOSPITALS,
  PENDING_AUTO_APPROVAL,
} as const;

export type UserStatusName =
  | "Pending"
  | "Approved"
  | "NonResponsive"
  | "Disabled"
  | "Blocked"
  | "Active"
  | "Registered R4U"
  | "Invited"
  | "Incomplete"
  | "Suspended"
  | "Posting Paused"
  | "No Assigned Hospitals"
  | "Pending Auto Approval";

export const userStatusName = {
  [PENDING]: "Pending",
  [APPROVED]: "Approved",
  [NONRESPONSIVE]: "NonResponsive",
  [DISABLED]: "Disabled",
  [BLOCKED]: "Blocked",
  [ACTIVE]: "Active",
  [REGISTERED_R4U]: "Registered R4U",
  [INVITED]: "Invited",
  [INCOMPLETE]: "Incomplete",
  [SUSPENDED]: "Suspended",
  [POSTING_PAUSED]: "Posting Paused",
  [NO_ASSIGNED_HOSPITALS]: "No Assigned Hospitals",
  [PENDING_AUTO_APPROVAL]: "Pending Auto Approval",
};

export type UserStatus = (typeof USER_STATUS)[keyof typeof USER_STATUS];

export const isUserStatus = (value: any): value is UserStatus =>
  Object.values(USER_STATUS).includes(value);

export const parseUserStatus = (status: string | null): UserStatus => {
  if (status === null) {
    return PENDING;
  }

  const parsedStatus = parseInt(status, 10);

  if (isUserStatus(parsedStatus)) {
    return parsedStatus;
  } else {
    return PENDING;
  }
};
