import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerSVG = styled.svg`
  animation: ${spin} 1s linear infinite;
`;

export const LoadingWheel = () => (
  <SpinnerSVG
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
  >
    <path
      d="M14 8C14 9.28558 13.6188 10.5423 12.9046 11.6112C12.1903 12.6801 11.1752 13.5132 9.98744 14.0052C8.79972 14.4972 7.49279 14.6259 6.23191 14.3751C4.97104 14.1243 3.81285 13.5052 2.90381 12.5962C1.99476 11.6872 1.3757 10.529 1.1249 9.26809C0.874092 8.00721 1.00281 6.70028 1.49478 5.51256C1.98675 4.32484 2.81987 3.30968 3.88879 2.59545C4.95771 1.88122 6.21442 1.5 7.5 1.5"
      stroke="url(#paint0_angular_4681_22899)"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <defs>
      <radialGradient
        id="paint0_angular_4681_22899"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.5 8) rotate(90) scale(6.5)"
      >
        <stop offset="0.307292" stopColor="#412894" stopOpacity="0" />
        <stop offset="1" stopColor="#412894" />
      </radialGradient>
    </defs>
  </SpinnerSVG>
);
