import React from "react";
import { Slider as AntdSlider } from "antd/es";

import { FormLabel } from "../FormLabel";

import { StyledSlider } from "./SliderStyles";

export type SliderProps = React.ComponentProps<typeof AntdSlider> & {
  label?: string;
  type: "white" | "blue";
  testId?: string;
};

export const Slider: React.FC<SliderProps> = (props) => {
  const { dots, marks, max, label, testId } = props;
  // Edge case: when dots and marks are selected, use the max step size possible if none was provided
  const step = props.step || (dots && marks ? max || 100 : undefined);
  return (
    <div data-testid={testId}>
      <FormLabel>{label}</FormLabel>
      <StyledSlider {...props} step={step} />
    </div>
  );
};
