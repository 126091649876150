import React from "react";
import { ProgressProps } from "antd";

import { blueBackground, blueDark600 } from "../../styles/constants";

import { StyledProgress } from "./ProgressBarStyles";

export interface AntProgressBarProps extends ProgressProps {}

export const ProgressBar: React.FC<AntProgressBarProps> = (props) => {
  return (
    <StyledProgress
      {...props}
      showInfo={false}
      strokeColor={props.strokeColor ?? blueDark600}
      trailColor={props.trailColor ?? blueBackground}
      type="line"
      strokeWidth={props.strokeWidth ?? 24}
      data-testid="progressBar"
    />
  );
};
