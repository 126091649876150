import { Heading, Modal, RadioGroup, spacingS } from "@RooUI";
import React, { useEffect, useState } from "react";

import { ModalDescriptionWrapper } from "./styles";

interface ShiftPricingSurveyModalBodyProps {
  surveyDescription: string;
  surveyChoices: string[];
  setSurveyAnswer: (newValue: string) => void;
}

interface ShiftPricingSurveyModalProps
  extends ShiftPricingSurveyModalBodyProps,
    React.ComponentProps<typeof Modal> {
  surveyQuestion: string;
}

export const ShiftPricingSurveyModal = ({
  surveyQuestion,
  surveyDescription,
  surveyChoices,
  setSurveyAnswer,
  primaryButtonProps,
  ...modalProps
}: ShiftPricingSurveyModalProps) => {
  if (!surveyQuestion || !surveyDescription || !surveyChoices || surveyChoices.length === 0) {
    return null;
  }
  return (
    <Modal
      {...modalProps}
      header={surveyQuestion}
      primaryButtonProps={primaryButtonProps}
      size="m"
      body={
        <ShiftPricingSurveyModalBody
          surveyDescription={surveyDescription}
          surveyChoices={surveyChoices}
          setSurveyAnswer={setSurveyAnswer}
        />
      }
    />
  );
};

const ShiftPricingSurveyModalBody: React.FC<ShiftPricingSurveyModalBodyProps> = ({
  surveyDescription,
  surveyChoices,
  setSurveyAnswer,
}) => {
  const [selectedChoice, setSelectedChoice] = useState("");
  const [otherAnswer, setOtherAnswer] = useState("");

  const choices = [
    ...surveyChoices.map((choice) => ({ label: choice, value: choice })),
    { label: "Other", value: "Other" },
  ];

  useEffect(() => {
    if (selectedChoice === "Other") {
      const sanitizedAnswer = otherAnswer.trim().replace(/[<>]/g, "");
      setSurveyAnswer(sanitizedAnswer);
    } else {
      setSurveyAnswer(selectedChoice);
    }
  }, [selectedChoice, otherAnswer, setSurveyAnswer]);

  return (
    <>
      <ModalDescriptionWrapper>
        <Heading level={5}>{surveyDescription}</Heading>
      </ModalDescriptionWrapper>
      <RadioGroup
        radios={choices}
        onChange={(v) => setSelectedChoice(v)}
        singleColumn
        value={selectedChoice}
      />
      {selectedChoice === "Other" && (
        <textarea
          id="otherAnswer"
          className="form-control"
          maxLength={100}
          value={otherAnswer}
          name="otherAnswerInput"
          onChange={(e) => setOtherAnswer(e.target.value)}
          placeholder="Please specify your reason"
          style={{ marginTop: spacingS }}
        ></textarea>
      )}
    </>
  );
};
