import React, { createContext, useCallback, useMemo, useState } from "react";

export interface ToastData {
  message: string;
  description: string;
  variant: "success" | "error" | "info" | "warning";
  style?: React.CSSProperties;
}

interface ToastContextType {
  toastData: ToastData | null;
  showSuccessToast: (args: { description: string; message?: string }) => void;
  showErrorToast: (args: { description: string; message?: string }) => void;
  hideToast: () => void;
}

export const ToastContext = createContext<ToastContextType>({
  toastData: null,
  showSuccessToast: () => {},
  showErrorToast: () => {},
  hideToast: () => {},
});

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [toastData, setToastData] = useState<ToastData | null>(null);

  const showSuccessToast = useCallback(
    ({ description, message }: { description: string; message?: string }) => {
      setToastData({ description, message: message || "Roo-hoo!", variant: "success" });
    },
    []
  );

  const showErrorToast = useCallback(
    ({ description, message }: { description: string; message?: string }) => {
      setToastData({ description, message: message || "Uh-oh!", variant: "error" });
    },
    []
  );

  const hideToast = useCallback(() => {
    setToastData(null);
  }, []);

  const contextValue = useMemo(
    () => ({
      toastData,
      showSuccessToast,
      showErrorToast,
      hideToast,
    }),
    [toastData, showSuccessToast, showErrorToast, hideToast]
  );

  return <ToastContext.Provider value={contextValue}>{children}</ToastContext.Provider>;
};
