import React, { FC } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { grayWhite } from "../../styles/constants";

interface LoadingProps {
  color?: string;
  size?: "small" | "regular";
}

const SIZE_MAP = {
  small: 16,
  regular: 24,
};
export const Loading: FC<LoadingProps> = ({ color = grayWhite, size = "regular" }) => {
  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: SIZE_MAP[size], color, fill: color }} spin />}
    />
  );
};
