import dayjs from "dayjs";
import { find, forEach, isEmpty, isObject, map, orderBy, remove } from "lodash";

import { getProcedureTypeLabels } from "../Common/shiftDataHelper";
import shiftRequestTimeLeft from "../Common/ShiftRequestTimeLeft";
import {
  aEquipmentOptions,
  aSoftwareOptions,
  aSpecializationOptionsForCA,
  aVetAreasOfPracticeKeys,
  aVetAreasOfPracticeLabels,
} from "../constants/checkBoxConstants";
import {
  ANIMAL_TYPE,
  PROCEDURE_TYPE,
  SPECIALIZATION_TYPE,
  oApptTypesOptions,
} from "../constants/dropDownConstants";
import { SURGERY_TIER, characterAnimalTypes } from "../constants/radiobuttonConstants";
import { formatPrice } from "../helpers/textUtility";
import { Event, EventTracker } from "../tracking/service/EventTracker/EventTrackerService";

import { ShiftDetailsObj } from "./Footer/ShiftDetails";
import { ProfilePanelTypes, ShiftStatus } from "./ProfilePanelTypes";

export const getFirstActiveRequest = (shiftRequestDetails: any) => {
  return find(shiftRequestDetails, (itm: any) => {
    const isExpired = shiftRequestTimeLeft(
      null,
      false,
      itm.shiftRequestEndTimestamp
    )?.requestExpired;
    return !isExpired;
  });
};

export const getActiveRequestContractorIds = (shiftRequestDetails: any) => {
  const activeRequestContractorIds: number[] = [];
  shiftRequestDetails.forEach((request: any) => {
    const timeLeftDetails = shiftRequestTimeLeft(null, false, request.shiftRequestEndTimestamp);
    if (!timeLeftDetails.requestExpired) {
      activeRequestContractorIds.push(request.vetId || request.techId);
    }
  });
  return activeRequestContractorIds;
};

const sortShiftRequests = (shiftRequestDetails: any) => {
  const firstActiveRequest = getFirstActiveRequest(shiftRequestDetails);
  if (firstActiveRequest) {
    remove(shiftRequestDetails, { requestId: firstActiveRequest.requestId });
    shiftRequestDetails.splice(0, 0, firstActiveRequest);
  }
  return orderBy(shiftRequestDetails, "shiftRequestEndTimestamp", "desc");
};

const formatShiftRequestDetails = (isVet: boolean, shiftRequests: any) => {
  return map(shiftRequests, (itm: any) => {
    if (!itm.requestId) itm.requestId = itm.id;
    if (!itm.contractorId) itm.contractorId = isVet ? itm.vetId : itm.techId;
    if (!itm.fullName) itm.fullName = isVet ? itm.vetName : itm.techName;
    return itm;
  });
};

export const getFormattedShiftData = (
  isVet: boolean,
  shiftData: any,
  isAllRequestsExpired?: boolean
) => {
  const { startTime, shiftStartTime, endTime, shiftEndTime, shiftRequestDetails } = shiftData;
  shiftData.startTime = shiftStartTime || startTime;
  shiftData.endTime = shiftEndTime || endTime;
  let formattedShiftRequestDetails = shiftRequestDetails;
  if (isObject(shiftRequestDetails) && !isEmpty(shiftRequestDetails)) {
    shiftData.shiftRequestDetails = formatShiftRequestDetails(isVet, [shiftRequestDetails]);
  }
  if (Array.isArray(shiftRequestDetails) && shiftRequestDetails?.length) {
    formattedShiftRequestDetails = formatShiftRequestDetails(isVet, shiftRequestDetails);
    if (!isAllRequestsExpired && formattedShiftRequestDetails.length > 1) {
      formattedShiftRequestDetails = sortShiftRequests(formattedShiftRequestDetails);
    }
    shiftData.shiftRequestDetails = formattedShiftRequestDetails;
  }
  return shiftData;
};

export const getContractorBasicDetails = (isVet: boolean, data: any) => {
  const profileImage = data?.profileImage?.length ? data.profileImage[0]?.imagePath : null;
  const fullName = data.user?.length ? data.user[0]?.fullName : "";
  const userId = data.user?.length ? data.user[0]?.userId : "";

  let basicInfo: any = { profileImage, fullName, userId };
  if (isVet) {
    const vetData = data.vet[0];
    basicInfo = {
      ...basicInfo,
      hasSurgery: vetData.isPrefSurgery ? true : false,
      yearsOfPractice: vetData?.yearsOfPractice,
    };
  } else {
    const techData = data.tech[0];
    basicInfo = {
      ...basicInfo,
      yearsOfPractice: techData?.yearsOfPractice,
      techTier: techData?.tier,
    };
  }
  return basicInfo;
};

export const getTechCredentialsAndLicenses = (data: any, states: any) => {
  const { isLicensed, isCertified, isRegistered, isVTS, credentialStateIds, techLicences } = data;
  const credentialTypes = [];
  if (isLicensed) credentialTypes.push("Licensed");
  if (isCertified) credentialTypes.push("Certified");
  if (isRegistered) credentialTypes.push("Registered");
  if (isVTS) credentialTypes.push("VTS");

  const credentialStateIdsArray = credentialStateIds?.split(",");
  const credentialStates: any = [];
  forEach(credentialStateIdsArray, (id: string) => {
    const stateLabel = find(states, { id: parseInt(id) })?.state_code;
    credentialStates.push({
      id,
      state: stateLabel,
    });
  });

  const licenseList: any[] = [];
  if (techLicences?.length) {
    forEach(techLicences, (itm: any) => {
      const stateLabel = find(states, { id: itm.id })?.state_code;
      let stateRegistrationLabel = null;
      if (itm.isStateRegistered !== null) {
        stateRegistrationLabel = itm.isStateRegistered ? "Yes" : "No";
      }
      licenseList.push({
        state: stateLabel,
        licenceNumber: itm.licenceNumber,
        expiresOn: `${itm.expirationMonth}/${itm.expirationYear}`,
        isStateRegistered: stateRegistrationLabel,
      });
    });
  }
  return { credentialTypes, credentialStates, licenseList };
};

export const getVetExperienceDetails = (data: any, states: any) => {
  const { vet, vetLicences, vetProcedure, vetSpecialization, vetAnimalType } = data;
  const licenseList: any[] = [];
  const procedureList: any[] = [];
  const specializationList: any[] = [];
  const animalTypes: any[] = [];

  if (vetLicences?.length) {
    forEach(vetLicences, (itm: any) => {
      const stateLabel = find(states, { id: itm.id })?.state_name;
      licenseList.push(`${stateLabel}: ${itm.licenceNumber}`);
    });
  }

  if (vetProcedure?.length) {
    forEach(vetProcedure, (itm: any) => {
      const procedureType = find(PROCEDURE_TYPE, { id: itm.procedureTypeId })?.procedureType;
      procedureList.push(procedureType);
    });
  }

  if (vetSpecialization?.length) {
    forEach(vetSpecialization, (itm: any) => {
      const specializationType = find(SPECIALIZATION_TYPE, {
        id: itm.specializationId,
      })?.specialization_type;
      specializationList.push(specializationType);
    });
  }

  if (vetAnimalType?.length) {
    forEach(vetAnimalType, (itm: any) => {
      const animalType = find(ANIMAL_TYPE, {
        id: itm.animalTypeId,
      })?.type;
      animalTypes.push(animalType);
    });
  }

  const experienceDetails = [
    {
      header: "School",
      data: vet?.vetSchool,
    },
    {
      header: "Licenses",
      data: licenseList,
    },
    {
      header: "Procedures",
      data: procedureList,
    },
    {
      header: "Works with",
      data: animalTypes,
    },
    {
      header: "Specialties",
      data: specializationList,
    },
  ];
  return experienceDetails;
};

export const getTechExpereinceDetails = (data: any) => {
  const { techSchool, graduationYear, degreeCertificate, yearsOfPractice, specializationIds } =
    data;
  const aSpecializationIds = specializationIds ? specializationIds.split(",") : [];
  const experienceDetails = [
    {
      header: "School",
      data: techSchool,
    },
    {
      header: "Degree/Certificate",
      data: degreeCertificate,
    },
    {
      header: "Graduation Year",
      data: graduationYear,
    },
    {
      header: "Total years as a Tech",
      data: yearsOfPractice,
    },
  ];
  if (aSpecializationIds) {
    const specilazationLabels: string[] = [];
    forEach(aSpecializationIds, (id: string) => {
      const specilazationLabel = find(aSpecializationOptionsForCA, { id: parseInt(id) })?.label;
      if (specilazationLabel) specilazationLabels.push(specilazationLabel);
    });
    if (specilazationLabels?.length)
      experienceDetails.push({
        header: "Specialization",
        data: specilazationLabels,
      });
  }
  return experienceDetails;
};

export const getPreferences = (isVet: boolean, data: any) => {
  const { characterTypeId, apptFlow, appointmentFlow } = data;
  let preferenceDetails: any = {
    appointmentFlow: isVet ? apptFlow : appointmentFlow,
    characterType:
      characterTypeId >= 0 ? find(characterAnimalTypes, { id: characterTypeId })?.description : "",
  };
  if (isVet) {
    const { clientInteract, staffInteract } = data;
    const prefAreaOfPractice: string[] = [];
    aVetAreasOfPracticeKeys.forEach((key, i) => {
      if (parseInt(data[key]) === 1) prefAreaOfPractice.push(aVetAreasOfPracticeLabels[i]);
    });
    preferenceDetails = { ...preferenceDetails, prefAreaOfPractice, clientInteract, staffInteract };
  } else {
    const { softwareIds, equipmentIds, practiceManagementSoftware, otherEquipment } = data;
    const aSoftwareIds = softwareIds?.split(",") || [];
    const aEquipmentIds = equipmentIds?.split(",") || [];

    const softwareList: string[] = [];
    const equipmentList: string[] = [];

    forEach(aSoftwareIds, (id: string) => {
      const software =
        parseInt(id) === 5
          ? practiceManagementSoftware
          : find(aSoftwareOptions, { id: parseInt(id) })?.label;
      if (software) softwareList.push(software);
    });

    forEach(aEquipmentIds, (id: string) => {
      const equipment =
        parseInt(id) === 4 ? otherEquipment : find(aEquipmentOptions, { id: parseInt(id) })?.label;
      if (equipment) equipmentList.push(equipment);
    });

    preferenceDetails = {
      ...preferenceDetails,
      softwareList: softwareList?.length ? softwareList.join(", ") : "",
      equipmentList: equipmentList?.length ? equipmentList.join(", ") : "",
    };
  }
  return preferenceDetails;
};

export const getRatingDetails = ({ isVet, contractorId, shiftDetails, contractorDetails }: any) => {
  const { shiftDate, ratingId, lastSelectedWorkAgain } = shiftDetails;
  const { fullName, profileImage } = contractorDetails;
  let ratingDetails: any = {
    shiftDate,
    ratingId,
    lastSelectedWorkAgain,
  };
  if (!isVet) {
    ratingDetails = {
      ...ratingDetails,
      techName: fullName,
      techImage: profileImage,
      techId: contractorId,
    };
  }
  return ratingDetails;
};

export const getShiftDetailsToDisplay = (
  isVet: boolean,
  shiftData: any,
  isMobile?: boolean,
  isAdmin?: boolean
) => {
  const {
    shiftStartTime,
    shiftEndTime,
    startTime,
    endTime,
    shiftAmount,
    isSurgeryExpected,
    surgeryTier,
    shiftRequestDetails,
  } = shiftData;
  const {
    requestedShiftStartTime,
    requestedShiftEndTime,
    isRequestWithoutSurgery,
    requestedHospitalShiftAmount,
    requestedVetShiftAmount,
    bidSubmittedBy,
    vetShiftAmount,
    techShiftAmount,
  } = shiftRequestDetails?.length ? shiftRequestDetails[0] : shiftRequestDetails;

  const originalTime = `${shiftStartTime || startTime} - ${shiftEndTime || endTime}`;
  const newTime = `${requestedShiftStartTime} - ${requestedShiftEndTime}`;
  const isRequestedNewTime = requestedShiftStartTime && originalTime !== newTime;

  let shiftDetails: ShiftDetailsObj[] = [
    {
      key: `Time${isMobile ? "" : ":"}`,
      value: originalTime,
      updatedValue: isRequestedNewTime ? newTime : null,
      "data-testid": "shiftTime",
    },
  ];
  if (isVet) {
    shiftDetails = [
      ...shiftDetails,
      {
        key: `Surgery${isMobile ? "" : ":"}`,
        value: isSurgeryExpected ? find(SURGERY_TIER, { value: surgeryTier })?.shortLabel : "No",
        updatedValue: isRequestWithoutSurgery ? "No surgery" : null,
        "data-testid": "isSurgeryExpected",
      },
      {
        key: `Price${isMobile ? "" : ":"}`,
        value: formatPrice(shiftAmount),
        updatedValue:
          requestedHospitalShiftAmount && shiftAmount != requestedHospitalShiftAmount
            ? formatPrice(requestedHospitalShiftAmount)
            : null,
        "data-testid": "shiftAmount",
      },
    ];
  } else {
    shiftDetails.push({
      key: `Price${isMobile ? "" : ":"}`,
      value: formatPrice(shiftAmount),
      "data-testid": "shiftAmount",
    });
  }
  if (isAdmin) {
    if (bidSubmittedBy) {
      shiftDetails.unshift({
        key: `Bid${isMobile ? "" : ":"}`,
        value: `Submitted by ${bidSubmittedBy}`,
      });
    }
    shiftDetails.pop();
    if (isVet) {
      shiftDetails.push({
        key: `Vet Price${isMobile ? "" : ":"}`,
        value: formatPrice(vetShiftAmount),
        updatedValue:
          requestedVetShiftAmount && vetShiftAmount != requestedVetShiftAmount
            ? formatPrice(requestedVetShiftAmount)
            : null,
        "data-testid": "vetShiftAmount",
      });
    } else {
      shiftDetails.push({
        key: `Tech Price${isMobile ? "" : ":"}`,
        value: formatPrice(techShiftAmount),
        updatedValue: null,
        "data-testid": "techShiftAmount",
      });
    }
    shiftDetails.push({
      key: `Total Price${isMobile ? "" : ":"}`,
      value: formatPrice(shiftAmount),
      updatedValue:
        requestedHospitalShiftAmount && shiftAmount != requestedHospitalShiftAmount
          ? formatPrice(requestedHospitalShiftAmount)
          : null,
      "data-testid": "shiftAmount",
    });
  }
  return shiftDetails;
};

export const getFullfilledShiftDetails = (isVet: boolean, shiftData: any, isMobile?: boolean) => {
  const { shiftDate, startTime, endTime, shiftAmount } = shiftData;

  let shiftDetails: ShiftDetailsObj[] = [];
  const currentTime = `${startTime} - ${endTime}`;
  if (isVet) {
    const { surgeryTier, isSurgeryExpected, confirmShiftRequestDetails, originalShiftDetails } =
      shiftData;
    const { requestedShiftStartTime, requestedShiftEndTime, requestedHospitalShiftAmount } =
      confirmShiftRequestDetails;

    let isRequestedNewTime = false;
    let originalTime;
    let requestedTime;
    let originalIsSurgeryExpected;
    let isSurgeryExpectedChanged = false;
    let originalShiftAmount;
    let isShiftAmountChanged = false;
    if (originalShiftDetails) {
      originalTime = `${originalShiftDetails.startTime} - ${originalShiftDetails.endTime}`;
      requestedTime = `${requestedShiftStartTime} - ${requestedShiftEndTime}`;
      isRequestedNewTime =
        (requestedShiftStartTime || requestedShiftEndTime) && originalTime !== requestedTime;

      originalIsSurgeryExpected = originalShiftDetails.isSurgeryExpected;
      isSurgeryExpectedChanged = isSurgeryExpected != originalIsSurgeryExpected;

      originalShiftAmount = originalShiftDetails.shiftAmount;
      isShiftAmountChanged = requestedHospitalShiftAmount && shiftAmount != originalShiftAmount;
    }

    shiftDetails = [
      {
        key: `Date${isMobile ? "" : ":"}`,
        value: dayjs(shiftDate).format("ddd MMM DD, YYYY"),
      },
      {
        key: `Time${isMobile ? "" : ":"}`,
        value: isRequestedNewTime ? originalTime : currentTime,
        updatedValue: isRequestedNewTime ? currentTime : null,
      },
      {
        key: `Surgery${isMobile ? "" : ":"}`,
        value:
          isSurgeryExpected || originalIsSurgeryExpected
            ? find(SURGERY_TIER, {
                value: isSurgeryExpectedChanged ? originalShiftDetails.surgeryTier : surgeryTier,
              })?.shortLabel
            : "No",
        updatedValue: isSurgeryExpectedChanged ? "No surgery" : null,
      },
      {
        key: `Price${isMobile ? "" : ":"}`,
        value: isShiftAmountChanged ? formatPrice(originalShiftAmount) : formatPrice(shiftAmount),
        updatedValue: isShiftAmountChanged ? formatPrice(shiftAmount) : null,
      },
    ];
  } else {
    shiftDetails = [
      {
        key: `Date${isMobile ? "" : ":"}`,
        value: dayjs(shiftDate).format("ddd MMM DD, YYYY"),
      },
      {
        key: `Time${isMobile ? "" : ":"}`,
        value: currentTime,
      },
      { key: `Price${isMobile ? "" : ":"}`, value: formatPrice(shiftAmount) },
    ];
  }
  return shiftDetails;
};

export const getUnfilledShiftDetails = ({ isVet, shiftData, isTexasFlow, isMobile }: any) => {
  const {
    shiftDate,
    startTime,
    endTime,
    shiftStartTime,
    shiftEndTime,
    flexStartTimeRange,
    flexEndTimeRange,
    shiftAmount,
    isSurgeryExpected,
  } = shiftData;
  let shiftDetails: ShiftDetailsObj[] = [
    {
      key: `Date${isMobile ? "" : ":"}`,
      value: dayjs(shiftDate).format("ddd MMM DD, YYYY"),
    },
    {
      key: `Time${isMobile ? "" : ":"}`,
      value: `${shiftStartTime || startTime}-${shiftEndTime || endTime}`,
      updatedFlexText: flexStartTimeRange || flexEndTimeRange ? "Flexible" : null,
    },
  ];

  if (isVet) {
    const {
      surgeryTier,
      isFlexibleSurgery,
      expectedAppointmentTypeId,
      expectedNoOfAppointments,
      isWalkInsOrDropOffs,
      additionalDoctor,
    } = shiftData;
    shiftDetails.push({
      key: `Surgery${isMobile ? "" : ":"}`,
      value: isSurgeryExpected ? find(SURGERY_TIER, { value: surgeryTier })?.shortLabel : "No",
      updatedFlexText: isFlexibleSurgery ? "Flexible" : null,
    });
    if (expectedAppointmentTypeId) {
      shiftDetails.push({
        key: `Expected appointment types${isMobile ? "" : ":"}`,
        value: oApptTypesOptions[expectedAppointmentTypeId].label,
      });
    }
    shiftDetails.push({
      key: `Expected number of appointments${isMobile ? "" : ":"}`,
      value: expectedNoOfAppointments || 0,
    });
    shiftDetails = [
      ...shiftDetails,
      {
        key: `Additional doctor(s) practicing during shift${isMobile ? "" : ":"}`,
        value: additionalDoctor ? "Yes" : "No",
      },
      {
        key: `Walk-ins or drop-offs possible${isMobile ? "" : ":"}`,
        value: Boolean(isWalkInsOrDropOffs) ? "Yes" : "No",
      },
    ];
  } else {
    const { procedureTypeIds, typeOfSurgeryIds, tier } = shiftData;
    const techTier: any = String(tier) === "4" && isTexasFlow ? "Specialty" : tier;
    const techSurgeryText =
      procedureTypeIds || typeOfSurgeryIds
        ? getProcedureTypeLabels(procedureTypeIds || typeOfSurgeryIds)
        : "";
    shiftDetails = [
      ...shiftDetails,
      {
        key: `Tier${isMobile ? "" : ":"}`,
        value: techTier,
      },
    ];

    if (isSurgeryExpected && techSurgeryText) {
      shiftDetails.push({ key: `Surgery${isMobile ? "" : ":"}`, value: techSurgeryText });
    }
  }
  shiftDetails.push({ key: `Total Price${isMobile ? "" : ":"}`, value: formatPrice(shiftAmount) });
  return shiftDetails;
};

export const checkIfAlternateOptionSelected = (shiftDetails: any) => {
  const {
    shiftStartTime,
    shiftEndTime,
    startTime,
    endTime,
    isSurgeryExpected,
    shiftAmount,
    shiftRequestDetails,
  } = shiftDetails;
  const {
    requestedShiftStartTime,
    requestedShiftEndTime,
    isRequestWithoutSurgery,
    requestedHospitalShiftAmount,
    isVetBid,
  } = shiftRequestDetails;
  const originalTime = `${shiftStartTime || startTime} - ${shiftEndTime || endTime}`;
  const newTime = `${requestedShiftStartTime} - ${requestedShiftEndTime}`;
  const isRequestedNewTime = requestedShiftStartTime && originalTime !== newTime;
  if (
    isRequestedNewTime ||
    (isSurgeryExpected && isRequestWithoutSurgery) ||
    isVetBid ||
    (requestedHospitalShiftAmount && shiftAmount != requestedHospitalShiftAmount)
  )
    return true;
  else return false;
};

export const checkIfActionComponentHidden = (
  isMobile: boolean,
  panelType: ProfilePanelTypes,
  shiftStatus: ShiftStatus
) => {
  let isHideFooter = false;
  if (
    isMobile &&
    shiftStatus === ShiftStatus.Requested &&
    panelType === ProfilePanelTypes.RequestedState
  ) {
    isHideFooter = true;
  } else if (shiftStatus === ShiftStatus.Boost && panelType === ProfilePanelTypes.ShiftDetails) {
    isHideFooter = true;
  } else if (panelType === ProfilePanelTypes.OnlyProfile) {
    isHideFooter = true;
  }
  return isHideFooter;
};

export const cancelPanelEventTracking = ({
  shiftId,
  shiftStatus,
  isVet,
}: {
  shiftId: number;
  isVet?: boolean;
  shiftStatus: ShiftStatus;
}) => {
  EventTracker.send({
    eventName: isVet
      ? Event.Name.HOSPITAL_CANCEL_VET_SHIFT_ACTION_PANEL
      : Event.Name.HOSPITAL_CANCEL_TECH_SHIFT_ACTION_PANEL,
    eventType: Event.Type.CLICK,
    entityType: isVet ? Event.Entity.VET_SHIFT : Event.Entity.TECH_SHIFT,
    entityId: shiftId,
    context: { shiftStatus: getShiftStatusText(shiftStatus) },
  });
};

export const confirmRequestEventTracking = ({
  shiftId,
  shiftStatus,
  isVet,
  contractorId,
}: {
  shiftId: number;
  contractorId: number;
  isVet?: boolean;
  shiftStatus: ShiftStatus;
}) => {
  EventTracker.send({
    eventName: isVet
      ? Event.Name.HOSPITAL_CONFIRM_VET_SHIFT_SUBMIT
      : Event.Name.HOSPITAL_CONFIRM_TECH_SHIFT_SUBMIT,
    eventType: Event.Type.CLICK,
    entityType: isVet ? Event.Entity.VET_SHIFT : Event.Entity.TECH_SHIFT,
    entityId: shiftId,
    context: {
      [isVet ? "vetId" : "techId"]: contractorId,
      shiftStatus: getShiftStatusText(shiftStatus),
    },
  });
};

export const declineRequestEventTracking = ({
  shiftId,
  shiftStatus,
  isVet,
  contractorId,
}: {
  shiftId: number;
  contractorId: number;
  isVet?: boolean;
  shiftStatus: ShiftStatus;
}) => {
  EventTracker.send({
    eventName: isVet
      ? Event.Name.HOSPITAL_DECLINE_VET_SHIFT_REQUEST
      : Event.Name.HOSPITAL_DECLINE_TECH_SHIFT_REQUEST,
    eventType: Event.Type.CLICK,
    entityType: isVet ? Event.Entity.VET_SHIFT : Event.Entity.TECH_SHIFT,
    entityId: shiftId,
    context: {
      [isVet ? "vetId" : "techId"]: contractorId,
      shiftStatus: getShiftStatusText(shiftStatus),
    },
  });
};

export const removeShiftEventTracking = ({
  shiftId,
  shiftStatus,
  isVet,
}: {
  shiftId: number;
  isVet?: boolean;
  shiftStatus: ShiftStatus;
}) => {
  EventTracker.send({
    eventName: isVet ? Event.Name.HOSPITAL_REMOVE_VET_SHIFT : Event.Name.HOSPITAL_REMOVE_TECH_SHIFT,
    eventType: Event.Type.CLICK,
    entityType: isVet ? Event.Entity.VET_SHIFT : Event.Entity.TECH_SHIFT,
    entityId: shiftId,
    context: { shiftStatus: getShiftStatusText(shiftStatus) },
  });
};

export const getShiftStatusText = (shiftStatus: ShiftStatus) => {
  switch (shiftStatus) {
    case ShiftStatus.Unfilled:
      return "unfilled";
    case ShiftStatus.Requested:
      return "requested";
    case ShiftStatus.Confirmed:
      return "confirmed";
    case ShiftStatus.Completed:
      return "completed";
    case ShiftStatus.Expired:
      return "expired";
    case ShiftStatus.AllRequestExpired:
      return "expired-requests";
    case ShiftStatus.Unavailable:
      return "unavailable";
    default:
      return "";
  }
};
