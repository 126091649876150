import React, { useContext } from "react";
import { Toast } from "@RooUI";

import { ToastContext } from "../context/ToastContext";

export const ToastContainer = () => {
  const { toastData, hideToast } = useContext(ToastContext);

  return (
    <>
      {toastData && (
        <Toast
          {...toastData}
          style={{ zIndex: 1060, ...(toastData.style ?? {}) }}
          onHide={hideToast}
        />
      )}
    </>
  );
};
