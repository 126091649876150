import { Chip, Heading, Link, Text, purpleBackground } from "@RooUI";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";

import { rooFetch } from "../../api/common/rooFetch";
import { Event, EventTracker } from "../../tracking/service/EventTracker/EventTrackerService";

interface LicenseType {
  LicenseNumber: string;
  LicenseStatusTypeName: string;
  OriginalLicenseDateStr: string;
  LicenseExpirationDateStr: string;
  DisciplineFlag: boolean | null;
  LicenseDisciplineStatusName: string;
}

interface LicenseDataType {
  LicenseList: Array<LicenseType>;
  LicenseTypeName: string;
  FirstName: string;
  LastName: string;
  StreetLine1: string;
  City: string;
  StateCode: string;
}

const LicenseViewer = () => {
  const params = new URLSearchParams(window.location.search);
  const licenseNumber = params.get("licenseNumber");
  const [licenseInfo, setLicenseInfo] = useState(null);
  const [licenseData, setLicenseData] = useState<LicenseDataType[]>([]);

  const getLicenseList = () => {
    const licenseList: Array<{
      createdDate: string;
      expiredDate: string;
      licenseNumber: string;
      licenseStatus: string;
      displineFlag: string;
    }> = [];

    licenseData.forEach((lData) => {
      lData.LicenseList?.forEach((license) => {
        licenseList.push({
          createdDate: license?.OriginalLicenseDateStr,
          expiredDate: license?.LicenseExpirationDateStr,
          licenseNumber: license?.LicenseNumber,
          licenseStatus: license?.LicenseStatusTypeName,
          displineFlag: license?.LicenseDisciplineStatusName,
        });
      });
    });
    return licenseList;
  };

  const fetchLicense = () => {
    rooFetch(
      window.RooConfig.API_URL + `api/license/fetchLicenseInfo?licenseNumber=${licenseNumber}`
    )
      .then((response) => response.json())
      .then((licenseData) => {
        if (licenseData.data[0]) {
          if (Array.isArray(licenseData.data)) {
            setLicenseData(licenseData.data);
            setLicenseInfo(licenseData.data[0]);
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error, { extra: { context: "Error getting user license data" } });
      });
  };

  useEffect(() => {
    EventTracker.send({
      eventName: Event.Name.ADMIN_LICENSE_INFO,
      eventType: Event.Type.PAGE_VIEW,
      entityType: undefined,
      entityId: undefined,
      context: {
        action: `view license date page for license ${licenseNumber}`,
        type: licenseNumber,
      },
    });
    fetchLicense();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLicenseRows = () => {
    const licenseList = getLicenseList();

    return licenseList.map((license, lindex) => {
      return (
        <tr key={lindex}>
          <td>
            <Chip text={license.licenseStatus} backgroundColor={purpleBackground} />{" "}
            {license.licenseNumber}
          </td>
          <td>{license.createdDate}</td>
          <td>{license.expiredDate}</td>
          <td>{license.displineFlag}</td>
        </tr>
      );
    });
  };

  //   const renderDisciplineActionRows = () => {
  //     const disciplineActionList = getDisciplineActionList();
  //     if (disciplineActionList.length === 0) {
  //       return (
  //         <tr>
  //           <td>--</td>
  //           <td>--</td>
  //           <td>--</td>
  //           <td>--</td>
  //         </tr>
  //       );
  //     }
  //     return null;
  //   };

  const renderSpacing = (size: "small" | "medium" | "large") => {
    const heightMap = {
      small: 12,
      medium: 20,
      large: 24,
    };
    return <div style={{ height: heightMap[size] }} />;
  };

  const userFullName = licenseInfo?.FirstName
    ? licenseInfo.FirstName + " " + licenseInfo.LastName
    : null;

  return (
    <div className="license-content">
      <Link
        href={window.location.origin + "/admin"}
        onClick={() => {
          EventTracker.send({
            eventName: Event.Name.ADMIN_LICENSE_INFO_BACK_NAV,
            eventType: Event.Type.CLICK,
            entityType: undefined,
            entityId: undefined,
            context: {
              location: "license data page",
              type: licenseNumber,
            },
          });
        }}
      >
        Back to Admin
      </Link>
      {renderSpacing("small")}
      <Heading level={1}>License Details</Heading>
      {renderSpacing("large")}
      <Heading level={2}>{userFullName}</Heading>
      {renderSpacing("small")}
      <Text>
        ### {`${licenseInfo?.StreetLine1}, ${licenseInfo?.City}, ${licenseInfo?.StateCode}`} #####
      </Text>
      {renderSpacing("large")}

      <Heading level={6}>Credential Info</Heading>
      <table className="license-table">
        <thead>
          <tr>
            <th>{licenseInfo?.LicenseTypeName}</th>
            <th>Issue Date</th>
            <th>Expiration Date</th>
            <th>Discipline Status</th>
          </tr>
        </thead>
        <tbody>{renderLicenseRows()}</tbody>
      </table>

      {renderSpacing("medium")}
      {/* <Heading level={6}>Disipline History</Heading>
      <table className="license-table">
        <thead>
          <tr>
            <th>Credential Number</th>
            <th>Discipline Status</th>
            <th>Displine Type</th>
            <th>Public Document</th>
          </tr>
        </thead>
        <tbody>{renderDisciplineActionRows()}</tbody>
      </table> */}
    </div>
  );
};

// eslint-disable-next-line no-restricted-exports
export default LicenseViewer;
