import { GET } from "@roo-dev/roo-node-types";

import { rooFetch } from "../../common/rooFetch";

type GetAllConferenceEventsResponse = GET.Admin.ConferenceEvent.GetAllConferenceEventsResponse;
type GetConferenceEventResponse = GET.Admin.ConferenceEvent.GetConferenceEventResponse;

const getAllConferenceEventsUrl = `${window.RooConfig.API_URL}api/admin/getAllConferenceEvents`;
const getConferenceEventUrl = `${window.RooConfig.API_URL}api/admin/getConferenceEvent`;
const getConferenceEventBySlugUrl = `${window.RooConfig.API_URL}api/admin/getConferenceEventBySlug`;

export const getAllConferenceEvents = async (): Promise<GetAllConferenceEventsResponse> => {
  const response = await rooFetch(getAllConferenceEventsUrl);
  return response.json();
};

export const getConferenceEvent = async (
  conferenceEventId: string
): Promise<GetConferenceEventResponse> => {
  const response = await rooFetch(`${getConferenceEventUrl}/${conferenceEventId}`);
  return response.json();
};

export const getConferenceEventBySlug = async (
  slug: string
): Promise<GetConferenceEventResponse> => {
  const response = await rooFetch(`${getConferenceEventBySlugUrl}/${slug}`);
  return response.json();
};
