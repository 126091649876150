import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { APIResponse } from "../common/response";
import { get } from "../common/rooFetch";
import { CACHE } from "../utility/constants";

export enum MarketStatus {
  NEW_MARKET = "NEW_MARKET",
  OUT_OF_MARKET = "OUT_OF_MARKET",
  IN_MARKET = "IN_MARKET",
}

export const marketStatusSchema = z.nativeEnum(MarketStatus);

const getMarketStatus = async (userId: number): Promise<MarketStatus> => {
  const response = await get<APIResponse<{ status: MarketStatus }>>(
    `api/user/${userId}/marketStatus`
  );

  const parsedResponse = marketStatusSchema.safeParse(response.data);

  if (!parsedResponse.success) {
    Sentry.captureException(parsedResponse.error);
    throw new Error("Invalid response format for MarketStatus.");
  }
  return parsedResponse.data;
};

export const useGetMarketStatus = (userId: number) => {
  const queryKey = [CACHE.GET_MARKET_STATUS, userId];
  return useQuery(queryKey, () => getMarketStatus(userId), {
    enabled: !!userId && userId > 0,
  });
};
