import moment, { Moment } from "moment";
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";

import { getHospitalCalendlyCall } from "./api/getHospitalCalendlyCall";
import { Task, completeTask, getHospitalTasks } from "./api/tasks";
import { getCalendlyLink } from "./api/user/getCalendlyLink";
import { showToast } from "./Common/Toasts/action/ToastActions";
import { isRooFeatureEnabled } from "./Common/Wrappers/isRooFeatureEnabled";
import { FEATURE_FLAGS } from "./constants/postHogFeatureFlags";
import hospitalOnboardingTasks from "./locales/en/hospitalOnboarding.json";
import { Event, EventTracker } from "./tracking/service/EventTracker/EventTrackerService";

export type TaskTypeID = keyof typeof hospitalOnboardingTasks;

export const TASK_TYPE: { [K in TaskTypeID]: K } = {
  HOSPITAL_ONBOARDING_COMPLETE_PROFILE: "HOSPITAL_ONBOARDING_COMPLETE_PROFILE",
  HOSPITAL_ONBOARDING_ACCEPT_POLICIES: "HOSPITAL_ONBOARDING_ACCEPT_POLICIES",
  HOSPITAL_ONBOARDING_PREVIEW_SHIFT_CREATION: "HOSPITAL_ONBOARDING_PREVIEW_SHIFT_CREATION",
  HOSPITAL_ONBOARDING_MANAGE_BILLING: "HOSPITAL_ONBOARDING_MANAGE_BILLING",
  HOSPITAL_ONBOARDING_ADD_TEAM_MEMBERS: "HOSPITAL_ONBOARDING_ADD_TEAM_MEMBERS",
  HOSPITAL_ONBOARDING_SCHEDULE_CALL: "HOSPITAL_ONBOARDING_SCHEDULE_CALL",
  HOSPITAL_ONBOARDING_GROUP_ONBOARDING_CALL: "HOSPITAL_ONBOARDING_GROUP_ONBOARDING_CALL",
};

interface TaskContextType {
  tasks: Task[];
  loading: boolean;
  error: string | null;
  markTaskComplete: (
    taskId: number,
    taskType: string,
    toastTitle: string,
    toastDescription?: string,
    isSkip?: boolean
  ) => Promise<void>;
  loadTasks: (createdHospitalId?: number) => Promise<void>;
  isTaskCenterEnabled: boolean;
  calendlyLink: string | null;
  calendlyCallDate: Moment | null;
}

const TaskContext = createContext<TaskContextType | undefined>(undefined);

interface TaskProviderProps {
  children: ReactNode;
  hospitalId?: number;
  userId?: number;
}

export const TaskProvider: React.FC<TaskProviderProps> = ({ children, hospitalId, userId }) => {
  const isTaskCenterEnabled = !!isRooFeatureEnabled(FEATURE_FLAGS.ONBOARDING_TASK_CENTER);

  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [calendlyLink, setCalendlyLink] = useState<string | null>(null);
  const [calendlyCallDate, setCalendlyCallDate] = useState<Moment | null>(null);
  const dispatch = useDispatch();

  const loadTasks = useCallback(
    async (createdHospitalId?: number) => {
      if (!hospitalId) return;

      try {
        const fetchedTasks = await getHospitalTasks(createdHospitalId ?? hospitalId);
        setTasks(fetchedTasks);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch tasks");
        setLoading(false);
      }
    },
    [hospitalId]
  );

  const loadCalendlyLink = useCallback(async () => {
    if (!userId) return;
    try {
      const calendlyLink = await getCalendlyLink({ userId });
      setCalendlyLink(calendlyLink);
    } catch (err) {
      setError("Failed to fetch Calendly link");
    }
  }, [userId]);

  const loadCalendlyCall = useCallback(async () => {
    if (!hospitalId) return;
    try {
      const calendlyCallDatetime = await getHospitalCalendlyCall(hospitalId);
      setCalendlyCallDate(calendlyCallDatetime ? moment(calendlyCallDatetime) : null);
    } catch (err) {
      setError("Failed to fetch calendly call");
    }
  }, [hospitalId]);

  useEffect(() => {
    loadTasks();
  }, [loadTasks]);

  useEffect(() => {
    loadCalendlyLink();
  }, [loadCalendlyLink]);

  useEffect(() => {
    loadCalendlyCall();
  }, [loadCalendlyCall]);

  const markTaskComplete = useCallback(
    async (
      taskId: number,
      taskType: string,
      toastTitle: string,
      toastDescription?: string,
      isSkip?: boolean
    ) => {
      try {
        await completeTask(taskId);
        dispatch(
          showToast({
            variant: "success",
            message: toastTitle,
            description: toastDescription ?? "Task has been marked as complete",
          })
        );

        const eventName = isSkip
          ? Event.Name.TASK_CENTER_SKIP_TASK
          : Event.Name.TASK_CENTER_COMPLETE_TASK;
        const eventType = isSkip ? Event.Type.CLICK : Event.Type.IMPRESSION;
        EventTracker.send({
          eventName: eventName,
          eventType: eventType,
          entityType: Event.Entity.HOSPITAL,
          entityId: hospitalId,
          context: {
            taskId,
            taskType,
          },
        });

        await loadTasks();
      } catch (err) {
        setError("Failed to mark task as complete");
        dispatch(
          showToast({
            variant: "error",
            message: "Error",
            description: "Failed to complete task.",
          })
        );
      }
    },
    [hospitalId, dispatch, loadTasks]
  );

  const value = useMemo(
    () => ({
      tasks,
      loading,
      error,
      markTaskComplete,
      isTaskCenterEnabled,
      calendlyLink,
      calendlyCallDate,
      loadTasks,
    }),
    [
      tasks,
      loading,
      error,
      markTaskComplete,
      isTaskCenterEnabled,
      calendlyLink,
      calendlyCallDate,
      loadTasks,
    ]
  );

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>;
};

export const useTaskContext = (): TaskContextType => {
  const context = useContext(TaskContext);
  if (context === undefined) {
    throw new Error("useTaskContext must be used within a TaskProvider");
  }
  return context;
};

// task helpers
export const findTaskByType = (tasks: Task[], taskType: string): Task | undefined => {
  return tasks.find(({ type }) => type.toUpperCase() === taskType.toUpperCase());
};

export const getTaskDetails = (taskType: string) => {
  return hospitalOnboardingTasks[taskType.toUpperCase() as TaskTypeID];
};
