import { CustomToastProps } from "../../components/Toast";

export const SUCCESS_MESSAGE: CustomToastProps = {
  variant: "success",
  message: "Success",
  description: "Your profile was updated!",
};

export const ERROR_MESSAGE: CustomToastProps = {
  variant: "error",
  message: "Error",
  description: "There was an error updating your profile.",
};
