import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Heading } from "@RooUI";

import {
  bulkUpdateNotifications,
  updateNotificationSettings,
  useNotificationSettings,
} from "../../../api/notifications/notifications";
import {
  SHIFTS,
  UserTypeId,
  sideNavItems,
  sideNavKeyMap,
} from "../../../constants/notificationConstants";
import {
  HOSPITAL_USER_TYPE,
  STUDENT_USER_TYPE,
  TECH_USER_TYPE,
  VET_USER_TYPE,
} from "../../../constants/UserTypeConstants";
import { HospitalsSelect } from "../../../Hospital/rooUniversityHospital/components/externships/dashboard/HospitalsSelect";
import { UserNotificationSettingFromAPI } from "../interfaces";
import { determineChannelStatuses, processNotificationSettings, showToastUtil } from "../utils";

import { StudentOrHospitalNotifications } from "./StudentorHospitalNotifications";
import { NotificationHeaderContainer } from "./styles";
import { VetOrTechNotifications } from "./VetorTechNotifications";

export interface ToggleUpdateParams {
  label: string;
  value: boolean;
  notificationTypeId: number;
}

export interface ToggleChangeParams {
  emailEnabled: number;
  smsEnabled: number;
  pnEnabled: number;
  notificationTypeId: number;
}

export interface HospitalData {
  id: number;
  name: string;
  isInternshipsAvaiable: number; // There's a typo in the API
}

const NotificationSettingsNew = ({
  currentUser,
  userId,
}: {
  currentUser: any; // TODO
  userId: string;
}) => {
  const hospitalId = currentUser?.hospitalId;

  const dispatch = useDispatch();
  const isPrimaryHospital = currentUser.userId === currentUser.hospitalUserId;
  const isMultiHospitalAccess = currentUser?.myHospitalsList?.length > 1;

  const userTypeId = currentUser?.userTypeId as UserTypeId;
  const [results, setResults] = useState([]);

  const [notificationSettings, setNotificationSettings] = useState<
    UserNotificationSettingFromAPI[]
  >([]);

  const isHospital = userTypeId === HOSPITAL_USER_TYPE;
  const isVetOrTech = userTypeId === VET_USER_TYPE || userTypeId == TECH_USER_TYPE;
  const isHospitalOrStudent = isHospital || userTypeId === STUDENT_USER_TYPE;
  const userSideNavKey = sideNavKeyMap[userTypeId];

  const { isLoading, isError, isSuccess, data } = useNotificationSettings({
    userId,
    hospitalId,
  });

  const onUpdateToggle = async (payload: ToggleChangeParams) => {
    const { status } = await updateNotificationSettings({
      userId,
      hospitalId,
      ...payload,
    });

    showToastUtil(status, dispatch);
  };

  const handleToggleChange = async ({ label, value, notificationTypeId }: ToggleUpdateParams) => {
    // Update local state based on the toggle change.
    const updatedSettings = notificationSettings.map((setting) =>
      setting.notification_type_code === notificationTypeId
        ? {
            ...setting,
            email_enabled: label === "Email" ? Number(value) : setting.email_enabled,
            sms_enabled: label === "SMS" ? Number(value) : setting.sms_enabled,
            push_enabled: label === "Push Notifications" ? Number(value) : setting.push_enabled,
          }
        : setting
    );

    setNotificationSettings(updatedSettings);

    const currentSetting = notificationSettings.find(
      (s) => s.notification_type_code === notificationTypeId
    );

    // Prepare payload for the backend update.
    await onUpdateToggle({
      emailEnabled: label === "Email" ? Number(value) : currentSetting?.email_enabled,
      smsEnabled: label === "SMS" ? Number(value) : currentSetting?.sms_enabled,
      pnEnabled: label === "Push Notifications" ? Number(value) : currentSetting?.push_enabled,
      notificationTypeId: notificationTypeId,
    });
  };

  const toggleAllSettings = async (value: number) => {
    const shiftSettingsTypeId = 29; // Manages the all notificiations toggle.
    const currentTab = sideNavItems[userSideNavKey].find((item: any) => item.label === SHIFTS);

    // Update all settings based on the value passed.
    const newUpdatedSettings = notificationSettings.map((setting) => {
      if (
        currentTab.typeCodes.includes(setting.notification_type_code) &&
        (!currentTab?.disabledOnPrimary?.includes(setting.notification_type_code) ||
          !isPrimaryHospital)
      ) {
        return { ...setting, email_enabled: value, sms_enabled: value, push_enabled: value };
      }

      // Additional check for vet techs on the Shifts tab with the specific setting type.
      if (userTypeId === VET_USER_TYPE || userTypeId === TECH_USER_TYPE) {
        if (
          currentTab.title === SHIFTS &&
          currentTab.typeCodes.includes(setting.notification_type_code)
        ) {
          return { ...setting, email_enabled: value, sms_enabled: value, push_enabled: value };
        }
      }

      return setting;
    });

    setNotificationSettings(newUpdatedSettings);

    // Prepare the list of settings to be updated in the backend.
    const newCurrentTabSettings = newUpdatedSettings.filter((setting) => {
      return (
        currentTab.typeCodes.includes(setting.notification_type_code) ||
        ((userTypeId === VET_USER_TYPE ||
          userTypeId === TECH_USER_TYPE ||
          userTypeId === HOSPITAL_USER_TYPE) &&
          currentTab.title === SHIFTS &&
          setting.notification_type_code === shiftSettingsTypeId)
      );
    });

    const { status } = await bulkUpdateNotifications({
      notificationSettingsList: newCurrentTabSettings,
      userId: userId,
      hospitalId: currentUser?.hospitalId,
    });

    showToastUtil(status, dispatch);
  };

  const areAllNotificationsEnabled = () => {
    if (!results?.length) {
      return 0;
    }

    const toggleInfo = results[0]; // shifts

    for (const toggle of toggleInfo.shifts) {
      for (const channel of toggle.toggleOptions) {
        if (!channel.value) {
          return 0;
        }
      }
    }

    return 1;
  };

  useEffect(() => {
    if (isSuccess && data) {
      setNotificationSettings(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (notificationSettings) {
      setResults(
        determineChannelStatuses(
          sideNavItems[userSideNavKey],
          processNotificationSettings(notificationSettings),
          isPrimaryHospital
        )
      );
    }
  }, [notificationSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container" id="hospital-notification-settings">
      <NotificationHeaderContainer>
        <Heading level={1} data-testid="notification-page-content">
          Notification Settings
        </Heading>
        {isMultiHospitalAccess && <HospitalsSelect />}
      </NotificationHeaderContainer>
      {isError && <div>Error fetching notification settings.</div>}
      {!isError && !isLoading && isVetOrTech && (
        <VetOrTechNotifications
          handleToggleChange={handleToggleChange}
          processedData={results}
          areAllNotificationsEnabled={areAllNotificationsEnabled}
          toggleAllSettings={toggleAllSettings}
          shiftPreferencesId={notificationSettings[0]?.notification_settings_id}
          frequencyTypeCode={notificationSettings[0]?.frequency_type_code}
        />
      )}
      {!isError && !isLoading && isHospitalOrStudent && (
        <StudentOrHospitalNotifications
          handleToggleChange={handleToggleChange}
          processedData={results}
          areAllNotificationsEnabled={areAllNotificationsEnabled}
          toggleAllSettings={toggleAllSettings}
        />
      )}
    </div>
  );
};

// eslint-disable-next-line no-restricted-exports
export default NotificationSettingsNew;
