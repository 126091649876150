import React, { CSSProperties } from "react";

import { grayWhite, purpleBase, smallMediaBreakpoint } from "../../styles/constants";
import { Heading } from "../Heading";
import { Icon, IconProps } from "../Icon";
import { Loading } from "../Loading";
import { ProgressBar } from "../ProgressBar";
import { BoldText } from "../Text";

import { CheckListTask, ChecklistCard } from "./ChecklistCard";
import {
  CelebrationEmoji,
  CompletionMessage,
  Header,
  HeadingTitleContainer,
  ProgressBarContainer,
  SectionCardContainer,
  TaskList,
  TaskProgressContainer,
  TaskProgressCount,
} from "./SectionCardStyles";

export enum SectionCardType {
  CHECKLIST = "CHECKLIST",
  DEFAULT = "DEFAULT",
}

// defaults to generic container
interface GenericContainerProps {
  type?: SectionCardType.DEFAULT;
  headerText: string;
  headerContent?: React.ReactNode;
  headerIconName?: IconProps["name"];
  children: React.ReactNode;
}

export type { CheckListTask };
interface ChecklistProps {
  type: SectionCardType.CHECKLIST;
  tasks: CheckListTask[];
  completionMessageTitle: string;
  completionMessage: string;
  showTaskProgress?: boolean;
  isLoading?: boolean;
}

export type SectionCardProps = (GenericContainerProps | ChecklistProps) & {
  overrideContainerStyles?: CSSProperties;
};

interface RenderSectionCardProps {
  headerText: string;
  bodyContent: React.ReactNode;
  headerContent?: React.ReactNode;
  headerIconName?: IconProps["name"];
  overrideContainerStyles?: CSSProperties;
}

const renderSectionCard = ({
  headerText,
  headerIconName,
  headerContent,
  bodyContent,
  overrideContainerStyles,
}: RenderSectionCardProps) => {
  return (
    <SectionCardContainer
      overrideStyles={overrideContainerStyles}
      data-testid="sectionCardContainer"
    >
      <Header>
        <HeadingTitleContainer>
          {headerIconName && <Icon name={headerIconName} size="xl" color={purpleBase} />}
          <Heading level={4} color={purpleBase}>
            {headerText}
          </Heading>
        </HeadingTitleContainer>
        {headerContent}
      </Header>
      {bodyContent}
    </SectionCardContainer>
  );
};

export const SectionCard = (props: SectionCardProps) => {
  if (props.type === SectionCardType.CHECKLIST) {
    const {
      tasks,
      completionMessageTitle,
      completionMessage,
      showTaskProgress,
      overrideContainerStyles,
      isLoading,
    } = props;

    const completedTasks = tasks.filter((task) => task.isCompleted).length;
    const percentCompleted = (completedTasks / tasks.length) * 100;
    const allTasksCompleted = completedTasks === tasks.length;

    const renderChecklistProgress = () => {
      if (!showTaskProgress || allTasksCompleted) return null;

      return (
        <TaskProgressContainer>
          <ProgressBarContainer>
            <ProgressBar percent={percentCompleted} strokeWidth={8} trailColor={grayWhite} />
          </ProgressBarContainer>
          <TaskProgressCount>
            <BoldText>{completedTasks}</BoldText> out of <BoldText>{tasks.length}</BoldText>{" "}
            completed
          </TaskProgressCount>
        </TaskProgressContainer>
      );
    };

    const renderChecklistBody = () => {
      if (isLoading) {
        return (
          <CompletionMessage>
            <Loading color={purpleBase} />
            <Heading level={4}>Loading tasks</Heading>
          </CompletionMessage>
        );
      }
      return allTasksCompleted ? (
        <CompletionMessage>
          <CelebrationEmoji>🎉</CelebrationEmoji>
          <Heading data-testid="completionMessageTitle" level={4}>
            {completionMessageTitle}
          </Heading>
          <Heading data-testid="completionMessage" level={5}>
            {completionMessage}
          </Heading>
        </CompletionMessage>
      ) : (
        <TaskList>
          {tasks.map((task) => (
            <ChecklistCard
              key={task.title}
              title={task.title}
              description={task.description}
              ctaText={task.ctaText}
              ctaAction={task.ctaAction}
              skipAction={task.skipAction}
              isCompleted={task.isCompleted}
              isPrimaryCTA={task.isPrimaryCTA}
            />
          ))}
        </TaskList>
      );
    };

    const checklistCustomStyles = {
      maxHeight: "580px",
      minHeight: allTasksCompleted || isLoading ? "580px" : "auto",
      height: "auto",
      [`@media (max-width: ${smallMediaBreakpoint})`]: {
        maxHeight: "none",
      },
    };

    return renderSectionCard({
      headerText: "Tasks",
      headerIconName: "AssignmentTurnedIn",
      headerContent: renderChecklistProgress(),
      bodyContent: renderChecklistBody(),
      overrideContainerStyles: {
        ...checklistCustomStyles,
        ...overrideContainerStyles,
      },
    });
  }

  return renderSectionCard({ ...props, bodyContent: props.children });
};
