import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { MarketStatus, useGetMarketStatus } from "../api/user/useGetMarketStatus";
import { CalendlyStatus, GetUserInfoResponse, useGetUserInfo } from "../api/user/useGetUserInfo";
import { useGetServerFeatureFlagVariantKey } from "../api/user/useServerFeatureFlag";
import { AccountActivationCallModal } from "../Common/Modals/AccountActivationCallModal";
import { CalendlyPointOfEntry } from "../constants/posthogEventContextTypes";
import { FEATURE_FLAGS, FEATURE_FLAG_VARIANTS } from "../constants/postHogFeatureFlags";
import * as userStatusTypes from "../constants/userStatusTypes";
import { TECH_USER_TYPE, VET_USER_TYPE } from "../constants/UserTypeConstants";

interface VetTechDashboardContextType {
  showShiftPreview: boolean;
  showPendingState: boolean;
  showBookCalendlyAlert: boolean;
  showCalendlyBookedAlert: boolean;
  showOnboardingBanner: boolean;
  calendlyCallStartDatetime: string | null;
  isLoadingMarketStatus: boolean;
  calendlyStatus: CalendlyStatus | null;
  userInfo?: GetUserInfoResponse["data"]["results"][number];
  userInfoFetched: boolean;
  isCalendlyCallPromoEnabled: boolean;

  // Used to know how the user got to account activation modal (which leads to Calendly)
  setPointOfEntry: (pointOfEntry: CalendlyPointOfEntry | null) => void;
}

const defaultContextValue: VetTechDashboardContextType = {
  showShiftPreview: false,
  showPendingState: false,
  showBookCalendlyAlert: false,
  showCalendlyBookedAlert: false,
  showOnboardingBanner: false,
  calendlyCallStartDatetime: null,
  isLoadingMarketStatus: false,
  userInfoFetched: false,
  calendlyStatus: null,
  isCalendlyCallPromoEnabled: false,

  setPointOfEntry: () => {},
};
const VetTechDashboardContext = createContext<VetTechDashboardContextType>(defaultContextValue);

export const VetTechDashboardProvider = ({ children }: { children: React.ReactNode }) => {
  const [pointOfEntry, setPointOfEntry] = useState<CalendlyPointOfEntry | null>(null);
  const [isCalendlyCallPromoEnabled, setIsCalendlyCallPromoEnabled] = useState(false);

  const loginData: any = useSelector((state: any) => state.login);
  const { email, userId, vetId, userTypeId, isShowOnboarding } = loginData;

  const isVet = parseInt(userTypeId) === VET_USER_TYPE;
  const isTech = parseInt(userTypeId) === TECH_USER_TYPE;
  const userTypeValue = parseInt(localStorage.getItem("isActive") || "0");

  const { data: marketStatus, isLoading: isLoadingMarketStatus } = useGetMarketStatus(userId);
  const { data: getUserInfoData, isFetched: userInfoFetched } = useGetUserInfo({
    userTypeId: isVet ? VET_USER_TYPE : TECH_USER_TYPE,
    email,
    enabled: !!vetId,
  });

  const userInfo = getUserInfoData?.data?.results[0];
  const inMarket = marketStatus === MarketStatus.IN_MARKET;

  const contextValue = useMemo(() => {
    const isPending = userTypeValue === userStatusTypes.PENDING;

    const showShiftPreview = isPending && inMarket && isVet;
    const showPendingState = !showShiftPreview && isPending;

    const callComplete = userInfo?.calendlyStatus === CalendlyStatus.Completed;
    const callScheduled = userInfo?.calendlyStatus === CalendlyStatus.Scheduled;

    const showBookCalendlyAlert =
      showShiftPreview && userInfoFetched && !callScheduled && !callComplete;
    const showCalendlyBookedAlert = showShiftPreview && callScheduled;

    const showTechOnboardingBanner = isTech && isShowOnboarding === 0;
    const showVetOnboardingBanner =
      isVet &&
      isShowOnboarding === 0 &&
      userInfoFetched &&
      !showBookCalendlyAlert &&
      !showCalendlyBookedAlert;

    return {
      showShiftPreview,
      showPendingState,
      showBookCalendlyAlert,
      showCalendlyBookedAlert,
      showOnboardingBanner: showTechOnboardingBanner || showVetOnboardingBanner,
      isLoadingMarketStatus,
      calendlyCallStartDatetime: userInfo?.calendlyCallStartDatetime || null,
      calendlyStatus: userInfo?.calendlyStatus || null,
      pointOfEntry,
      setPointOfEntry,
      userInfo,
      userInfoFetched,
      isCalendlyCallPromoEnabled,
    };
  }, [
    inMarket,
    userTypeValue,
    userInfoFetched,
    userInfo,
    isVet,
    isTech,
    isShowOnboarding,
    isLoadingMarketStatus,
    pointOfEntry,
    isCalendlyCallPromoEnabled,
  ]);

  const { data: calendlyCallPromoVariantKey } = useGetServerFeatureFlagVariantKey(
    userId,
    FEATURE_FLAGS.CALENDLY_CALL_PROMO,
    contextValue.showBookCalendlyAlert
  );

  const isCalendlyCallPromoVariantKeyTest =
    calendlyCallPromoVariantKey === FEATURE_FLAG_VARIANTS.TEST;

  useEffect(() => {
    setIsCalendlyCallPromoEnabled(
      contextValue.showShiftPreview && isCalendlyCallPromoVariantKeyTest
    );
  }, [contextValue.showShiftPreview, isCalendlyCallPromoVariantKeyTest]);

  return (
    <VetTechDashboardContext.Provider value={contextValue}>
      {!!pointOfEntry && (
        <AccountActivationCallModal
          vetId={vetId}
          isCalendlyCallPromoEnabled={isCalendlyCallPromoEnabled}
          showModal={!!pointOfEntry}
          pointOfEntry={pointOfEntry}
          onCloseModal={() => setPointOfEntry(null)}
        />
      )}

      {children}
    </VetTechDashboardContext.Provider>
  );
};

export const useVetTechDashboardContext = () => {
  const context = useContext(VetTechDashboardContext);
  if (context === undefined) {
    throw new Error("useVetTechDashboardContext must be used within a VetTechDashboardProvider");
  }
  return context;
};

export { VetTechDashboardContext };
