import { HOSPITAL_USER_TYPE, UserType } from "../constants/UserTypeConstants";

import { ErrorResponse } from "./common/error";
import { APIResponse } from "./common/response";
import { get, rooFetch } from "./common/rooFetch";
import { ProfileImage } from "./vet";

export interface HospitalSettings {
  hospitalName?: string;
  hospitalAddress?: string;
  zipcode: string;
  city: string;
  stateId: number;
  aptUnitSuite?: string;
  hospitalWebsite?: string;
  phoneNumber: string;
  roleId?: string;
  latitude?: string;
  longitude?: string;
}

export interface AccreditationCheckboxes {
  id: number;
  label: string;
  checked?: boolean;
  "data-testid": string;
}

export interface HospitalProfile {
  typeOfHospital: number;
  isAahaAccredited?: number;
  averageNoOfDVMWorkPerDay?: number;
  averageNoOfTechPerDvmPerDay?: number;
  medicalDirector?: string;
  averageTransactionVolumePerDay: number;
  averageTransactionValue: number;
  averageSurgeryVolumePerDay: number;
  hospitalMaintainRecords: number;
  practiceManagementSoftware?: string;
  practiceManagementSoftwareOther?: string;
  wantsFullTime?: number;
  typicalLunchBreakSetup?: number;
  hospitalReliefWork?: number;
  hospitalDressCodes?: string;
  hospitalLogo?: string;
  hasApptAcupuncture?: number;
  hasApptChiropractic?: number;
  hasApptDental?: number;
  hasApptEmergency?: number;
  hasApptGeneral?: number;
  hasApptHighVol?: number;
  hasApptLargeAnimal?: number;
  hasApptLaser?: number;
  hasApptNonwell?: number;
  hasApptShelter?: number;
  hasApptSpecialty?: number;
  hasApptSurgery?: number;
  hasApptUrgentCare?: number;
  hasApptWalkin?: number;
  hasApptWellness?: number;
  staffInteract?: number;
  apptFlow?: number;
  clientInteract?: number;
  amenitiesNotes?: string;
  parkingNotes?: string;
  hospitalImageUrl?: string;
  letterName?: string;
  letterPath?: string;
  latitude?: string;
  longitude?: string;
  hasFearFreeCertification?: number;
  accreditations?: AccreditationCheckboxes[];
}

export interface User {
  userId?: string | number;
  characterTypeId: number;
  additionalSkills?: string;
  stateName?: string;
  emailId?: string;
}

export interface HospitalDataResponse extends HospitalSettings, HospitalProfile {}
export interface HospitalProfileResponse {
  user: User[];
  hospital: HospitalDataResponse[];
  profileImage: ProfileImage[];
}

export interface HospitalProfileForm extends HospitalProfile, HospitalSettings, User {
  profileImage: ProfileImage[];
  hospitalPreferences?: { label: string; id: string; checked: boolean }[];
  hospitalDressCodeIds?: number[];
}

export const fetchHospitalProfile = async (
  userTypeId: UserType,
  userId: string,
  hospitalId: string
): Promise<HospitalProfileResponse> => {
  const response = await rooFetch(
    `${window.RooConfig.API_URL}api/hospital/getHopsitalProfileInfoById?userType=${userTypeId}&userId=${userId}&hospitalId=${hospitalId}`
  );

  const { data } = await response.json();
  return data;
};

export interface UpdateHospitalProfileRequest extends Omit<HospitalProfile, "hospitalDressCodes"> {
  userId: string;
  userTypeId: UserType;
  hospitalId: string;
  hospitalDressCodes: number[];
}
export const updateHospitalProfile = async (
  request: UpdateHospitalProfileRequest
): Promise<HospitalProfileResponse> => {
  const response = await rooFetch(`${window.RooConfig.API_URL}api/hospital/hospitalEditProfile`, {
    method: "PUT",
    body: JSON.stringify(request),
  });
  const { data } = await response.json();
  return data;
};

interface UpdateHospitalSettingsRequest extends HospitalSettings {
  userId: string;
  hospitalId: string;
}

export const updateHospitalSettings = async (
  request: UpdateHospitalSettingsRequest
): Promise<HospitalProfileResponse> => {
  const response = await rooFetch(`${window.RooConfig.API_URL}api/hospital/HospitalSettingInfo`, {
    method: "PUT",
    body: JSON.stringify(request),
  });
  const { data } = await response.json();
  return data;
};

interface CreateHospitalRequest {
  userId: number;
  hospitalName: string;
  hospitalAddress: string;
  hospitalState: number;
  hospitalCity: string;
  hospitalZipcode: string;
  hospitalPhoneNumber: string;
  hearAboutRoo?: string;
  selfIdentifiedEnterpriseName?: string;
  anticipatedReliefNeed?: string;
}

export type CreateHospitalResponse =
  | APIResponse<{
      calendlyLink: string;
      hospitalId: number;
      rooState: boolean;
    }>
  | ErrorResponse;

export const createHospital = async (
  request: CreateHospitalRequest
): Promise<CreateHospitalResponse> => {
  const response = await rooFetch(`${window.RooConfig.API_URL}api/hospital`, {
    method: "POST",
    body: JSON.stringify(request),
  });

  return response.json();
};

interface UpdateHospitalRequest {
  hospitalId: number;
  isRegistrationComplete: number;
  isForRegistration: number;
}

export const updateHospital = async (request: UpdateHospitalRequest) => {
  rooFetch(`${window.RooConfig.API_URL}api/hospital`, {
    method: "PATCH",
    body: JSON.stringify({ ...request, userTypeId: HOSPITAL_USER_TYPE }),
  });
};

type HospitalDuplicatesResponse = APIResponse<{ isDuplicate: boolean }>;

export const checkForDuplicateHospital = async (
  hospitalAddress: string,
  hospitalCity: string,
  hospitalZipcode: string,
  hospitalStateId: number
): Promise<boolean> => {
  const response = await get<HospitalDuplicatesResponse>(
    `api/hospital/duplicates?hospitalAddress=${hospitalAddress}&hospitalCity=${hospitalCity}&hospitalZipcode=${hospitalZipcode}&hospitalStateId=${hospitalStateId}`
  );
  if (!response.success) return false;
  else return response.data.isDuplicate;
};

export type GetWorkedWithProvidersResponse = {
  vets: { vetId: number; userId: number; fullName: string }[];
  techs: { techId: number; userId: number; fullName: string }[];
};

export const getWorkedWithProviders = async (
  hospitalId: number
): Promise<GetWorkedWithProvidersResponse> => {
  const response = await get<APIResponse<GetWorkedWithProvidersResponse>>(
    `api/hospital/getWorkedWithProviders/${hospitalId}`
  );
  return response.data;
};
