import styled from "styled-components";

import { SmallBodyText, redBase } from "../../styles/constants";

export const ErrorTextStyles = styled.div<{ show: boolean }>`
  ${SmallBodyText};
  color: ${redBase};
  margin-top: 2px;
  visibility: ${({ show }) => (show ? "show" : "hidden")};
  white-space: pre-wrap;
  svg {
    margin-right: 2px;
  }
`;
