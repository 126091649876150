import styled from "styled-components";

import { spacingM } from "../../../styles/constants";

export const PasswordContainer = styled.div`
  margin-top: ${spacingM};
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: ${spacingM};
`;
