import { cloneDeep, find, forEach, map } from "lodash";

import { HOSPITAL_USER_TYPES } from "../constants/checkBoxConstants";
import {
  GROUP_ADMIN,
  HOSPITAL_ADMIN,
  HOSPITAL_USER,
  ManageUsersByRolesMapping,
  manageUsersByRolesMapping,
} from "../constants/UserRoleConstants";

type ManageUserRole = {
  value: number;
  label: string;
  name: string;
};
type HospitalDetails = {
  hospitalId: number;
  hospitalName: string;
};
export type HospitalGroup = {
  groupId: number;
  name: string;
  hospitals: Array<HospitalDetails>;
  isGroup: boolean;
};
type HospitalUser = {
  userId: number;
  fullName: string;
  email: string;
  userStatus: number;
  userRoleId: number;
  isManageUsers?: number;
  accessibleHospitalIds?: string | AccessibleHospital;
  accessibleHospitals?: Array<AccessibleHospital>;
};
type UserByHospitals = {
  hospitalId: string;
  users: Array<HospitalUser>;
};
type AccessibleHospital = {
  value: number;
  label: string;
  isManageUsers: number;
};
type UpdateUserRole = {
  userId: number;
  hospitalIds: Array<string>;
  userRoleId: number;
  isManageUsers: number;
  isUpdateInfo: boolean;
  byUserId: number;
};

export const getManageUserTypesMapping = (userRoleId: ManageUsersByRolesMapping) => {
  const userRoleIds = manageUsersByRolesMapping[userRoleId];
  const managerUserRolesMapping: Array<ManageUserRole> = [];
  forEach(userRoleIds, (role) => {
    const hospitaUserType = find(HOSPITAL_USER_TYPES, { value: role });
    if (hospitaUserType) managerUserRolesMapping.push(hospitaUserType);
  });

  return managerUserRolesMapping;
};

export const parseHospitalsPerGroup = (hospitalGroups: HospitalGroup[]) => {
  const hospitals: Array<HospitalDetails> = [];
  const hospitalsPerGroup = map(hospitalGroups, (group) => {
    group.hospitals = JSON.parse(`[${group.hospitals}]`);
    hospitals.push(...group.hospitals);
    group.isGroup = group.groupId ? true : false;
    return group;
  });
  return { hospitalsPerGroup, hospitals };
};

export const getFilteredUserByHospitals = (
  allUsers: Array<HospitalUser>,
  usersByHospitals: Array<UserByHospitals>,
  hospitalIds: string
) => {
  hospitalIds = hospitalIds ? JSON.parse(`[${hospitalIds}]`) : [];
  // clear existing users data for selected hospitals
  usersByHospitals = map(usersByHospitals, (hospital) => {
    if (hospitalIds.includes(hospital.hospitalId)) {
      hospital.users = [];
    }
    return hospital;
  });

  forEach(allUsers, (user) => {
    const {
      userId,
      fullName,
      email,
      isManageUsers,
      userStatus,
      userRoleId,
      accessibleHospitalIds,
    } = user;
    const accessibleHospitals = accessibleHospitalIds
      ? JSON.parse(`[${accessibleHospitalIds}]`)
      : [];
    const userInfo = {
      userId,
      fullName,
      email,
      isManageUsers,
      userStatus,
    } as HospitalUser;
    forEach(accessibleHospitals, (hospital) => {
      const { hospitalId, isManageUsers } = hospital;
      userInfo.isManageUsers = isManageUsers;
      userInfo.userRoleId =
        userRoleId === GROUP_ADMIN ? GROUP_ADMIN : isManageUsers ? HOSPITAL_ADMIN : HOSPITAL_USER;
      const hospitalExist = find(usersByHospitals, { hospitalId });
      if (hospitalExist) {
        hospitalExist.users.push({
          userId,
          fullName,
          email,
          isManageUsers,
          userStatus,
          userRoleId:
            userRoleId === GROUP_ADMIN
              ? GROUP_ADMIN
              : isManageUsers
              ? HOSPITAL_ADMIN
              : HOSPITAL_USER,
        });
      } else {
        usersByHospitals.push({
          hospitalId,
          users: [
            {
              userId,
              fullName,
              email,
              isManageUsers,
              userStatus,
              userRoleId:
                userRoleId === GROUP_ADMIN
                  ? GROUP_ADMIN
                  : isManageUsers
                  ? HOSPITAL_ADMIN
                  : HOSPITAL_USER,
            },
          ],
        });
      }
    });
  });
  return usersByHospitals;
};

export const getFilteredUserByPersons = (
  allUsers: Array<HospitalUser>,
  allHospitals: Array<HospitalDetails>
) => {
  return map(allUsers, (user) => {
    const { accessibleHospitalIds } = user;

    const accessibleHospitals = accessibleHospitalIds
      ? JSON.parse(`[${accessibleHospitalIds}]`)
      : [];
    user.accessibleHospitals = [];
    forEach(accessibleHospitals, (itm) => {
      const hospitalExist = find(allHospitals, { hospitalId: itm.hospitalId });
      if (hospitalExist) {
        const { hospitalId, hospitalName } = hospitalExist;
        user.accessibleHospitals.push({
          value: hospitalId,
          label: hospitalName,
          isManageUsers: itm.isManageUsers,
        });
      }
    });
    delete user.accessibleHospitalIds;
    return user;
  });
};

export const getFilteredHospitalAdmins = (
  allUsers: Array<HospitalUser>,
  allHospitals: Array<HospitalDetails>
) => {
  const hospitalAdmins: Array<HospitalUser> = [];
  forEach(allUsers, (user) => {
    const { accessibleHospitalIds } = user;

    const accessibleHospitals = accessibleHospitalIds
      ? JSON.parse(`[${accessibleHospitalIds}]`)
      : [];
    user.accessibleHospitals = [];
    forEach(accessibleHospitals, (itm) => {
      const hospitalExist = find(allHospitals, { hospitalId: itm.hospitalId });
      if (hospitalExist) {
        const { hospitalId, hospitalName } = hospitalExist;
        const { isManageUsers } = itm;
        if (isManageUsers === 1) {
          user.accessibleHospitals.push({
            value: hospitalId,
            label: hospitalName,
            isManageUsers,
          });
        }
      }
    });
    if (user.accessibleHospitals?.length) {
      hospitalAdmins.push(user);
    }
    delete user.accessibleHospitalIds;
  });
  return hospitalAdmins;
};

export const getFilteredHospitalUsers = (
  allUsers: Array<HospitalUser>,
  allHospitals: Array<HospitalDetails>
) => {
  const hospitalUsers: Array<HospitalUser> = [];
  forEach(allUsers, (user) => {
    const { accessibleHospitalIds } = user;

    const accessibleHospitals = accessibleHospitalIds
      ? JSON.parse(`[${accessibleHospitalIds}]`)
      : [];
    user.accessibleHospitals = [];
    forEach(accessibleHospitals, (itm) => {
      const hospitalExist = find(allHospitals, { hospitalId: itm.hospitalId });
      if (hospitalExist) {
        const { hospitalId, hospitalName } = hospitalExist;
        const { isManageUsers } = itm;
        if (isManageUsers !== 1) {
          user.accessibleHospitals.push({
            value: hospitalId,
            label: hospitalName,
            isManageUsers,
          });
        }
      }
    });
    if (user.accessibleHospitals?.length) {
      hospitalUsers.push(user);
    }
    delete user.accessibleHospitalIds;
  });
  return hospitalUsers;
};

export const updateUserRole = (usersByHospitals: Array<UserByHospitals>, data: UpdateUserRole) => {
  const { hospitalIds, userId, userRoleId, isManageUsers } = data;
  const hospitalId = hospitalIds[0];
  return map(usersByHospitals, (obj) => {
    const hospitalObj = cloneDeep(obj);
    const user = find(hospitalObj.users, { userId });
    if (user) {
      if (userRoleId === GROUP_ADMIN) {
        user.userRoleId = GROUP_ADMIN;
      }
      if (hospitalObj.hospitalId === hospitalId) {
        user.isManageUsers = isManageUsers;
      }
    }
    return hospitalObj;
  });
};

export const getUserStatus = (status: number) => {
  let statusValue = "";
  if (status === 0) {
    statusValue = "Pending";
  } else if (status === 1) {
    statusValue = "Approved";
  } else if (status === 2) {
    statusValue = "Non-Responsive";
  } else if (status === 3) {
    statusValue = "Disabled";
  } else if (status === 4) {
    statusValue = "Blocked";
  } else if (status === 5) {
    statusValue = "Active";
  } else if (status === 6) {
    statusValue = "REGISTERED_R4U";
  } else if (status === 7) {
    statusValue = "Invited";
  }

  return statusValue;
};
