import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@RooUI";

import HeaderLogIn from "../Login/HeaderLogIn";
import { rooFetch } from "../api/common/rooFetch";

const Unsubscribe = () => {
  const history = useHistory();

  useEffect(() => {
    const query = history.location.search;
    rooFetch(window.RooConfig.API_URL + `api/user/unsubscribe${query}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBtnClick = () => {
    window.location.href = `${window.location.origin}/notification`;
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="inner-container">
          <HeaderLogIn />
          <div className="container">
            <div className="col-12 text-center p-20 mt-5">
              <h5 className="secondary-title text-blue pb-2">
                You have unsubscribed successfully from this type of email
              </h5>
              <div className="pt-1">
                <Button
                  style={{ margin: "auto" }}
                  title="Manage your notification settings"
                  buttonType="primary"
                  onClick={handleBtnClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// eslint-disable-next-line no-restricted-exports
export default Unsubscribe;
