import style from "styled-components";

import {
  blueBase,
  blueDark600Shadow,
  grayWhite,
  h5Headlines,
  purpleBase,
  purpleDark600,
} from "../../../styles/constants";

import { TabButtonProps } from ".";

export const TabButton = style.button<TabButtonProps>`
    width: 100%;
    height: 60px;
    background-color: ${(props) => (props.isSelected ? purpleBase : grayWhite)};
    display: flex;
    align-items: center;
    justify-content: center;
    ${h5Headlines}
    color: ${(props) => (props.isSelected ? grayWhite : "")};
    border: none;
    border-radius: 12px;
    cursor: pointer;
    box-shadow: 0px 2px 4px 0px ${blueDark600Shadow};
    @media (max-width: 768px) {
        width: 100%;
        height: 44px;
        display: ${(props) => (!props.isMobileMenuOpen ? "none" : "inline")};
    }
    &:hover {
        background-color: ${(props) => (props.isSelected ? purpleDark600 : grayWhite)};
    }
    &:focus {
        outline: none;
    }
    &:focus-visible {
        background-color: ${(props) => (props.isSelected ? purpleBase : grayWhite)};
        border-color: ${blueBase};
        border-width: 4px;
        color: ${(props) => (props.isSelected ? grayWhite : "")};
        outline: none;
    }
`;

export const TabButtonMobileHeader = style.button<TabButtonProps>`
    display: none;
    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 44px;
        background-color: ${(props) => (props.isSelected ? purpleBase : grayWhite)};
        ${h5Headlines}
        color: ${(props) => (props.isSelected ? grayWhite : "")};
        border: none;
        border-radius: 12px;
        box-shadow: 0px 2px 4px 0px ${blueDark600Shadow};
    }
    img {
        margin-left: 8px;
    }
    &:hover {
        background-color: ${(props) => (props.isSelected ? purpleDark600 : grayWhite)};
    }
    &:focus {
        outline: none;
    }
    &:focus-visible {
        background-color: ${(props) => (props.isSelected ? purpleBase : grayWhite)};
        border-color: ${blueBase};
        border-width: 4px;
        color: ${(props) => (props.isSelected ? grayWhite : "")};
        outline: none;
    }
`;
