import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { rooResponseSchema } from "../common/response";
import { get } from "../common/rooFetch";
import { CACHE } from "../utility/constants";

/**
 * ContactOwner
 */

export const contactOwner = z.object({
  id: z.number(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  email: z.string(),
  mobilePhone: z.string().nullable(),
  photo: z.string().nullable(),
});

export type ContactOwner = z.infer<typeof contactOwner>;

/**
 * ContactOwnersResponse
 */

export const contactOwnerResponseSchema = rooResponseSchema.extend({
  data: contactOwner,
});

export type ContactOwnerResponse = z.infer<typeof contactOwnerResponseSchema>;

const getContactOwner = async (vetId: number) => {
  const response = await get<ContactOwnerResponse>(`api/vet/${vetId}/contactOwner`);

  // Validate the response data against the schema
  const parsedResponse = contactOwner.safeParse(response.data);

  // If validation fails, capture the error and throw it so React Query can handle it
  if (!parsedResponse.success) {
    Sentry.captureException(parsedResponse.error);
    throw new Error("Invalid response format for contact owner.");
  }

  return response;
};

/**
 * useContactOwner
 */

export const useContactOwner = ({ vetId }: { vetId: number }) => {
  const queryKey = [CACHE.CONTACT_OWNER, vetId];

  return useQuery(queryKey, () => getContactOwner(vetId));
};
