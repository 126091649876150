import { APIResponse } from "../common/response";
import { rooFetch } from "../common/rooFetch";

import { UserIdentifier } from "./userTypes";

type Request = UserIdentifier;

type Response = APIResponse<{
  calendlyLink: string;
}>;

export const getCalendlyLink = async (request: Request) => {
  const response = await rooFetch(
    `${window.RooConfig.API_URL}api/user/getCalendlyLink?userId=${request.userId}`,
    { method: "POST" }
  );

  const data: Response = await response.json();
  return data?.data?.calendlyLink;
};
