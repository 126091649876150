import {
  Avatar,
  BodyText,
  FlexLayout,
  Icon,
  MediumText,
  SmallText,
  blueDark600,
  disabledTextColor,
  greenBase,
} from "@RooUI";
import React from "react";
import styled from "styled-components";

import { Event } from "@roo-dev/event-tracking";

import { useContactOwner } from "../../api/vet/useContactOwner";
import { CalendlyPointOfEntry } from "../../constants/posthogEventContextTypes";
import { formatPrice } from "../../helpers/textUtility";
import { CALENDLY_CALL_PROMO_AMOUNT } from "../../RegistrationNew/Components/RegistrationMeetYourRooRep/RegistrationMeetYourRooRep";
import fallbackAvatar from "../../static/images/fallback_avatar.svg";
import TrackingPixel from "../../tracking/components/TrackingPixel";
import { useRooTranslation } from "../Wrappers/useRooTranslation";

interface MeetYourRooRepProps {
  vetId: number;
  pointOfEntry: CalendlyPointOfEntry;
  marginBottom?: string;
  questionsAboutRooText?: string;
  isCalendlyCallPromoEnabled?: boolean;
}
export const MeetYourRooRep = ({
  vetId,
  questionsAboutRooText,
  pointOfEntry,
  marginBottom = "32px",
  isCalendlyCallPromoEnabled,
}: MeetYourRooRepProps) => {
  const { data } = useContactOwner({ vetId });
  const { firstName, lastName, photo } = data?.data || {};
  const { t } = useRooTranslation();

  const callText =
    firstName && lastName
      ? `👋 Your call will be with ${firstName} ${lastName} — your local Roo Representative`
      : `👋 Your call will be with your local Roo Representative`;

  const formattedPromoAmount = formatPrice(CALENDLY_CALL_PROMO_AMOUNT);
  const section1Title = isCalendlyCallPromoEnabled
    ? t("registration.meetYourRooRep.promo.section1.title")
    : t("registration.meetYourRooRep.section1.title");
  const section1Description = isCalendlyCallPromoEnabled
    ? t("registration.meetYourRooRep.promo.section1.description")
    : t("registration.meetYourRooRep.section1.description");
  const section2Title = isCalendlyCallPromoEnabled
    ? t("registration.meetYourRooRep.promo.section2.title", { promoAmount: formattedPromoAmount })
    : t("registration.meetYourRooRep.section2.title");
  const section2Description = isCalendlyCallPromoEnabled
    ? t("registration.meetYourRooRep.promo.section2.description")
    : questionsAboutRooText
    ? questionsAboutRooText
    : t("registration.meetYourRooRep.section2.description");
  const section2Icon = isCalendlyCallPromoEnabled ? "Promo" : "SoundDetectionDogBarkingRound";
  return (
    <FlexLayout vertical gap="xxl" style={{ marginBottom: marginBottom }}>
      {/* What will happen on the call */}
      <FlexLayout vertical gap="l">
        <FlexLayout align="start" gap="m">
          <Icon name="StethoscopeOutlined" color={greenBase} />
          <FlexLayout vertical gap="xs">
            <MediumText color={blueDark600}>{section1Title}</MediumText>
            <Text>{section1Description}</Text>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout align="start" gap="m">
          <Icon name={section2Icon} color={greenBase} />
          <FlexLayout vertical gap="xs">
            <MediumText color={blueDark600}>{section2Title}</MediumText>
            <Text>{section2Description}</Text>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      {/* Roo rep */}
      <FlexLayout align="center" gap="m" className="border-top pt-2" data-testid="rooRep">
        {photo ? <Avatar size={64} shape="circle" src={photo} /> : <RooLogo src={fallbackAvatar} />}
        <StyledSmallText color={disabledTextColor} data-testid="localRepresentative">
          {callText}
        </StyledSmallText>
      </FlexLayout>

      <TrackingPixel
        eventName={Event.Name.VET_VIEW_ACTIVATION_CALL}
        eventType={Event.Type.IMPRESSION}
        entityType={Event.Entity.VET}
        entityId={vetId}
        context={{ pointOfEntry }}
      />
    </FlexLayout>
  );
};

const Text = styled.div`
  ${BodyText}
`;

const RooLogo = styled.img`
  height: 64px;
  width: 64px;
`;

const StyledSmallText = styled(SmallText)`
  flex: 1;
`;
