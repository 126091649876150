import React from "react";
import { Flex as AntdFlex, FlexProps as AntdFlexProps } from "antd";
import styled from "styled-components";

import {
  spacingL,
  spacingM,
  spacingS,
  spacingXL,
  spacingXS,
  spacingXXL,
} from "../../styles/constants";

export interface FlexProps extends Omit<AntdFlexProps, "gap"> {
  gap?: "xxl" | "xl" | "l" | "m" | "s" | "xs";
}

const StyledFlex = styled(AntdFlex)<FlexProps>`
  gap: ${(props) => {
    switch (props.gap) {
      case "xxl":
        return spacingXXL;
      case "xl":
        return spacingXL;
      case "l":
        return spacingL;
      case "m":
        return spacingM;
      case "s":
        return spacingS;
      case "xs":
        return spacingXS;
      default:
        return "0";
    }
  }};
`;

export const FlexLayout = ({ children, ...props }: FlexProps) => {
  return <StyledFlex {...props}>{children}</StyledFlex>;
};
