import * as Sentry from "@sentry/react";
import lodash from "lodash";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import { UPDATE_ACCESS_OF_HOSPITAL_USER } from "../../../constants/enterpriseConstants";
import * as ErrorMessages from "../../../constants/errorMessage";
import * as ActionTypes from "../../../constants/hospitalUsers";
import * as LoaderActions from "../../../constants/loader";

export function* addNewHospitalUserAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/enterprise/createHospitalUser",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const responseData = yield response.json();

    const data = lodash.cloneDeep(responseData.data);

    yield put({ type: LoaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: ActionTypes.ADD_HOSPITAL_USER_SUCCESS,
        payload: { ...data, errMsg: responseData.message },
      });
    } else {
      yield put({
        type: ActionTypes.ADD_HOSPITAL_USER_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while adding new hospital user" } });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: ActionTypes.ADD_HOSPITAL_USER_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

export function* addNewHospitalGroupAdminAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/enterprise/saveGroupAdmin",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const responseData = yield response.json();

    const data = lodash.cloneDeep(responseData.data);

    yield put({ type: LoaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: ActionTypes.ADD_HOSPITAL_GROUP_ADMIN_SUCCESS,
        payload: { ...data, errMsg: responseData.message },
      });
    } else {
      yield put({
        type: ActionTypes.ADD_HOSPITAL_GROUP_ADMIN_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while adding new hospital group admin" },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: ActionTypes.ADD_HOSPITAL_GROUP_ADMIN_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

export function* editHospitalUserDetailsAsync(action) {
  try {
    const { isOnlyUpdateAccess } = action.payload;
    delete action.payload.isOnlyUpdateAccess;
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/updateHospitalUser`,
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    const responseData = yield response.json();

    const data = lodash.cloneDeep(responseData.data);

    yield put({ type: LoaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      if (isOnlyUpdateAccess) {
        yield put({ type: UPDATE_ACCESS_OF_HOSPITAL_USER, payload: action.payload });
      } else {
        yield put({
          type: ActionTypes.EDIT_HOSPITAL_USER_DETAILS_SUCCESS,
          payload: data,
        });
      }
    } else {
      yield put({
        type: ActionTypes.EDIT_HOSPITAL_USER_DETAILS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while editing hospital user details" },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: ActionTypes.EDIT_HOSPITAL_USER_DETAILS_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

export function* checkIfUserCanBeDeletedAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/checkIfUserCanBeDeleted`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const responseData = yield response.json();

    const data = lodash.cloneDeep(responseData.data);

    yield put({ type: LoaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: ActionTypes.CHECK_IS_USER_CAN_BE_DELETED_SUCCESS,
        payload: { checkIfUserCanBeDeleted: data },
      });
    } else {
      yield put({
        type: ActionTypes.CHECK_IS_USER_CAN_BE_DELETED_SUCCESS,
        payload: {
          checkIfUserCanBeDeleted: { canDelete: false, errMsg: ErrorMessages.EXCEPTION_MESSAGE },
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while checking if user can be deleted" },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: ActionTypes.CHECK_IS_USER_CAN_BE_DELETED_SUCCESS,
      payload: {
        checkIfUserCanBeDeleted: { canDelete: false, errMsg: ErrorMessages.EXCEPTION_MESSAGE },
      },
    });
  }
}

export function* deleteHospitalUserAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/deleteHospitalUser`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const responseData = yield response.json();

    const data = lodash.cloneDeep(responseData.data);

    yield put({ type: LoaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: ActionTypes.DELETE_HOSPITAL_USER_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: ActionTypes.DELETE_HOSPITAL_USER_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting hospital user" } });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: ActionTypes.DELETE_HOSPITAL_USER_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

export function* getHospitalUserDetailsAsync(action) {
  try {
    const { userId, userRoleId, isManageUsers } = action.payload;
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/getHospitalUserDetails`,
      {
        method: "POST",
        body: JSON.stringify({ userId, userRoleId, isManageUsers }),
      }
    );

    const responseData = yield response.json();

    const data = lodash.cloneDeep(responseData.data);

    yield put({ type: LoaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: ActionTypes.GET_HOSPITAL_USER_DETAILS_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: ActionTypes.GET_HOSPITAL_USER_DETAILS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting hospital user details" },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: ActionTypes.GET_HOSPITAL_USER_DETAILS_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootManageHospitalUserDetailsSaga() {
  yield takeEvery(ActionTypes.ADD_HOSPITAL_USER_REQUEST, addNewHospitalUserAsync);
  yield takeEvery(ActionTypes.ADD_HOSPITAL_GROUP_ADMIN, addNewHospitalGroupAdminAsync);
  yield takeEvery(ActionTypes.EDIT_HOSPITAL_USER_DETAILS_REQUEST, editHospitalUserDetailsAsync);
  yield takeEvery(ActionTypes.DELETE_HOSPITAL_USER, deleteHospitalUserAsync);
  yield takeEvery(ActionTypes.CHECK_IS_USER_CAN_BE_DELETED, checkIfUserCanBeDeletedAsync);
  yield takeEvery(ActionTypes.GET_HOSPITAL_USER_DETAILS_REQUEST, getHospitalUserDetailsAsync);
}
