import React, { ComponentProps, createContext, useCallback, useMemo, useState } from "react";

const ModalRenderer = <T,>({
  component,
  props,
}: {
  component: React.FunctionComponent<T>;
  props: ComponentProps<typeof component>;
}) => component(props);

type ModalContextValue = {
  closeModal: () => void;
  openModal: <T>(
    modalKey: React.FunctionComponent<T>,
    props: ComponentProps<typeof modalKey>
  ) => void;
};

export const ModalContext = createContext<ModalContextValue>({
  closeModal: () => {},
  openModal: () => {},
});

export const ModalProvider = (props: { children: React.ReactElement }) => {
  const [currentModal, setCurrentModal] = useState<React.ReactElement | null>(null);

  const closeModal = useCallback(() => setCurrentModal(null), []);

  const openModal = useCallback(
    <T,>(modal: React.FunctionComponent<T>, modalProps: ComponentProps<typeof modal>) => {
      setCurrentModal(<ModalRenderer component={modal} props={modalProps} />);
    },
    []
  );

  const value = useMemo(
    () => ({
      closeModal,
      openModal,
    }),
    [closeModal, openModal]
  );
  return (
    <ModalContext.Provider value={value}>
      {currentModal}
      {props.children}
    </ModalContext.Provider>
  );
};
