import React, { useEffect, useState } from "react";

import { greenBase, purpleBase, redBase, yellowBase } from "../../styles/constants";
import { Icon, IconProps } from "../Icon";

import {
  CustomToast,
  FlexContainer,
  ToastCloseIconContainer,
  ToastDescription,
  ToastMessage,
  ToastTextContainer,
} from "./CustomToastStyles";

export interface CustomToastProps {
  variant?: "success" | "warning" | "error" | "info"; // Success, Warning, Error, else Info
  message: string; // Do not change for Success, Warning, or Error variants -  min 5 char, max 35
  description: string; // max char 43
  color?: string; // component background color
  borderColor?: string;
  style?: object;
  remainsOnPage?: boolean; // ignore for use in Roo-React, only used for storybook info page
  onHide?: () => void;
  "data-testid"?: string;
}

const VARIANT_ICON: { [key: string]: { name: IconProps["name"]; color: string } } = {
  success: {
    name: "CheckCircleOutline",
    color: greenBase,
  },
  warning: {
    name: "ErrorOutline",
    color: yellowBase,
  },
  error: {
    name: "Cancel",
    color: redBase,
  },
  info: {
    name: "OutlineInfo",
    color: purpleBase,
  },
};
export const Toast: React.FC<CustomToastProps> = ({
  variant,
  message,
  description,
  color,
  borderColor,
  onHide,
  remainsOnPage,
  style,
}) => {
  const [showToast, setShowToast] = useState(true);
  const iconData = variant ? VARIANT_ICON[variant] : VARIANT_ICON.info;

  useEffect(() => {
    if (!remainsOnPage) {
      setTimeout(() => {
        setShowToast(false);
        if (onHide) {
          onHide();
        }
      }, 4000);
    }
  }, [onHide, remainsOnPage]);

  return (
    <>
      {showToast ? (
        <CustomToast
          style={style}
          variant={variant}
          message={message}
          description={description}
          color={color}
          borderColor={borderColor}
          onHide={onHide}
          remainsOnPage={remainsOnPage}
          data-testid="toastContainer"
        >
          <ToastCloseIconContainer>
            <Icon name="Close" onClick={() => setShowToast(false)} />
          </ToastCloseIconContainer>
          <FlexContainer>
            <Icon {...iconData} />
            <ToastTextContainer>
              <ToastMessage>{message}</ToastMessage>
              <ToastDescription>{description}</ToastDescription>
            </ToastTextContainer>
          </FlexContainer>
        </CustomToast>
      ) : null}
    </>
  );
};
