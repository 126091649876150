import * as Sentry from "@sentry/react";
import {
  type UseMutationOptions,
  type UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

// Uses sessionStorage to persist state on refresh
// can refactor this to accept any non-object types in the future
export const usePersistState = <T extends object>(
  storageKey: string,
  initialState: T
): [T, Dispatch<SetStateAction<T>>, () => void] => {
  const [state, setState] = useState<T>(() => {
    try {
      const storageInBrowser = sessionStorage.getItem(storageKey);
      if (storageInBrowser) {
        return JSON.parse(storageInBrowser);
      }
    } catch (error) {
      Sentry.captureException(error, { extra: { context: "Error getting stored data" } });
    }
    return initialState;
  });

  useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(state));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const clearPersistedState = () => {
    sessionStorage.removeItem(storageKey);
  };

  return [state, setState, clearPersistedState];
};

export type CustomQueryOptions<TResponse> = Omit<
  UseQueryOptions<TResponse, Error>,
  "queryFn" | "queryKey"
>;
export const createUseQueryHook = <TData>({
  queryKey,
  queryFn,
}: {
  queryKey: string;
  queryFn: () => Promise<TData>;
}) => {
  return (options: CustomQueryOptions<TData> = {}) =>
    useQuery<TData, Error>({ queryKey: [queryKey], queryFn, ...options });
};

export type CustomMutationOptions<TData, TVariables> = Omit<
  UseMutationOptions<TData, Error, TVariables>,
  "mutationFn"
>;

export const createUseMutationHook = <TData, TVariables = void>({
  mutationFn,
}: {
  mutationFn: (variables: TVariables) => Promise<TData>;
}) => {
  return (options: CustomMutationOptions<TData, TVariables> = {}) =>
    useMutation<TData, Error, TVariables>({ mutationFn, ...options });
};
