import * as Sentry from "@sentry/react";
import { call, put, select, takeEvery } from "redux-saga/effects";

import { showToast } from "../../../Common/Toasts/action/ToastActions";
import { rooFetch } from "../../../api/common/rooFetch";
import {
  SET_LOCAL_HOSPITAL_HAS_BILLING_SETUP,
  SET_SESSION_REQUEST,
} from "../../../constants/Login";
import * as errorMessage from "../../../constants/errorMessage";
import * as actionTypes from "../../../constants/hospitalConstants";
import { UNSET_LOADER } from "../../../constants/loader";

const getHasPaymentFailed = (state) => state.login.hasPaymentFailed;

function* fetchHospitalSettingsInfoAsync(action) {
  try {
    const getPaymentOnly = action?.payload?.getPaymentOnly || 0;
    const hospitalId = action?.payload?.hospitalId || localStorage.getItem("hospitalId");

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/hospital/hospitalSettingInfo?hospitalId=${hospitalId}&getPaymentOnly=${getPaymentOnly}`
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.FETCH_HOSPITAL_SETTING_INFO_SUCCESS,
        payload: {
          hospitalId,
          getPaymentOnly,
          ...data.data,
        },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.FETCH_HOSPITAL_SETTING_INFO_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching hospital settings info: " },
    });
    yield put({
      type: actionTypes.FETCH_HOSPITAL_SETTING_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* fetchMyAccountSettingsInfoAsync(action) {
  try {
    const { userId } = action.payload;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/user/myAccountInfo/${userId}`
    );

    const resData = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (resData.status_code === 200) {
      yield put({
        type: actionTypes.FETCH_MY_ACCOUNT_SETTING_INFO_SUCCESS,
        payload: resData.data,
      });
    } else if (resData.status_code === 400) {
      yield put({
        type: actionTypes.FETCH_MY_ACCOUNT_SETTING_INFO_FAIL,
        payload: { status: resData.status_code, message: resData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching my account settings info: " },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.FETCH_MY_ACCOUNT_SETTING_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* updateMyAccountSettingsInfoAsync(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + `api/user/updateAccountInfo`, {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    const resData = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (resData.status_code === 200) {
      yield put({
        type: actionTypes.UPDATE_MY_ACCOUNT_SETTING_INFO_SUCCESS,
        payload: action.payload,
      });
    } else if (resData.status_code === 400) {
      yield put({
        type: actionTypes.UPDATE_MY_ACCOUNT_SETTING_INFO_FAIL,
        payload: { status: resData.status_code, message: resData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating my account settings info: " },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.UPDATE_MY_ACCOUNT_SETTING_INFO_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* resetPaymentFailedFlag() {
  const hasPaymentFailed = yield select(getHasPaymentFailed);
  if (hasPaymentFailed) {
    yield put({
      type: actionTypes.UPDATE_HAS_PAYMENT_FAILED_FLAG,
      payload: { hasPaymentFailed: 0 },
    });
  }
}

function* addHospitalCardAsync(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/hospital/card", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      const stripeCustomerId = action.payload.customerId || data.data.stripeCustomerId;
      yield put({ type: SET_LOCAL_HOSPITAL_HAS_BILLING_SETUP });
      yield put({
        type: actionTypes.ADD_HOSPITAL_CARD_SUCCESS,
        payload: {
          paymentInformation: data.data.cards,
          stripeCustomerId: stripeCustomerId,
          cardId: action.payload.cardId ? action.payload.cardId : null,
        },
      });
      yield resetPaymentFailedFlag();
    } else if (data.status_code === 400) {
      yield put(
        showToast({
          variant: "error",
          message: "Card Error",
          description: "Card not added. Please try again or try a different card.",
        })
      );
      yield put({
        type: actionTypes.ADD_HOSPITAL_CARD_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while adding hospital card: " } });
    yield put({
      type: actionTypes.ADD_HOSPITAL_CARD_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* deleteHospitaCardAsync(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/hospital/card", {
      method: "DELETE",
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: actionTypes.DELETE_HOSPITAL_CARD_SUCCESS,
        payload: { paymentInformation: data.data.cards },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.DELETE_HOSPITAL_CARD_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting hospital card: " } });
    yield put({
      type: actionTypes.DELETE_HOSPITAL_CARD_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* updateHospitalSettingsAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/HospitalSettingInfo ",
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({ type: SET_SESSION_REQUEST });
      yield put({
        type: actionTypes.UPDATE_HOSPITAL_SETTING_INFORMATION_SUCCESS,
        payload: { updatedInfo: action.payload },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.UPDATE_HOSPITAL_SETTING_INFORMATION_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating hospital settings: " },
    });
    yield put({
      type: actionTypes.UPDATE_HOSPITAL_SETTING_INFORMATION_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* changePrimaryContactAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/changePrimaryContact ",
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: actionTypes.CHANGE_PRIMARY_CONTACT_SUCCESS,
        payload: action.payload,
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.CHANGE_PRIMARY_CONTACT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while changing primary contact: " },
    });
    yield put({
      type: actionTypes.CHANGE_PRIMARY_CONTACT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* updateBillingEmailsAsync(action) {
  try {
    const payloadBillingEmails = action.payload?.billingEmails ?? [];
    let billingEmailsArray = [];
    if (Array.isArray(payloadBillingEmails)) {
      billingEmailsArray = payloadBillingEmails;
    } else if (typeof payloadBillingEmails === "string") {
      billingEmailsArray = payloadBillingEmails.split(",");
    }

    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/hospital/billingEmails", {
      method: "PUT",
      body: JSON.stringify({ ...action.payload, billingEmails: billingEmailsArray }),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      // yield put({ type: SET_SESSION_REQUEST });
      yield put({
        type: actionTypes.UPDATE_BILLING_EMAILS_SUCCESS,
        payload: { updatedInfo: action.payload },
      });
    } else if (data.status_code === 400 || !data.status_code) {
      yield put(
        showToast({
          variant: "error",
          message: "Could not update billing emails",
          description: data.message,
        })
      );
      yield put({
        type: actionTypes.UPDATE_BILLING_EMAILS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while updating billing emails: " } });
    yield put({
      type: actionTypes.UPDATE_BILLING_EMAILS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* setDefaultHospitalCardAsync(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/hospital/card ", {
      method: "PUT",
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: actionTypes.SET_DEFAULT_CARD_SUCCESS,
        payload: { hospitalInformation: action.payload, defaultCard: action.payload.cardId },
      });
      yield resetPaymentFailedFlag();
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.SET_DEFAULT_CARD_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while setting default hospital card: " },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.SET_DEFAULT_CARD_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootHospitalSettingsSaga() {
  yield takeEvery(actionTypes.FETCH_MY_ACCOUNT_SETTING_INFO, fetchMyAccountSettingsInfoAsync);
  yield takeEvery(actionTypes.UPDATE_MY_ACCOUNT_SETTING_INFO, updateMyAccountSettingsInfoAsync);
  yield takeEvery(actionTypes.ADD_HOSPITAL_CARD_REQUEST, addHospitalCardAsync);
  yield takeEvery(actionTypes.DELETE_HOSPITAL_CARD_REQUEST, deleteHospitaCardAsync);
  yield takeEvery(actionTypes.UPDATE_HOSPITAL_SETTING_INFORMATION, updateHospitalSettingsAsync);
  yield takeEvery(actionTypes.CHANGE_PRIMARY_CONTACT, changePrimaryContactAsync);
  yield takeEvery(actionTypes.UPDATE_BILLING_EMAILS, updateBillingEmailsAsync);
  yield takeEvery(actionTypes.FETCH_HOSPITAL_SETTING_INFO, fetchHospitalSettingsInfoAsync);
  yield takeEvery(actionTypes.SET_DEFAULT_CARD_REQUEST, setDefaultHospitalCardAsync);
}
