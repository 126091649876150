import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { GET } from "@roo-dev/roo-node-types";

import { APIResponse } from "../../api/common/response";
import { rooFetch } from "../../api/common/rooFetch";
import { SET_SESSION_REQUEST } from "../../constants/Login";
import * as errorMessage from "../../constants/errorMessage";
import * as C from "../../constants/registerTechConstants";
import {
  CompleteTechProfileActionType,
  DeleteResumeActionType,
  FetchHireflixLinkActionType,
  UploadResumeActionType,
} from "../Actions/RegisterTechActions";

export function* handleCompleteTechProfileAction(action: CompleteTechProfileActionType) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/tech/updateTechProfile",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    const data: APIResponse<{ referralCode: string }> = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: C.COMPLETE_TECH_PROFILE_SUCCESS,
        payload: {
          referralCode: data.data.referralCode,
        },
      });
      yield put({ type: SET_SESSION_REQUEST });
    } else {
      yield put({
        type: C.COMPLETE_TECH_PROFILE_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while completing tech profile: " } });
  }
}

export function* uploadResumeAsync(action: UploadResumeActionType) {
  const formData = new FormData();
  const techId = action.payload.techId ? action.payload.techId : localStorage.getItem("techId");

  for (const element of action.payload.files) {
    // check if we have more than 1 dot in file name
    if (element.name.split(".").length > 2) {
      let actual = element.name.split(".");
      const extention = actual[actual.length - 1];
      actual.splice(actual.length - 1, 1);
      actual = actual.join("_");
      formData.append("fileName", actual + "." + extention);
    }
    formData.append("document", element);
    formData.append("userId", techId); // yes techId , key name "userId" is used for s3 storage folder name
    formData.append("userType", "tech");
    formData.append("uploadFileType", "resume");
  }
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/user/uploadDocument",
      {
        method: "POST",
        body: formData,
      }
    );

    const data: APIResponse<{ data: any }> = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: C.UPLOAD_TECH_RESUME_SUCCESS,
        payload: { status: data.status_code, techResume: data.data.data },
      });
    } else {
      yield put({
        type: C.UPLOAD_TECH_RESUME_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while uploading tech resume: " } });
    yield put({
      type: C.UPLOAD_TECH_RESUME_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* deleteResumeAsync(action: DeleteResumeActionType) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/user/deleteResume",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    const data: APIResponse<{ data: any }> = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: C.DELETE_TECH_RESUME_SUCCESS,
        payload: { status: data.status_code, techResume: data.data.data },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: C.DELETE_TECH_RESUME_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while deleting tech resume: " } });
    yield put({
      type: C.DELETE_TECH_RESUME_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getHireflixLinkAsync(action: FetchHireflixLinkActionType) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/tech/hireflixLink?userId=" + action.userId
    );

    const data: GET.Tech.HireflixLinkResponse = yield response.json();

    if (data.status_code === 200) {
      yield put({ type: C.FETCH_TECH_HIREFLIX_LINK_SUCCESS, payload: data.data });
    } else {
      yield put({
        type: C.FETCH_TECH_HIREFLIX_LINK_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting hireflix link: " } });
    yield put({
      type: C.FETCH_TECH_HIREFLIX_LINK_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootRegisterTechSaga() {
  yield takeEvery(C.COMPLETE_TECH_PROFILE_REQUEST, handleCompleteTechProfileAction);
  yield takeEvery(C.UPLOAD_TECH_RESUME_REQUEST, uploadResumeAsync);
  yield takeEvery(C.DELETE_TECH_RESUME_REQUEST, deleteResumeAsync);
  yield takeEvery(C.FETCH_TECH_HIREFLIX_LINK_REQUEST, getHireflixLinkAsync);
}
