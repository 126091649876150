import { Button, ButtonTabs, Container, Heading, Link } from "@RooUI";
import { usePostHog } from "posthog-js/react";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { CreateTechDataAccountResponse } from "../../../../api/tech";
import { CreateVetDataAccountResponse } from "../../../../api/vet";
import { useConferenceEvent } from "../../../hooks/useConferenceEvent";
import { HospitalSignUpContainer } from "../hospitalSignUp/HospitalSignUpContainer";
import { BigText, HeaderContainer } from "../styles";
import { TechSignUpContainer } from "../techSignUp/TechSignUpContainer";
import { EventCalendlySignUp, EventCalendlySignUpProps } from "../vetSignUp/EventCalendlySignUp";
import { VetSignUpContainer } from "../vetSignUp/VetSignUpContainer";

import { EventSignupSuccessContainer } from "./EventSignUpSuccessContainer";
import successImage from "./success.svg";

export type OnTechSignedUp = (data: CreateTechDataAccountResponse["data"]) => void;
export type OnHospitalHubSpotFormSubmitted = () => void;

export type OnVetSignedUp = (data: CreateVetDataAccountResponse["data"]) => void;

export type CompletionStatus =
  | "approved"
  | "pending"
  | "hubSpotFormSubmitted"
  | "awaitingCalendly"
  | undefined;

export const EventSignUp = () => {
  const { eventSlug, booth, hospitalHubspotFormId } = useConferenceEvent();
  const [done, setDone] = useState<CompletionStatus>(undefined);
  const [calendly, setCalendly] = useState<EventCalendlySignUpProps | undefined>(undefined);
  const history = useHistory();
  const postHog = usePostHog();

  const onTechSignedUp: OnTechSignedUp = (_data) => setDone("pending");
  const onHospitalHubSpotFormSubmitted: OnHospitalHubSpotFormSubmitted = () =>
    setDone("hubSpotFormSubmitted");

  const onVetSignedUp: OnVetSignedUp = ({
    userId,
    vetId,
    email,
    phoneNumber,
    firstName,
    lastName,
  }) => {
    setCalendly({
      ...{
        userId: parseInt(userId),
        vetId: parseInt(vetId),
        email,
        phone: phoneNumber ?? "",
        name: `${firstName} ${lastName}`,
      },
      onBack: reset,
    });
    setDone("awaitingCalendly");
  };

  const reset = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    setCalendly(undefined);
    setDone(undefined);
    postHog.reset();
  }, [postHog]);

  useEffect(() => {
    if (done) {
      const timeout = setTimeout(reset, 10000);
      return () => clearTimeout(timeout);
    }

    return () => null;
  }, [done, reset]);

  const getSuccessText = (done: CompletionStatus) => {
    switch (done) {
      case "approved":
        return "We have screened your license. Congratulations, you're approved! Start requesting shifts on Roo now!";
      case "hubSpotFormSubmitted":
        return "Thanks for submitting the form. We will get in touch with you shortly. Have a great conference.";
      case "awaitingCalendly":
        return "Thanks for signing up for Roo! Login on your own device to complete your registration.";
      default:
        return "You're signed up. You will be able to finish registration via a link in your email.";
    }
  };

  if (done) {
    return (
      <EventSignupSuccessContainer onClickButton={reset}>
        <img src={successImage} />
        <Heading level={3} data-testid="successText">
          {getSuccessText(done)}
        </Heading>
      </EventSignupSuccessContainer>
    );
  }

  if (calendly) {
    return <EventCalendlySignUp {...calendly} />;
  }

  return (
    <>
      <HeaderContainer>
        <Heading level={1}>Welcome to Roo!</Heading>
      </HeaderContainer>
      <ButtonTabs
        tabNameArr={[
          {
            index: 0,
            name: "I'm a Vet",
            panelContent: (
              <VetSignUpContainer booth={booth} onSuccess={onVetSignedUp} eventSlug={eventSlug} />
            ),
            testId: "vetTab",
          },
          {
            index: 1,
            name: "I'm a Vet Tech / Assistant",
            panelContent: (
              <TechSignUpContainer booth={booth} onSuccess={onTechSignedUp} eventSlug={eventSlug} />
            ),
            testId: "techTab",
          },
          {
            index: 2,
            name: "I'm a Hospital Manager / Owner",
            panelContent: (
              <HospitalSignUpContainer
                onSuccess={onHospitalHubSpotFormSubmitted}
                hospitalHubspotFormId={hospitalHubspotFormId}
              />
            ),
            testId: "hospitalTab",
          },
          {
            index: 3,
            name: "I'm a Student",
            panelContent: (
              <Container>
                <BigText>
                  We’re excited to have you! At this time, students must use our site to create an
                  account. You can register at{" "}
                  <Link onClick={() => history.push("/registerStudent")}>
                    roo.vet/registerStudent
                  </Link>
                </BigText>
                <Button
                  buttonType="primary"
                  title="Go To Roo.Vet"
                  onClick={() => history.push("/registerStudent")}
                />
              </Container>
            ),
            testId: "studentTab",
          },
        ]}
        defaultSelectedTabIndex={0}
      />
    </>
  );
};
