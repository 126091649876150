import { useTranslation as useTranslationOriginal } from "react-i18next";

import { RooTranslationKey } from "../../Init/Config/i18n";

export const useRooTranslation = () => {
  const { t, ...rest } = useTranslationOriginal();
  const typedT = t as (key: RooTranslationKey, options?: Record<string, any>) => string;

  return { t: typedT, ...rest };
};
