import styled from "styled-components";
import { spacingL, spacingM, spacingXS } from "@RooUI";

export const TextContainer = styled.div`
  margin-bottom: ${spacingM};
`;

export const StyledList = styled.ul`
  padding-left: ${spacingL};
`;

export const StyledListItem = styled.li`
  margin-top: ${spacingXS};
`;
