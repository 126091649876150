import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Heading, Link, ProgressBar, tealBase, tealLightest } from "@RooUI";

import HeaderLoggedIn from "../../../Common/HeaderLoggedIn";
import { useRegistrationContext } from "../Common/registrationContext";
import { useAppSelector } from "../../../store/service";
import { identifyPosthog } from "../../../Common/Wrappers/identifyPosthog";
import { useRedirect } from "../Common/hooks/useRedirect";

import { Container, Content, Header, PageContainer, Subheader } from "./styles";

export type RegistrationPageContainerProps = {
  linkProps?: Omit<React.ComponentProps<typeof Link>, "icon">;
  title?: string;
  children?: React.ReactNode;
  hideProgressBar?: boolean;
  subheader?: React.ReactNode;
};

export const RegistrationPageContainer: React.FC<RegistrationPageContainerProps> = ({
  children,
  title,
  linkProps,
  hideProgressBar,
  subheader,
}) => {
  const { registrationContext } = useRegistrationContext();
  const location = useLocation();
  const isVetProfileCreated = useAppSelector((state: any) => state.registerVet.isProfileCreated);
  const isTechProfileCreated = useAppSelector(
    (state: any) => state.registerTech.isProfileCompleted
  );
  const { redirectToReferral, redirectToTechHomepage } = useRedirect();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const { userId, userType, metroAreaId, email, firstName, lastName } = registrationContext;

    if (isVetProfileCreated || isTechProfileCreated) {
      identifyPosthog({ userId, userType, metroAreaId, email, firstName, lastName });
    }

    if (isVetProfileCreated) {
      redirectToReferral();
    } else if (isTechProfileCreated) {
      redirectToTechHomepage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVetProfileCreated, isTechProfileCreated]);

  return (
    <PageContainer className="inner-container">
      <HeaderLoggedIn // @ts-expect-error TS2322
        hideToggler={true}
      />
      <Container>
        <Content>
          <Header>
            {/* Use an empty div to standardize spacing when no link props are given */}
            {linkProps ? <Link {...linkProps} icon={"ArrowBack"} /> : <div />}
            <div>
              <Heading level={1}>{title}</Heading>
              <Subheader>{subheader}</Subheader>
            </div>
            {!hideProgressBar ? (
              <ProgressBar
                percent={registrationContext.progressPercent}
                strokeColor={tealBase}
                trailColor={tealLightest}
                strokeWidth={12}
              />
            ) : null}
          </Header>
          <div>{children}</div>
        </Content>
      </Container>
    </PageContainer>
  );
};
