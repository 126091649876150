import React, { ReactNode } from "react";

import { Icon, IconProps } from "../Icon";

import { ChipStyle } from "./ChipStyles";

export interface ChipProps {
  type?: "clickable" | "label";
  text?: string;
  borderColor?: string;
  textColor?: string;
  backgroundColor?: string;
  children?: ReactNode;
  onClick?: () => void;
  icon?: IconProps["name"];
  iconColor?: string;
  selected?: boolean;
}

export const Chip: React.FC<ChipProps> = (props) => {
  const {
    text,
    borderColor,
    backgroundColor,
    children,
    textColor,
    onClick,
    icon,
    iconColor,
    type = "label",
    selected = false,
  } = props;

  const MAX_LENGTH = 25;

  // Truncate text if it exceeds the max length
  const truncatedText =
    text && text.length > MAX_LENGTH ? text.substring(0, MAX_LENGTH - 3) + "..." : text;

  return (
    <ChipStyle
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      textColor={textColor}
      selected={selected}
      onClick={onClick}
      type={type}
    >
      {icon && <Icon name={icon} color={iconColor} size="s" />}
      {text && <span>{truncatedText}</span>}
      {children}
    </ChipStyle>
  );
};
