import dayjs, { type Dayjs } from "dayjs";
import moment from "moment/moment";

import { getIndividuallySharedShiftGroupIdFromParams } from "../../Common/Panels/components/service";
import { ShiftStatus } from "../../ProfilePanels/ProfilePanelTypes";
import { createShortLink } from "../../api/shortlink";
import { UserType, VET_USER_TYPE } from "../../constants/UserTypeConstants";
import { APPOINTMENT_TYPES, DAYS_OF_WEEK, HOSPITAL_TYPES } from "../../constants/checkBoxConstants";
import { DEFAULT_UTM_SOURCE, UTM_SOURCE_FALLBACK } from "../../constants/constants";
import { tooltipText } from "../../constants/tooltipText";
import { Event, EventTracker } from "../../tracking/service/EventTracker/EventTrackerService";
import { type LastSearch } from "../hooks/useLastSearch";

import {
  AdvancedSearch,
  Meridiem,
  SavedSearchParam,
  Search,
  Time,
  booleanFilters,
  checkboxGroups,
  isBooleanFilter,
  isNumberFilter,
  isRank,
  numberFilters,
  savedSearchParams,
  stringFilters,
} from "./types";

/**
 * Convert a Dayjs object to a search API compatible 12-hour format object.
 * @param Dayjs
 * @returns Time
 */
export const serializeTime = (value: Dayjs): Time => ({
  hour: (value.hour() % 12 || 12).toString().padStart(2, "0"),
  minute: value.minute().toString().padStart(2, "0"),
  meridiem: value.hour() < 12 ? "AM" : "PM",
});

/**
 * Convert a search API compatible 12-hour format object to a Dayjs object.
 * @param Time
 * @returns Dayjs
 */
export const deserializeTime = ({ hour: h, minute: m, meridiem }: Time): Dayjs => {
  const hour12 = parseInt(h, 10);
  let hour24 = hour12;

  if (meridiem === "PM" && hour12 !== 12) {
    hour24 += 12;
  } else if (meridiem === "AM" && hour12 === 12) {
    hour24 = 0;
  }

  const minute = parseInt(m, 10);
  return dayjs().hour(hour24).minute(minute).second(0);
};

export const isValidTimeFormat = (value: string) =>
  value.includes(" ") && value.includes(":") && (value.includes("AM") || value.includes("PM"));

export const isValidTime = (h: string, m: string, meridiem: string) => {
  const hour = parseInt(h, 10);
  const min = parseInt(m, 10);

  const isInvalid =
    isNaN(hour) ||
    hour < 1 ||
    hour > 12 ||
    isNaN(min) ||
    min < 0 ||
    min > 59 ||
    !["AM", "PM"].includes(meridiem);

  return !isInvalid;
};

export const parseTime = (timeString: string | undefined, defaultMeridiem: Meridiem) => {
  if (!timeString) {
    return { hour: "", minute: "", meridiem: defaultMeridiem };
  }

  const [timeComponent, meridiem = defaultMeridiem] = timeString.split(" ");
  const [hour = "", minute = ""] = timeComponent ? timeComponent.split(":") : [];
  return { hour, minute, meridiem: meridiem as Meridiem };
};

export const isSavedSearchParam = (key: string): key is SavedSearchParam =>
  savedSearchParams.includes(key as SavedSearchParam);

export const isDefaultSearch = ({
  search,
  zipcode,
}: {
  search: LastSearch;
  zipcode: string | null;
}) =>
  search.zipcode === zipcode &&
  search.miles === 100 &&
  !search.startTime &&
  !search.endTime &&
  !search.daysOfWeek?.length &&
  !search.isBoostedShiftsOnly &&
  !search.isInstantBookable &&
  !search.hospitalName &&
  !search.typeOfHospital &&
  !search.expectedAppointmentTypeId &&
  !search.isFearFreeCertified &&
  !search.isDEALicenseRequired &&
  !search.additionalDoctor &&
  !search.isFavoritesOnly &&
  !search.minPay &&
  search.rank === "date";

export const countFilters = (search: Search) =>
  checkboxGroups.reduce(
    (sum, k) => sum + search[k].reduce((sum, c) => sum + (c.checked ? 1 : 0), 0),
    0
  ) +
  booleanFilters.reduce((sum, k) => sum + (search[k] ? 1 : 0), 0) +
  stringFilters.reduce((sum, k) => sum + (search[k].length > 0 ? 1 : 0), 0) +
  numberFilters.reduce((sum, k) => sum + (typeof search[k] === "number" ? 1 : 0), 0) +
  (isValidTime(search.startTimeInputHour, search.startTimeInputMinute, search.startTimeInputPeriod)
    ? 1
    : 0) +
  (isValidTime(search.endTimeInputHour, search.endTimeInputMinute, search.endTimeInputPeriod)
    ? 1
    : 0) +
  (search.rank === "date" ? 0 : 1);

export const deserializeSearch = (params: URLSearchParams) =>
  Array.from(params).reduce((filter, [key, value]) => {
    if (!isSavedSearchParam(key)) {
      return filter;
    }

    if (key === "expectedAppointmentTypeId") {
      const ids = value.split(",");

      filter.expectedAppointmentTypeId = APPOINTMENT_TYPES.map((checkbox) => ({
        ...checkbox,
        checked: ids.includes(checkbox.id.toString()),
      }));
    } else if (key === "typeOfHospital") {
      const ids = value.split(",");

      filter.typeOfHospital = HOSPITAL_TYPES.map((checkbox) => ({
        ...checkbox,
        id: parseInt(checkbox.id),
        value: parseInt(checkbox.value),
        checked: ids.includes(checkbox.id.toString()),
      }));
    } else if (key === "daysOfWeek") {
      const ids = value.split(",");

      filter.daysOfWeek = DAYS_OF_WEEK.map((checkbox) => ({
        ...checkbox,
        checked: ids.includes(checkbox.id),
      }));
    } else if (key === "rank") {
      const rank = value.toLowerCase();

      if (isRank(rank)) {
        filter.rank = rank;
      }
    } else if (key === "startDate" || key === "endDate") {
      filter[key] = new Date(value.replace(/-/g, "/"));
    } else if (key === "startTime") {
      if (isValidTimeFormat(value)) {
        const [time, period] = value.split(" ");
        const [hour, minute] = time.split(":");
        filter.startTimeInputHour = hour;
        filter.startTimeInputMinute = minute;
        filter.startTimeInputPeriod = period.toUpperCase() === "AM" ? "AM" : "PM";
      }
    } else if (key === "endTime") {
      if (isValidTimeFormat(value)) {
        const [time, period] = value.split(" ");
        const [hour, minute] = time.split(":");
        filter.endTimeInputHour = hour;
        filter.endTimeInputMinute = minute;
        filter.endTimeInputPeriod = period === "AM" ? "AM" : "PM";
      }
    } else if (key === "hospitalName") {
      filter[key] = decodeURIComponent(value).split(",");
    } else if (isBooleanFilter(key) || key === "usePreferences") {
      filter[key] = value === "true";
    } else if (isNumberFilter(key) || key === "miles") {
      const parsedValue = parseInt(value, 10);

      if (!isNaN(parsedValue)) {
        filter[key] = parsedValue;
      }
    } else {
      filter[key] = value;
    }

    return filter;
  }, {} as Partial<Search>);

export const loadSavedSearch = (entityId: number | undefined, entityType: UserType) => {
  const params = new URLSearchParams(window.location.search);
  const filters = deserializeSearch(params);

  window.history.replaceState(null, "", location.pathname);

  EventTracker.send({
    eventName: Event.Name.VIEW_SHARED_SEARCH,
    eventType: Event.Type.CLICK,
    entityType: entityType === VET_USER_TYPE ? Event.Entity.VET : Event.Entity.TECH,
    entityId: entityId,
    context: { origin: params.get("utm_source") || UTM_SOURCE_FALLBACK },
  });

  return filters;
};

export const isFromSavedSearch = (savedSearchTimestamp?: Date): boolean => {
  return savedSearchTimestamp
    ? moment(savedSearchTimestamp).isAfter(moment().subtract(30, "minutes"))
    : false;
};

export const isFromIndividuallySharedShift = (shiftGroupId: number) => {
  return shiftGroupId === getIndividuallySharedShiftGroupIdFromParams();
};

export const getCurrentUrl = () => {
  return `${window.location.href}`.split("?")[0];
};

export const createSharedShiftLink = async (searchObject: { [key: string]: any }) => {
  // Do we need this?
  searchObject.isSearchClicked = true;
  if (searchObject.hospitalName) {
    searchObject.hospitalName = encodeURIComponent(searchObject.hospitalName.join(","));
  }

  const search = savedSearchParams.reduce(
    (acc, key) => (searchObject[key] ? { ...acc, [key]: searchObject[key] } : acc),
    {} as Record<keyof AdvancedSearch, unknown>
  );

  const query = Object.entries(search)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  // Example: `/vet?zipcode=95472&miles=10&startDate=2024-09-30&endDate=2024-10-01&rank=date`
  const shortLink = await createShortLink(
    `${getCurrentUrl()}?${query}&utm_source=${DEFAULT_UTM_SOURCE}`
  );
  navigator.clipboard.writeText(shortLink);

  const { vetId, techId } = searchObject;
  const entityType = vetId ? Event.Entity.VET : techId ? Event.Entity.TECH : undefined;
  const entityId = vetId ? vetId : techId ? techId : undefined;

  EventTracker.send({
    eventName: Event.Name.CREATE_SHARE_LINK,
    eventType: Event.Type.CLICK,
    entityType: entityType,
    entityId: entityId,
    context: {
      shortLink,
      searchParams: search,
    },
  });

  return shortLink;
};

export const createSharedIndividualShiftLink = async ({
  shiftGroupId,
}: {
  shiftGroupId: number;
}) => {
  const shortLink = await createShortLink(
    `${getCurrentUrl()}?shiftGroupId=${shiftGroupId}&utm_source=${DEFAULT_UTM_SOURCE}`
  );

  EventTracker.send({
    eventName: Event.Name.CREATE_SHARE_LINK,
    eventType: Event.Type.CLICK,
    entityType: undefined,
    entityId: undefined,
    context: {
      individualShift: true,
      shortLink,
      shiftGroupId,
    },
  });

  return shortLink;
};

export const getShiftStatusFromMyShiftSection = (sectionName: string) => {
  if (sectionName === "completed") return ShiftStatus.Completed;
  else if (sectionName === "confirmed") return ShiftStatus.Confirmed;
  else if (sectionName === "pending" || sectionName === "requested") return ShiftStatus.Requested;
  else if (sectionName === "expired") return ShiftStatus.AllRequestExpired;
  else return ShiftStatus.Unfilled;
};

export const getTechRequestBtnTooltipText = ({
  isStripeAccountVerifyAgain,
  isVerifyMicroDeposits,
  probationLimitReached,
}: {
  isStripeAccountVerifyAgain: boolean;
  isVerifyMicroDeposits: boolean;
  probationLimitReached: boolean;
}) => {
  if (isStripeAccountVerifyAgain || isVerifyMicroDeposits) {
    return tooltipText.VERIFY_BANK_ACCOUNT_DETAILS;
  } else if (probationLimitReached) {
    return tooltipText.TECH_PROBATION_LIMIT_REACHED;
  } else return "";
};

interface SendEndOfResultsEventParams {
  totalShifts: number;
  lastPage: number;
  searchParameters: Record<string, unknown>;
  shiftId: number | null;
  isVet: boolean;
  isShiftPreview?: boolean;
}
export const sendEndOfResultsEvent = ({
  totalShifts,
  lastPage,
  searchParameters,
  shiftId,
  isVet,
  isShiftPreview,
}: SendEndOfResultsEventParams) => {
  const context = {
    results: totalShifts,
    pages: lastPage + 1,
    searchParams: searchParameters,
    isShiftPreview,
  };

  EventTracker.send({
    eventName: isVet
      ? Event.Name.VET_VIEW_SEARCH_END_OF_RESULT
      : Event.Name.TECH_VIEW_SEARCH_END_OF_RESULT,
    eventType: Event.Type.IMPRESSION,
    entityType: isVet ? Event.Entity.VET_SHIFT : Event.Entity.TECH_SHIFT,
    entityId: shiftId ?? undefined,
    context: context,
  });
};
