import style from "styled-components";

import { spacingXL } from "../../styles/constants";

import { TabPanelContainerProps } from ".";

export const TabPanelContainer = style.div<TabPanelContainerProps>`
    width: 100%;
    padding: ${spacingXL} 0px;
    display: ${(props) => (props.isSelected ? "block" : "none")};
    overflow-y: auto;
    scrollbar-gutter: ${(props) => (props.scrollbarSpace ? "stable both-edges" : "auto")};
    @media (max-width: 768px) {
        position: ${(props) => (props.isMobileMenuOpen ? "absolute" : "relative")};
        top: ${(props) => (props.isMobileMenuOpen ? "44px" : "0px")};
        height: 100%;
    }
`;
