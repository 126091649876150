import { spacingL, spacingS } from "@RooUI";
import styled from "styled-components";

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingL};
`;

export const ModalDescriptionWrapper = styled.div`
  margin-bottom: ${spacingS};
`;
