import React from "react";

import { TabNested as TabNestedComponent } from "./TabNestedStyles";

export interface TabNestedProps {
  children?: React.ReactNode;
  isSelected?: boolean;
  onClick?: (e: any) => void;
  index?: number;
  disabled?: boolean;
}

export const TabNested: React.FC<TabNestedProps> = (props) => {
  const { index, disabled } = props;
  return (
    <TabNestedComponent {...props} value={index} disabled={disabled}>
      {props.children}
    </TabNestedComponent>
  );
};
