import * as Sentry from "@sentry/react";
import lodash from "lodash";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import * as ErrorMessages from "../../../constants/errorMessage";
import * as ActionTypes from "../../../constants/hospitalUsers";
import * as LoaderActions from "../../../constants/loader";

export function* getHospitalUserListAsync(action) {
  try {
    const isIncludeActiveOnly = action.payload?.isIncludeActiveOnly ? 1 : 0;
    const includePrimaryUser = action.payload?.includePrimaryUser ? 1 : 0;
    const isIncludeBlocked = action.payload?.isIncludeBlocked ? 1 : 0;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        "api/hospital/" +
        action.payload.hospitalId +
        `/user?isIncludeActiveOnly=${isIncludeActiveOnly}&includePrimaryUser=${includePrimaryUser}&isIncludeBlocked=${isIncludeBlocked}`
    );

    const responseData = yield response.json();

    const data = lodash.cloneDeep(responseData.data);

    yield put({ type: LoaderActions.UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({ type: ActionTypes.GET_HOSPITAL_USER_LIST_SUCCESS, payload: data });
    } else {
      yield put({
        type: ActionTypes.GET_HOSPITAL_USER_LIST_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting hospital user list: " },
    });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: ActionTypes.GET_HOSPITAL_USER_LIST_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

export function* blockHospitalUserAsync(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/user/blockHospitalUser", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    yield put({ type: LoaderActions.UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({ type: ActionTypes.BLOCK_HOSPITAL_USER_SUCCESS, payload: data });
    } else {
      yield put({
        type: ActionTypes.BLOCK_HOSPITAL_USER_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while blocking hospital user: " } });
    yield put({ type: LoaderActions.UNSET_LOADER });
    yield put({
      type: ActionTypes.BLOCK_HOSPITAL_USER_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootManageHospitalUsersSaga() {
  yield takeEvery(ActionTypes.GET_HOSPITAL_USER_LIST_REQUEST, getHospitalUserListAsync);
  yield takeEvery(ActionTypes.BLOCK_HOSPITAL_USER, blockHospitalUserAsync);
}
