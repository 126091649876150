import style from "styled-components";

import {
  h1Headlines,
  h2Headlines,
  h3Headlines,
  h4Headlines,
  h5Headlines,
  h6Headlines,
} from "../../styles/constants";

import { HeadingProps } from ".";

const colorExtension = ({ color }: Pick<HeadingProps, "color">) => (color ? `color: ${color}` : "");

export const H1 = style.h1`
  ${h1Headlines}
  ${colorExtension}
`;

export const H2 = style.h2`
  ${h2Headlines}
  ${colorExtension}
`;

export const H3 = style.h3`
  ${h3Headlines}
  ${colorExtension}
`;

export const H4 = style.h4`
  ${h4Headlines}
  ${colorExtension}
`;

export const H5 = style.h5`
  ${h5Headlines}
  ${colorExtension}
`;

export const H6 = style.h6`
  ${h6Headlines}
  ${colorExtension}
`;
