import { APIResponse } from "../common/response";
import { rooFetch } from "../common/rooFetch";
import { UserEntity } from "../user/userTypes";
import { License, VetPreferredAreasOfPractice } from "../vet";

// Parameters accepted by the endpoint
export type Request = {
  userId: number;
  vetId: number;
} & Partial<
  UserEntity &
    VetPreferredAreasOfPractice & {
      phoneNumber: string;
      licenses: License[];
      deletedLicenses: undefined;
      vetSchool: string;
      userType: undefined;
      referralCode: string;
      yearsOfPractice: number;
      preferredProcedures: number[];
      preferredAreasOfPractice: boolean;
      typicalAvailableDays: number[];
      lookingToStart: number;
      currentSituation: number;
      typicalAvailableDaysOpenResponse?: string;
      lookingToStartOpenResponse?: string;
    }
>;

export type Response<RequestBody extends Request> = APIResponse<
  RequestBody & {
    metroAreaId?: number;
    stateId?: number;
    stateName?: string;
  }
>;

export const patchVet = async (request: Request): Promise<Response<Request>> => {
  const response = await rooFetch(`${window.RooConfig.API_URL}api/vet/vet`, {
    method: "PATCH",
    body: JSON.stringify(request),
  });

  if (response.status !== 200) {
    throw new Error("Api failed");
  }

  const data = await response.json();
  return data;
};
