import React from "react";

import { AntInputProps, Input } from "..";
import { Modify } from "../../../helpers/types";

export const EmailInput = (
  props: Modify<AntInputProps, { onChange?: (email: string) => void }>
) => {
  return (
    <Input
      label="Email"
      name="email"
      {...props}
      inputMode="email"
      onChange={(e) => {
        props.onChange?.(e.target.value.replace(/ /g, ""));
      }}
    />
  );
};
