import styled from "styled-components";

import {
  blueBackground,
  blueLight300Shadow,
  grayBase,
  grayWhite,
  spacingM,
  spacingS,
} from "../../styles/constants";
import { Button } from "../Button";

import { PanelProps } from ".";

export const PanelContainer = styled.div<PanelProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: ${(props) => `${props.isShowPanel ? "" : "none"}`};
`;
export const Panel = styled.div<PanelProps>`
  width: 460px;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  top: 0;
  right: 0;
  z-index: 100;
  background-color: ${(props) => `${props.panelStyle === "action" ? blueBackground : grayWhite}`};
  animation-name: fadeInRight;
  animation-duration: 500ms;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  @media screen and (max-width: 768px) {
    width: 385px;
  }
  @media screen and (max-width: 428px) {
    width: 100%;
  }
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`;

export const Overlay = styled.div`
  background-color: ${grayBase};
  opacity: 0.25;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;
export const ActionContainer = styled.div`
  min-height: 120px;
  width: 460px;
  background-color: ${grayWhite};
  box-shadow: 0px 4px 20px 0px ${blueLight300Shadow};
  display: flex;
  align-items: center;
  padding: 24px;
  @media screen and (max-width: 768px) {
    width: 385px;
  }
  @media screen and (max-width: 428px) {
    width: 100%;
  }
`;

export const ActionButton = styled(Button)`
  width: 100%;
`;

export const CloseContainer = styled.div<{ $hasBreadcrumb?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ $hasBreadcrumb }) => `${$hasBreadcrumb ? "space-between" : "flex-end"}`};
  padding: ${spacingM};
`;

export const BreadCrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacingS};
`;
