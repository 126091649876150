import style from "styled-components";

import {
  BodyText,
  grayBase,
  grayDark600,
  grayWhite,
  greenBackground,
  greenBase,
  h6Headlines,
  purpleBase,
  purpleBaseShadow,
  redBackground,
  redBase,
  yellowBackground,
  yellowBase,
} from "../../styles/constants";
import { ToastZIndex } from "../../styles/constants/z-indexes";

import { CustomToastProps } from "./index";

export const CustomToast = style.button<CustomToastProps>`
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    position: fixed;
    top: 68px;
    right: 28px;
    width: 440px;
    min-height: 94px;
    box-shadow: 0px 2px 4px ${purpleBaseShadow};
    border: none;
    z-index: ${ToastZIndex};
    ${(props) => {
      if (props.variant === "success") {
        return `
          border-top: 2px solid ${greenBase};
          background-color: ${greenBackground};
          `;
      }
      if (props.variant === "warning") {
        return `
          border-top: 2px solid ${yellowBase};
          background-color: ${yellowBackground};
          `;
      }
      if (props.variant === "error") {
        return `
          border-top: 2px solid ${redBase};
          background-color: ${redBackground};
          `;
      }
      return `
          border-top: 2px solid ${purpleBase};
          background-color: ${grayWhite};
          `;
    }}
    animation: 1s slide-down;
    @keyframes slide-down {
        from {
          top: -68px;
        }
        to {
          top: 68px;
        }
    }
    @media (max-width: 480px) {
        top: 44px;
        right: calc((100vw - 304px)/2);
        width: 304px;
        height: 68px;
        animation: 1s slide-up;
        @keyframes slide-down {
            from {
              top: -44px;
            }
            to {
              top: 44px;
            }
        }
    }
`;

export const ToastCloseIconContainer = style.div`
    position: absolute;
    right: 17px;
    top: 15px;  
`;

export const ToastTextContainer = style.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin: 0 0 0 16px;
    text-align: left;
    @media (max-width: 480px) {
        margin: 0 0 0 8px;
    }
`;
export const ToastMessage = style.div`
    ${h6Headlines}
    color: ${grayDark600};
`;
export const ToastDescription = style.div`
    ${BodyText}
    color: ${grayBase};
    @media (max-width: 480px) {
        font-size: 12px;
        line-height: 20px;
    }
`;

export const FlexContainer = style.div`
    display: flex;
    margin: 24px 0 0 16px;
    @media (max-width: 480px) {
        margin: 2px 8px 0 8px;
    }
`;
