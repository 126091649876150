import React from "react";

import { BodyText } from "../InfoStyles";

import { StatusIndicatorIcon, StatusIndicatorStyled } from "./StatusIndicatorStyles";

export interface StatusIndicatorProps {
  status: "success" | "warning" | "error" | "info";
  text: string;
  "data-testid"?: string;
}

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  status,
  text,
  "data-testid": testId,
}) => {
  return (
    <StatusIndicatorStyled data-testid={testId}>
      <StatusIndicatorIcon status={status} />
      <BodyText>{text}</BodyText>
    </StatusIndicatorStyled>
  );
};
