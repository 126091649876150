import * as Sentry from "@sentry/react";

import { rooFetch } from "../../../api/common/rooFetch";

const callSendResetPasswordEmail = async (email: string) => {
  try {
    return await rooFetch(`${window.RooConfig.API_URL}api/user/canSendResetPasswordEmail/${email}`);
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error updating hospital fear free certification: " },
    });

    throw new Error(error);
  }
};

export const canSendResetPasswordEmail = async (email: string) => {
  const response = await callSendResetPasswordEmail(email);
  const responseData = await response.json();
  const { canSendResetPasswordEmail } = responseData;
  return canSendResetPasswordEmail;
};
