import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";

import { initialState } from "./initial-state";
import reducer from "./reducer";
import rootSaga from "./saga";

const sagaMiddleware = createSagaMiddleware();

const isProduction =
  window.location.hostname === "roo.vet" || window.location.hostname === "www.roo.vet";
const enableReduxLogger = localStorage.getItem("enableReduxLogger") === "true";
const nonProductionMiddlewares: Array<any> = [sagaMiddleware];
if (enableReduxLogger) {
  nonProductionMiddlewares.push(logger);
}
// Only log actions when not on production server.
const store = isProduction
  ? createStore(reducer, initialState, applyMiddleware(...[sagaMiddleware]))
  : createStore(
      reducer,
      initialState,
      composeWithDevTools(applyMiddleware(...nonProductionMiddlewares))
    );

// Don't run the middleware in test environment.
if (process.env.NODE_ENV !== "test") {
  sagaMiddleware.run(rootSaga);
}

// Typescript
export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;

// eslint-disable-next-line no-restricted-exports
export default store;
