import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import { SET_LOCAL_VET_HAS_PAYMENT_SETUP } from "../../../constants/Login";
import * as UserTypes from "../../../constants/UserTypeConstants";
import * as ErrorMessages from "../../../constants/errorMessage";
import { UNSET_LOADER } from "../../../constants/loader";
import * as FirstTimerActionTypes from "../../../constants/vetConstants";

function* fetchIAVtokenAsync(action) {
  try {
    const { vetId, isVerifyMicroDeposits } = action.payload;
    const redirectURI = window.location.href;
    const oSend = { vetId, redirectURI };
    if (isVerifyMicroDeposits) {
      oSend.isVerifyMicroDeposits = isVerifyMicroDeposits;
    }
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/vet/iavToken", {
      method: "POST",
      body: JSON.stringify(oSend),
    });

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      const IAVToken = data.data?.data;
      const isRequireSsnLast4 = data.data?.isRequireSsnLast4;
      yield put({
        type: FirstTimerActionTypes.FETCH_IAV_TOKEN_SUCCESS,
        payload: { status: data.status_code, IAVToken, isRequireSsnLast4 },
      });
    } else if (data.status_code === 500 || data.status_code === 400) {
      yield put({
        type: FirstTimerActionTypes.FETCH_IAV_TOKEN_FAIL,
        payload: {
          status: data.status_code,
          message: ErrorMessages.EXCEPTION_MESSAGE,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while fetching IAV token: " } });
    yield put({
      type: FirstTimerActionTypes.FETCH_IAV_TOKEN_FAIL,
      payload: {
        status: error.status_code,
        message: ErrorMessages.EXCEPTION_MESSAGE,
      },
    });
  }
}

function* setDefaultBankAccountAsync(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/vet/addBankAccount", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({ type: SET_LOCAL_VET_HAS_PAYMENT_SETUP });
      yield put({ type: FirstTimerActionTypes.HIDE_FIRST_TIMER_MODAL });
      yield put({
        type: FirstTimerActionTypes.SET_VET_DEFAULT_BANK_SUCCESS,
        payload: { status: data.status_code, isFromSettings: action.isFromSettings !== null },
      });
    } else if (data.status_code === 400 || data.status_code === 500) {
      yield put({
        type: FirstTimerActionTypes.SET_VET_DEFAULT_BANK_FAIL,
        payload: { status: data.status_code },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while setting default bank account: " },
    });
    yield put({
      type: FirstTimerActionTypes.SET_VET_DEFAULT_BANK_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

function* switchDefaultBankAccountAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/vet/switchDefaultBankAccount",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: FirstTimerActionTypes.GET_VET_SETTING_INFORMATION,
        vetId: action.payload.vetId,
        userTypeId: UserTypes.VET_USER_TYPE,
      });
      yield put({
        type: FirstTimerActionTypes.SWITCH_VET_DEFAULT_BANK_SUCCESS,
        payload: { status: data.status_code },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: FirstTimerActionTypes.SWITCH_VET_DEFAULT_BANK_FAIL,
        payload: { status: data.status_code },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while switching default bank account: " },
    });
    yield put({
      type: FirstTimerActionTypes.SWITCH_VET_DEFAULT_BANK_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

function* deleteVetBankAccountAsync(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/vet/bankAccount", {
      method: "DELETE",
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: FirstTimerActionTypes.DELETE_VET_BANK_ACCOUNT_SUCCESS,
        payload: { data: action.payload.vetInformation, message: data.message },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: FirstTimerActionTypes.DELETE_VET_BANK_ACCOUNT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while deleting vet bank account: " },
    });
    yield put({
      type: FirstTimerActionTypes.DELETE_VET_BANK_ACCOUNT_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

function* updateMicroDepositsVerifiedAsync(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/vet/addBankAccount", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: FirstTimerActionTypes.UPDATE_VET_MICRO_DEPOSITS_VERIFIED_SUCCESS,
        payload: { status: data.status_code },
      });
    } else if (data.status_code === 400 || data.status_code === 500) {
      yield put({
        type: FirstTimerActionTypes.UPDATE_VET_MICRO_DEPOSITS_VERIFIED_FAIL,
        payload: { status: data.status_code },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating micro deposits verified: " },
    });
    yield put({
      type: FirstTimerActionTypes.UPDATE_VET_MICRO_DEPOSITS_VERIFIED_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

function* updateMicroDepositsFailedAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/vet/updateMicroDepositsFailed",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: FirstTimerActionTypes.UPDATE_VET_MICRO_DEPOSITS_FAILED_SUCCESS,
        payload: { status: data.status_code },
      });
    } else if (data.status_code === 400 || data.status_code === 500) {
      yield put({
        type: FirstTimerActionTypes.UPDATE_VET_MICRO_DEPOSITS_FAILED_FAIL,
        payload: { status: data.status_code },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating micro deposits failed: " },
    });
    yield put({
      type: FirstTimerActionTypes.UPDATE_VET_MICRO_DEPOSITS_FAILED_FAIL,
      payload: { status: error.status_code, message: ErrorMessages.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootVetDashboardSaga() {
  yield takeEvery(FirstTimerActionTypes.FETCH_VET_IAV_TOKEN, fetchIAVtokenAsync);
  yield takeEvery(FirstTimerActionTypes.SET_VET_DEFAULT_BANK, setDefaultBankAccountAsync);
  yield takeEvery(FirstTimerActionTypes.SWITCH_VET_DEFAULT_BANK, switchDefaultBankAccountAsync);
  yield takeEvery(FirstTimerActionTypes.DELETE_VET_BANK_ACCOUNT_REQUEST, deleteVetBankAccountAsync);
  yield takeEvery(
    FirstTimerActionTypes.UPDATE_VET_MICRO_DEPOSITS_VERIFIED,
    updateMicroDepositsVerifiedAsync
  );
  yield takeEvery(
    FirstTimerActionTypes.UPDATE_VET_MICRO_DEPOSITS_FAILED,
    updateMicroDepositsFailedAsync
  );
}
