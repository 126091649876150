import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import * as UserSaga from "../../../Admin/Users/sagas/UserSagas";
import { rooFetch } from "../../../api/common/rooFetch";
import * as C from "../../../constants/adminUsersList";
import * as ActionTypes from "../../../constants/editRating";
import * as errorMessage from "../../../constants/errorMessage";
import * as LoaderActions from "../../../constants/loader";

export function* updateRatingAsync(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/user/adminRatingToUser", {
      method: "PUT",
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    yield put({ type: LoaderActions.UNSET_LOADER });

    if (data.status_code === 200 && action && action.payload) {
      yield UserSaga.getRatingSummaryAsync({
        type: C.GET_RATING_SUMMARY,
        payload: {
          hospitalId: action.payload.hospitalId,
          userTypeId: action.payload.userTypeId,
          isFromAdmin: action.payload.isFromAdmin,
          userId: action.payload.userId,
          vetId: action.payload.vetId,
          techId: action.payload.techId,
          offset: undefined,
        },
      });

      yield put({ type: ActionTypes.UPDATE_RATING_SUCCESS, payload: action.payload });
    } else {
      yield put({
        type: ActionTypes.UPDATE_RATING_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while updating rating: " } });
    yield put({
      type: ActionTypes.UPDATE_RATING_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* editRatingSaga() {
  yield takeEvery(ActionTypes.UPDATE_RATING_REQUEST, updateRatingAsync);
}
