export function truncateText(str: string, nWords: number) {
  const aWords = str.split(" ");
  const aTruncated = aWords.slice(0, nWords);
  const strResult = aTruncated.join(" ").trim();
  return `${strResult} ...`;
}

export function formatPrice(
  n: number,
  options?: { isEditing?: boolean; isForceDecimal?: boolean; isNoDollarSign?: boolean }
) {
  if (typeof n === "undefined" || n === null) {
    n = 0;
  }

  const isForceDecimal = options ? options.isForceDecimal : false;
  const isEditing = options ? options.isEditing : false;

  // convert float prices: REFACTOR TO DO: float values should not be saved in db

  // const nPriceDecimal = Math.floor(n * 100) / 100;  // this looses trailing 0 when editing input

  // truncate cents in case passed float
  let cents = "";
  let dollars = "";
  let priceNoFloat = String(n);
  const aDollarsAndCents = priceNoFloat.split(".");
  if (aDollarsAndCents[1]) {
    cents = aDollarsAndCents[1].slice(0, 2); // truncate in case passed float
    dollars = aDollarsAndCents[0];
    priceNoFloat = `${dollars}.${cents}`;
  }

  const dollarSign = options?.isNoDollarSign ? "" : "$";
  let sPrice = dollarSign + `${priceNoFloat.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  if (!isEditing && sPrice.slice(-2, -1) === ".") {
    sPrice += "0";
  }
  if (isForceDecimal && !sPrice.includes(".")) {
    sPrice += ".00";
  }
  return sPrice;
}

export function humanizePrice(price: number): string {
  if (price < 1000) {
    return `$${price}`;
  } else {
    const thousands = Math.floor(price / 100) / 10;
    return `$${thousands.toFixed(1)}k`;
  }
}

export function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

export const getOrdinal = (n: number) => {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const formatDrName = (fullName: string) => {
  if (!fullName) {
    return "";
  }

  const aNames = fullName?.split(" ");
  if (aNames.length > 1 && aNames[0].toLowerCase().startsWith("dr")) {
    aNames.shift(); // remove "Dr" if already in name
  }
  return `Dr. ${aNames.join(" ")}`;
};

export const getCopyrightYear = () => {
  return new Date().getFullYear();
};
