import { useQuery } from "@tanstack/react-query";

import { FeatureFlagName } from "../../constants/postHogFeatureFlags";
import { APIResponse } from "../common/response";
import { get } from "../common/rooFetch";
import { CACHE } from "../utility/constants";

const isFeatureFlagEnabled = async (
  userId: number,
  featureFlagName: FeatureFlagName
): Promise<boolean> => {
  const response = await get<APIResponse<boolean>>(
    `api/user/${userId}/featureFlagEnabled?featureFlagName=${featureFlagName}`
  );
  return response.data;
};

const getFeatureFlagVariantKey = async (
  userId: number,
  featureFlagName: string
): Promise<string | boolean | undefined> => {
  const response = await get<APIResponse<string | boolean | undefined>>(
    `api/user/${userId}/featureFlagVariantKey?featureFlagName=${featureFlagName}`
  );
  return response.data;
};

export const useIsServerFeatureFlagEnabled = (
  userId: number,
  featureFlagName: FeatureFlagName,
  precondition: boolean = true
) => {
  const queryKey = [CACHE.FEATURE_FLAG_ENABLED, userId, featureFlagName];
  return useQuery(queryKey, () => isFeatureFlagEnabled(userId, featureFlagName), {
    enabled: precondition && !!userId && userId > 0 && !!featureFlagName,
  });
};

export const useGetServerFeatureFlagVariantKey = (
  userId: number,
  featureFlagName: FeatureFlagName,
  precondition: boolean = true
) => {
  const queryKey = [CACHE.FEATURE_FLAG_VARIANT_KEY, userId, featureFlagName];
  return useQuery(queryKey, () => getFeatureFlagVariantKey(userId, featureFlagName), {
    enabled: precondition && !!userId && userId > 0 && !!featureFlagName,
  });
};
