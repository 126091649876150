import styled from "styled-components";

import { BodyText as RooBodyText, h6Headlines } from "../styles/constants";

export const HeaderText = styled.div`
  ${h6Headlines}
`;
export const BodyText = styled.div`
  ${RooBodyText}
`;
