import React from "react";

import { AuthUtility } from "../helpers/AuthUtility";
import { clearAuthAndRedirect } from "../LogOut/LogOut";
import rooLogo from "../static/images/roo_logo_updated.svg";

const HeaderLogIn = ({ showLogout = false }: { showLogout?: boolean }) => {
  const handleLogout = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    clearAuthAndRedirect();
  };

  return (
    <header className="site-header">
      <div className="container">
        <nav
          className="navbar navbar-expand-lg d-flex justify-content-between align-items-center"
          role="presentation"
        >
          <a className="nabar-brand text-style-none" href="/" role="main">
            <img src={rooLogo} />
          </a>
          <span className="sr-only">Home</span>
          {AuthUtility.isAuthorisedUser() && showLogout && (
            <a onClick={(e) => handleLogout(e)} className="mt-2" href="#" data-testid="logoutBtn">
              Logout
            </a>
          )}
        </nav>
      </div>
    </header>
  );
};

// eslint-disable-next-line no-restricted-exports
export default HeaderLogIn;
