import React, { FC } from "react";
import { Popover as AntdPopover, PopoverProps } from "antd";

import { GlobalStyle } from "./PopoverStyles";

export const Popover: FC<PopoverProps> = (props) => {
  return (
    <>
      <GlobalStyle />
      <AntdPopover {...props} />
    </>
  );
};
