import { useEffect } from "react";

import { useRegistrationContext } from "../registrationContext";

export const useProgressPercentEffect = (newProgressPercent: number) => {
  const { setRegistrationContext } = useRegistrationContext();

  useEffect(() => {
    setRegistrationContext((prevContext) => ({
      registrationContext: {
        ...prevContext.registrationContext,
        progressPercent: newProgressPercent,
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
