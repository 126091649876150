import { InputNumber } from "antd";
import styled from "styled-components";

import {
  BodyText,
  blueDark600,
  blueLightest,
  grayBackground,
  grayBase,
  grayLight300,
  grayWhite,
  inputBorderRadius,
  redBase,
} from "../../../styles/constants";

export const CustomInputNumber = styled(InputNumber).withConfig({
  shouldForwardProp: () => true,
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 8px;
  width: clamp(90px, 100%, 700px);
  height: 48px;
  background: ${grayWhite};
  border: 2px solid ${blueLightest};
  border-radius: ${inputBorderRadius};

  .ant-input-number-input-wrap {
    width: 100%;
    .ant-input-number-input {
      ${BodyText}
      color: ${grayBase};
    }
  }
  .ant-input-number-input-wrap ::placeholder {
    ${BodyText}
    color: ${(props) => `${props.status == "error" ? redBase : grayLight300}`};
  }
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border-color: ${redBase};
    color: ${redBase};
  }
  :where(.css-dev-only-do-not-override-10ed4xt).ant-input-number-disabled,
  :where(.css-dev-only-do-not-override-10ed4xt).ant-input[disabled] {
    background-color: ${grayBackground};
  }
  &:hover,
  &:focus,
  &:focus-within {
    border: 2px solid ${blueDark600};
  }
  .ant-input-number-input {
    padding: 0;
    width: 100%;
  }
`;
