import * as Sentry from "@sentry/react";

import { rooFetch } from "../api/common/rooFetch";

import { extractUrlInfo } from "./urlUtils";

export interface UtmParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
}

export interface AdParams {
  ad_set_id?: string;
  ad_group_id?: string;
  ad_id?: string;
}

export function trackParamsInSession(utmParams: UtmParams, adParams: AdParams = {}) {
  let requestUrl = "";

  try {
    const startSessionUrl = new URL(window.RooConfig.API_URL + "startSession");

    // Utm params
    for (const [utmKey, utmValue] of Object.entries(utmParams)) {
      startSessionUrl.searchParams.append(utmKey, utmValue);
    }

    // Ad params
    for (const [adKey, adValue] of Object.entries(adParams)) {
      startSessionUrl.searchParams.append(adKey, adValue);
    }

    // Referrer
    if (document.referrer) {
      startSessionUrl.searchParams.append("referrer", document.referrer);
    }

    requestUrl = startSessionUrl.toString();
    return rooFetch(requestUrl);
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "error init session", url: requestUrl } });
    return undefined;
  }
}

export const initSession = () => {
  const params = new URLSearchParams(window.location.search);
  const utmParameterKeys = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term"];
  const adParameterKeys = [
    "ad_set_id", // Facebook
    "ad_group_id", // Google
    "ad_id", // Facebook, Google
  ];

  const utmParams: Record<string, string> = {};
  const adParams: Record<string, string> = {};
  const referrerUrlInfo = extractUrlInfo(document.referrer);

  populateParams(utmParameterKeys, utmParams, params, referrerUrlInfo);
  populateParams(adParameterKeys, adParams, params, referrerUrlInfo);

  return trackParamsInSession(utmParams, adParams);
};

function populateParams(
  parameterKeys: string[],
  paramsObj: Record<string, string>,
  params: URLSearchParams,
  referrerUrlInfo: ReturnType<typeof extractUrlInfo> | null
) {
  for (const key of parameterKeys) {
    const value = extractParam(key, params, referrerUrlInfo);

    if (value) {
      paramsObj[key] = value;
    }
  }
}

function extractParam(
  key: string,
  params: URLSearchParams,
  referrerUrlInfo: ReturnType<typeof extractUrlInfo> | null
) {
  const referrerParameterValue =
    referrerUrlInfo?.hostname === window.location?.hostname
      ? referrerUrlInfo?.searchParams[key]
      : null;

  const value = params.get(key) || referrerParameterValue;
  return value ? value.trim() : null;
}
