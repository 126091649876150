import style from "styled-components";

import { Color, blueLighter, greenBase, redBase, yellowBase } from "../../styles/constants";

import { StatusIndicatorProps } from ".";

const STATUS_COLORS: Record<StatusIndicatorProps["status"], Color> = {
  success: greenBase,
  warning: yellowBase,
  error: redBase,
  info: blueLighter,
};

export const StatusIndicatorStyled = style.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const StatusIndicatorIcon = style.div<Pick<StatusIndicatorProps, "status">>`
    ${({ status }) => `background-color: ${STATUS_COLORS[status]};`}
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
`;
