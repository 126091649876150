import styled from "styled-components";

import {
  FormLabelText,
  blueDark600,
  disabledTextColor,
  disabledTextDark,
  grayLightest,
  grayWhite,
  greenBase,
  h5Headlines,
} from "../../styles/constants";

interface CustomToggleProps {
  disabled?: boolean;
  checked?: boolean;
  isFullWidth?: boolean;
}

const generateBackgroundColorToggleBackground = (props: CustomToggleProps) => {
  if (props.disabled) {
    return grayLightest;
  } else {
    return greenBase;
  }
};

export const ToggleContainer = styled.div<CustomToggleProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: ${(props) => (props.isFullWidth ? "space-between" : "")};
  flex-wrap: wrap;
`;

export const ToggleLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0 !important;
`;

export const ToggleDiv = styled.div<CustomToggleProps>`
  position: relative;
  width: 39px;
  height: 20px;
  background: ${(props) =>
    props.disabled ? grayLightest : props.checked ? greenBase : disabledTextDark};
  border: 2px solid
    ${(props) => (props.disabled ? grayLightest : props.checked ? greenBase : disabledTextDark)};
  border-radius: 40px;
  transition: 300ms all;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    top: 50%;
    left: 0;
    background: ${grayWhite};
    transform: translate(0, -50%);
  }
  svg {
    position: absolute;
    right: 2px;
    top: 2px;
    transition: opacity 100ms;
    opacity: 0;
  }
`;

export const ToggleInput = styled.input`
  opacity: 0;
  position: absolute;
  &:checked + ${ToggleDiv} {
    background: ${(props) => generateBackgroundColorToggleBackground(props)};
    &:before {
      transform: translate(19px, -50%);
    }
    svg {
      opacity: 1;
    }
  }
`;

export const ToggleText = styled.span<CustomToggleProps>`
  ${FormLabelText}
  color: ${(props) => `${props.disabled ? disabledTextColor : blueDark600};`};
`;

export const ToggleTextLarge = styled.span<CustomToggleProps>`
  ${h5Headlines}
  color: ${(props) => `${props.disabled ? disabledTextColor : ""};`};
`;
