import { createGlobalStyle } from "styled-components";

import * as colorImport from "./constants/colors";
import * as spacingImport from "./constants/spacing";

const colors = colorImport as { [key: string]: string };
const spacing = spacingImport as { [key: string]: string };

export const Theme = createGlobalStyle`
  :root {
      ${Object.keys(colors).map((key) => `--${key}: ${colors[key]};`)}
      ${Object.keys(spacing).map((key) => `--${key}: ${spacing[key]};`)}
  }
`;
