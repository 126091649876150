import * as C from "../../constants/hospitalConstants";
import { SHOW_TRAINING_POPUP } from "../../constants/Login";
export function fetchCommonDataAction(data) {
  return {
    type: C.FETCH_ADD_SHIFT_COMMON_DATA_REQUEST,
    payload: data,
  };
}

export function calculateBulkPricingAction(data) {
  return {
    type: C.CALCULATE_BULK_SHIFT_PRICING,
    payload: data,
  };
}

export function fetchHolidaysAction(payload) {
  return {
    type: C.FETCH_HOLIDAYS,
    payload,
  };
}

export function resetHolidayAction() {
  return {
    type: C.RESET_HOLIDAYS,
  };
}

export function addShiftAction(data) {
  return {
    type: C.ADD_SHIFT,
    payload: data,
  };
}

export function getShiftFullfillmentPredictionAction(data) {
  return {
    type: C.GET_SHIFT_FULLFILLMENT_PREDICTION,
    payload: data,
  };
}

export function fetchPreviousShiftsAction() {
  return {
    type: C.FETCH_PREVIOUS_SHIFT_REQUEST,
  };
}

export function resetShiftUpdatedFlag() {
  return {
    type: C.RESET_SHIFT_UPDATED_FLAG,
  };
}

export function showHospitalBillingModal(data) {
  return {
    type: C.SHOW_HOSPITAL_BILLING_MODAL,
    payload: data,
  };
}

export function hideHospitalBillingModal() {
  return {
    type: C.HIDE_HOSPITAL_BILLING_MODAL,
  };
}

export function showProfilePanel(data) {
  return {
    type: C.SHOW_PROFILE_PANEL_REQUEST,
    payload: data,
  };
}

export function hideProfilePanel() {
  return {
    type: C.HIDE_PROFILE_PANEL,
  };
}

export function initializeVetRatingCount() {
  return {
    type: C.INITIALIZE_DEFAULT_VET_RATING_COUNT,
  };
}

export function initializeTechRatingCount() {
  return {
    type: C.INITIALIZE_DEFAULT_TECH_RATING_COUNT,
  };
}

export function getPendingRatingModal(payload = {}) {
  return {
    type: C.GET_HOSPITAL_PENDING_VET_RATING_REQUEST,
    payload,
  };
}

export const getVetShiftsAction = (data) => {
  return {
    type: C.GET_VET_SHIFTS,
    payload: data,
  };
};

export function confirmVet(data) {
  return {
    type: C.MOBILE_CONFIRM_VET_REQUEST,
    data: data,
  };
}

export function closeShiftActionResultMessageModal() {
  return {
    type: C.CLOSE_SHIFT_ACTION_RESULT_MESSAGE_MODAL,
  };
}

export function getAutoConfirmContractorListAction(data) {
  return {
    type: C.GET_AUTO_CONFIRM_CONTRACTOR_LIST,
    payload: data,
  };
}

export function addProviderToAutoConfirmAction(data) {
  return {
    type: C.ADD_PROVIDER_TO_AUTO_CONFIRM,
    payload: data,
  };
}

export function saveFutureAutoConfirmAction(data) {
  return {
    type: C.SAVE_FUTURE_AUTO_CONFIRM,
    payload: data,
  };
}

export function deleteFutureAutoConfirmAction(data) {
  return {
    type: C.DELETE_FUTURE_AUTO_CONFIRM,
    payload: data,
  };
}

export function disableFutureAutoConfirmAction(data) {
  return {
    type: C.DISABLE_FUTURE_AUTO_CONFIRM,
    payload: data,
  };
}

export function enableFutureAutoConfirmAction(data) {
  return {
    type: C.ENABLE_FUTURE_AUTO_CONFIRM,
    payload: data,
  };
}

export function getBlockedContractorListAction(data) {
  return {
    type: C.GET_BLOCKED_CONTRACTOR_LIST,
    payload: data,
  };
}

export function removeBlockedContractorAction(data) {
  return {
    type: C.REMOVE_BLOCKED_CONTRACTOR,
    payload: data,
  };
}

export function blockContractorAction(data) {
  return {
    type: C.BLOCK_CONTRACTOR,
    payload: data,
  };
}

export const getShiftDetailsAction = (shiftGroupId, shiftId) => {
  return {
    type: C.FETCH_SHIFT_DETAILS,
    shiftGroupId: shiftGroupId,
    shiftId: shiftId,
    payload: {},
  };
};

export const getInitializeStateAction = () => {
  return {
    type: C.INITIALIZE_ADD_SHIFT_STATE,
  };
};

export function editShiftAction(data) {
  return {
    type: C.EDIT_SHIFT,
    payload: data,
  };
}

export function initializeRatingModalFlag() {
  return {
    type: C.INITIALIZE_RATING_MODAL_FLAG,
  };
}

export function fetchShiftCountForNotification() {
  return {
    type: C.FETCH_SHIFT_COUNT_NOTIFICATION_REQUEST,
  };
}

export function hideNotificationModal() {
  return {
    type: C.NOTIFICATION_MODAL_CLOSE,
  };
}

export function showNotificationModal() {
  return {
    type: C.NOTIFICATION_MODAL_OPEN,
  };
}

export function bulkDeleteShifts(data) {
  return {
    type: C.BULK_DELETE_SHIFTS_H,
    payload: data,
  };
}

export function clearBulkDeleteData() {
  return {
    type: C.CLEAR_BULK_DELETE_DATA,
  };
}

export function boostAllShifts(data) {
  return {
    type: C.BOOST_ALL_SHIFTS,
    payload: data,
  };
}

export function clearBoostAllData() {
  return {
    type: C.CLEAR_BOOST_ALL_DATA,
  };
}

export function updateHospitalTourStatus() {
  return {
    type: C.UPDATE_HOSPITAL_TOUR_STATUS,
  };
}

export function updateRooUniCalloutStatus() {
  return {
    type: C.UPDATE_ROO_UNI_CALLOUT_STATUS,
  };
}

// --- TECH related

export const getTechShiftsAction = (data) => {
  return {
    type: C.GET_TECH_SHIFTS,
    payload: data,
  };
};

export function addTechShiftRequestAction(data) {
  return {
    type: C.FETCH_ADD_TECH_SHIFT_REQUEST,
    payload: data,
  };
}

export function fetchPreviousTechShiftsRequestAction() {
  return {
    type: C.FETCH_PREVIOUS_TECH_SHIFT_REQUEST,
  };
}

export function updateRequestedShiftsLocal() {
  return {
    type: C.UPDATE_REQUESTED_SHIFTS_LOCAL,
  };
}

export function resetTechShiftUpdatedFlag() {
  return {
    type: C.RESET_TECH_SHIFT_UPDATED_FLAG,
  };
}

export function confirmTech(data) {
  return {
    type: C.HOSPITAL_CONFIRM_TECH_REQUEST,
    data: data,
  };
}

export function closeConfirmTechShiftMessageModal() {
  return {
    type: C.CLOSE_SHIFT_ACTION_RESULT_MESSAGE_MODAL,
  };
}

export const getTechShiftDetailsAction = (data) => {
  return {
    type: C.FETCH_TECH_SHIFT_DETAILS_FOR_EDIT,
    payload: data,
  };
};

export const getInitializeTechStateAction = () => {
  return {
    type: C.INITIALIZE_ADD_TECH_SHIFT_STATE,
  };
};

export function editTechShiftRequestAction(data) {
  return {
    type: C.EDIT_TECH_SHIFT_REQUEST,
    payload: data,
  };
}

export function fetchTechShiftCountForNotification() {
  return {
    type: C.FETCH_TECH_SHIFT_COUNT_NOTIFICATION_REQUEST,
  };
}

export function getPendingTechRatingModal(payload = {}) {
  return {
    type: C.GET_HOSPITAL_PENDING_TECH_RATING_REQUEST,
    payload,
  };
}

export function boostAllTechShifts(data) {
  return {
    type: C.BOOST_ALL_TECH_SHIFTS,
    payload: data,
  };
}

export function clearBoostAllTechData() {
  return {
    type: C.CLEAR_BOOST_ALL_TECH_DATA,
  };
}

// --- END TECH

export function downloadPDFAction(data) {
  return {
    type: C.DOWNLOAD_PDF_REQUEST,
    payload: data,
  };
}

export function showTrainingPopup() {
  return {
    type: SHOW_TRAINING_POPUP,
  };
}

export const removeConfirmedTechAction = (data) => {
  return {
    type: C.HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_REQUEST,
    payload: data,
  };
};

export const resetRemoveConfirmedTechAction = () => {
  return {
    type: C.RESET_HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_ACTION,
  };
};

export function setReloadTechShiftsFlagAction() {
  return {
    type: C.SET_RELOAD_TECH_SHIFTS_FLAG,
  };
}

export function submitOvertimeResponse(data) {
  return {
    type: C.SUBMIT_OVERTIME,
    payload: data,
  };
}

export function boostShift(data) {
  return {
    type: C.BOOST_SHIFT,
    payload: data,
  };
}

export function getHospitalResponseRatingData(hospitalId) {
  return {
    type: C.HOSPITAL_RESPONSE_RATING_DATA,
    payload: hospitalId,
  };
}

export function getHospitalResponseRatingComments(action) {
  return {
    type: C.HOSPITAL_RESPONSE_RATING_COMMENTS,
    payload: action,
  };
}

export function resetBoostShift(data) {
  return {
    type: C.RESET_BOOST_SHIFT,
    payload: data,
  };
}

export function resetAddShiftFail() {
  return {
    type: C.RESET_ADD_SHIFT_FAIL,
  };
}

export function resetAddTechShiftFail() {
  return {
    type: C.RESET_ADD_TECH_SHIFT_FAIL,
  };
}

export function getHospitalResponseRating(data) {
  return {
    type: C.HOSPITAL_RESPONSE_RATING,
    payload: data,
  };
}

export function updateHospitalResponseRatingStatus(data) {
  return {
    type: C.UPDATE_HOSPITAL_RESPONSE_RATING_STATUS,
    payload: data,
  };
}

export function threeMonthsHospitalShifts(data) {
  return {
    type: C.GET_THREE_MONTHS_HOSPITAL_SHIFTS,
    payload: data,
  };
}

export function getPendingReviewData(data) {
  return {
    type: C.PENDING_REVIEW_DATA,
    payload: data,
  };
}

export function getTechPendingReviewData(data) {
  return {
    type: C.TECH_PENDING_REVIEW_DATA,
    payload: data,
  };
}

export function setIsModalOpenFlag() {
  return {
    type: C.SET_MODAL_OPEN_FLAG,
  };
}

export function resetIsModalOpenFlag() {
  return {
    type: C.RESET_MODAL_OPEN_FLAG,
  };
}

export function getPendingReviewsFlag() {
  return {
    type: C.GET_HOSPITAL_PENDING_REVIEWS_FLAG,
  };
}

export function createHospitalInvoicedCustomer(data) {
  return {
    type: C.CREATE_HOSPITAL_INVOICED_CUSTOMER,
    payload: data,
  };
}

export function hideBlockVetTechConfirmationModal(data) {
  return {
    type: C.HIDE_BLOCK_TECHVET_CONFIRMATION_MODAL,
    payload: data,
  };
}

export function getHasPaymentFailedFlag() {
  return {
    type: C.GET_HAS_PAYMENT_FAILED_FLAG_REQUEST,
  };
}

export function getInvoicedCustomer(data) {
  return {
    type: C.GET_INVOICED_CUSTOMER,
    payload: data,
  };
}

export function addCancellationShiftIds(shiftId) {
  return {
    type: C.ADD_CANCELLATION_SHIFT_ID,
    payload: shiftId,
  };
}

export function getRefundAmount(data) {
  return {
    type: C.GET_REFUND_AMOUNT,
    payload: data,
  };
}
