import _ from "lodash";
import React, { Context, FC, createContext, useContext, useEffect, useMemo, useState } from "react";

import { CustomToastProps, Toast } from "../../components/Toast";

import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "./constants";

interface Index<T> {
  form?: T;
  onChange?: <K extends keyof T>(name: K, value: T[K]) => void;
  onSave?: () => void;
  onCancel?: () => void;
  loading?: boolean;
  errors?: Partial<Record<keyof T, string>>;
}

interface FromProviderPropTypes<T> {
  children: React.ReactNode;
  defaultForm: T;
  save: (form: T) => Promise<any>;
  validate: (form: T) => Partial<Record<keyof T, string>> | undefined;
}

export function createFormContext<T>(initial?: Index<T>): Context<Index<T> | undefined> {
  return createContext<Index<T> | undefined>(initial);
}

export function createForm<T>(): {
  useFormContext: () => Index<T>;
  FormProvider: React.FC<FromProviderPropTypes<T>>;
} {
  const FormContext = createFormContext<T>({});

  const FormProvider: FC<FromProviderPropTypes<T>> = ({
    children,
    defaultForm,
    save,
    validate,
  }) => {
    const [form, setForm] = useState<T>(defaultForm);
    const [message, setMessage] = useState<CustomToastProps | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    const [errors, setErrors] = useState<Index<T>["errors"]>({});
    useEffect(() => {
      setForm(defaultForm);
    }, [defaultForm]);
    const onChange: Index<T>["onChange"] = (name, value) => {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
      const newErrors: Index<T>["errors"] = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    };

    const onSave = () => {
      setLoading(true);
      const errors = validate(form);
      if (errors && !_.isEmpty(errors)) {
        setErrors(errors);
        setLoading(false);
        return;
      }
      save(form)
        .then(() => {
          setMessage(SUCCESS_MESSAGE);
          setLoading(false);
        })
        .catch(() => {
          setMessage(ERROR_MESSAGE);
          setLoading(false);
        });
    };

    const onCancel = () => {
      setForm(defaultForm);
      setErrors({});
    };

    const value = useMemo(
      () => ({
        form,
        errors,
        onChange,
        onSave,
        onCancel,
        loading,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [form, errors, loading]
    );
    return (
      <FormContext.Provider value={value}>
        {form && children}
        {message && <Toast {...message} onHide={() => setMessage(undefined)} />}
      </FormContext.Provider>
    );
  };

  const useFormContext = () => useContext(FormContext)!;

  return { useFormContext, FormProvider };
}
