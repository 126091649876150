import { Flex } from "antd";
import React, { useEffect, useState } from "react";

import { Icon } from "../../Icon";
import { SmallText } from "../../Text";
import { LoadingWheel } from "../LoadingWheel";

export const SaveStatus = ({
  loading,
  message,
  isSaved,
}: {
  loading: boolean;
  message: string;
  isSaved: boolean;
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!loading && isSaved) {
      setVisible(true);
    }
  }, [loading, isSaved]);

  return (
    <Flex gap="6px" align="center">
      {loading ? (
        <>
          <LoadingWheel />
          <SmallText>{message}</SmallText>
        </>
      ) : (
        visible && (
          <>
            <Icon name="CheckCircle" color="green" size="s" />
            <SmallText>{message}</SmallText>
          </>
        )
      )}
    </Flex>
  );
};
