import posthog from "posthog-js";
import { useEffect } from "react";

import { AuthUtility } from "../helpers/AuthUtility";

export const clearAuthAndRedirect = () => {
  localStorage.clear();
  sessionStorage.clear();

  posthog.reset();

  AuthUtility.setIsAuthorisedUser(false);
  AuthUtility.clearPreviousSession();

  const timeStamp = new Date().getTime();
  window.location.replace(`/login/${timeStamp}`);
};

export const LogOut = () => {
  useEffect(() => {
    clearAuthAndRedirect();
  }, []);

  return null;
};
