import * as C from "../../../constants/adminShiftsList";
import * as ConfirmMessage from "../../../constants/confirmMessage";
import * as ErrorMessage from "../../../constants/errorMessage";

const initialState = {
  shiftList: [],
  shiftLogs: [],
  calloutList: [],
  totalCount: 0,
  shiftEditedFromAdmin: false,
  shiftPriceChanged: null,
  isPayVetSuccess: null,
  isPayVetFailed: false,
  message: null, // This needs to be removed when we refactor AdminShfits.js
  cancelPaymentRequest: null,
  shiftRequestRemoved: null,
  shiftConfirmed: null,
  errorTypeConfirmingShift: null,
  vetList: [],
  techList: [],
  isOTPaySuccess: false,
  isShiftListFetched: null,
  searchParam: "",
  lastActionContractorShiftGroupId: 0,
  isLastActionOnVet: false,
  isActionCompleted: false,
  prevContractorConfirmed: 0, //previous confirmed vet
  currContractorConfirmed: 0,
  lastActionShiftAmount: 0,
  modalMessage: ErrorMessage.EXCEPTION_MESSAGE,
  selectedShiftRatings: null,
  isUpdateRatingsSuccess: false,
  updateRatingsErrorMessage: "",
  oShiftBillingByShiftId: {},
  oShiftOTByShiftId: {},
  oShiftBonusByShiftId: {},
  oShiftRTTRByShiftId: {},
  oShiftOtherByShiftId: {},
  oContractorOTByShiftId: {},
  oContractorShiftBonusByShiftId: {},
  oContractorOtherByShiftId: {},
  showFeedbackForm: null,
  isMostRecentLimit: true,
};

// eslint-disable-next-line default-param-last
const shiftReducer = (state = initialState, action) => {
  const { isOvertime, isShiftBonus, isRTTR, isOther, isVetBid } = action.payload
    ? action.payload
    : {};
  let key;

  switch (action.type) {
    case C.GET_SHIFT_LIST:
      return {
        ...state,
        shiftEditedFromAdmin: false,
        shiftPriceChanged: null,
        isPayVetSuccess: null,
        isPayVetFailed: false,
        message: null,
        cancelPaymentRequest: null,
        shiftConfirmed: null,
        shiftRequestRemoved: null,
        errorTypeConfirmingShift: null,
        modalMessage: ErrorMessage.EXCEPTION_MESSAGE,
        isActionCompleted: false,
        isUpdateRatingsSuccess: false,
        updateRatingsErrorMessage: "",
        isOTPaySuccess: false,
      };
    case C.GET_SHIFT_LIST_SUCCESS:
      if (!action.payload.isVetShifts && action.payload.data && action.payload.data.length) {
        // if this is tech shift list then sort no-replacement shifts to top
        action.payload.data.sort((a, b) => b.isNoReplacement - a.isNoReplacement);
      }

      return {
        ...state,
        shiftList: action.payload.data,
        totalCount: action.payload.totalCount,
        shiftPriceChanged: null,
        shiftRequestRemoved: null,
        shiftConfirmed: null,
        errorTypeConfirmingShift: null,
      };
    case C.GET_SHIFT_LOGS:
      return {
        ...state,
      };
    case C.GET_SHIFT_LOGS_SUCCESS:
      return {
        ...state,
        shiftLogs: action.payload.data,
      };
    case C.GET_SHIFT_LOGS_FAIL:
      return {
        ...state,
      };
    case C.GET_CALLOUT_LIST_SUCCESS:
      return {
        ...state,
        calloutList: action.payload.data,
      };
    case C.INIT_ADMIN_RATINGS:
    case C.GET_ADMIN_RATINGS:
      return {
        ...state,
        selectedShiftRatings: null,
        updateRatingsErrorMessage: "",
        isUpdateRatingsSuccess: false,
      };
    case C.GET_ADMIN_RATINGS_SUCCESS:
      return {
        ...state,
        selectedShiftRatings: action.payload.data,
      };
    case C.GET_ADMIN_RATINGS_FAIL:
      return {
        ...state,
        updateRatingsErrorMessage: action.payload.message,
      };

    case C.GET_SHIFT_BILLING_INFO_SUCCESS:
      let billingInfo = { ...state.oShiftBillingByShiftId };
      if (action.payload.data) {
        billingInfo = {
          ...billingInfo,
          [action.payload.data[0].shiftId]: action.payload.data,
        };
      }
      return {
        ...state,
        oShiftBillingByShiftId: billingInfo,
      };

    case C.GET_SHIFT_ADDITIONAL_PAY_INFO_SUCCESS:
      if (isOvertime) {
        key = "oShiftOTByShiftId";
      } else if (isShiftBonus) {
        key = "oShiftBonusByShiftId";
      } else if (isRTTR) {
        key = "oShiftRTTRByShiftId";
      } else if (isOther) {
        key = "oShiftOtherByShiftId";
      }
      return {
        ...state,
        [key]: {
          ...state[key],
          [action.payload.data[0].shiftId]: action.payload.data,
        },
      };

    case C.GET_CONTRACTOR_ADDITIONAL_PAY_SUCCESS:
      if (isOvertime) {
        key = "oContractorOTByShiftId";
      } else if (isShiftBonus) {
        key = "oContractorShiftBonusByShiftId";
      } else if (isOther) {
        key = "oContractorOtherByShiftId";
      } else {
        key = "oContractorBillingByShiftId";
      }

      return {
        ...state,
        [key]: {
          ...state[key],
          [action.payload.data.shiftId]: action.payload.data,
        },
      };

    case C.ADMIN_UPDATE_RATINGS:
      return {
        ...state,
        selectedShiftRatings: null,
      };
    case C.ADMIN_UPDATE_RATINGS_SUCCESS:
      return {
        ...state,
        isUpdateRatingsSuccess: true,
      };
    case C.ADMIN_UPDATE_RATINGS_FAIL:
      return {
        ...state,
        updateRatingsErrorMessage: action.payload.message,
      };
    case C.ADMIN_EDIT_SHIFT_SUCCESS:
      return {
        ...state,
        isActionCompleted: true,
        modalMessage: action.payload?.isConfirmedMoreThan90DaysAgo
          ? ConfirmMessage.EDIT_SHIFT_CONFIRMED_MORE_THAN_90_DAYS_AGO
          : isVetBid
          ? ConfirmMessage.EDIT_VET_BID_SHIFT_CONFIRM
          : ConfirmMessage.EDIT_SHIFT_CONFIRM,
      };
    case C.ADMIN_EDIT_SHIFT_FAIL:
      return {
        ...state,
        isActionCompleted: true,
      };
    case C.ADMIN_EDIT_SHIFT_PRICE_SUCCESS:
      return {
        ...state,
        shiftPriceChanged: true,
        isActionCompleted: true,
        modalMessage: action.payload?.isConfirmedMoreThan90DaysAgo
          ? ConfirmMessage.EDIT_SHIFT_CONFIRMED_MORE_THAN_90_DAYS_AGO
          : ConfirmMessage.ADMIN_SHIFT_PRICE_UPDATE_SUCCESS,
      };
    case C.ADMIN_EDIT_SHIFT_PRICE_FAIL:
      return {
        ...state,
        shiftPriceChanged: false,
      };
    case C.ADMIN_ADD_SHIFT_SUCCESS:
      return {
        ...state,
        isActionCompleted: true,
        modalMessage: ConfirmMessage.ADD_SHIFT_CONFIRM,
      };
    case C.ADMIN_ADD_SHIFT_FAIL:
      return {
        ...state,
        isActionCompleted: true,
        modalMessage: action.payload.message,
      };
    case C.PAY_TO_VET_SUCCESS:
      return {
        ...state,
        isPayVetSuccess: true,
      };
    case C.PAY_TO_VET_FAIL:
      return {
        ...state,
        isPayVetFailed: true,
        message: action.payload.message,
      };
    case C.CANCEL_PAYMENT_SUCCESS:
      return {
        ...state,
        cancelPaymentRequest: true,
      };
    case C.CANCEL_PAYMENT_FAIL:
      return {
        ...state,
        cancelPaymentRequest: false,
        message: action.payload.message,
      };
    case C.ADMIN_REMOVE_SHIFT_REQUEST:
      return {
        ...state,
        lastActionContractorShiftGroupId: action.payload.shiftGroupId,
        isLastActionOnVet: action.payload.vets ? true : false,
        lastActionShiftAmount: action.payload.shiftAmount,
        lastShiftAction: "removeRequestedContractor",
      };
    case C.ADMIN_REMOVE_SHIFT_REQUEST_SUCCESS:
      return {
        ...state,
        shiftRequestRemoved: true,
      };

    case C.ADMIN_REMOVE_SHIFT_REQUEST_FAIL:
      return {
        ...state,
        shiftRequestRemoved: false,
      };
    case C.ADMIN_CONFIRM_SHIFT_REQUEST_SUCCESS:
      return {
        ...state,
        shiftConfirmed: true,
        errorTypeConfirmingShift: null,
      };
    case C.ADMIN_CONFIRM_SHIFT_REQUEST_FAIL:
      return {
        ...state,
        shiftConfirmed: false,
        errorTypeConfirmingShift: action.payload.message,
      };
    case C.ADMIN_GET_ALL_VETS_SUCCESS:
      return {
        ...state,
        vetList: action.payload.vetList,
        techList: [],
      };
    case C.SET_LAST_SHIFT_ACTION_DATA:
      return {
        ...state,
        showFeedbackForm: true,
        lastActionContractorShiftGroupId: action.payload.shiftGroupId,
        isLastActionOnVet: action.payload.isVetShift,
        prevContractorConfirmed:
          action.payload.shiftAction === "switchContractor"
            ? action.payload.oldContractorId
            : action.payload.contractorId,
        currContractorConfirmed:
          action.payload.shiftAction === "switchContractor" ? action.payload.contractorId : null,
        lastActionShiftAmount: action.payload.shiftAmount,
        lastActionShiftDate: action.payload.shiftDate,
        lastActionHospitalId: action.payload.hospitalId,
        lastShiftActionId: action.payload.shiftId,
        lastActionShiftEndtime: action.payload.shiftEndDatetime,
        lastShiftAction: action.payload.shiftAction,
      };
    case C.RESET_LAST_SHIFT_ACTION_DATA:
      return {
        ...state,
        showFeedbackForm: false,
        lastActionContractorShiftGroupId: 0,
        isLastActionOnVet: false,
        prevContractorConfirmed: 0,
        currContractorConfirmed: 0,
        lastActionShiftAmount: 0,
        lastActionHospitalId: 0,
        lastShiftActionId: 0,
        lastActionShiftEndtime: null,
        lastShiftAction: null,
      };
    case C.ADMIN_REQUEST_SHIFT_SUCCESS:
      return {
        ...state,
        isActionCompleted: true,
        modalMessage: ConfirmMessage.ADMIN_REQUEST_VET_SHIFT_SUCCESS,
      };
    case C.ADMIN_REQUEST_SHIFT_FAIL:
      return {
        ...state,
        isActionCompleted: true,
        modalMessage: ErrorMessage[action.payload.message] || action.payload.message,
      };
    case C.ADMIN_GET_ALL_TECHS_SUCCESS:
      return {
        ...state,
        techList: action.payload.techList,
        vetList: [],
      };
    case C.ADMIN_REQUEST_TECH_SHIFT_SUCCESS:
      return {
        ...state,
        isActionCompleted: true,
        modalMessage: ConfirmMessage.ADMIN_REQUEST_VET_SHIFT_SUCCESS,
      };
    case C.ADMIN_REQUEST_TECH_SHIFT_FAIL:
      return {
        ...state,
        isActionCompleted: true,
        modalMessage: ErrorMessage[action.payload.message],
      };
    case C.ADMIN_REQUEST_TECH_SHIFT_OVERLAPS:
      return {
        ...state,
        isActionCompleted: true,
        modalMessage: ConfirmMessage.ADMIN_REQUEST_SHIFT_OVERLAPS,
      };
    case C.ADMIN_OVERTIME_ALREADY_EXISTS:
      return {
        ...state,
        isActionCompleted: true,
        isOTPaySuccess: true,
        modalMessage: ConfirmMessage.ADMIN_OVERTIME_ALREADY_EXISTS,
      };
    case C.ADMIN_VET_ADDITIONAL_PAYOUT_SUCCESS:
      return {
        ...state,
        isActionCompleted: true,
        isOTPaySuccess: true,
        modalMessage: ConfirmMessage.ADMIN_PAYMENT_TO_VET_SUCCESS,
      };
    case C.ADMIN_VET_ADDITIONAL_PAYOUT_FAIL:
      return {
        ...state,
        isActionCompleted: true,
        isOTPaySuccess: false,
        modalMessage: action.payload.message,
      };

    case C.ADMIN_TECH_ADDITIONAL_PAYOUT_SUCCESS:
      return {
        ...state,
        isActionCompleted: true,
        isOTPaySuccess: true,
        modalMessage: ConfirmMessage.ADMIN_PAYMENT_TO_VET_SUCCESS,
      };
    case C.ADMIN_TECH_ADDITIONAL_PAYOUT_FAIL:
      return {
        ...state,
        isActionCompleted: true,
        isOTPaySuccess: false,
        modalMessage: action.payload.message,
      };

    case C.ADMIN_REQUEST_AND_CONFIRM_SHIFT_SUCCESS:
      return {
        ...state,
        isActionCompleted: true,
        modalMessage: ConfirmMessage.ADMIN_SHIFT_PROCESSED,
      };
    case C.ADMIN_REQUEST_AND_CONFIRM_SHIFT_FAIL:
      return {
        ...state,
        isActionCompleted: true,
      };
    case C.ADMIN_EDIT_TECH_SHIFT_SUCCESS:
      return {
        ...state,
        isActionCompleted: true,
        modalMessage: action.payload?.isConfirmedMoreThan90DaysAgo
          ? ConfirmMessage.EDIT_SHIFT_CONFIRMED_MORE_THAN_90_DAYS_AGO
          : ConfirmMessage.EDIT_SHIFT_CONFIRM,
      };
    case C.ADMIN_EDIT_TECH_SHIFT_FAIL:
      return {
        ...state,
        isActionCompleted: true,
      };
    case C.ADMIN_ADD_TECH_SHIFT_SUCCESS:
      return {
        ...state,
        isActionCompleted: true,
        modalMessage: ConfirmMessage.ADD_SHIFT_CONFIRM,
      };
    case C.ADMIN_ADD_TECH_SHIFT_FAIL:
      return {
        ...state,
        isActionCompleted: true,
      };
    case C.INITIALIZE_ADMIN_STATE:
      return {
        ...state,
        lastActionContractorShiftGroupId: 0,
        isLastActionOnVet: false,
        prevContractorConfirmed: 0,
        currContractorConfirmed: 0,
        lastActionShiftAmount: 0,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default shiftReducer;
