// COMMON

import {
  HOSPITAL_USER_TYPE,
  STUDENT_USER_TYPE,
  TECH_USER_TYPE,
  VET_USER_TYPE,
} from "./UserTypeConstants";

// NAV
export const SHIFTS = "Shifts";
export const CHAT = "Chat";
export const MARKETING = "Marketing";
export const SHIFT_REQUEST = "Shift request";

// SHIFT SECTIONS
export const SHIFT_UPDATES = "Shift updates";
export const NO_SECTIONS = "";

// TOGGLE IDS
export const EMAIL = "Email";
export const SMS = "SMS";
export const PUSH_NOTIFICATIONS = "Push Notifications";

export const DEFAULT_TOGGLE_OPTIONS = {
  [EMAIL]: {
    label: EMAIL,
    value: false,
    disabled: false,
  },
  [SMS]: {
    label: SMS,
    value: false,
    disabled: false,
  },
  [PUSH_NOTIFICATIONS]: {
    label: PUSH_NOTIFICATIONS,
    value: false,
    disabled: false,
  },
};

/* VETS */
export const RATINGS = "Ratings";
export const PAYMENTS = "Payments";

/* SHIFTS */
// SECTIONS:
export const NEW_SHIFT_ALERTS = "New shift alerts";
export const FAVORITE_HOSPITALS = "New shifts from your favorite hospitals";
export const WEEKLY_NEW_SHIFT_RECOMMENDATIONS = "Weekly new shift recommendations";
export const SHIFT_REQUESTS = "Shift requests";
export const SHIFT_REMINDERS = "Shift reminders";
export const SHIFT_CONFIRMATIONS = "Shift confirmations";
export const MATKETING_SMS = "Marketing SMS";
export const PROMO = "Promotions";
export const WEEKLY_DIGEST = "Weekly digest";

export const VET_TECH_SHIFTS = {
  [NEW_SHIFT_ALERTS]: {
    label: "Get notified when new shifts are posted",
    channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
    typeCode: 29,
  },
  [FAVORITE_HOSPITALS]: {
    label: "Get notified when your favorite hospitals post new shifts",
    channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
    typeCode: 1,
  },
  [WEEKLY_NEW_SHIFT_RECOMMENDATIONS]: {
    label: "",
    channels: [EMAIL],
    typeCode: 2,
  },
  [SHIFT_REQUESTS]: {
    label: "Get a confirmation when you request a shift",
    channels: [EMAIL],
    typeCode: 3,
  },
  [SHIFT_UPDATES]: {
    label: "Get notified when shift details change",
    channels: [EMAIL],
    typeCode: 4,
  },
  [SHIFT_REMINDERS]: {
    label: "",
    channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
    typeCode: 5,
  },
  [SHIFT_CONFIRMATIONS]: {
    label: "Get notified when your shift requests are confirmed or declined",
    channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
    typeCode: 6,
  },
  [WEEKLY_DIGEST]: {
    label: "Receive weekly shift recommendations",
    channels: [EMAIL],
    typeCode: 36,
  },
};

export const VET_TECH_MARKETING = {
  [MATKETING_SMS]: {
    label: "",
    channels: [SMS],
    typeCode: 19,
  },
  [PROMO]: {
    label: "",
    channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
    typeCode: 17,
  },
};

/* HOSPITALS */
export const REVIEWS_AND_STATS = "Reviews and Stats";
export const COMMUNITY = "Community";
export const BILLING = "Billing";
export const GENERAL = "General";

/* SHIFTS */
// SHIFT SECTIONS
export const NEW_SHIFT_POSTED = "New shift posted";
export const TECH_CALL_OUT = "Tech call out";
export const SHIFT_BOOSTING = "Shift boosting recommendation";

export const HOSPITAL_SHIFTS = {
  [NEW_SHIFT_POSTED]: {
    label: "Get notified when new shifts are posted",
    channels: [EMAIL],
    typeCode: 7,
  },
  [SHIFT_UPDATES]: {
    label: "Get notified when shift details change",
    channels: [EMAIL],
    typeCode: 4,
  },
  [SHIFT_REQUEST]: {
    label: "Get notified regarding requests on your posted shifts",
    channels: [EMAIL, SMS],
    typeCode: 9,
    disabledOnPrimary: true,
  },
  [TECH_CALL_OUT]: {
    label: "",
    channels: [EMAIL],
    typeCode: 10,
    disabledOnPrimary: true,
  },
  [SHIFT_BOOSTING]: {
    label: "Get notified regarding boost recommendations for posted shifts",
    channels: [EMAIL],
    typeCode: 35,
  },
};

export type UserTypeId =
  | typeof VET_USER_TYPE
  | typeof TECH_USER_TYPE
  | typeof HOSPITAL_USER_TYPE
  | typeof STUDENT_USER_TYPE;

type SideNavKey = "vet_or_tech" | "hospital" | "student";

export const sideNavKeyMap: Record<UserTypeId, SideNavKey> = {
  [VET_USER_TYPE]: "vet_or_tech",
  [TECH_USER_TYPE]: "vet_or_tech",
  [HOSPITAL_USER_TYPE]: "hospital",
  [STUDENT_USER_TYPE]: "student",
};

export const sideNavItems: Record<SideNavKey, any> = {
  vet_or_tech: [
    {
      label: SHIFTS,
      title: SHIFTS,
      content: VET_TECH_SHIFTS,
      typeCodes: [1, 2, 3, 4, 5, 6, 29, 36],
    },
    {
      label: RATINGS,
      title: "Ratings reminders",
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
          typeCode: 11,
        },
      },
    },
    {
      label: CHAT,
      title: CHAT,
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
          typeCode: 12,
        },
      },
    },
    {
      label: "Payments",
      title: "Payments",
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
          typeCode: 13,
        },
      },
    },
    {
      label: "Marketing",
      title: "Marketing",
      content: VET_TECH_MARKETING,
      typeCodes: [19, 17],
    },
  ],
  hospital: [
    {
      label: SHIFTS,
      title: SHIFTS,
      content: HOSPITAL_SHIFTS,
      typeCodes: [7, 4, 8, 9, 10, 35],
      disabledOnPrimary: [9, 10],
    },
    {
      label: REVIEWS_AND_STATS,
      title: "Reviews",
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL, SMS],
          typeCode: 20,
        },
      },
    },
    {
      label: CHAT,
      title: CHAT,
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL, SMS, PUSH_NOTIFICATIONS],
          typeCode: 12,
        },
      },
    },
    {
      label: COMMUNITY,
      title: COMMUNITY,
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL],
          typeCode: 21,
        },
      },
    },
    {
      label: BILLING,
      title: BILLING,
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL],
          typeCode: 22,
          disabledOnPrimary: true,
        },
      },
      disabledOnPrimary: [22],
    },
    {
      label: GENERAL,
      title: GENERAL,
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [EMAIL],
          typeCode: 23,
          disabledOnPrimary: true,
        },
      },
      disabledOnPrimary: [23],
    },
    {
      label: MARKETING,
      title: MARKETING,
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [SMS],
          typeCode: 19,
        },
      },
    },
  ],
  student: [
    {
      label: MARKETING,
      title: MARKETING,
      content: {
        [NO_SECTIONS]: {
          label: "",
          channels: [SMS],
        },
      },
    },
  ],
};

export const testResponse = [
  {
    user_notification_settings_id: 29597,
    sms_global_unsubscribe: 0,
    email_global_unsubscribe: 0,
    pn_global_unsubscribe: 0,
    notification_settings_id: 368921,
    email_enabled: 1,
    sms_enabled: 1,
    push_enabled: 1,
    frequency_type_code: 3,
    frequency_type_name: "Once a day",
    notification_type_code: 29,
    notification_type_name: "New shift alert",
    // @ts-expect-error: test object
    notification_type_description: null,
  },
  {
    user_notification_settings_id: 29597,
    sms_global_unsubscribe: 0,
    email_global_unsubscribe: 0,
    pn_global_unsubscribe: 0,
    notification_settings_id: 368922,
    email_enabled: 1,
    sms_enabled: 1,
    push_enabled: 0,
    // @ts-expect-error: test object
    frequency_type_code: null,
    // @ts-expect-error: test object
    frequency_type_name: null,
    notification_type_code: 1,
    notification_type_name: "Favorite hospitals new shift alerts",
    notification_type_description: "Get notified when your favorite hospitals post new shifts.",
  },
];
