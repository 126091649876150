import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { rooFetch } from "../../../api/common/rooFetch";
import { UNSET_LOADER } from "../../../constants/loader";
import * as W9ActionTypes from "../../../constants/w9";

export function* getW9ByDateAsync(action) {
  const {
    startDate,
    endDate,
    isTech,
    isGetAllWithShiftInMonth,
    isBackgroundCheck,
    searchText,
    isForYear,
  } = action.payload;

  const apiURL = `api/vet/getW9ByDate`;
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + apiURL, {
      method: "POST",
      body: JSON.stringify({
        startDate,
        endDate,
        isGetAllWithShiftInMonth,
        isBackgroundCheck,
        isTech,
        searchText,
      }),
    });

    const responseData = yield response.json();
    yield put({ type: UNSET_LOADER });
    let oData;
    if (isForYear) {
      oData = { w9ListForYear: responseData.data.data };
    } else {
      oData = { w9List: responseData.data.data };
    }
    if (responseData.status_code === 200) {
      yield put({ type: W9ActionTypes.GET_W9_LIST_SUCCESS, payload: oData });
    } else {
      yield put({
        type: W9ActionTypes.GET_W9_LIST_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting w9 list: " } });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootW9Saga() {
  yield takeEvery(W9ActionTypes.GET_W9_LIST, getW9ByDateAsync);
}
