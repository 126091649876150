import styled from "styled-components";
import { DatePicker } from "antd";

import {
  BodyText,
  blueLightest,
  disabledTextDark,
  grayBase,
  grayWhite,
} from "../../styles/constants";

import { AntDatePickerProps } from ".";

export const DatePickerContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  img {
    cursor: pointer;
  }
`;

export const CustomDatePicker = styled(DatePicker)<AntDatePickerProps>`
  height: 48px;
  width: ${(props) => (props.picker == "year" ? "120px" : "140px")};
  border: 2px solid ${blueLightest};
  border-radius: 12px;
  background: ${grayWhite};
  padding: 12px 8px;
  ${BodyText}
  color: ${grayBase};
  cursor: pointer;
  :hover {
    border: 2px solid ${blueLightest};
  }

  &.ant-picker .ant-picker-input > input[disabled] {
    color: ${disabledTextDark};
  }
  ${(props) =>
    props.style &&
    `
      ${props.style}
    `}
`;
