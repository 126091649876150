import React from "react";

import { Color } from "../../styles/constants";

import { ErrorText as ErrorTextComponent } from "./ErrorText";
import {
  BoldText as BoldTextComponent,
  MediumText as MediumTextComponent,
  SmallMediumText as SmallMediumTextComponent,
  SmallText as SmallTextComponent,
  Text as TextComponent,
} from "./TextStyles";

export interface TextProps {
  children?: React.ReactNode;
  className?: string;
  testId?: string;
  color?: Color;
  disabled?: boolean;
  error?: boolean;
}

export const Text: React.FC<TextProps> = (props) => {
  const { testId } = props;
  return (
    <TextComponent data-testid={testId} className={props.className} {...props}>
      {props.children}
    </TextComponent>
  );
};

export const BoldText: React.FC<TextProps> = (props) => {
  const { testId } = props;
  return (
    <BoldTextComponent data-testid={testId} className={props.className} {...props}>
      {props.children}
    </BoldTextComponent>
  );
};

export const MediumText: React.FC<TextProps> = (props) => {
  const { testId } = props;
  return (
    <MediumTextComponent data-testid={testId} className={props.className} {...props}>
      {props.children}
    </MediumTextComponent>
  );
};

export const SmallText: React.FC<TextProps> = (props) => {
  const { testId } = props;
  return (
    <SmallTextComponent data-testid={testId} className={props.className} {...props}>
      {props.children}
    </SmallTextComponent>
  );
};

export const ErrorText: React.FC<TextProps> = (props) => {
  const { testId } = props;
  return (
    <ErrorTextComponent data-testid={testId} className={props.className} {...props}>
      {props.children}
    </ErrorTextComponent>
  );
};

export const SmallMediumText: React.FC<TextProps> = (props) => {
  const { testId } = props;
  return (
    <SmallMediumTextComponent data-testid={testId} className={props.className} {...props}>
      {props.children}
    </SmallMediumTextComponent>
  );
};
