import React from "react";
import { useSelector } from "react-redux";
import { Loading } from "@RooUI";

import { RootState } from "../../store";
import HeaderLoggedIn from "../HeaderLoggedIn";
import NotificationSettingsNew from "../NotificationSettingsNew/components/NotificationSettings";

const NotificationSettingsContainer = () => {
  const globalState = useSelector((state: RootState) => state);
  const { login } = globalState;
  const { userId } = login;
  if (!userId) {
    return <Loading />;
  } else {
    return (
      <div className="inner-container sm">
        <HeaderLoggedIn />
        <NotificationSettingsNew currentUser={login} userId={userId} />
      </div>
    );
  }
};

// eslint-disable-next-line no-restricted-exports
export default NotificationSettingsContainer;
