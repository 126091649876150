import * as Sentry from "@sentry/react";
import { Auth } from "aws-amplify";
import { call, put, takeEvery } from "redux-saga/effects";

import { cognitoLogin } from "../../../Login/Saga/LoginSagas";
import { rooFetch } from "../../../api/common/rooFetch";
import { SET_AUTHORISE_USER } from "../../../constants/Login";
import * as errorMessage from "../../../constants/errorMessage";
import * as C from "../../../constants/rooAdminConstants";

function* registerRooInternalUserAsync(action) {
  try {
    const { email, password, userId, userTypeId } = action.payload;

    const oSignUp = yield Auth.signUp({
      username: email,
      password: password,
      attributes: {
        "custom:usertype": String(userTypeId),
      },
    });
    const cognitoId = oSignUp.userSub;

    yield put({ type: SET_AUTHORISE_USER }); //TO set authorize flag once user registered
    yield putRegisterRooInternalUserData({
      email,
      password,
      userId,
      cognitoId,
    });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while registering roo internal user: " },
    });
    yield put({
      type: C.REGISTER_ROO_INTERNAL_USER_FAIL,
      payload: { status: 409, message: error.message },
    });
  }
}

function* putRegisterRooInternalUserData(userData) {
  try {
    const { email, password, userId, cognitoId } = userData;
    const session = yield Auth.signIn(email, password);

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/users/registerRooInternalUser",
      {
        method: "POST",
        headers: { "x-access-token": `Bearer ${session.signInUserSession.idToken.jwtToken}` },
        body: JSON.stringify({ email, userId, cognitoId }),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield call(cognitoLogin, { email, password });
      yield put({
        type: C.REGISTER_ROO_INTERNAL_USER_SUCCESS,
        payload: {
          status: data.status_code,
          userId: userId,
          email: email,
        },
      });
      yield put({ type: SET_AUTHORISE_USER }); //TO set authorize flag once user registered
    } else if (data.status_code === 400) {
      yield put({
        type: C.REGISTER_ROO_INTERNAL_USER_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while registering roo internal user: " },
    });
    yield put({
      type: C.REGISTER_ROO_INTERNAL_USER_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootRegisterRooAdminSaga() {
  yield takeEvery(C.REGISTER_ROO_INTERNAL_USER, registerRooInternalUserAsync);
}
