import style, { css } from "styled-components";

import {
  BoldBodyText,
  SmallBodyText,
  grayBase,
  grayLight400,
  greenBase,
  greenDark600,
  redBase,
  redDark600,
} from "../../styles/constants";

import { LinkTextProps } from ".";

export const LinkContainer = style.div`
  display: inline-flex;
  gap: 2px;
  align-items: center;
  cursor: pointer;
`;

const baseLinkStyle = css<LinkTextProps>`
  ${(props) => (props.linkSize === "small" ? SmallBodyText : BoldBodyText)}
  font-weight: 600 !important;
  text-decoration: underline !important;
  ${(props) => props.disabled && `opacity: 0.5;`}
`;

const baseLinkColorStyle = css`
  color: ${greenBase} !important;
  &:hover {
    color: ${greenDark600} !important;
  }
`;

const dangerLinkColorStyle = css`
  color: ${redBase} !important;
  &:hover {
    color: ${redDark600} !important;
  }
`;

const mutedLinkColorStyle = css`
  color: ${grayLight400} !important;
  &:hover {
    color: ${grayBase} !important;
  }
`;

export const Link = style.a<LinkTextProps>`
  ${baseLinkStyle}
  ${baseLinkColorStyle}
`;

export const TextLink = style.p<LinkTextProps>`
  ${baseLinkStyle}
  ${baseLinkColorStyle}
  margin-bottom: 0 !important;
`;

export const DangerLink = style.a<LinkTextProps>`
  ${baseLinkStyle}
  ${dangerLinkColorStyle}
`;

export const DangerTextLink = style.p<LinkTextProps>`
  ${baseLinkStyle}
  ${dangerLinkColorStyle}
  margin-bottom: 0 !important;
`;

export const MutedLink = style.a<LinkTextProps>`
  ${baseLinkStyle}
  ${mutedLinkColorStyle}
`;

export const MutedTextLink = style.p<LinkTextProps>`
  ${baseLinkStyle}
  ${mutedLinkColorStyle}
  margin-bottom: 0 !important;
`;

export const Icon = style.img`
  width: 16px;
  height: 16px;
`;
