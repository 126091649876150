import React, { FC } from "react";
import { Button } from "@RooUI";

import { ButtonContainer } from "./styles";

interface ButtonPropTypes {
  onSave: () => void;
  onCancel: () => void;
  loading: boolean;
  testId?: string;
}
export const Buttons: FC<ButtonPropTypes> = ({ onSave, onCancel, loading, testId = "" }) => {
  // Adding default empty string to testId to avoid 'undefinedSave' or 'undefinedCancel'
  return (
    <ButtonContainer>
      <Button
        title="Save"
        buttonType="primary"
        onClick={onSave}
        loading={loading}
        testId={`${testId}Save`}
      />
      <Button title="Cancel" onClick={onCancel} loading={loading} testId={`${testId}Cancel`} />
    </ButtonContainer>
  );
};
