import React from "react";
import { ProgressProps } from "antd";

import { grayBackground, greenBase } from "../../styles/constants";

import { StyledProgress } from "./CircularProgressStyled";

export interface AntProgressBarProps extends ProgressProps {}

export const CircularProgress: React.FC<AntProgressBarProps> = (props) => {
  return (
    <StyledProgress {...props} strokeColor={greenBase} trailColor={grayBackground} type="circle" />
  );
};
