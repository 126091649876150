import styled from "styled-components";

import { disabledTextDark, smallMediaBreakpoint, spacingL } from "../../styles/constants";
import { TwoColumnGrid } from "../Grid";
import { FormLabel } from "../FormLabel";

import { RadioGroupProps } from "./RadioGroup";

export const RadioGroupGrid = styled(TwoColumnGrid)<{ rowGap?: string; marginTop?: string }>`
  row-gap: ${({ rowGap }) => (rowGap ? rowGap : "0px")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0px")};
`;

export const SingleColumnDiv = styled.div<Pick<RadioGroupProps, "shouldWrapRadios">>`
  ${(props) => `${getWrappedRadiosStyle(props.shouldWrapRadios)}`};
`;

const getWrappedRadiosStyle = (shouldWrapRadios?: boolean) => {
  if (!shouldWrapRadios) {
    return "";
  }
  return `
      display: inline-flex;
      flex-wrap: wrap;
      gap: ${spacingL}
  `;
};

export const StyledFormLabel = styled(FormLabel)`
  color: ${({ disabled }) => (disabled ? disabledTextDark : "inherit")};
`;

export const RadioGroupFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacingL};
  align-items: center;
  @media (max-width: ${smallMediaBreakpoint}) {
    align-items: unset;
  }
`;
