const initialState = {
  onboardingInfo: [],
  isLoaded: false,
};

// eslint-disable-next-line default-param-last
const vetOnboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

// eslint-disable-next-line no-restricted-exports
export default vetOnboardingReducer;
