import * as Sentry from "@sentry/react";
import lodash from "lodash";
import { call, put, select, takeEvery } from "redux-saga/effects";

import { showPromoShiftSignUpToast, showToast } from "../../Common/Toasts/action/ToastActions";
import { rooFetch } from "../../api/common/rooFetch";
import { SHIFT_REQUEST } from "../../constants/brazeEventNameConstants";
import * as calendarActions from "../../constants/calendar";
import * as errorMessage from "../../constants/errorMessage";
import { UNSET_LOADER } from "../../constants/loader";
import * as vetDashboardActions from "../../constants/vetConstants";
import { logBrazeCustomEvent } from "../../helpers/brazeUtility";

export function* getMyShiftListAsync(action) {
  try {
    const url =
      window.RooConfig.API_URL +
      "api/hospital/shift/getShiftsByHospitalOrContractorId?isFromMobile=0&allShift=0&year=" +
      action.payload.year +
      "&month=" +
      action.payload.month +
      "&vetId=" +
      action.payload.vetId;

    const response = yield call(rooFetch, url);
    const responseData = yield response.json();
    const data = lodash.cloneDeep(responseData.data);
    yield put({ type: UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: calendarActions.GET_MY_SHIFTS_SUCCESS,
        payload: data,
        eventListForVet: action.payload.eventListForVet,
      });
    } else {
      yield put({
        type: calendarActions.GET_MY_SHIFTS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting vet shift list: " } });
  }
}

export function* getMyShiftDetailsAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        "api/hospital/shift/getShiftDetailsById?shiftGroupId=" +
        action.payload.shiftGroupId +
        "&vetId=" +
        action.payload.vetId
    );

    const responseData = yield response.json();

    if (responseData.status_code === 200) {
      const data = [...responseData.data.data];
      yield put({ type: calendarActions.GET_MY_SHIFTS_DETAILS_SUCCESS, payload: data[0] });
    } else {
      yield put({
        type: calendarActions.GET_MY_SHIFTS_DETAILS_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting vet shift details: " },
    });
  }
}

function* requestVetShiftAsync(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/vet/shift/requestShift", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (data.status_code === 200) {
      const requestMessage = data.message;
      if (requestMessage == "User blocked") {
        localStorage.setItem("isActive", 4);
        window.location.reload();
      } else {
        if (data.data.added === 0) {
          const { hospitalName } = action.payload;
          yield put({
            type: calendarActions.REQUEST_VET_SHIFT_NOT_AVAILABLE,
            payload: { hospitalName, message: data.data.message },
          });
        } else {
          yield put({
            type: calendarActions.REQUEST_VET_SHIFT_SUCCESS,
            payload: { status: data.status_code, data: data.data.data },
          });
          if (data.data.data.isAutoConfirmed) {
            yield put(
              showToast({
                variant: "success",
                message: "Roo-hoo!",
                description: `Your shift request is confirmed.`,
              })
            );
          }

          logBrazeCustomEvent(SHIFT_REQUEST, { shift_id: action.payload.shiftId });
          if (data.data.data.promoId) yield put(showPromoShiftSignUpToast());
        }
        yield put({
          type: vetDashboardActions.CHECK_VET_LICENSE_EXPIRING_REQUEST,
          payload: { vetId: action.payload.vetId },
        });
      }
    } else if (data.status_code === 400) {
      yield put({
        type: calendarActions.REQUEST_VET_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while requesting vet shift: " } });
    yield put({
      type: calendarActions.REQUEST_VET_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootVetCalendarSaga() {
  yield takeEvery(calendarActions.GET_MY_SHIFTS, getMyShiftListAsync);
  yield takeEvery(calendarActions.GET_MY_SHIFTS_DETAILS, getMyShiftDetailsAsync);
  yield takeEvery(calendarActions.REQUEST_VET_SHIFT, requestVetShiftAsync);
}
