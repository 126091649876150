import React from "react";

export const MdsLocalPharmacyOutlined = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px"
    fill="#e8eaed"
    {...props}
  >
    <path d="M120-120v-60l87-255-87-255v-60h527l62-170 68 27-52 143h115v60l-88 255 88 255v60H120Zm332-161h60v-124h124v-60H512v-124h-60v124H328v60h124v124ZM182-180h596l-88-255 88-255H182l88 255-88 255Zm298-255Z" />
  </svg>
);
MdsLocalPharmacyOutlined.isCustomSvg = true;
