import React from "react";

export const MdTrust = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#e8eaed"
    {...props}
  >
    <path d="M11.1937 2.59002L5.42893 4.75482C5.15242 4.86365 4.92945 5.0382 4.75152 5.28157C4.5834 5.51153 4.5 5.76871 4.5 6.06761V10.6115C4.5 12.7549 5.1113 14.7553 6.34178 16.6255C7.56319 18.4818 9.19605 19.743 11.2523 20.4293C11.3249 20.4535 11.3959 20.4711 11.4654 20.4827C11.5193 20.4917 11.5807 20.4976 11.6503 20.4994C11.6655 20.498 11.6808 20.4973 11.6963 20.4973C11.7852 20.4973 11.8618 20.491 11.9272 20.48C11.9968 20.4684 12.0678 20.4508 12.1403 20.4266C14.196 19.7431 15.8292 18.4795 17.0509 16.6228C18.2814 14.7527 18.8927 12.7522 18.8927 10.6089V6.06493C18.8927 5.76945 18.8097 5.51133 18.64 5.27729C18.4636 5.03394 18.2418 4.86159 17.9638 4.75218L12.199 2.59005C12.0261 2.5287 11.8593 2.5 11.6963 2.5C11.5334 2.5 11.3666 2.52869 11.1937 2.59002ZM11.7538 21.4967C11.7349 21.4989 11.7158 21.5 11.6963 21.5C11.5604 21.5 11.4282 21.4903 11.301 21.4691C11.1779 21.4486 11.0562 21.418 10.9361 21.378C8.66263 20.6192 6.84861 19.2151 5.50639 17.1751C4.17321 15.1489 3.5 12.9586 3.5 10.6115V6.06761C3.5 5.56368 3.64674 5.09833 3.94425 4.69139C4.23132 4.29873 4.60682 4.00268 5.06752 3.82241L5.07393 3.8199L10.8538 1.64949C11.1292 1.55113 11.4108 1.5 11.6963 1.5C11.9819 1.5 12.2635 1.55119 12.5389 1.64955L12.5463 1.65221L18.3252 3.81968C18.7843 3.99936 19.161 4.29231 19.4496 4.69032C19.7455 5.09853 19.8927 5.56295 19.8927 6.06493V10.6089C19.8927 12.9559 19.2195 15.1462 17.8863 17.1724C16.5443 19.212 14.7306 20.6191 12.4566 21.3753C12.3364 21.4154 12.2147 21.4459 12.0916 21.4664C11.9824 21.4846 11.8695 21.4944 11.7538 21.4967ZM16.6222 7.7633C16.8485 7.92154 16.9037 8.23328 16.7455 8.45959L11.5566 15.8803C11.471 16.0027 11.3352 16.0804 11.1863 16.0922C11.0374 16.104 10.891 16.0486 10.7872 15.9412L7.81681 12.8664C7.62495 12.6678 7.63042 12.3512 7.82902 12.1594C8.02762 11.9675 8.34416 11.973 8.53602 12.1716L11.0848 14.8099L15.926 7.88654C16.0842 7.66024 16.3959 7.60506 16.6222 7.7633Z" />
  </svg>
);
MdTrust.isCustomSvg = true;
