import React, { FC } from "react";
import { Tooltip as AntDTooltip, TooltipProps } from "antd";

import { grayWhite, primaryFont } from "../../styles/constants";

export const Tooltip: FC<TooltipProps> = (props) => (
  <AntDTooltip
    {...props}
    overlayInnerStyle={{
      fontFamily: primaryFont,
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      color: grayWhite,
    }}
  >
    {""}
    {props.children}
  </AntDTooltip>
);
