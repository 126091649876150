import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { AdminTransferContractorResponse } from "../../../api/admin/transfer/contractor";
import { AdminUsersVetsResponse } from "../../../api/admin/users/vets";
import { rooFetch } from "../../../api/common/rooFetch";
import * as UserTypes from "../../../constants/UserTypeConstants";
import * as ActionTypes from "../../../constants/contractorBonus";
import { EXCEPTION_MESSAGE } from "../../../constants/errorMessage";
import { UNSET_LOADER } from "../../../constants/loader";
import { ACTIVE } from "../../../constants/userStatusTypes";
import { GetUserListAction, PayBonusAction } from "../actions/ContractorBonusActions";

export function* getUserListAsync(action: GetUserListAction) {
  try {
    const { userType } = action.payload;
    const isGetVetUsers = userType == UserTypes.VET_USER_TYPE;
    const api = isGetVetUsers ? "api/admin/users/vets/" : "api/admin/users/techs/";

    const searchParams = `?statusType=${ACTIVE}&isShowTestAccounts=true&isFromContractBonus=true`;

    const response: Response = yield call(rooFetch, window.RooConfig.API_URL + api + searchParams);

    yield put({ type: UNSET_LOADER });
    const responseData: AdminUsersVetsResponse = yield response.json();
    const data = responseData.data;

    if (responseData.status_code === 200) {
      yield put({
        type: ActionTypes.ADMIN_GET_USER_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: ActionTypes.ADMIN_GET_USER_FAIL,
        payload: { status: responseData.status_code, message: EXCEPTION_MESSAGE },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting user list" } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: ActionTypes.ADMIN_GET_USER_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

export function* payBonusToContractorAsync(action: PayBonusAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/admin/transfer/contractor",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    yield put({ type: UNSET_LOADER });
    const responseData: AdminTransferContractorResponse = yield response.json();
    const data = responseData.data;
    if (responseData.status_code === 200) {
      yield put({
        type: ActionTypes.ADMIN_PAY_CONTRACTOR_BONUS_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: ActionTypes.ADMIN_PAY_CONTRACTOR_BONUS_FAIL,
        payload: { status: responseData.status_code, message: responseData.data },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while paying bonus to contractor" },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: ActionTypes.ADMIN_PAY_CONTRACTOR_BONUS_FAIL,
      payload: { status: error.status_code, message: error.message },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootContractorBonusSaga() {
  yield takeEvery(ActionTypes.ADMIN_GET_USER_REQUEST, getUserListAsync);
  yield takeEvery(ActionTypes.ADMIN_PAY_CONTRACTOR_BONUS, payBonusToContractorAsync);
}
