import { useQuery } from "@tanstack/react-query";

import {
  NotificationShiftSettingAttribute,
  UserNotificationSettingFromAPI,
} from "../../Common/NotificationSettingsNew/interfaces";
import { APIError, get, rooFetch } from "../common/rooFetch";
import { CACHE } from "../utility/constants";

const baseUrl = `${window.RooConfig.NOTIFICATION_SETTINGS_API_URL}/api/notification`;

export const useNotificationSettings = ({
  userId,
  hospitalId,
}: {
  userId: string;
  hospitalId?: string;
}) =>
  useQuery<UserNotificationSettingFromAPI[], APIError<unknown>>({
    queryFn: () =>
      get(`${baseUrl}/getAllSettings/${userId}${hospitalId ? `?hospitalId=${hospitalId}` : ""}`),
    queryKey: [CACHE.GET_NOTIFICATION_SETTINGS, userId, hospitalId],
    retry: false,
  });

export const useNotificationAttributes = ({
  notificationSettingsId,
}: {
  notificationSettingsId: number;
}) =>
  useQuery<NotificationShiftSettingAttribute[], APIError<unknown>>({
    queryFn: () => get(`${baseUrl}/getNotificationAttributes/${notificationSettingsId}`),
    queryKey: [CACHE.GET_NOTIFICATION_SETTINGS, notificationSettingsId],
    retry: false,
  });

export const updateNotificationSettings = async (request: {
  notificationTypeId: number;
  userId: string;
  hospitalId?: string;
  smsEnabled: number;
  emailEnabled: number;
  pnEnabled: number;
}) =>
  rooFetch(`${baseUrl}/notificationToggles`, {
    method: "PUT",
    body: JSON.stringify(request),
  });

export const bulkUpdateNotifications = async (request: {
  notificationSettingsList: any[];
  userId: string;
  hospitalId: string;
}) =>
  rooFetch(`${baseUrl}/bulkUpdate`, {
    method: "PUT",
    body: JSON.stringify(request),
  });

export const saveShiftPreferences = async (request: {
  shiftPreferenceSettings: any[];
  notificationId: number;
  frequencyId: number;
}) =>
  rooFetch(`${baseUrl}/shiftPreference`, {
    method: "PUT",
    body: JSON.stringify(request),
  });
