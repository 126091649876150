import { RateProps } from "antd";
import React from "react";

import { Color } from "../../styles/constants";
import { ErrorText } from "../Text";

import { RatingStyled } from "./RatingStyles";

export interface RatingProps extends RateProps {
  error?: string;
  starColor?: Color;
  labels?: boolean;
}

export const Rating: React.FC<RatingProps> = (props) => {
  const { error } = props;
  return (
    <>
      <RatingStyled {...props} /> {error && <ErrorText>{error}</ErrorText>}
    </>
  );
};
