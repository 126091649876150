import styled from "styled-components";

import { smallMediaBreakpoint } from "../../styles/constants/mediaBreakpoints";
import { spacingXL } from "../../styles/constants";

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${spacingXL};
  @media (max-width: ${smallMediaBreakpoint}) {
    grid-template-columns: 1fr;
    gap: ${spacingXL};
  }
`;

export const TwoColumnLayout = styled.div`
  display: grid;
  --gap: ${spacingXL};
  gap: var(--gap);
  grid-template-columns: repeat(auto-fit, minmax(max(50% - var(--gap), min(350px, 100%)), 1fr));
`;

export const FiveColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: ${spacingXL};
`;

export const FourColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: ${spacingXL};
`;

export const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${spacingXL};

  @media (max-width: ${smallMediaBreakpoint}) {
    grid-template-columns: 1fr;
    gap: ${spacingXL};
  }
`;
