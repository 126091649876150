import styled from "styled-components";
import {
  mediumMediaBreakpoint,
  smallMediaBreakpoint,
  spacingL,
  spacingM,
  spacingS,
  spacingXS,
} from "@RooUI";

export const DetailsContainer = styled.div`
  margin: ${spacingM} 0;
`;
export const DetailContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacingS};
  margin: ${spacingS} 0;
`;

export const SpecialtyContainer = styled.div`
  display: flex;
  gap: ${spacingS};
  flex-wrap: wrap;
`;

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${smallMediaBreakpoint}) {
    flex-direction: column;
    align-items: baseline;
    gap: ${spacingM};
  }
`;

export const StudentCardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacingS};
`;

export const StudentCardsContainer = styled.div`
  display: grid;
  gap: ${spacingL};
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: ${mediumMediaBreakpoint}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${smallMediaBreakpoint}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StudentDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingXS};
  margin-left: ${spacingS};
`;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingL};
`;

export const SpecialtiesList = styled.ul`
  padding-left: ${spacingL};
`;

export const PanelActionContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${spacingL};
  button,
  div {
    width: 100%;
  }
  > div:last-child {
    width: auto;
    text-align: center;
  }
`;

export const HospitalSelectContainer = styled.div`
  width: 300px;
  @media (max-width: ${smallMediaBreakpoint}) {
    width: 100%;
  }
`;

export const ShowMobile = styled.div`
  display: none;
  @media (max-width: 790px) {
    display: block;
  }
`;

export const HideMobile = styled.div`
  display: block;
  @media (max-width: 790px) {
    display: none;
  }
`;

export const StudentChipContainer = styled.div`
  width: max-content;
`;

export const ExternshipDashboardPage = styled.div`
  scrollbar-gutter: stable both-edges;
`;

export const CTAContainer = styled.div`
  width: max-content;
  align-self: flex-end;
  @media (max-width: ${smallMediaBreakpoint}) {
    width: 100%;
  }
`;

export const EmptyStateCTAContainer = styled.div`
  width: max-content;
  @media (max-width: ${smallMediaBreakpoint}) {
    width: 100%;
  }
`;
