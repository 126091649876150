import styled from "styled-components";

export const BoostInputContainer = styled.div<{ isFullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ isFullWidth }) => (isFullWidth ? `` : `align-items: end;`)}

  gap: 2px;
  .boost-input {
    ${({ isFullWidth }) => (isFullWidth ? `` : `width: 130px;`)}
    input {
      ${({ isFullWidth }) => (isFullWidth ? `` : `text-align: right;`)}
    }
  }
`;

export const PendingEnterpriseStatusAlertContainer = styled.div`
  margin-top: 8px;
  > div {
    @media (max-width: 1200px) {
    }
  }
`;
