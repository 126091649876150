import React from "react";

import { ThreeColumnGrid, TwoColumnGrid } from "../Grid";

import { SingleColumnFormFields } from "./styles";

export interface FormFieldsProps {
  columns?: 1 | 2 | 3;
  children?: React.ReactNode;
}

export const FormFields: React.FC<FormFieldsProps> = ({ columns = 1, children }) => {
  if (columns === 1) {
    return <SingleColumnFormFields>{children}</SingleColumnFormFields>;
  } else if (columns === 2) {
    return <TwoColumnGrid>{children}</TwoColumnGrid>;
  }
  return <ThreeColumnGrid>{children}</ThreeColumnGrid>;
};
