import style from "styled-components";

import { h6Headlines, purpleBase, purpleDark600, purpleLightest } from "../../../styles/constants";

import { TabNestedProps } from ".";

export const TabNested = style.button<TabNestedProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: ${(props) => (props.isSelected ? purpleBase : purpleLightest)};
    height: 40px;
    width: 100%;
    ${h6Headlines}
    color: ${purpleBase};
    cursor: ${(props) => (props.disabled ? "text" : "pointer")};
    &:hover {
        color: ${purpleDark600};
        border-bottom-color: ${(props) => (props.isSelected ? purpleDark600 : purpleLightest)};
    }
    &:focus,
    &:focus-visible {
        outline: none !important;
    }
`;
