import React, { FC, ReactNode } from "react";
import { CarouselProps } from "antd";

import { Icon } from "../Icon";

import { CarouselStyled } from "./CarouselStyled";

export interface CarouselPropTypes extends CarouselProps {
  children: ReactNode;
  slidesToShow?: number;
}
export const Carousel: FC<CarouselPropTypes> = ({ children, slidesToShow = 1, ...props }) => {
  return (
    <CarouselStyled
      arrows
      slidesToShow={slidesToShow}
      {...props}
      prevArrow={<Icon name="KeyboardArrowLeft" size="xl" />}
      nextArrow={<Icon name="KeyboardArrowRight" size="xl" />}
    >
      {children}
    </CarouselStyled>
  );
};
