import style from "styled-components";

import {
  SmallBodyText,
  SmallMediumBodyText,
  grayBase,
  purpleBackground,
  spacingS,
  spacingXS,
} from "../../styles/constants";

interface ChipDivProps {
  type: "clickable" | "label";
  borderColor?: string;
  backgroundColor?: string;
  textColor?: string;
  selected?: boolean;
}

export const ChipStyle = style.div<ChipDivProps>`
  display: inline-flex;
  padding: ${(props) =>
    `${props.type === "clickable" ? `${spacingXS} ${spacingS}` : `0px ${spacingS}`}`};
  justify-content: center;
  align-items: center;
  gap: ${spacingXS};
  border-radius: ${(props) => `${props.type === "clickable" ? "20px" : spacingXS}`};
  background: ${(props) => {
    if (props.selected) {
      return purpleBackground;
    }
    if (props.backgroundColor) {
      return props.backgroundColor;
    }
    return "unset";
  }};
  border: ${(props) =>
    ` ${props.type === "clickable" ? "2px" : "1px"} solid ${
      props.borderColor ? props.borderColor : props.backgroundColor
    }`};
  span {
    ${(props) => (props.type === "clickable" ? SmallMediumBodyText : SmallBodyText)};
    text-align: center;
    color: ${(props) => {
      if (props.textColor) {
        return props.textColor;
      }
      if (props.borderColor) {
        return props.borderColor;
      }
      return grayBase;
    }};
  }
  cursor: ${(props) => (props.type === "clickable" ? "pointer" : "unset")};
`;
