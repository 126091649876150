import { ROO_MAIL } from "./constants";
export const EXCEPTION_MESSAGE = "Something went wrong. Please try again later. ";
export const OFFLINE = "Please check your internet connection and try again. ";
export const LOGIN_UNAPPROVED_MESSAGE =
  "Your application is under review, please check back again later or contact us.";
export const LOGIN_BLOCKED_MESSAGE = `You are currently blocked from using the Roo platform. Please contact ${ROO_MAIL} for support.`;
export const LOGIN_SUSPENDED_MESSAGE = `You are currently suspended from using the Roo platform. Please contact ${ROO_MAIL} for support.`;
export const LOGIN_REJECTED_MESSAGE = `Your account is currently under review, please email ${ROO_MAIL}, call 1-833-Roo-Vets, or LiveChat our website so we can activate your account!`;
export const SHIFT_REQUEST_EXPIRED_MESSAGE = `Your confirmation window is expired, please contact ${ROO_MAIL} if you’d like to still confirm.`;
export const PREVIOUS_REQUEST_EXPIRE_MESSAGE = "Your previous request has expired";
export const TECH_PROBATION_LIMIT =
  "Sorry, you're still on probation! That means you're currently limited to up to five confirmed shifts. Once all five of those shifts are complete and rated by the hospital, you'll be free to request more.";
export const FORGOT_PASSWORD_LIMIT_EXCEEDED =
  "You have exceeded the limit. Please try again after some time";
export const FORGOT_PASSWORD_EMAIL_NOT_FOUND = "An email will be sent shortly";
export const FORGOT_PASSWORD_CODE_EXPIRED =
  "This link is expired. Please click on forgot password link to change the password";
export const ADMIN_SHIFT_PRICE_UPDATE_FAIL = "Please enter valid amount";
export const ADMIN_SHIFT_PRICE_UPDATE_REDUCTION_FAIL = "Please enter valid negative amount";
export const ADMIN_SHIFT_EXPIRED = "This shift is already expired";
export const ADMIN_SMS_SENT_FAIL = "Unable to send message";
export const NOT_CONFIRMED_SHIFT_REQUEST_EXPIRED =
  "This request cannot be confirmed because the shift request is expired.";
export const NOT_CONFIRMED_SHIFT_DELETED =
  "This request cannot be confirmed because the shift was removed.";
export const NOT_CONFIRMED_ALREADY_CONFIRMED =
  "This request cannot be confirmed because the shift already has a confirmed provider.";
export const NOT_CONFIRMED_REQUEST_DELETED = "This request has just been removed.";
export const NOT_CONFIRMED_TECH_REQUEST_DELETED = "This request has just been removed.";
export const NOT_CONFIRMED_PROBATION_LIMIT =
  "This request cannot be confirmed because the tech has already reached the five probation shift limit.";
export const NOT_REQUESTED_PROBATION_LIMIT =
  "Unable to add tech shift request. Tech is at the probation limit.";
export const EXISTING_IS_NOT_HOSPITAL_USER = "Existing user is not a hospital user.";
export const EXISTING_IS_NOT_HOSPITAL_USER_VET =
  "Existing user is not a hospital user. User has a vet account.";
export const EXISTING_IS_NOT_HOSPITAL_USER_TECH =
  "Existing user is not a hospital user. User has a tech account.";
export const EXISTING_IS_NOT_HOSPITAL_USER_STUDENT =
  "Existing user is not a hospital user. User has a student account.";
export const HOSPITAL_USER_EXIST = "The user with same email id already exists";
export const HOSPITAL_USER_EXIST_FOR_NON_ENTERPRISE =
  "Your hospital needs to be part of an enterprise to add users that are already users with another hospital";
export const HOSPITAL_USER_EXIST_FOR_OTHER_ENTERPRISE =
  "This user is already a user in a hospital outside of your enterprise";
export const GROUP_ADMIN_EXIST =
  "This group admin has already been added as a group admin to this group.";
export const GROUP_ADMIN_EXIST_FOR_OTHER_ENTERPRISE =
  "Your hospital needs to be part of an enterprise to add users that are already users with another hospital";
export const CANNOT_DELETE_PRIMARY_CONTACT = "Cannot delete primary contact";

export const TECH_APPROVE_ERROR_MESSAGE = "Please select Tier to approve tech";
export const ADMIN_REFER_PAY_APPROVE_ERROR_MESSAGE = "User bank account is not associated with Roo";
export const VALID_NUMBER_FOR_RATING = "Please enter valid number";
export const DELAY_INVOICE_PDF =
  "Note: You will not be charged for this shift until 14 days prior to the shift";
export const NO_ASSIGNED_HOSPITALS =
  "No hospitals have been enabled for this enterprise. Roo may still be setting up your enterprise configuration. Please reach out to hello@roo.vet if you are still experiencing trouble.";
