import * as Sentry from "@sentry/react";
import { call, put, takeEvery } from "redux-saga/effects";

import { POST } from "@roo-dev/roo-node-types";

import { UploadedHospital } from "../../Admin/Enterprise/components/BulkUpload/types";
import { rooFetch } from "../../api/common/rooFetch";
import * as actionTypes from "../../constants/enterpriseConstants";
import * as errorMessage from "../../constants/errorMessage";
import { UNSET_LOADER } from "../../constants/loader";
import {
  BatchUploadHospitalAction,
  GetFormattedHospitalUploadListAction,
} from "../actions/batchUploadActions";

function* batchUploadHospitals(action: BatchUploadHospitalAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/internal_relief/batchUploadHospitals`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const resData: POST.Enterprise.InternalRelief.BatchUploadHospitalsResponse & {
      uploadedHospitals: UploadedHospital[];
    } = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (response.status === 200) {
      const { uploadedHospitals } = resData;
      yield put({
        type: actionTypes.BATCH_UPLOAD_HOSPITALS_SUCCESS,
        payload: uploadedHospitals?.length,
      });
    } else {
      yield put({
        type: actionTypes.BATCH_UPLOAD_HOSPITALS_FAIL,
        payload: { status: response.status, message: resData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while batch uploading hospitals: " },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.BATCH_UPLOAD_HOSPITALS_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getFormattedHospitalUploadList(action: GetFormattedHospitalUploadListAction) {
  try {
    const response: Response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/enterprise/internal_relief/getFormattedHospitalUploadList`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const resData: POST.Enterprise.InternalRelief.GetFormattedHospitalUploadListResponse =
      yield response.json();
    yield put({ type: UNSET_LOADER });
    if (response.status === 200) {
      yield put({
        type: actionTypes.GET_FORMATTED_HOSPITAL_UPLOAD_LIST_SUCCESS,
        payload: resData.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_FORMATTED_HOSPITAL_UPLOAD_LIST_FAIL,
        payload: { status: response.status, message: resData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting formatted hospital upload list: " },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.GET_FORMATTED_HOSPITAL_UPLOAD_LIST_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootInternalReliefSaga() {
  yield takeEvery(actionTypes.BATCH_UPLOAD_HOSPITALS_REQUEST, batchUploadHospitals);
  yield takeEvery(actionTypes.GET_FORMATTED_HOSPITAL_UPLOAD_LIST, getFormattedHospitalUploadList);
}
