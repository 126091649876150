import * as Sentry from "@sentry/react";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";

import { SET_TOAST_DATA } from "../../Common/Toasts/constants";
import { checkDST } from "../../Common/dateUtils";
import { setDefaultShiftDetails } from "../../ProfilePanels/actions/actions";
import { get, post, rooFetch } from "../../api/common/rooFetch";
import * as UserTypes from "../../constants/UserTypeConstants";
import { GET_ENTERPRISE_INVOICED_CUSTOMER_SUCCESS } from "../../constants/enterpriseConstants";
import * as errorMessage from "../../constants/errorMessage";
import * as actionTypes from "../../constants/hospitalConstants";
import { SET_LOADER, UNSET_LOADER } from "../../constants/loader";

// --- SELECTORS
export const getHospitalId = (state) => state.login.hospitalId;
export const getIsShiftPostsExpire = (state) => state.login.isShiftPostsExpire;
export const getCurrentCalendarDate = (state) => state.hospital.currentCalendarDate;
const getProfilePanelData = (state) => state.hospital.profilePanelData;
const getIsFetchignVetReviews = (state) => state.hospital.isFetchingVetReviews;
const getIsFetchingTechReviews = (state) => state.hospital.isFetchingTechReviews;
const getIsPendingVetReviews = (state) => state.hospital.isPendingVetRatings;
const getIsPendingTechReviews = (state) => state.hospital.isPendingTechRatings;

function* fetchCommonDataAsync(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/utility/loadCommonData", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.FETCH_ADD_SHIFT_COMMON_DATA_SUCCESS,
        payload: {
          commonData: data.data,
        },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.FETCH_ADD_SHIFT_COMMON_DATA_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while fetching common data: " } });
    yield put({
      type: actionTypes.FETCH_ADD_SHIFT_COMMON_DATA_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* calculateBulkShiftsPricing(action) {
  try {
    const { isConcat } = action.payload;
    delete action.payload.isConcat;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/shift/calculateBulkShiftsPricing",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    const resData = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (resData.status_code === 200) {
      yield put({
        type: actionTypes.CALCULATE_BULK_SHIFT_PRICING_SUCCESS,
        payload: { pricing: resData.data, isConcat },
      });
    } else if (resData.status_code === 400) {
      yield put({
        type: actionTypes.CALCULATE_BULK_SHIFT_PRICING_FAIL,
        payload: { status: resData.status_code, message: resData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while calculating bulk shifts pricing: " },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.CALCULATE_BULK_SHIFT_PRICING_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getHolidays(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/utility/getHolidaysWithinDateRange",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    const resData = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (resData.status_code === 200) {
      yield put({
        type: actionTypes.FETCH_HOLIDAYS_SUCCESS,
        payload: { holidayList: resData.data },
      });
    } else {
      yield put({
        type: actionTypes.FETCH_HOLIDAYS_FAIL,
        payload: { holidayList: [] },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while fetching holidays: " } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.FETCH_HOLIDAYS_FAIL,
      payload: { holidayList: [] },
    });
  }
}

// get vet shifts for selected calendar month AND all shifts with shiftDate > a month ago
export function* getVetShiftsAsync(action) {
  try {
    const { hospitalId } = action.payload;
    let year;
    let month;
    if (action.payload.year) {
      year = action.payload.year;
      month = action.payload.month;
    } else {
      const currentCalendarDate = yield select(getCurrentCalendarDate);
      year = currentCalendarDate.format("YYYY");
      month = currentCalendarDate.format("MM");
    }

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        "api/hospital/shift/getShiftsByHospitalOrContractorId?year=" +
        year +
        "&month=" +
        month +
        "&hospitalId=" +
        hospitalId
    );

    const reponseData = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (reponseData.status_code === 200) {
      const sLocalIsShiftPostsExpire = localStorage.getItem("isShiftPostsExpire");
      let isShiftPostsExpire;
      if (sLocalIsShiftPostsExpire) {
        isShiftPostsExpire = parseInt(sLocalIsShiftPostsExpire);
      } else {
        isShiftPostsExpire = yield select(getIsShiftPostsExpire);
      }
      yield put({
        type: actionTypes.GET_VET_SHIFTS_SUCCESS,
        payload: { oShiftsDB: reponseData.data, isShiftPostsExpire: parseInt(isShiftPostsExpire) },
        currentCalendarDate: action.payload.currentCalendarDate,
      });
    } else {
      yield put({
        type: actionTypes.GET_VET_SHIFTS_FAIL,
        payload: { status: reponseData.status_code, message: reponseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting vet shifts: " } });
  }
}

function* fetchPreviousShiftsAsync() {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        "api/hospital/shift/getShiftTemplates/" +
        localStorage.getItem("hospitalId")
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({
        type: actionTypes.FETCH_PREVIOUS_SHIFT_SUCCESS,
        payload: { previousShifts: data.data },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.FETCH_PREVIOUS_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching previous shifts: " },
    });
    yield put({
      type: actionTypes.FETCH_PREVIOUS_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* createHospitalInvoicedCustomerAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/createInvoicedCustomer",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({ type: actionTypes.CREATE_HOSPITAL_INVOICED_CUSTOMER_SUCCESS });
      // TODO: update to get hospital invoiced customer success actions
      yield put({
        type: GET_ENTERPRISE_INVOICED_CUSTOMER_SUCCESS,
        payload: data.data,
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.CREATE_HOSPITAL_INVOICED_CUSTOMER_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while creating hospital invoiced customer: " },
    });
    yield put({
      type: actionTypes.CREATE_HOSPITAL_INVOICED_CUSTOMER_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* addShiftAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/shift/addShift",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: actionTypes.FETCH_SHIFT_COUNT_NOTIFICATION_REQUEST });
      yield put({ type: actionTypes.NOTIFICATION_MODAL_OPEN });
      yield put({
        type: actionTypes.ADD_SHIFT_SUCCESS,
        payload: { status: data.status_code, shiftGroupId: data?.data?.id },
      });
    } else if (data.status_code === 412) {
      yield put({
        type: actionTypes.ADD_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    } else if (data.status_code === 500) {
      yield put({
        type: actionTypes.ADD_SHIFT_FAIL,
        payload: { status: data.status_code },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while adding shift: " } });
    yield put({
      type: actionTypes.ADD_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getShiftFullfillmentPredictionAsync(action) {
  try {
    yield put({ type: SET_LOADER });

    const responseData = yield post(
      window.RooConfig.SFP_API_BASE_URL + "/getShiftFulfillmentPrediction",
      action.payload,
      false,
      {
        "x-api-access-key": window.RooConfig.SFP_API_KEY,
      }
    );

    yield put({ type: UNSET_LOADER });

    if (responseData) {
      yield put({
        type: actionTypes.GET_SHIFT_FULLFILLMENT_PREDICTION_SUCCESS,
        payload: responseData,
      });
    } else {
      yield put({
        type: actionTypes.GET_SHIFT_FULLFILLMENT_PREDICTION_FAIL,
        payload: { status: 500, message: "Failed to get shift fulfillment prediction" },
      });
    }
  } catch (error) {
    yield put({ type: UNSET_LOADER });

    Sentry.captureException(error, {
      extra: { context: "Error while getting shift fullfillment prediction: " },
    });

    yield put({
      type: actionTypes.GET_SHIFT_FULLFILLMENT_PREDICTION_FAIL,
      payload: { status: error.status, message: error.message },
    });
  }
}

export function* getPendingHospitalVetRatingsAsync(action) {
  try {
    const { isGetAllReviews, hospitalId } = action?.payload || {};

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/hospital/shift/getPendingReviewNotification?hospitalId=${hospitalId}&isGetAllReviews=${isGetAllReviews}`
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      if (data.data === undefined) {
        if (isGetAllReviews) {
          const isFetchingTechReviews = yield select(getIsFetchingTechReviews);
          const isPendingTechRatings = yield select(getIsPendingTechReviews);
          if (!isFetchingTechReviews && !isPendingTechRatings) {
            yield put({
              type: actionTypes.UPDATE_HOSPITAL_PENDING_REVIEWS_FLAG,
              payload: { isShowPendingReviews: 0, hospitalIds: hospitalId },
            });
          }
        }
        yield put({
          type: actionTypes.GET_HOSPITAL_PENDING_VET_RATING_FAIL,
          payload: { status: data.status_code },
        });
      } else {
        yield put({
          type: actionTypes.GET_HOSPITAL_PENDING_VET_RATING_SUCCESS,
          payload: {
            status: data.status_code,
            message: data.message,
            defaultHospVetRatingDetails: data.data.data["0"],
            defaultRatingTotalCount: data.data.totalCount,
          },
        });
      }
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.GET_HOSPITAL_PENDING_VET_RATING_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting pending hospital vet ratings: " },
    });
    yield put({
      type: actionTypes.GET_HOSPITAL_PENDING_VET_RATING_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* confirmVetRequestAsync(action) {
  try {
    if (action.data.requestedShiftStartTime) {
      action.data.isDST = checkDST(action.data.shiftDate);
    }
    const oConfirm = {
      ...action.data,
      userId: localStorage.getItem("userId"),
    };
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/shift/confirmShift",
      {
        method: "POST",
        body: JSON.stringify(oConfirm),
      }
    );

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.MOBILE_CONFIRM_VET_SUCCESS,
        payload: { status: data.status_code, date: data.data },
      });
      if (data?.data?.data?.hasPaymentFailed) {
        yield put({
          type: actionTypes.UPDATE_HAS_PAYMENT_FAILED_FLAG,
          payload: { hasPaymentFailed: 1 },
        });
      }
    } else {
      yield put({
        type: actionTypes.MOBILE_CONFIRM_VET_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while confirming vet request: " } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.MOBILE_CONFIRM_VET_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getShiftDetailsAsync(action) {
  try {
    const shiftQuery = action.shiftId
      ? `shiftId=${action.shiftId}`
      : `shiftGroupId=${action.shiftGroupId}`;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/shift/getShiftDetailsById?" + shiftQuery
    );

    const responseData = yield response.json();
    const data = [...responseData.data.data];

    if (responseData.status_code === 200) {
      yield put({ type: UNSET_LOADER });
      if (action.payload.mockVetUserId && action.payload.mockHospitalId) {
        data[0] = {
          ...data[0],
          vetUserId: action.payload.mockVetUserId,
          hospitalId: action.payload.mockHospitalId,
          isChatViewOnly: true,
        };
        yield put({
          type: actionTypes.FETCH_SHIFT_DETAILS_SUCCESS,
          payload: {
            data,
          },
        });
      } else {
        yield put({ type: actionTypes.FETCH_SHIFT_DETAILS_SUCCESS, payload: data });
      }
    } else {
      yield put({
        type: actionTypes.FETCH_SHIFT_DETAILS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting shift details: " } });
  }
}

function* editShiftDetailsAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/shift/updateShift",
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.EDIT_SHIFT_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: actionTypes.EDIT_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while editing shift details: " } });
    yield put({
      type: actionTypes.EDIT_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* fetchShiftCountForNotification() {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/user/shiftCount?hospitalId=${localStorage.getItem("hospitalId")}`
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.FETCH_SHIFT_COUNT_NOTIFICATION_SUCCESS,
        payload: {
          status: data.status_code,
          shiftCount: data.data.result[0].shiftCount,
        },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.FETCH_SHIFT_COUNT_NOTIFICATION_FAIL,
        payload: { status: data.data.status_code, message: data.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching shift count for notification: " },
    });
    yield put({
      type: actionTypes.FETCH_SHIFT_COUNT_NOTIFICATION_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// --- tech related

function* fetchPreviousTechShiftsAsync() {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        "api/hospital/tech_shift/getShiftTemplates/" +
        localStorage.getItem("hospitalId")
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.FETCH_PREVIOUS_TECH_SHIFT_SUCCESS,
        payload: { previousTechShifts: data.data },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.FETCH_PREVIOUS_TECH_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching previous tech shifts: " },
    });
    yield put({
      type: actionTypes.FETCH_PREVIOUS_TECH_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* addTechShiftAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/tech_shift/addShift",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: actionTypes.FETCH_TECH_SHIFT_COUNT_NOTIFICATION_REQUEST });
      yield put({ type: actionTypes.NOTIFICATION_MODAL_OPEN });
      yield put({
        type: actionTypes.FETCH_ADD_TECH_SHIFT_SUCCESS,
        payload: { status: data.status_code, shiftGroupId: data?.data?.id },
      });
    } else if (data.status_code === 412) {
      yield put({
        type: actionTypes.FETCH_ADD_TECH_SHIFT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    } else if (data.status_code === 500) {
      yield put({
        type: actionTypes.FETCH_ADD_TECH_SHIFT_FAIL,
        payload: { status: data.status_code },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while adding tech shift: " } });
    yield put({
      type: actionTypes.FETCH_ADD_TECH_SHIFT_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getPendingHospitalTechRatingsAsync(action) {
  try {
    const isCheckBlockedTechCount = action?.payload?.isCheckBlockedTechCount || false;
    const { isGetAllReviews } = action?.payload || {};
    const hospitalId = localStorage.getItem("hospitalId");

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/hospital/tech_shift/getPendingReviewNotification?hospitalId=${hospitalId}&isCheckBlockedTechCount=${isCheckBlockedTechCount}&isGetAllReviews=${isGetAllReviews}`
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      if (data.data === undefined) {
        if (isGetAllReviews) {
          const isFetchingVetReviews = yield select(getIsFetchignVetReviews);
          const isPendingVetRatings = yield select(getIsPendingVetReviews);
          if (!isFetchingVetReviews && !isPendingVetRatings) {
            yield put({
              type: actionTypes.UPDATE_HOSPITAL_PENDING_REVIEWS_FLAG,
              payload: { isShowPendingReviews: 0, hospitalIds: hospitalId },
            });
          }
        }
        yield put({
          type: actionTypes.GET_HOSPITAL_PENDING_TECH_RATING_FAIL,
          payload: { status: data.status_code },
        });
      } else {
        if (isCheckBlockedTechCount) {
          yield put({
            type: actionTypes.SET_BLOCKED_CONTRACTOR_COUNT,
            payload: {
              blockedTechCount: data.data.blockedTechCount,
            },
          });
        }
        yield put({
          type: actionTypes.GET_HOSPITAL_PENDING_TECH_RATING_SUCCESS,
          payload: {
            status: data.status_code,
            message: data.message,
            defaultHospTechRatingDetails: data.data.data["0"],
            defaultRatingTotalCount: data.data.totalCount,
          },
        });
      }
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.GET_HOSPITAL_PENDING_TECH_RATING_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting pending hospital tech ratings: " },
    });
    yield put({
      type: actionTypes.GET_HOSPITAL_PENDING_TECH_RATING_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getTechShiftsAsync(action) {
  try {
    const { hospitalId } = action.payload;
    let year;
    let month;

    if (action.payload.year) {
      year = action.payload.year;
      month = action.payload.month;
    } else {
      const currentCalendarDate = yield select(getCurrentCalendarDate);
      year = currentCalendarDate.format("YYYY");
      month = currentCalendarDate.format("MM");
    }

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        "api/hospital/tech_shift/getShiftsByHospitalOrContractorId?year=" +
        year +
        "&month=" +
        month +
        "&hospitalId=" +
        hospitalId
    );

    const responseData = yield response.json();

    if (responseData.status_code === 200) {
      yield put({ type: UNSET_LOADER });
      yield put({
        type: actionTypes.GET_TECH_SHIFTS_SUCCESS,
        payload: responseData.data,
        currentCalendarDate: action.payload.currentCalendarDate,
      });
    } else {
      yield put({ type: UNSET_LOADER });
      yield put({
        type: actionTypes.GET_TECH_SHIFTS_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while getting tech shifts: " } });
  }
}

export function* confirmTechRequestAsync(action) {
  try {
    const oConfirm = {
      ...action.data,
      userId: localStorage.getItem("userId"),
    };
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/tech_shift/confirmShift",
      {
        method: "POST",
        body: JSON.stringify(oConfirm),
      }
    );

    const data = yield response.json();
    if (data.status_code === 200) {
      yield put({ type: UNSET_LOADER });
      yield put({
        type: actionTypes.HOSPITAL_CONFIRM_TECH_SUCCESS,
        payload: { status: data.status_code, date: data.data },
      });
      if (data?.data?.data?.hasPaymentFailed) {
        yield put({
          type: actionTypes.UPDATE_HAS_PAYMENT_FAILED_FLAG,
          payload: { hasPaymentFailed: 1 },
        });
      }
    } else {
      yield put({ type: UNSET_LOADER });
      yield put({
        type: actionTypes.HOSPITAL_CONFIRM_TECH_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while confirming tech request: " } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.HOSPITAL_CONFIRM_TECH_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* getTechShiftDetailsAsync(action) {
  try {
    const { shiftGroupId, getTechRegistrationFlow, isFromPanel } = action.payload;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/hospital/tech_shift/getShiftDetailsById?getTechRegistrationFlow=${getTechRegistrationFlow}&shiftGroupId=${shiftGroupId}`
    );

    const responseData = yield response.json();
    const data = [...responseData.data.data];

    if (responseData.status_code === 200) {
      yield put({ type: UNSET_LOADER });
      if (isFromPanel) {
        yield put(setDefaultShiftDetails(data[0]));
      } else {
        yield put({ type: actionTypes.FETCH_TECH_SHIFT_DETAILS_FOR_EDIT_SUCCESS, payload: data });
      }
    } else {
      yield put({
        type: actionTypes.FETCH_TECH_SHIFT_DETAILS_FOR_EDIT_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting tech shift details: " },
    });
  }
}

function* editTechShiftDetailsAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/tech_shift/updateShift",
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (data.status_code === 200) {
      yield put({
        type: actionTypes.EDIT_TECH_SHIFT_REQUEST_SUCCESS,
        payload: { status: data.status_code },
      });
    } else {
      yield put({
        type: actionTypes.EDIT_TECH_SHIFT_REQUEST_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while editing tech shift details: " },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.EDIT_TECH_SHIFT_REQUEST_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* fetchTechShiftCountForNotification() {
  try {
    let hospitalId = yield select(getHospitalId);

    if (!hospitalId) {
      hospitalId = localStorage.getItem("hospitalId");
    }

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/user/techShiftCount?hospitalId=${hospitalId}`
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.FETCH_TECH_SHIFT_COUNT_NOTIFICATION_SUCCESS,
        payload: { status: data.status_code, shiftCount: data.data.result[0].shiftCount },
      });
    } else if (data.status_code === 400) {
      yield put({
        type: actionTypes.FETCH_TECH_SHIFT_COUNT_NOTIFICATION_FAIL,
        payload: { status: data.data.status_code, message: data.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while fetching tech shift count for notification: " },
    });
    yield put({
      type: actionTypes.FETCH_TECH_SHIFT_COUNT_NOTIFICATION_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

// --- end tech related

function* downloadPDFAsync(action) {
  let errors = false;
  let delayPDF = false;

  try {
    const params = new URLSearchParams(
      Object.entries({
        shiftGroupId: action.payload.shiftGroupId,
        isAdditionalPay: action.payload.isAdditionalPay,
        additionalPayType: action.payload.additionalPayType,
        transactionId: action.payload.transactionId,
      }).reduce(
        /** @param {Record<string, string>} acc */
        (acc, [key, value]) => {
          if (value !== undefined) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      )
    );

    const url = `api/${
      action.payload.userTypeId === UserTypes.TECH_USER_TYPE ? "tech" : "vet"
    }/shift/invoicePDF?${params.toString()}`;

    const response = yield rooFetch(url, {
      headers: { accept: "application/pdf" },
    });

    // console.log("invoicePDF", response);
    const blob = yield response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", `Roo_Invoice#${action.payload.shiftGroupId}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    errors = false;
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while downloading pdf: " } });
    delayPDF = error.status === 412;
    errors = true;
  }

  if (errors === false) {
    yield put({ type: actionTypes.DOWNLOAD_PDF_SUCCESS, payload: { status: 200 } });
  } else if (errors === true) {
    yield put({
      type: actionTypes.DOWNLOAD_PDF_FAIL,
      payload: { status: 500, delayPDF: delayPDF, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getAutoConfirmContractorListAsync(action) {
  try {
    const { hospitalId } = action.payload;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/hospital/getAutoConfirmContractorList/${hospitalId}`
    );

    const responseData = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.GET_AUTO_CONFIRM_CONTRACTOR_LIST_SUCCESS,
        payload: { ...responseData.data },
      });
    } else {
      yield put({
        type: actionTypes.GET_AUTO_CONFIRM_CONTRACTOR_LIST_FAIL,
        payload: { status_code: responseData.status_code, message: responseData.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting auto confirm contractor list: " },
    });
    yield put({
      type: actionTypes.GET_AUTO_CONFIRM_CONTRACTOR_LIST_FAIL,
      payload: { status_code: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* addProviderToAutoConfirm(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/shiftAutoConfirm/", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });
    yield put({ type: UNSET_LOADER });
    if (response.status === 200) {
      yield put({
        type: SET_TOAST_DATA,
        payload: {
          variant: "success",
          message: "Roo-hoo!",
          description: `${action.payload?.vetId ? "Vet" : "Tech"} was added to auto-confirm.`,
        },
      });
      yield put({
        type: actionTypes.GET_AUTO_CONFIRM_CONTRACTOR_LIST,
        payload: { hospitalId: action.payload.hospitalId },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while adding provider to auto confirm: " },
    });
    yield put({
      type: SET_TOAST_DATA,
      payload: {
        variant: "error",
        message: "Error",
        description: `Unable to add ${action.payload?.vetId ? "vet" : "tech"} to auto-confirm.`,
      },
    });
  }
}

function* saveFutureAutoConfirmAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/saveFutureAutoConfirm",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.SAVE_FUTURE_AUTO_CONFIRM_SUCCESS,
        payload: { status_code: data.status_code },
      });
      yield put({
        type: actionTypes.CLOSE_FUTURE_AUTO_CONFIRM,
      });
    } else {
      yield put({
        type: actionTypes.SAVE_FUTURE_AUTO_CONFIRM_FAIL,
        payload: { status_code: data.status_code, message: data.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while saving future auto confirm: " },
    });
    yield put({
      type: actionTypes.SAVE_FUTURE_AUTO_CONFIRM_FAIL,
      payload: { status_code: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* deleteFutureAutoConfirmAsync(action) {
  const { isRecommended, ...VetOrTechPayload } = action.payload;
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/deleteFutureAutoConfirm",
      {
        method: "POST",
        body: JSON.stringify(VetOrTechPayload),
      }
    );

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      const { vetId, techId } = action.payload;

      yield put({
        type: actionTypes.DELETE_FUTURE_AUTO_CONFIRM_SUCCESS,
        payload: { vetId, techId, isRecommended },
      });
      yield put({
        type: SET_TOAST_DATA,
        payload: {
          variant: "success",
          message: "Roo-hoo!",
          description: `${action.payload?.vetId ? "Vet" : "Tech"} was removed from auto-confirm.`,
        },
      });
    } else {
      yield put({
        type: actionTypes.DELETE_FUTURE_AUTO_CONFIRM_FAIL,
        payload: { status_code: data.status_code, message: data.data.message },
      });
      yield put({
        type: SET_TOAST_DATA,
        payload: {
          variant: "error",
          message: "Error",
          description: `Error removing ${
            action.payload?.vetId ? "vet" : "tech"
          } from auto-confirm.`,
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while deleting future auto confirm: " },
    });
    yield put({
      type: actionTypes.DELETE_FUTURE_AUTO_CONFIRM_FAIL,
      payload: { status_code: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
    yield put({
      type: SET_TOAST_DATA,
      payload: {
        variant: "error",
        message: "Error",
        description: `Error removing ${action.payload?.vetId ? "vet" : "tech"} from auto-confirm.`,
      },
    });
  }
}

function* disableFutureAutoConfirmAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/disableFutureAutoConfirm",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      const { vetId, techId } = action.payload;
      yield put({
        type: actionTypes.DISABLE_FUTURE_AUTO_CONFIRM_SUCCESS,
        payload: { vetId, techId },
      });
    } else {
      yield put({
        type: actionTypes.DISABLE_FUTURE_AUTO_CONFIRM_FAIL,
        payload: { status_code: data.status_code, message: data.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while disabling future auto confirm: " },
    });
    yield put({
      type: actionTypes.DISABLE_FUTURE_AUTO_CONFIRM_FAIL,
      payload: { status_code: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* enableFutureAutoConfirmAsync(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/enableFutureAutoConfirm",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      const { vetId, techId, isRecommended } = action.payload;
      yield put({
        type: actionTypes.ENABLE_FUTURE_AUTO_CONFIRM_SUCCESS,
        payload: { vetId, techId, isRecommended },
      });
    } else {
      yield put({
        type: actionTypes.ENABLE_FUTURE_AUTO_CONFIRM_FAIL,
        payload: { status_code: data.status_code, message: data.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while enabling future auto confirm: " },
    });
    yield put({
      type: actionTypes.ENABLE_FUTURE_AUTO_CONFIRM_FAIL,
      payload: { status_code: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* getBlockedContractorListAsync(action) {
  try {
    const { isVet, isTech, hospitalId } = action.payload;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/hospital/getBlockedContractorList/${hospitalId}?isVet=${isVet}&isTech=${isTech}`
    );

    const responseData = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.GET_BLOCKED_CONTRACTOR_LIST_SUCCESS,
        payload: {
          isVet,
          isTech,
          ...responseData.data,
        },
      });
    } else {
      yield put({
        type: actionTypes.GET_BLOCKED_CONTRACTOR_LIST_FAIL,
        payload: { status_code: responseData.status_code, message: responseData.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting blocked contractor list: " },
    });
    yield put({
      type: actionTypes.GET_BLOCKED_CONTRACTOR_LIST_FAIL,
      payload: { status_code: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* removeBlockedContractorAsync(action) {
  try {
    let data;
    if (!action.payload.isNewlyAdded) {
      const body = { ...action.payload };
      delete body.isNewlyAdded;
      const response = yield call(
        rooFetch,
        window.RooConfig.API_URL + "api/hospital/removeBlockedContractor",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );

      data = yield response.json();
    }
    yield put({ type: UNSET_LOADER });

    if (action.payload.isNewlyAdded || data.status_code === 200) {
      const { vetId, techId } = action.payload;
      yield put({
        type: actionTypes.REMOVE_BLOCKED_CONTRACTOR_SUCCESS,
        payload: { vetId, techId },
      });
    } else {
      yield put({
        type: actionTypes.REMOVE_BLOCKED_CONTRACTOR_FAIL,
        payload: { status_code: data.status_code, message: data.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while removing blocked contractor: " },
    });
    yield put({
      type: actionTypes.REMOVE_BLOCKED_CONTRACTOR_FAIL,
      payload: { status_code: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

function* blockContractorAsync(action) {
  try {
    yield put({ type: SET_LOADER });
    let data;
    if (!action.payload.isNewlyAdded) {
      const body = { ...action.payload };
      delete body.isNewlyAdded;
      delete body.fullName;
      delete body.profileImage;
      const response = yield call(
        rooFetch,
        window.RooConfig.API_URL + "api/hospital/blockContractor",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      data = yield response.json();
    }

    yield put({ type: UNSET_LOADER });

    if (action.payload.isNewlyAdded || data.status_code === 200) {
      yield put({
        type: actionTypes.BLOCK_CONTRACTOR_SUCCESS,
        payload: { data: action.payload },
      });
    } else {
      yield put({
        type: actionTypes.BLOCK_CONTRACTOR_FAIL,
        payload: { status: data.status_code, message: errorMessage.EXCEPTION_MESSAGE },
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while blocking contractor: " } });
    yield put({ type: UNSET_LOADER });

    yield put({
      type: actionTypes.BLOCK_CONTRACTOR_FAIL,
      payload: { status: error.status_code, message: errorMessage.EXCEPTION_MESSAGE },
    });
  }
}

export function* removeConfirmedTech(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/tech/shift/removeTech", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });
    const responseData = yield response.json();

    yield put({ type: UNSET_LOADER });
    if (responseData.status_code === 200) {
      yield put({ type: actionTypes.HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_SUCCESS });
    } else {
      yield put({
        type: actionTypes.HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_FAIL,
        payload: errorMessage.EXCEPTION_MESSAGE,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while removing confirmed tech: " } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_FAIL,
      payload: errorMessage.EXCEPTION_MESSAGE,
    });
  }
}

export function* submitOvertimeResponse(action) {
  try {
    yield put({ type: SET_LOADER });
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/submitovertime",
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    const responseData = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.SUBMIT_OVERTIME_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: actionTypes.SUBMIT_OVERTIME_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while submitting overtime response: " },
    });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.SUBMIT_OVERTIME_FAIL,
    });
  }
}

export function* boostShift(action) {
  try {
    const response = yield call(rooFetch, window.RooConfig.API_URL + "api/hospital/boostShift", {
      method: "POST",
      body: JSON.stringify(action.payload),
    });
    const responseData = yield response.json();

    yield put({ type: UNSET_LOADER });
    if (responseData.status_code === 200) {
      yield put({ type: actionTypes.BOOST_SHIFT_SUCCESS, payload: action.payload });
    } else {
      yield put({
        type: actionTypes.BOOST_SHIFT_FAIL,
        payload: errorMessage.EXCEPTION_MESSAGE,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while boosting shift: " } });
    yield put({ type: UNSET_LOADER });
    yield put({
      type: actionTypes.BOOST_SHIFT_FAIL,
      payload: errorMessage.EXCEPTION_MESSAGE,
    });
  }
}

function* getHospitalResponseRatingDataAsync(action) {
  try {
    const hospitalId = action.payload;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/hospital/hospitalResponseRatingData/${hospitalId}`
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      const responseRatingData = data.data;
      yield put({
        type: actionTypes.HOSPITAL_RESPONSE_RATING_DATA_SUCCESS,
        payload: responseRatingData,
      });
    } else {
      yield put({
        type: actionTypes.HOSPITAL_RESPONSE_RATING_DATA_FAIL,
        payload: { status_code: data.status_code, message: data.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting hospital response rating data: " },
    });
    yield put({
      type: UNSET_LOADER,
    });
  }
}

function* getHospitalRatingCommentsAsync(action) {
  try {
    const hospitalId = action.payload ? action.payload : localStorage.getItem("hospitalId");

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/hospital/hospitalRatingComments/${hospitalId}`
    );

    const data = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.HOSPITAL_RESPONSE_RATING_COMMENTS_SUCCESS,
        payload: {
          hospitalRatingComments: data.data.hospitalRatingComments,
        },
      });
    } else {
      yield put({
        type: actionTypes.HOSPITAL_RESPONSE_RATING_COMMENTS_FAIL,
        payload: { status_code: data.status_code, message: data.data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting hospital rating comments: " },
    });
    yield put({
      type: UNSET_LOADER,
    });
  }
}

function* getHospitalResponseRating(action) {
  try {
    const hospitalId = action.payload ? action.payload : localStorage.getItem("hospitalId");

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/hospital/getHospitalResponseRating?hospitalId=${hospitalId}`
    );

    const responseData = yield response.json();

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.HOSPITAL_RESPONSE_RATING_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: actionTypes.HOSPITAL_RESPONSE_RATING_FAIL,
        payload: { status: responseData.status_code, message: responseData.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting hospital response rating: " },
    });
    yield put({
      type: UNSET_LOADER,
    });
  }
}

function* updateHospitalResponseRatingStatus(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/hospital/updateHospitalResponseRatingStatus`,
      {
        method: "PUT",
        body: JSON.stringify(action.payload),
      }
    );

    const data = yield response.json();

    if (data.status_code === 200) {
      yield put({
        type: actionTypes.UPDATE_HOSPITAL_RESPONSE_RATING_STATUS_SUCCESS,
        payload: { data: action.payload.responseRatingStatus },
      });
    } else {
      yield put({
        type: actionTypes.UPDATE_HOSPITAL_RESPONSE_RATING_STATUS_FAIL,
        payload: { status: data.status_code, message: data.message },
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while updating hospital response rating status: " },
    });
    yield put({
      type: UNSET_LOADER,
    });
  }
}

function* getThreeMonthsHospitalShifts(action) {
  try {
    const startDate = action.payload.startDate ? action.payload.startDate : "";

    const { endDate, userType, isGetCompletedOnly, daysOfWeek, providerName, hospitalId } =
      action.payload;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/hospital/getThreeMonthsHospitalShifts?hospitalId=${hospitalId}&userType=${userType}&startDate=${startDate}&endDate=${endDate}&isGetCompletedOnly=${isGetCompletedOnly}${
          daysOfWeek && daysOfWeek.length ? `&daysOfWeek=${daysOfWeek}` : ""
        }${providerName ? `&providerName=${providerName}` : ""}`
    );

    const responseData = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.GET_THREE_MONTHS_HOSPITAL_SHIFTS_SUCCESS,
        payload: { ...responseData.data, isGetCompletedOnly },
      });
    } else {
      yield put({
        type: actionTypes.GET_THREE_MONTHS_HOSPITAL_SHIFTS_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "Error while getting three months hospital shifts: " },
    });
    yield put({ type: UNSET_LOADER });
  }
}

function* bulkDeleteShifts(action) {
  try {
    const apiEndpoint = action.payload.isVetShifts
      ? "api/hospital/shift/bulkDeleteShifts"
      : "api/hospital/tech_shift/bulkDeleteShifts";
    action.payload.userId = parseInt(localStorage.getItem("userId"));

    const response = yield call(rooFetch, window.RooConfig.API_URL + apiEndpoint, {
      method: "PUT",
      body: JSON.stringify(action.payload),
    });

    const responseData = yield response.json();

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.BULK_DELETE_SHIFTS_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: actionTypes.BULK_DELETE_SHIFTS_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "Error while bulk deleting shifts: " } });
    yield put({ type: UNSET_LOADER });
  }
}

function* boostAllShifts(action) {
  try {
    const isFromDashboard = action.payload.isFromDashboard;
    const userId = action.payload.userId ? action.payload.userId : localStorage.getItem("userId");
    const payload = {
      shifts: action.payload.shifts,
      userId: userId,
    };
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/shift/boostMultipleShifts",
      {
        method: "PUT",
        body: JSON.stringify(payload),
      }
    );
    const responseData = yield response.json();
    yield put({ type: UNSET_LOADER });
    if (responseData.status_code === 200) {
      if (isFromDashboard) {
        yield put({
          type: actionTypes.BOOST_ALL_SHIFTS_SUCCESS_NO_MODAL,
          payload: responseData.data,
        });
      } else
        yield put({
          type: actionTypes.BOOST_ALL_SHIFTS_SUCCESS,
          payload: responseData.data,
        });
    } else {
      yield put({
        type: actionTypes.BOOST_ALL_SHIFTS_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "boostAllShifts :: error" } });
    yield put({ type: UNSET_LOADER });
  }
}

function* boostAllTechShifts(action) {
  try {
    const isFromDashboard = action.payload.isFromDashboard;
    const userId = action.payload.userId ? action.payload.userId : localStorage.getItem("userId");
    const payload = {
      shifts: action.payload.shifts,
      userId: userId,
    };

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + "api/hospital/tech_shift/boostMultipleShifts",
      {
        method: "PUT",
        body: JSON.stringify(payload),
      }
    );
    const responseData = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (responseData.status_code === 200) {
      if (isFromDashboard) {
        yield put({
          type: actionTypes.BOOST_ALL_TECH_SHIFTS_SUCCESS_NO_MODAL,
          payload: responseData.data,
        });
      } else
        yield put({
          type: actionTypes.BOOST_ALL_TECH_SHIFTS_SUCCESS,
          payload: responseData.data,
        });
    } else {
      yield put({
        type: actionTypes.BOOST_ALL_TECH_SHIFTS_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "boostAllTechShifts :: error" } });
    yield put({ type: UNSET_LOADER });
  }
}

function* getPendingReviewData(action) {
  try {
    const hospitalId = action.payload;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/hospital/shift/getPendingReviewData?hospitalId=${hospitalId}`
    );

    const responseData = yield response.json();

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.PENDING_REVIEW_DATA_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: actionTypes.PENDING_REVIEW_DATA_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "getPendingReviewData :: error" } });
    yield put({ type: UNSET_LOADER });
  }
}

function* getTechPendingReviewData(action) {
  try {
    const hospitalId = action.payload;

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/hospital/tech_shift/getTechPendingReviewData?hospitalId=${hospitalId}`
    );

    const responseData = yield response.json();

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.TECH_PENDING_REVIEW_DATA_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: actionTypes.TECH_PENDING_REVIEW_DATA_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "getTechPendingReviewData :: error " } });
    yield put({ type: UNSET_LOADER });
  }
}

function* updateHospitalTourStatus() {
  try {
    const hospitalId = localStorage.getItem("hospitalId");

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/hospital/updateHospitalTourStatus/${hospitalId}`
    );

    const responseData = yield response.json();

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.UPDATE_HOSPITAL_TOUR_STATUS_SUCCESS,
      });
    } else {
      yield put({
        type: actionTypes.UPDATE_HOSPITAL_TOUR_STATUS_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "updateHospitalTourStatus :: error " } });
    yield put({ type: UNSET_LOADER });
  }
}

function* showProfilePanelRequest(action) {
  const currentData = yield select(getProfilePanelData);
  if (
    currentData?.contractorId !== action.payload?.contractorId ||
    currentData?.shiftData?.shiftGroupId !== action.payload?.shiftData?.shiftGroupId ||
    currentData?.isVet !== action.payload?.isVet
  ) {
    yield put({ type: actionTypes.HIDE_PROFILE_PANEL });
    yield delay(0);
    yield put({ type: actionTypes.SHOW_PROFILE_PANEL, payload: action.payload });
  }
}

function* getPendingReviewsFlag() {
  try {
    const hospitalId = localStorage.getItem("hospitalId");

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/hospital/getPendingReviewsFlag/${hospitalId}`
    );

    const responseData = yield response.json();

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.GET_HOSPITAL_PENDING_REVIEWS_FLAG_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_HOSPITAL_PENDING_REVIEWS_FLAG_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "getPendingReviewsFlag :: error " } });
    yield put({
      type: actionTypes.GET_HOSPITAL_PENDING_REVIEWS_FLAG_FAIL,
    });
    yield put({ type: UNSET_LOADER });
  }
}

function* updatePendingReviewsFlag(action) {
  try {
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/hospital/updatePendingReviewsFlag`,
      {
        method: "POST",
        body: JSON.stringify(action.payload),
      }
    );
    const responseData = yield response.json();
    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.UPDATE_HOSPITAL_PENDING_REVIEWS_FLAG_SUCCESS,
        isShowPendingReviews: action.payload.isShowPendingReviews,
      });
    } else {
      yield put({
        type: actionTypes.UPDATE_HOSPITAL_PENDING_REVIEWS_FLAG_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "updateHospitalTourStatus :: error " } });
    yield put({
      type: actionTypes.UPDATE_HOSPITAL_PENDING_REVIEWS_FLAG_FAIL,
    });
    yield put({ type: UNSET_LOADER });
  }
}

function* updateRooUniCalloutStatus() {
  try {
    const userId = localStorage.getItem("userId");
    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/user/updateShowExternshipsCallout/${userId}`,
      { method: "POST" }
    );
    const responseData = yield response.json();
    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.UPDATE_ROO_UNI_CALLOUT_STATUS_SUCCESS,
      });
    } else {
      yield put({
        type: actionTypes.UPDATE_ROO_UNI_CALLOUT_STATUS_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "updateRooUniCalloutStatus :: error " } });
    yield put({ type: UNSET_LOADER });
  }
}

function* getInvoicedCustomerAsync() {
  try {
    const hospitalId = localStorage.getItem("hospitalId");

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/hospital/invoicedCustomer/${hospitalId}`
    );

    const responseData = yield response.json();
    yield put({ type: UNSET_LOADER });

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.GET_INVOICED_CUSTOMER_SUCCESS,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_INVOICED_CUSTOMER_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "getInvoicedCustomer :: error " } });
    yield put({ type: UNSET_LOADER });
  }
}

function* getHasPaymentFailedFlag() {
  try {
    const hospitalId = localStorage.getItem("hospitalId");

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL + `api/hospital/getHasPaymentFailedFlag/${hospitalId}`
    );

    const responseData = yield response.json();

    if (responseData.status_code === 200) {
      yield put({
        type: actionTypes.UPDATE_HAS_PAYMENT_FAILED_FLAG,
        payload: responseData.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_HAS_PAYMENT_FAILED_FLAG_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { context: "getHasPaymentFailedFlag :: error " } });
    yield put({
      type: actionTypes.GET_HAS_PAYMENT_FAILED_FLAG_FAIL,
    });
    yield put({ type: UNSET_LOADER });
  }
}

function* getRefundAmountForCancellationAsync(action) {
  try {
    const { shiftGroupId, shiftType } = action.payload;
    yield put({ type: SET_LOADER });

    const response = yield call(
      rooFetch,
      window.RooConfig.API_URL +
        `api/hospital/getRefundAmountForCancellation/${shiftType}/${shiftGroupId}`
    );

    yield put({ type: UNSET_LOADER });

    if (response.status === 200) {
      const responseData = yield response.json();
      yield put({
        type: actionTypes.GET_REFUND_AMOUNT_SUCCESS,
        payload: responseData,
      });
    } else {
      yield put({
        type: actionTypes.GET_REFUND_AMOUNT_FAIL,
      });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { context: "getRefundAmountForCancellation :: error " },
    });

    yield put({
      type: actionTypes.GET_REFUND_AMOUNT_FAIL,
    });
    yield put({ type: UNSET_LOADER });
  }
}

// eslint-disable-next-line no-restricted-exports
export default function* rootHospitalSaga() {
  yield takeEvery(actionTypes.FETCH_ADD_SHIFT_COMMON_DATA_REQUEST, fetchCommonDataAsync);
  yield takeEvery(actionTypes.FETCH_HOLIDAYS, getHolidays);
  yield takeEvery(actionTypes.CALCULATE_BULK_SHIFT_PRICING, calculateBulkShiftsPricing);
  yield takeEvery(actionTypes.ADD_SHIFT, addShiftAsync);
  yield takeEvery(
    actionTypes.GET_SHIFT_FULLFILLMENT_PREDICTION,
    getShiftFullfillmentPredictionAsync
  );
  yield takeEvery(actionTypes.FETCH_PREVIOUS_SHIFT_REQUEST, fetchPreviousShiftsAsync);
  yield takeEvery(
    actionTypes.GET_HOSPITAL_PENDING_VET_RATING_REQUEST,
    getPendingHospitalVetRatingsAsync
  );
  yield takeEvery(actionTypes.GET_VET_SHIFTS, getVetShiftsAsync);
  yield takeEvery(actionTypes.MOBILE_CONFIRM_VET_REQUEST, confirmVetRequestAsync);
  yield takeEvery(actionTypes.FETCH_SHIFT_DETAILS, getShiftDetailsAsync);
  yield takeEvery(actionTypes.EDIT_SHIFT, editShiftDetailsAsync);
  yield takeEvery(
    actionTypes.FETCH_SHIFT_COUNT_NOTIFICATION_REQUEST,
    fetchShiftCountForNotification
  );
  yield takeEvery(actionTypes.BULK_DELETE_SHIFTS_H, bulkDeleteShifts);
  yield takeEvery(actionTypes.BOOST_ALL_SHIFTS, boostAllShifts);
  yield takeEvery(actionTypes.PENDING_REVIEW_DATA, getPendingReviewData);
  yield takeEvery(actionTypes.UPDATE_HOSPITAL_TOUR_STATUS, updateHospitalTourStatus);
  yield takeEvery(actionTypes.UPDATE_ROO_UNI_CALLOUT_STATUS, updateRooUniCalloutStatus);
  // --- tech related
  yield takeEvery(actionTypes.GET_TECH_SHIFTS, getTechShiftsAsync);
  yield takeEvery(actionTypes.FETCH_ADD_TECH_SHIFT_REQUEST, addTechShiftAsync);
  yield takeEvery(actionTypes.FETCH_PREVIOUS_TECH_SHIFT_REQUEST, fetchPreviousTechShiftsAsync);
  yield takeEvery(
    actionTypes.GET_HOSPITAL_PENDING_TECH_RATING_REQUEST,
    getPendingHospitalTechRatingsAsync
  );
  yield takeEvery(actionTypes.HOSPITAL_CONFIRM_TECH_REQUEST, confirmTechRequestAsync);
  yield takeEvery(actionTypes.FETCH_TECH_SHIFT_DETAILS_FOR_EDIT, getTechShiftDetailsAsync);
  yield takeEvery(actionTypes.EDIT_TECH_SHIFT_REQUEST, editTechShiftDetailsAsync);
  yield takeEvery(
    actionTypes.FETCH_TECH_SHIFT_COUNT_NOTIFICATION_REQUEST,
    fetchTechShiftCountForNotification
  );
  yield takeEvery(actionTypes.BOOST_ALL_TECH_SHIFTS, boostAllTechShifts);
  yield takeEvery(actionTypes.TECH_PENDING_REVIEW_DATA, getTechPendingReviewData);

  // --- end tech related
  yield takeEvery(actionTypes.DOWNLOAD_PDF_REQUEST, downloadPDFAsync);
  yield takeEvery(actionTypes.GET_AUTO_CONFIRM_CONTRACTOR_LIST, getAutoConfirmContractorListAsync);
  yield takeEvery(actionTypes.ADD_PROVIDER_TO_AUTO_CONFIRM, addProviderToAutoConfirm);
  yield takeEvery(actionTypes.SAVE_FUTURE_AUTO_CONFIRM, saveFutureAutoConfirmAsync);
  yield takeEvery(actionTypes.DELETE_FUTURE_AUTO_CONFIRM, deleteFutureAutoConfirmAsync);
  yield takeEvery(actionTypes.DISABLE_FUTURE_AUTO_CONFIRM, disableFutureAutoConfirmAsync);
  yield takeEvery(actionTypes.ENABLE_FUTURE_AUTO_CONFIRM, enableFutureAutoConfirmAsync);
  yield takeEvery(actionTypes.GET_BLOCKED_CONTRACTOR_LIST, getBlockedContractorListAsync);
  yield takeEvery(actionTypes.REMOVE_BLOCKED_CONTRACTOR, removeBlockedContractorAsync);
  yield takeEvery(actionTypes.BLOCK_CONTRACTOR, blockContractorAsync);
  yield takeEvery(
    actionTypes.HOSPITAL_TRIGGERED_REMOVE_CONFIRMED_TECH_REQUEST,
    removeConfirmedTech
  );

  yield takeEvery(actionTypes.SUBMIT_OVERTIME, submitOvertimeResponse);
  yield takeEvery(actionTypes.BOOST_SHIFT, boostShift);

  yield takeEvery(actionTypes.HOSPITAL_RESPONSE_RATING_DATA, getHospitalResponseRatingDataAsync);
  yield takeEvery(actionTypes.HOSPITAL_RESPONSE_RATING_COMMENTS, getHospitalRatingCommentsAsync);
  yield takeEvery(actionTypes.HOSPITAL_RESPONSE_RATING, getHospitalResponseRating);
  yield takeEvery(
    actionTypes.UPDATE_HOSPITAL_RESPONSE_RATING_STATUS,
    updateHospitalResponseRatingStatus
  );
  yield takeEvery(actionTypes.GET_THREE_MONTHS_HOSPITAL_SHIFTS, getThreeMonthsHospitalShifts);
  yield takeEvery(actionTypes.SHOW_PROFILE_PANEL_REQUEST, showProfilePanelRequest);
  yield takeEvery(actionTypes.GET_HOSPITAL_PENDING_REVIEWS_FLAG, getPendingReviewsFlag);
  yield takeEvery(actionTypes.UPDATE_HOSPITAL_PENDING_REVIEWS_FLAG, updatePendingReviewsFlag);
  yield takeEvery(
    actionTypes.CREATE_HOSPITAL_INVOICED_CUSTOMER,
    createHospitalInvoicedCustomerAsync
  );
  yield takeEvery(actionTypes.GET_HAS_PAYMENT_FAILED_FLAG_REQUEST, getHasPaymentFailedFlag);

  yield takeEvery(actionTypes.GET_INVOICED_CUSTOMER, getInvoicedCustomerAsync);
  yield takeEvery(actionTypes.GET_REFUND_AMOUNT, getRefundAmountForCancellationAsync);
}
