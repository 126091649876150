import React from "react";

export interface ModalComponentProps {
  showModal: boolean;
  modalBody: React.ReactNode;
  onCloseHandler?: (e: any) => void;
  title?: string;
  smModal?: boolean;
  lgModalWidth?: boolean;
  noHorizontalBodyScroll?: boolean;
  smModalWidth?: boolean;
  smModalHeight?: boolean;
  dNoneClose?: boolean;
  closeBtnClass?: string;
  modalClass?: string;
  addMarginClass?: string;
  modalContentClass?: string;
  modalDialogClass?: string;
  isEventClass?: string;
  isDisableOutsideClick?: boolean;
  lgModalHeight?: boolean;
  modalContainerStyle?: React.CSSProperties;
  noOverlay?: boolean;
  turnOffDefaultHeight?: boolean;
}

// eslint-disable-next-line no-restricted-exports
export default function ModalComponent({
  showModal,
  modalBody,
  onCloseHandler,
  smModal,
  lgModalWidth,
  noHorizontalBodyScroll,
  smModalWidth,
  smModalHeight,
  dNoneClose,
  closeBtnClass = "",
  modalClass = "",
  addMarginClass = "",
  modalContentClass = "",
  modalDialogClass = "",
  isEventClass,
  isDisableOutsideClick,
  lgModalHeight,
  modalContainerStyle = {},
  turnOffDefaultHeight = false,
}: ModalComponentProps) {
  const handleClose = onCloseHandler || undefined;

  const defaultHeightStyle = {
    height: "auto",
    minHeight: "auto",
    maxHeight: "auto",
    overflow: "initial",
  };
  return (
    <div
      className={`modal fade ${smModal ? "sm-modal " : ""} ${
        showModal ? "show mt-5" : ""
      } ${addMarginClass} ${modalClass}`}
      style={showModal ? { display: "block" } : { display: "none" }}
      data-testid={`${showModal ? "modalBody" : ""}`}
    >
      <div className="overlay" onClick={isDisableOutsideClick ? undefined : handleClose}></div>
      <div
        className={`modal-dialog ${turnOffDefaultHeight ? "" : "modal-dialog-centered"} ${
          lgModalWidth ? "lg-model" : ""
        } ${smModalWidth ? "sm-width" : ""} ${smModalHeight ? "sm-modal-height" : ""} ${
          lgModalHeight ? "lg-modal-height" : ""
        } ${modalDialogClass}`}
        style={turnOffDefaultHeight ? defaultHeightStyle : {}}
        role="document"
      >
        <div className={`modal-content ${modalContentClass} ${isEventClass}`}>
          <div className="modal-header h-auto">
            <button
              type="button"
              className={`close ${dNoneClose ? "d-none" : ""} ${closeBtnClass}`}
              data-dismiss="modal"
              onClick={handleClose}
              aria-label="Close"
              data-testid="closeModalBtn"
            ></button>
          </div>
          <div
            className={`modal-body ${noHorizontalBodyScroll ? "no-scroll-body" : ""}`}
            style={modalContainerStyle}
            data-testid="modalMsg"
          >
            <React.Fragment>{modalBody}</React.Fragment>
          </div>
        </div>
      </div>
    </div>
  );
}
