import { useEffect } from "react";

// toggles the visibility of the widget
export function toggleZendeskWidget(show: boolean) {
  if (window.zE) {
    window.zE("messenger", show ? "show" : "hide");
  }
}

// expands the widget
export function openZendeskChat() {
  if (window.zE) {
    window.zE("messenger", "show");
    window.zE("messenger", "open");
  }
}

export const useShowZendeskWidget = (show: boolean) => {
  useEffect(() => {
    toggleZendeskWidget(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
