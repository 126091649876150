import React from "react";

import { Color, grayBackground } from "../../styles/constants";

import { DividerContainer } from "./DividerStyles";

export interface DividerProps {
  color?: Color;
}

export const Divider = ({ color = grayBackground }: DividerProps) => {
  return <DividerContainer color={color} />;
};
