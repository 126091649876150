import style from "styled-components";
import {
  grayBackground,
  grayWhite,
  mediumMediaBreakpoint,
  smallMediaBreakpoint,
  spacingL,
  spacingS,
  spacingXS,
  spacingXXL,
} from "@RooUI";

export const PageContainer = style.div`
  background-color: ${grayWhite} !important;
  padding-top: 60px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  .site-header {
    box-shadow: 0px ${spacingXS} ${spacingS} 0px ${grayBackground};
  }
  margin-bottom: ${spacingXXL};
  overflow: unset !important;
  

  @media (max-width: ${smallMediaBreakpoint}) {
    margin-bottom: 100px;
  }
`;

export const Container = style.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${spacingXXL};
  gap: ${spacingXXL};
`;

export const Content = style.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingXXL};
  width: 100%;
  max-width: 1176px;
  padding-left: ${spacingXXL};
  padding-right: ${spacingXXL};
  
  @media (max-width: ${mediumMediaBreakpoint}) {
    width: 100%;
  }
`;

export const Header = style.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacingL};  
`;

export const Subheader = style.div`
  @media (min-width: ${mediumMediaBreakpoint}) {
    display: none
  }
`;

export const ButtonContainer = style.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
  @media (max-width: ${mediumMediaBreakpoint}) {
    align-self: unset;
    width: 100%;
    margin-top: ${spacingL}
  }
`;
