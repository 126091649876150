import { Dayjs as DayjsType } from "dayjs";

const Q1 = [0, 1, 2];
const Q2 = [3, 4];
const Q3 = [5, 6, 7];
// Keeping for reference? const Q4 = [8, 9, 10, 11];

export const TaxQuarters = {
  subtract(selectedDate: DayjsType) {
    const selectedMonth = selectedDate.month();
    const selectedYear = selectedDate.year();

    let newMonth;
    let newYear = selectedYear;

    if (Q1.includes(selectedMonth)) {
      newMonth = 8;
      newYear -= 1;
    } else if (Q2.includes(selectedMonth)) {
      newMonth = 0;
    } else if (Q3.includes(selectedMonth)) {
      newMonth = 3;
    } else {
      newMonth = 5;
    }
    return selectedDate.month(newMonth).year(newYear);
  },

  add(selectedDate: DayjsType) {
    const selectedMonth = selectedDate.month();
    const selectedYear = selectedDate.year();

    let newMonth;
    let newYear = selectedYear;

    if (Q1.includes(selectedMonth)) {
      newMonth = 3;
    } else if (Q2.includes(selectedMonth)) {
      newMonth = 5;
    } else if (Q3.includes(selectedMonth)) {
      newMonth = 8;
    } else {
      newMonth = 0;
      newYear += 1;
    }
    return selectedDate.month(newMonth).year(newYear);
  },

  getTaxDate(selectedDate: DayjsType) {
    const selectedMonth = selectedDate.month();

    let newMonth;

    if (Q1.includes(selectedMonth)) {
      newMonth = 0;
    } else if (Q2.includes(selectedMonth)) {
      newMonth = 3;
    } else if (Q3.includes(selectedMonth)) {
      newMonth = 6;
    } else {
      newMonth = 9;
    }
    return selectedDate.month(newMonth);
  },
};
