import style from "styled-components";

import { clickableShadowStyle, grayLight50, grayWhite } from "../../styles/constants";

interface ContainerProps {
  clickable?: boolean;
  nested?: boolean;
  backgroundColor?: string;
  borderColor?: string;
}

export const Container = style.div<ContainerProps>`
  padding: 24px 20px;
  background-color: ${(props) => props.backgroundColor ?? grayWhite};
  border-radius: 20px;
  margin-bottom: ${(props) => (props.nested ? 0 : 20)}px;
  border: ${(props) => {
    if (props.borderColor) {
      return `2px solid ${props.borderColor}`;
    } else if (props.clickable) {
      return "none";
    } else {
      return `2px solid ${grayLight50}`;
    }
  }};
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};

  box-shadow: ${(props) => {
    if (props.nested) {
      return "none";
    } else if (props.clickable) {
      return clickableShadowStyle;
    } else {
      return "none";
    }
  }};
`;
