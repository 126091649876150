import * as Sentry from "@sentry/react";
import io from "socket.io-client";

export enum SocketSource {
  APP_INIT = "app_init",
  LOGIN_SUCCESS = "login_success",
}

export const initSocketConnection = async (source: SocketSource) => {
  try {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      const socket = io(window.RooConfig.MESSAGING_SOCKET_URL, {
        auth: { token },
        transports: ["websocket", "polling"],
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        withCredentials: true,
      });
      socket.on("connect_error", (error) => {
        Sentry.captureException(error, {
          extra: { context: "Error connecting to socket. Falling back to polling.", token: token },
        });
        socket.io.opts.transports = ["polling", "websocket"];
      });
      socket.on("disconnect", (reason) => {
        if (reason === "io server disconnect") {
          // the disconnection was initiated by the server, you need to reconnect manually
          socket.connect();
        }
      });
      window.socket = socket;
      const reconnect = () => {
        if (!document.hidden) {
          socket.connect();
        } else {
          document.removeEventListener("visibilitychange", reconnect);
        }
      };
      document.addEventListener("visibilitychange", reconnect);
    } else if (source === SocketSource.LOGIN_SUCCESS) {
      throw new Error("Initiating socket after login succeeded, but no token found");
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};
